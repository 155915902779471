import React from 'react';
import DefaultLayout from "./default.layout";
import PropTypes from 'prop-types';
import ClearLayout from "./clear.layout";
import HomeDefaultLayout from "./home-default.layout";
import ProfileLayout from './profile.layout'
import FormBuilderLayout from "./form-builder.layout";
import Grid from "@material-ui/core/Grid";

const AppLayout = ({layout, toolbarPosition, children, showBackButton}) => {
    switch (layout) {
        case "clear":
            return (
                <ClearLayout toolbarPosition={toolbarPosition}>
                    {children}
                </ClearLayout>
            );
        case "form-builder":
            return (
                <FormBuilderLayout toolbarPosition={toolbarPosition}>
                    {children}
                </FormBuilderLayout>
            );
        case "default":
            return (
                <DefaultLayout toolbarPosition={toolbarPosition}>
                    {children}
                </DefaultLayout>
            );
        case "home-default":
            return (
                <HomeDefaultLayout toolbarPosition={toolbarPosition}>
                    {children}
                </HomeDefaultLayout>
            );
        case "profile":
            return (
                <ProfileLayout toolbarPosition={toolbarPosition} showBackButton={showBackButton}>
                    {children}
                </ProfileLayout>
            );
        default:
            return (
                <Grid container direction={"column"} justify={"center"}
                      style={{position: 'relative', minHeight: '100vh'}}>
                    <Grid item>
                        {children}
                    </Grid>
                </Grid>
            );
    }
};

AppLayout.defaultProps = {
    layout: 'default',
    toolbarPosition: 'absolute'
};

AppLayout.propTypes = {
    layout: PropTypes.oneOf(['default', 'profile', 'clear', 'form-builder', 'no-toolbar', 'super-admin', 'home-default']),
    toolbarPosition: PropTypes.string
};


export default AppLayout;
