import {MODE_VIEW_TEMPLATE} from "../../../index.constants";
import {generateID} from "../../../index.utils";
import FormItem from "../form-item.class";

export default class FileInputClass extends FormItem {
    constructor({displayName, name, viewMode, options, ...props}) {
        super(props);
        this.name = name || generateID(9);
        this.isEmpty = false;
        this.viewMode = viewMode || MODE_VIEW_TEMPLATE;
        this.type = 'file-input';
        this.options = options || [
            {
                label: 'File (excel, pdf, doc, ...)',
                value: 'application/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .pdf, .zip, .rar, .csv',
                checked: false
            },
            {
                label: 'Immagini (png, jpeg, gif)',
                value: 'image/*',
                checked: false
            }
        ];
        this.displayName = displayName || 'File Upload';
    }
}
