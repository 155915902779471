import React from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import useForm from "react-hook-form";
import {TextField} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import AuthService from "../../services/auth.service";
import {connect} from "react-redux";
import {handleToast} from "../../redux/actions";
import {getRegistryLabel} from "../../constant";

const ProfilePage = ({dispatch}) => {
    const {handleSubmit, errors, watch, setValue, register, reset} = useForm();
    const changePassword = async ({current_password, new_password}) => {
        try {
            var apiResult = await AuthService.changePassword({
                current_password,
                new_password
            });
            console.log(apiResult);
            // dispatch(handleToast({message: 'Password aggiornata con successo'}));
            dispatch(handleToast({message: apiResult.message}));
            reset();
        } catch (message) {
            dispatch(handleToast({message}))
        }
    };
    const account = AuthService.user.utente.accounts;
    const samePassword = (value) => value === watch('new_password') ? true : 'Le due password non coincidono';    
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Impostazioni Profilo ' + AuthService.user.utente.accounts.acc_name}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item container direction={"column"} spacing={1} alignItems={"center"}
                              style={{marginLeft: '2rem'}}>
                            {Object.keys(account).map((key, index) => {
                                return account[key] && getRegistryLabel(key) ? (
                                    <Grid item container spacing={2} key={`summary-${index}`}>
                                        <Grid item>
                                            <Typography variant={"subtitle2"}>{getRegistryLabel(key)}:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={"body1"}>{account[key]}</Typography>
                                        </Grid>
                                    </Grid>
                                ) : ''
                            })}
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Cambia password'}/>
                    </Grid>
                    <Grid item xs={10}>
                        <form onSubmit={handleSubmit(changePassword)}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField label={'Password corrente'}
                                               onChange={(e) => setValue('current_password', e.target.value)}
                                               inputRef={register({required: 'campo obbligatorio'})}
                                               type={'password'} name={'current_password'}
                                               error={!!errors['current_password']} fullWidth/>
                                    {errors['current_password'] &&
                                    <Typography variant={"caption"} color={"error"}>
                                        {errors['current_password'].message}
                                    </Typography>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={'Nuova password'}
                                               inputRef={register({required: 'campo obbligatorio'})}
                                               type={'password'} name={'new_password'}
                                               onChange={(e) => setValue('new_password', e.target.value)}
                                               error={!!errors['new_password']} fullWidth/>
                                    {errors['new_password'] &&
                                    <Typography variant={"caption"} color={"error"}>
                                        {errors['new_password'].message}
                                    </Typography>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={'Ripeti nuova password'}
                                               inputRef={register({name: 'repeatPassword'}, {validate: samePassword})}
                                               type={'password'} name={'repeatPassword'}
                                               onChange={(e) => setValue('repeatPassword', e.target.value)}
                                               error={!!errors['repeatPassword']} fullWidth/>
                                    {errors['repeatPassword'] &&
                                    <Typography variant={"caption"} color={"error"}>
                                        {errors['repeatPassword'].message}
                                    </Typography>}
                                </Grid>
                                <Grid item container justify={"center"}>
                                    <Fab color={"primary"} type={'submit'} variant={"extended"}>
                                        Modifica password
                                    </Fab>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default connect()(ProfilePage);
