import {FETCH_HANDLER} from "../types";

const fetchApiReducer = (state = {}, {type}) => {
    switch (type) {
        case `${FETCH_HANDLER}`:
            return {
                ...state,
                fetch: !state.fetch
            };
        default:
            return state;
    }
};

export default fetchApiReducer;
