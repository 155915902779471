import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Card, CardContent, CardHeader, Checkbox, CircularProgress, Fab, FormControlLabel, FormGroup, Grid, Icon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

import { ProtocolApi, RiskAreaMatrixApi } from '../../../services/api.service'
import { orderBy } from 'lodash'
import { useFormContext } from 'react-hook-form'
import RiskAssessmentMatrixProtocolliFilters from './risk-assessment-matrix-protocolli-filters'
import Popover from '../../ui/popover/popover'

const RiskAreaMatrix = new RiskAreaMatrixApi()
const Protocols = new ProtocolApi()
const RiskAssessmentMatrixProtocolli = ({ dispatch, onSave }) => {
  const { watch, setValue, register } = useFormContext()

  const [loading, setLoading] = useState(false)
  const [riskAreas, setRiskAreas] = useState(null)
  const [protocols, setProtocols] = useState([])

  const selectedArea = useMemo(() => {
    return riskAreas?.find(({ uuid }) => uuid === watch('risk_area'))
  }, [riskAreas, watch('risk_area')])

  const filters = useMemo(() => {
    return watch('protocolsFilters')
  }, [watch('protocolsFilters')])

  const filteredProtocols = useMemo(() => {
    return protocols
      .filter(item => !!item.pro_attivo)
      .filter((item) => {
        return (filters.generico ? item.pro_specifico == 0 : true) &&
          (filters.specifico ? item.pro_specifico == 1 : true) &&
          (filters.pro_formalizzato ? !!item.pro_formalizzato : true) &&
          (filters.pro_operativo ? !!item.pro_operativo : true) &&
          (filters.pro_procedure ? !!item.pro_procedure : true) &&
          (filters.pro_segregazione ? !!item.pro_segregazione : true) &&
          (filters.pro_poteri ? !!item.pro_poteri : true) &&
          (filters.pro_monitoraggio ? !!item.pro_monitoraggio : true) &&
          (filters.pro_tracciabilita ? !!item.pro_tracciabilita : true)
      })
  }, [protocols, filters])

  const isProtocolPreSelected = useCallback((protocol) => {
    const ids = watch('selectedProtocols')
    return !!ids?.includes(protocol.value)
  }, [watch('selectedProtocols')]) 

  const onCheckProtocol = useCallback((checked, protocol) => {
    const ids = watch('selectedProtocols')

    return checked
      ? setValue('selectedProtocols', [...ids, protocol.value])
      : setValue('selectedProtocols', ids.filter((id) => id !== protocol.value))
  }, [watch('selectedProtocols')])

  const fetchData = useCallback(async () => {
    setLoading(true)

    let riskAreas = await RiskAreaMatrix.query({
      matrice: 'protocolli'
    })

    riskAreas = riskAreas.map(area => ({
      ...area,
      aree_rischio_protocolli: area.aree_rischio_protocolli.filter(area_protocollo => !!area_protocollo.protocolli)
    }))

    let protocolli = await Protocols.query()
      
    protocolli = protocolli.map(({ uuid, pro_titolo, ...rest }) => ({
      ...rest,
      label: pro_titolo,
      value: uuid,
    }))

    setProtocols(orderBy(protocolli, ['label'], ['asc']))
    setRiskAreas(riskAreas)

    setLoading(false)
  }, [])

  const onClickSave = useCallback(async () => {
    await onSave()
    await fetchData()
  }, [onSave, fetchData])

  useEffect(() => {
    register({ name: 'selectedProtocols' })
  }, [])

  useEffect(() => {
    const protocols_ids = selectedArea?.aree_rischio_protocolli?.map((protocol) => protocol?.protocolli?.uuid) ?? []
    setValue('selectedProtocols', protocols_ids ?? [])
  }, [selectedArea])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (loading) return (
    <Grid item style={{minWidth: '100%', textAlign: 'center'}}>
      <CircularProgress size={150} />
    </Grid>
  )

  return (
    <Grid container justify={"center"}>
      <Grid item xs={12} md={10} lg={8}>
        <RiskAssessmentMatrixProtocolliFilters />
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Protocollo</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'rgba(205, 250, 219, .5)' }}>GE.</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'rgba(205, 250, 219, .5)' }}>SP.</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'rgba(246, 253, 195, .5)' }}>PR.</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'rgba(246, 253, 195, .5)' }}>SE.</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'rgba(246, 253, 195, .5)' }}>PO.</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'rgba(246, 253, 195, .5)' }}>MO.</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'rgba(246, 253, 195, .5)' }}>TR.</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'rgba(255, 207, 150, .5)' }}>FO.</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'rgba(255, 207, 150, .5)' }}>OP.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProtocols?.map((protocol) => (
                <TableRow
                  key={protocol.value}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!selectedArea}
                          checked={isProtocolPreSelected(protocol)}
                          onChange={(e) => onCheckProtocol(e.target.checked, protocol)}
                        />
                      }
                      label={protocol.label}
                    />
                  </TableCell>
                  <TableCell align={"center"} style={{ backgroundColor: 'rgba(205, 250, 219, .5)' }}>
                    <Popover title="Generico">
                      {!protocol.pro_specifico ?
                        <Icon style={{color: '#27ae60'}}>done</Icon> :
                        <Icon color={"error"}>block</Icon>}
                    </Popover>
                  </TableCell>
                  <TableCell align={"center"} style={{ backgroundColor: 'rgba(205, 250, 219, .5)' }}>
                    <Popover title="Specifico">
                      {protocol.pro_specifico ?
                        <Icon style={{color: '#27ae60'}}>done</Icon> :
                        <Icon color={"error"}>block</Icon>}
                    </Popover>
                  </TableCell>
                  <TableCell align={"center"} style={{ backgroundColor: 'rgba(246, 253, 195, .5)' }}>
                    <Popover title="Procedure">
                      {protocol.pro_procedure ?
                        <Icon style={{color: '#27ae60'}}>done</Icon> :
                        <Icon color={"error"}>block</Icon>}
                    </Popover>
                  </TableCell>
                  <TableCell align={"center"} style={{ backgroundColor: 'rgba(246, 253, 195, .5)' }}>
                    <Popover title="Segregazione">
                      {protocol.pro_segregazione ?
                        <Icon style={{color: '#27ae60'}}>done</Icon> :
                        <Icon color={"error"}>block</Icon>}
                    </Popover>
                  </TableCell>
                  <TableCell align={"center"} style={{ backgroundColor: 'rgba(246, 253, 195, .5)' }}>
                    <Popover title="Poteri">
                      {protocol.pro_poteri ?
                        <Icon style={{color: '#27ae60'}}>done</Icon> :
                        <Icon color={"error"}>block</Icon>}
                    </Popover>
                  </TableCell>
                  <TableCell align={"center"} style={{ backgroundColor: 'rgba(246, 253, 195, .5)' }}>
                    <Popover title="Monitoraggio">
                      {protocol.pro_monitoraggio ?
                        <Icon style={{color: '#27ae60'}}>done</Icon> :
                        <Icon color={"error"}>block</Icon>}
                    </Popover>
                  </TableCell>
                  <TableCell align={"center"} style={{ backgroundColor: 'rgba(246, 253, 195, .5)' }}>
                    <Popover title="Tracciabilità">
                      {protocol.pro_tracciabilita ?
                        <Icon style={{color: '#27ae60'}}>done</Icon> :
                        <Icon color={"error"}>block</Icon>}
                    </Popover>
                  </TableCell>
                  <TableCell align={"center"} style={{ backgroundColor: 'rgba(255, 207, 150, .5)' }}>
                    <Popover title="Formalizzato">
                      {protocol.pro_formalizzato ?
                        <Icon style={{color: '#27ae60'}}>done</Icon> :
                        <Icon color={"error"}>block</Icon>}
                    </Popover>
                  </TableCell>
                  <TableCell align={"center"} style={{ backgroundColor: 'rgba(255, 207, 150, .5)' }}>
                    <Popover title="Operativo">
                      {protocol.pro_operativo ?
                        <Icon style={{color: '#27ae60'}}>done</Icon> :
                        <Icon color={"error"}>block</Icon>}
                    </Popover>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Fab
          onClick={onClickSave}
          style={{
            position: 'fixed',
            right: '70px',
            top: '335px',
            width: '120px',
          }}
          aria-label={'Salva'}
          color={'secondary'}
          variant={"extended"}
          disabled={!selectedArea}
        >
          Salva
        </Fab>
      </Grid>
    </Grid>
  )
}

export default connect()(RiskAssessmentMatrixProtocolli)
