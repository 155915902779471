import React, { useCallback, useMemo } from "react";
import { Grid, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import DiscreteSlider from "../ui/discrete-slider/discrete-slider.component";
import { Radar } from "react-chartjs-2";
import { getBiggerValue } from "../../functions/insert-risk-assessment.helper";
import "../../constants/styles";
import { options } from "../../constants/graph";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { connect } from "react-redux";
import { handleModal } from "../../redux/actions";

const Step0 = ({ dispatch, reato, impatto, setImpatto, handleSaveStep, disabled = false }) => {
  const data = {
    labels: ["Economico", "Operativo", "Immagine"],
    datasets: [
      {
        label: "IMPATTO",
        data: [impatto.economico, impatto.operativo, impatto.immagine_rep],
        backgroundColor: ["rgba(116,186,248,0.5)"],
        borderColor: ["rgba(116,186,248,1)"],
      },
    ],
  };

  const economicValue = useMemo(() => {
    if (!reato) return 1
    
    const { rea_sanzione_amministrativa_max } = reato
    const value = parseInt(rea_sanzione_amministrativa_max ?? 0)

    if (value <= 250) return 1
    if (value >= 251 && value <= 500) return 2
    if (value >= 501 && value <= 750) return 3
    if (value >= 751 && value <= 950) return 4
    if (value >= 951) return 5
  }, [reato])

  const operativeValue = useMemo(() => {
    if (!reato) return 1
    
    const { reati231_sanz_interd } = reato
    const value = reati231_sanz_interd?.length ?? 0

    if (value < 4) return value + 1
    if (value >= 4) return 5
  }, [reato])

  const handleProposta = useCallback(() => {
    return dispatch(handleModal({
      open: true,
      maxWidth: 'sm',
      title: 'Proposta Risk Assessment',
      content: <>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>IMPATTO</TableCell>
                  <TableCell>Valore proposto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Economico</TableCell>
                  <TableCell>{economicValue}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Operativo</TableCell>
                  <TableCell>{operativeValue}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <Button
              style={{ fontWeight: "bold", marginTop: 20 }}
              onClick={() => {
                setImpatto({ ...impatto, economico: economicValue, operativo: operativeValue })
                dispatch(handleModal({ open: false }))
              }}
              color={"secondary"}
              variant={"contained"}
            >
              Applica
            </Button>
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
      </>,
    }));
  }, [economicValue, operativeValue])
  
  return (
    <Grid container className={"stepContainer"} xs={12}>
      <Grid container className={"riskValutazioneContent"} xs={12}>
        <Grid xs={12} item>
          <Typography variant={"subtitle1"}>
            Seleziona, oppure inserisci i valori da 1 (min) a 5 (max)
          </Typography>
        </Grid>
        <Grid xs={12} md={6} style={{ marginTop: "2em" }} item>
          <DiscreteSlider
            disabled={disabled}
            value={impatto.economico}
            setValue={(economico) =>
              setImpatto({
                ...impatto,
                economico,
              })
            }
            label={"Economico"}
          />
          <DiscreteSlider
            disabled={disabled}
            value={impatto.operativo}
            setValue={(operativo) =>
              setImpatto({
                ...impatto,
                operativo,
              })
            }
            label={"Operativo"}
          />
          <DiscreteSlider
            disabled={disabled}
            value={impatto.immagine_rep}
            setValue={(immagine_rep) =>
              setImpatto({
                ...impatto,
                immagine_rep,
              })
            }
            label={"Immagine e reputazione"}
          />
        </Grid>
        <Grid xs={12} md={6} className={"graphContainer"} item>
          <Radar options={options} data={data} />
        </Grid>
      </Grid>
      <Grid
        container
        className={"riskValutazioneFooter"}
        style={{ borderTop: "1px solid #AAAAAA", paddingTop: "2rem" }}
        xs={12}
      >
        <Grid xs={6} md={6} container>
          <Grid item>
            <Typography style={{ fontWeight: "bold"}} variant={"h6"}>
              VALORE MASSIMO:
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 20 }}>
            <Typography
              style={{ fontWeight: "bold"}}
              variant={"h5"}
              color={"primary"}
            >
              {getBiggerValue([
                impatto.economico,
                impatto.operativo,
                impatto.immagine_rep,
              ])}
              ,00
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={6} md={6} container style={{ justifyContent: "flex-end" }}>
          <Grid item>
            <Button
              style={{ fontWeight: "bold", marginRight: "15px" }}
              onClick={handleProposta}
              color={"primary"}
              variant={"outlined"}
              disabled={disabled}
            >
              Proposta
            </Button>
            <Button
              style={{ fontWeight: "bold" }}
              onClick={handleSaveStep}
              color={"primary"}
              variant={"contained"}
            >
              {disabled ? "prosegui" : "Salva e prosegui"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(null)(Step0)
