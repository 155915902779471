import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import {Breadcrumbs, FormControl, FormLabel, lighten, Radio, RadioGroup, TextField} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";
import {TableContainer, Table, TableHead, TableRow, TableBody} from "@material-ui/core";
import TableCell from '@material-ui/core/TableCell';
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import {handleModal, handleToast} from "../../redux/actions";
import CodiceEticoNewComponent from "../../components/CRUD/filesystem/codice-etico-new.component";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ValutazioniNewComponent from "../../components/CRUD/filesystem/valutazioni-new.component";
import {FilesystemApi} from "../../services/api.service";
import ValutazioniMediaComponent from "../../components/CRUD/filesystem/valutazioni-media.component";
import GeneraliNewComponent from "../../components/CRUD/filesystem/generali-new.component";
import GeneraliMediaComponent from "../../components/CRUD/filesystem/generali-media.component";

const FSApi = new FilesystemApi();

function createData(name, enctype, size, nice_last_update, uuid, full_mime_type, file_name, timestamp) {
    return { name, enctype, size, nice_last_update, uuid, full_mime_type, file_name, timestamp };
}

function descendingComparator(a, b, orderBy) {

    // Ordering by timestamp if needed
    if (orderBy === 'last_update') {
        orderBy = 'timestamp';
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nome File', align: 'left' },
    { id: 'enctype', numeric: false, disablePadding: false, label: 'Formato', align: 'right'  },
    { id: 'size', numeric: true, disablePadding: false, label: 'Dimensione (MB)', align: 'right'  },
    { id: 'last_update', numeric: true, disablePadding: false, label: 'Ultimo update', align: 'right'  },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Azioni', align: 'right'  },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align ? headCell.align : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        backgroundColor: "#F5F5F5",
        borderBottom: "2px solid !important",
        color: theme.palette.primary.main,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        color: theme.palette.text.primary,
        flex: '1 1 100%',
    },
    toolbarIcon: {
        color: theme.palette.primary.main
    },
    folderToolbarIcon: {
        color: theme.palette.primary.main,
        marginRight: "1em"
    },
    buttonUpload: {
        width: "200px"
    }
}));

const DownloadModal = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                b
            </Grid>
            <Grid item xs={12}>
                a
            </Grid>
        </Grid>
    );
};

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { dispatch, numSelected } = props;

    const openDownloadModal = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Carica un nuovo file',
            content: <GeneraliNewComponent />,
        }));
    };

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                <Icon className={classes.folderToolbarIcon}>
                    folder
                </Icon>
                Generale
            </Typography>

            <Tooltip title="Carica un nuovo Documento">
                <Button onClick={openDownloadModal} variant={"outlined"} color={"primary"} className={classes.buttonUpload}>
                    <Icon className={classes.toolbarIcon}>
                        upload
                    </Icon> Carica File
                </Button>
            </Tooltip>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        marginBottom: '1em',
        marginTop: '0em'
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
}));

const GeneralePage = ({dispatch, history}) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('last_update');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = React.useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Base Call
    React.useEffect(() => {
        const fetchData = async () => {
            const fetchExports = await FSApi.fullGeneraleFS();
            let tmpRows = [];
            for(let i = 0; i < fetchExports.length; i++) {
                tmpRows.push(createData(
                    fetchExports[i].name,
                    fetchExports[i].mime_type,
                    fetchExports[i].size,
                    fetchExports[i].nice_last_update,
                    fetchExports[i].uuid,
                    fetchExports[i].full_mime_type,
                    fetchExports[i].file_name,
                    fetchExports[i].timestamp
                ));
            }
            setRows(tmpRows);
        };
        fetchData();
    }, []);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const downloadMedia = async (media) => {
        await FSApi.downloadMedia(media.uuid, media.file_name);
    };

    const deleteMedia = async (media) => {
        if ( window.confirm('Confermi di voler eliminare il media?') ) {
            try {
                await FSApi.deleteGeneraliMedia(media.uuid);
                const fetchExports = await FSApi.fullGeneraleFS();
                dispatch(handleToast({message: 'Documento correttamente eliminato'}));
                let tmpRows = [];
                for (let i = 0; i < fetchExports.length; i++) {
                    tmpRows.push(createData(
                        fetchExports[i].name,
                        fetchExports[i].mime_type,
                        fetchExports[i].size,
                        fetchExports[i].nice_last_update,
                        fetchExports[i].uuid,
                        fetchExports[i].full_mime_type,
                        fetchExports[i].file_name,
                        fetchExports[i].timestamp
                    ));
                }
                setRows(tmpRows);
            } catch ( e ) {
                dispatch(handleToast({message:e}));
            }

        }
    };

    const updateMedia = (media) => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Modifica File',
            content: <GeneraliMediaComponent media={media} />,
        }));
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} justify={"center"} style={{paddingLeft: '3rem', paddingRight: '3rem', marginTop: '0'}}>
                
                <Grid item xs={12}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb" component={"div"}>
                        <Link underline="hover" color="inherit" href="/filesystem">
                            Repository
                        </Link>
                        <Typography color="textPrimary">Generale</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <EnhancedTableToolbar dispatch={dispatch} numSelected={selected.length} />
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                    {stableSort(rows, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.name);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.uuid}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell component="th" id={labelId} scope="row" padding="default">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title={row.full_mime_type} classes={{ tooltip: classes.noMaxWidth }}>
                                                            <Typography>{row.enctype}</Typography>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right">{row.size}</TableCell>
                                                    <TableCell align="right">{row.nice_last_update}</TableCell>
                                                    <TableCell align="right">
                                                        <Button onClick={() => downloadMedia(row)}>
                                                            <Icon color={"primary"}>
                                                                download
                                                            </Icon>
                                                        </Button>
                                                        <Button onClick={() => updateMedia(row)}>
                                                            <Icon color={"primary"}>
                                                                edit
                                                            </Icon>
                                                        </Button>
                                                        <Button onClick={() => deleteMedia(row)}>
                                                            <Icon color={"error"}>
                                                                delete
                                                            </Icon>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default connect()(GeneralePage);
