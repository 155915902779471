import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {searchFilter} from "../../../constant";
import {handleModal} from "../../../redux/actions";
import SectionComponent from "../../CRUD/wis-procedure-sections/wis-procedure-sections.component";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import {WisProcedureSectionsApi} from "../../../services/api.service";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {withRouter} from "react-router-dom";
import {Tooltip} from "@material-ui/core";

const WisProcedureSections = new WisProcedureSectionsApi();
const columns = [
    {id: 'ppw_ordinamento', label: 'Ordinamento', colSpan: 1, sortable: true},
    {id: 'ppw_titolo', label: 'Nome', colSpan: 2, sortable: true},
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {

        overflow: 'auto',
    },
});
const WisProcedureSectionsTable = ({dispatch, api, history, location: {search}}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sections, setSections] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('titolo');
    const [filter, setFilter] = React.useState(null);
    useEffect(() => {
        const params = search.replace('?', '').split('&').map((param) => {
            const keyValue = param.split('=');
            return {
                [keyValue[0]]: keyValue[1]
            }
        });
        const fetchData = async () => {
            const {parti_proc_wb} = await WisProcedureSections.query({
                ...Object.assign(...params)
            });
            setSections(parti_proc_wb);
            setTableRows(parti_proc_wb);
        };
        fetchData();
    }, [api.fetch, search]);
    
    useEffect(() => {
        setPage(0);
        setTableRows(sections.filter(searchFilter(filter, columns)))
    }, [filter, sections]);

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };
    const updateSection = (section) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Modifica sezione',
            content: <SectionComponent mode={'update'} section={section}/>,
        }));
    };

    const deleteSection = (section) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Elimina sezione',
            content: <SectionComponent mode={'delete'} section={section}/>,
        }));
    };

    return (
        <Paper className={classes.root}>
            <Toolbar>
                <TextField label={'Cerca'} fullWidth={true} style={{margin: '1rem 1rem 2rem'}}
                           onChange={(e) => setFilter(e.target.value)}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <Icon>search</Icon>
                                   </InputAdornment>
                           }}/>
            </Toolbar>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(
                                ({id, align, sortable, colSpan, label}) => (
                                    <TableCell key={id} align={align} colSpan={colSpan} style={{zIndex: 3}}>
                                        {sortable ?
                                            <TableSortLabel
                                                active={orderBy === id}
                                                direction={order}
                                                onClick={createSortHandler(id)}>
                                                {label}
                                            </TableSortLabel>
                                            : label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.orderBy(tableRows, [orderBy], [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(
                                (row, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={index}>
                                            <TableCell style={{width:100}} align={"left"}>
                                                {row.ppw_ordinamento}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {row.ppw_titolo}
                                            </TableCell>
                                            <TableCell align={"right"} style={{width:300}}>
                                                <Tooltip title={'Visualizza sottosezioni'}>
                                                    <IconButton
                                                        onClick={() => history.push(`/modello-organizzativo/procedura-whistleblowing?parent_uuid=${row.uuid}&parent_title=${row.ppw_titolo}`)}>
                                                        <Icon>subdirectory_arrow_right</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={'Modifica informazioni'}>
                                                    <IconButton onClick={updateSection(row)}>
                                                        <Icon>remove_red_eye</Icon>
                                                    </IconButton>
                                                </Tooltip>                                                
                                                <Tooltip title={'Elimina sezione'}>
                                                    <IconButton onClick={deleteSection(row)}>
                                                        <Icon color={"error"}>delete</Icon>
                                                    </IconButton>
                                                </Tooltip>                                                
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        {!sections.length &&
                        <TableRow hover tabIndex={-1}>
                            <TableCell align={"center"} colSpan={columns.length}>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    nessun dato
                                </Typography>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={(e, newPage) => setPage(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>);
};

WisProcedureSectionsTable.propTypes = {
    filterBy: PropTypes.object
};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};
export default connect(mapStateToProps)(withRouter(WisProcedureSectionsTable));
