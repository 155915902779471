import React from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  CircularProgress,
} from "@material-ui/core";
import AddCommentIcon from "@material-ui/icons/AddComment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { handleModal } from "../../redux/actions";
import { useDispatch, connect } from "react-redux";
import NotesModal from "../modals/notes-modal/notes-modal";
import NotesAccordionDetails from "./notesAccordion.details";
import { RiskAreaApi, ValutazioneApi } from "../../services/api.service";
import { parseNotes } from "../../functions/insert-risk-assessment.helper";

const valutazione = new ValutazioneApi();
const riskArea = new RiskAreaApi();

const NotesAccordion = ({ api, id, areaId, companyId }) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = React.useState(null);
  const [refetch, setRefetch] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchReatoNotes = await valutazione.getNotes(id);
      const fetchAreaNotes = await riskArea.getNotes(areaId);
      setNotes(parseNotes(fetchReatoNotes, fetchAreaNotes.data));
    };
    fetchData();
  }, [api.fetch, refetch]);

  const showModal = () => {
    dispatch(
      handleModal({
        open: true,
        maxWidth: "lg",
        title: "Inserisci o modifica le note",
        noActions: true,
        content: (
          <NotesModal
            setFatherNotes={setNotes}
            companyId={companyId}
            id={id}
            areaId={areaId}
          />
        ),
      })
    );
  };

  return (
    <Grid container style={{ marginTop: 50 }} xs={12}>
      <Accordion
        style={{
          boxShadow: "none",
          borderBottom: "1px solid gray",
          borderColor: "white",
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container style={{ alignItems: "center" }}>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                showModal();
              }}
              variant={"outlined"}
              color={"primary"}
            >
              Inserisci note
              <AddCommentIcon style={{ marginLeft: 10 }} />
            </Button>
            <Typography style={{ marginLeft: "auto" }}>Note</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <NotesAccordionDetails
            areaId={areaId}
            companyId={companyId}
            id={id}
            notes={notes}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const mapStateToProps = ({ fetchApiReducer }) => {
  return {
    api: fetchApiReducer,
  };
};

export default connect(mapStateToProps)(NotesAccordion);
