import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { connect, useDispatch } from "react-redux";
import { handleModal } from "../../../redux/actions/modal.action";
import PropTypes from "prop-types";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Typography from "@material-ui/core/Typography";
import {
  ValutazioneApi,
  RiskAreaApi,
  ExportApi,
} from "../../../services/api.service";
import Checkbox from '@material-ui/core/Checkbox';

import _ from "lodash";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import RiskAreasSelect from "../../ui/risk-areas-select/risk-areas-select.component";
import FunctionsSelect from "../../ui/functions-select/functions-select.component";
import ViolationSelect from "../../ui/violation-select-independent/violation-select-independent.component";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import {
  parseNotes,
  getRiskResiduoColor,
  getRiskInerenteColor,
} from "../../../functions/insert-risk-assessment.helper";
import NotesModal from "../../modals/notes-modal/notes-modal";

import NotesAccordionDetails from "../../notes-accordion/notesAccordion.details";

const Valutazioni = new ValutazioneApi();
const riskArea = new RiskAreaApi();

const columns = [
  { id: "arrow", label: "", colSpan: 1, sortable: false, width: "5%" },
  {
    id: "are_titolo",
    label: "Aree di rischio",
    colSpan: 2,
    sortable: true,
    width: "20%",
  },
  {
    id: "funzione_titolo",
    label: "Funzione aziendale",
    colSpan: 2,
    sortable: true,
    width: "15%",
  },
  {
    id: "rea_titolo",
    label: "Reato",
    colSpan: 3,
    width: "15%",
    sortable: true,
  },
  {
    id: "impatto_max",
    align: "center",
    width: "8%",
    label: "Impatto",
    colSpan: 0.5,
    marginLeft: 22,
    sortable: true,
  },
  {
    id: "probabilita_media",
    label: "Probabilità",
    width: "8%",
    marginLeft: 22,
    align: "center",
    colSpan: 0.5,
    sortable: true,
  },
  {
    id: "presidio_media",
    align: "center",
    marginLeft: 22,
    width: "8%",
    label: "Presidi",
    colSpan: 0.5,
    sortable: true,
  },
  {
    id: "rischio_inerente",
    label: "Rischio Inerente",
    width: "8%",
    align: "center",
    marginLeft: 22,
    colSpan: 1,
    sortable: true,
  },
  {
    id: "rischio_residuo",
    align: "center",
    marginLeft: 22,
    label: "Rischio Residuo",
    width: "8%",
    colSpan: 1,
    sortable: true,
  },
  { id: "azioni", label: "Azioni", width: "5%", colSpan: 2, sortable: false },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  tableWrapper: {
    overflow: "auto",
  }
});

const isEmpty = (notes) => {
  return (
    !notes.azienda.area &&
    !notes.azienda.reato &&
    !notes.consulente.area &&
    !notes.azienda.area
  );
};

const Row = ({ val, index, companyId, history, isChecked, onClickHandle }) => {
  const { height, width } = useWindowDimensions();
  const [open, setOpen] = React.useState(false);
  const [notes, setNotes] = React.useState(null);
  const [refetch, setRefetch] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchReatoNotes = await Valutazioni.getNotes(val.id);
      const fetchAreaNotes = await riskArea.getNotes(val.area.uuid);
      setNotes(parseNotes(fetchReatoNotes, fetchAreaNotes.data));
    };
    fetchData();
  }, [refetch]);

  const showModal = () => {
    dispatch(
      handleModal({
        open: true,
        maxWidth: "lg",
        title: "Inserisci o modifica le note",
        noActions: true,
        content: (
          <NotesModal
            requiresReload={false}
            setFatherNotes={setNotes}
            companyId={companyId}
            id={val.id}
            areaId={val.area.uuid}
          />
        ),
      })
    );
  };

  return (
    <>
      <TableRow hover tabIndex={-1} key={index}>
        <TableCell padding="checkbox">
          <Checkbox
              checked={isChecked}
              inputProps={{ 'aria-labelledby': val.id }}
              onClick={(event) => onClickHandle(event, val.id)}
          />
        </TableCell>
        <TableCell width={"5%"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell width={"20%"} align={"left"}>
          {val.area.are_titolo}
        </TableCell>
        <TableCell width={"15%"} align={"left"}>
          {val.funzione?.fun_funzione}
        </TableCell>
        <TableCell width={"15%"} align={"left"}>
          {val.reato.rea_titolo + " - " + val.reato.rea_articolo}
        </TableCell>
        <TableCell width={"8%"} align={"center"}>
          {val.impatto_max.toFixed(2)}
        </TableCell>
        <TableCell width={"8%"} align={"center"}>
          {val.probabilita_media.toFixed(2)}
        </TableCell>
        <TableCell width={"8%"} align={"center"}>
          {val.presidio_media.toFixed(2)}
        </TableCell>
        <TableCell width={"8%"} align={"center"}>
          <Typography style={getRiskInerenteColor(val.rischio_inerente)}>
            {val.rischio_inerente.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell width={"8%"} align={"center"}>
          <Typography style={getRiskResiduoColor(val.rischio_residuo)}>
            {" "}
            {val.rischio_residuo.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell width={"5%"} align={"right"}>
          <div
            style={{
              display: "flex",
              flexDirection: width < 1200 ? "column" : "row",
            }}
          >
            {notes && !isEmpty(notes) ? (
              <Grid item>
                <IconButton onClick={() => showModal()}>
                  <Icon>comment</Icon>
                </IconButton>
              </Grid>
            ) : (
              <Grid item>
                <IconButton onClick={() => showModal()}>
                  <Icon>add_comment</Icon>
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <IconButton
                onClick={() => {
                  history.push({
                    pathname: "/risk-assessment/visualizza-valutazione",
                    state: {
                      companyId: companyId,
                      area: val.area,
                      reato: val.reato,
                    },
                  });
                }}
              >
                <Icon>visibility</Icon>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  history.push({
                    pathname: "/risk-assessment/modifica-valutazione",
                    state: {
                      companyId: companyId,
                      area: val.area,
                      reato: val.reato,
                    },
                  });
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            </Grid>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {!notes || isEmpty(notes) ? (
              <Grid container className={"tableAccordionContainer"}>
                <Typography variant={"caption"}> Nessuna Nota</Typography>
              </Grid>
            ) : (
              <Grid container className={"tableAccordionContainer"}>
                <NotesAccordionDetails notes={notes} />
              </Grid>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ValutazioniTable = ({ dispatch, api, companyId, history, selectedRowsHandler }) => {
  const classes = useStyles();

  const [area, setArea] = React.useState(null);
  const [reato, setReato] = React.useState(null);
  const [funzione, setFunzione] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [valutazioni, setValutazioni] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("area_titolo");
  const [filter, setFilter] = React.useState(null);
  const [numSelected, setNumSelected] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const popTableRows = (data) => {
    setTableRows(
      data.map((a) => {
        return {
          are_titolo: a.area.are_titolo,
          rea_titolo: a.reato.rea_titolo,
          ...a,
        };
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await Valutazioni.queryNew(companyId);
      setValutazioni(data);
      popTableRows(data);
    };
    fetchData();
  }, [api.fetch]);

  useEffect(() => {

    setPage(0);

    popTableRows(
      valutazioni.filter((val) => {

        let funzioniValid = true;
        let areaValid = true;
        let reatoValid = true;
        let filterValid = true;

        if (funzione) {
          funzioniValid = false;
          if (val.area.funzioni.length > 0 ) {
            for ( let n = 0; n < val.area.funzioni.length; n++ ) {
              if ( parseInt(val.area.funzioni[n].id) === parseInt(funzione.id) ) {
                funzioniValid = true;
              }
            }
          }
        }

        if (area) {

          areaValid = parseInt(area.id) === parseInt(val.area.id);

        }

        if (reato) {

          reatoValid = parseInt(reato.id) === parseInt(val.reato.id);

        }

        if (filter) {
          filterValid = (
              val.area.are_titolo.toLowerCase().includes(filter.toLowerCase()) ||
              val.reato.rea_titolo.toLowerCase().includes(filter.toLowerCase()) ||
              val.reato.rea_articolo.toLowerCase().includes(filter.toLowerCase()) ||
              (val.funzione && val.funzione.fun_funzione.toLowerCase().includes(filter.toLowerCase()))
          )
        }

       return reatoValid && areaValid && funzioniValid && filterValid;

      })
    );
  }, [reato, area, funzione, filter]);

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const [selected, setSelected] = React.useState([]);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableRows.map((n) => n.id);
      setSelected(newSelecteds);
      setNumSelected(newSelecteds.length);
      selectedRowsHandler(newSelecteds);
      setRowCount(tableRows.length);
      return;
    }
    setSelected([]);
    setNumSelected(0);
    setRowCount(tableRows.length);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);console.log(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    setNumSelected(newSelected.length);
    selectedRowsHandler(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Grid
          container
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid item xs={12} md={3} className={"tableHeaderItem"}>
            <TextField
              label={"Cerca"}
              fullWidth={true}
              onChange={(e) => setFilter(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} className={"tableHeaderItem"}>
            <FunctionsSelect
                table
                companyId={companyId}
                setFunzione={setFunzione}
                funzione={funzione}
                label="Filtra per funzione az."
            />
          </Grid>
          <Grid item xs={12} md={3} className={"tableHeaderItem"}>
            <RiskAreasSelect
              table
              companyId={companyId}
              setArea={setArea}
              label="Filtra per area di rischio"
              funzione={funzione}
              reato={reato}
            />
          </Grid>
          <Grid item xs={12} md={3} className={"tableHeaderItem"}>
            <ViolationSelect
              table
              companyId={companyId}
              setReato={setReato}
              reato={reato}
              area={area}
              label="Filtra per reato"
            />
          </Grid>
        </Grid>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </TableCell>
              {columns.map(
                ({ id, align, sortable, width, label, marginLeft }) => (
                  <TableCell
                    width={width}
                    key={id}
                    align={align}
                    style={{ zIndex: 3 }}
                  >
                    {sortable ? (
                      <TableSortLabel
                        active={orderBy === id}
                        direction={order}
                        onClick={createSortHandler(id)}
                        style={{ marginLeft }}
                      >
                        {label}
                      </TableSortLabel>
                    ) : (
                      label
                    )}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.orderBy(tableRows, [orderBy], [order])
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((val, index) => {
                return (
                  <Row
                    key={index}
                    history={history}
                    val={val}
                    companyId={companyId}
                    index={index}
                    isChecked={isSelected(val.id)}
                    onClickHandle={handleClick}
                  />
                );
              })}
            {!valutazioni.length && (
              <TableRow hover tabIndex={-1}>
                <TableCell align={"center"} colSpan={columns.length}>
                  <Typography variant={"caption"} color={"textSecondary"}>
                    nessun dato
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!tableRows.length && (filter || reato || area) && (
              <TableRow hover tabIndex={-1}>
                <TableCell align={"center"} colSpan={columns.length}>
                  <Typography
                    style={{ cursor: "pointer" }}
                    variant={"button"}
                    onClick={() =>
                      history.push("/risk-assessment/nuova-valutazione")
                    }
                    color={"textSecondary"}
                  >
                    nessun valutazione trovata per la ricerca effettuata
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "next page",
        }}
        onChangePage={(e, newPage) => setPage(newPage)}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

ValutazioniTable.propTypes = {
  filterBy: PropTypes.object,
};

const mapStateToProps = ({ fetchApiReducer }) => {
  return {
    api: fetchApiReducer,
  };
};
export default connect(mapStateToProps)(ValutazioniTable);
