import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import RiskAreaTypologiesSelectComponent
    from "../../ui/risk-area-typologies-select/risk-area-typologies-select.component";
import {RiskAreaApi} from "../../../services/api.service";
import ReactQuill from "react-quill";
import {ReactQuillModules, requiredHtml} from "../../../constant";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const RiskArea = new RiskAreaApi();
const RiskAreaComponent = ({dispatch, mode, riskArea}) => {
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(riskArea.active);
    const addRiskAreaInputs = [
        {
            name: 'are_titolo',
            required: 'campo obbligatorio',
            label: 'Titolo',
            placeholder: 'Inserisci titolo',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete'
        }
    ];
    const riskAreaForm = useForm({
        mode: 'onSubmit',
        defaultValues: mode !== 'create' ? {
            ...riskArea,
            art_uuid: riskArea.aree_rischio_tipologie ? riskArea.aree_rischio_tipologie.uuid : ''
        } : {}
    });
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onRiskAreaSave = async (data) => {
        try {
            setLoading(true);
            switch (mode) {
                case 'create':
                    await RiskArea.create(data);
                    dispatch(handleToast({
                        message: 'Area di rischio creata'
                    }));
                    break;
                case 'update':
                    await RiskArea.update({
                        uuid: riskArea.uuid,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Area di rischio modificata'
                    }));
                    break;
                case 'delete':
                    await RiskArea.delete(riskArea.uuid);
                    dispatch(handleToast({
                        message: 'Area di rischio eliminata'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <FormContext {...riskAreaForm}>
            <form onSubmit={riskAreaForm.handleSubmit(onRiskAreaSave)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RiskAreaTypologiesSelectComponent disabled={mode === 'read' || mode === 'delete'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm inputs={addRiskAreaInputs}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} gutterBottom>Descrizione</Typography>
                        <ReactQuill defaultValue={riskAreaForm.watch('are_descrizione')}
                                    ref={riskAreaForm.register({
                                        name: 'are_descrizione'
                                    }, {validate: requiredHtml})}
                                    modules={ReactQuillModules}
                                    onChange={(html) => riskAreaForm.setValue('are_descrizione', html.replace("<p><br></p>", ""))}
                                    readOnly={mode === 'read' || mode === 'delete'}
                                    placeholder={'Inserisci descrizione'}/>
                        {riskAreaForm.errors['are_descrizione'] &&
                        <Typography variant={"caption"} color={'error'}>
                            {riskAreaForm.errors['are_descrizione'].message}
                        </Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox defaultChecked={mode !== 'create' ? active : false}
                                          disabled={mode === 'read' || mode === 'delete'}
                                          onChange={(e) => riskAreaForm.setValue('active', e.target.checked)}
                                          inputRef={riskAreaForm.register({name: 'active'})}
                                          color="secondary"/>
                            }
                            label="Attivo"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};
RiskAreaComponent.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
        {'indent': '-1'}, {'indent': '+1'}],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
};

RiskAreaComponent.defaultProps = {
    riskArea: {}
};
RiskAreaComponent.propTypes = {
    riskArea: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(RiskAreaComponent);
