import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import {SpecialModelsApi} from "../../../services/api.service";
import ReactQuill from "react-quill";
import {KeyboardDatePicker} from "@material-ui/pickers";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Radio from "@material-ui/core/Radio";
import {ReactQuillModules} from "../../../constant";
import moment from 'moment-timezone';

const SpecialModel = new SpecialModelsApi();
const SpecificModelComponent = ({dispatch, mode, model: {uuid, mos_note, mos_data_approvazione, mos_data_fine_validita, mos_data_inizio_validita}, preview}) => {
    const [loading, setLoading] = useState(false);
    const versionForm = useForm({
        mode: 'onSubmit',
        defaultValues: mode !== 'create' ? {
            mos_note,
            mos_data_approvazione,
            mos_data_fine_validita,
            mos_data_inizio_validita
        } : {
            order: 'aree'
        }
    });
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = preview ? 'Scarica anteprima' : 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onSpecialModelSave = async (data) => {
        try {
            setLoading(true);
            switch (mode) {
                case 'create':
                    const {filename} = await SpecialModel.create({
                        ...data,
                        preview
                    });
                    if (preview) {
                        try {
                            const file = await SpecialModel.downloadPreview(filename);
                            const url = window.URL.createObjectURL(file);
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = filename;
                            // 3. Append to html page
                            document.body.appendChild(link);
                            // 4. Force download
                            link.click();
                            // 5. Clean up and remove the link
                            link.parentNode.removeChild(link);
                        } catch (e) {
                            console.error(e);
                            dispatch(handleToast({
                                message: 'Impossibile scaricare l\'anteprima'
                            }));
                        }
                    } else {
                        dispatch(handleToast({
                            message: 'Versione creata'
                        }));
                    }
                    break;
                case 'update':
                    await SpecialModel.update({
                        uuid,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Versione modificata'
                    }));
                    break;
                case 'delete':
                    await SpecialModel.delete(uuid);
                    dispatch(handleToast({
                        message: 'Versione eliminata'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <FormContext {...versionForm}>
            <form onSubmit={versionForm.handleSubmit(onSpecialModelSave)}>
                <Grid container spacing={2}>
                    {!preview &&
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} gutterBottom>
                            Scegli le date di validità del documento
                        </Typography>
                    </Grid>}
                    {!preview &&
                    <Grid item xs={6}>
                        {/* <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Valido dal"
                            format="DD/MM/YYYY"
                            ref={versionForm.register({
                                name: 'mos_data_inizio_validita'
                            })}
                            value={versionForm.watch('mos_data_inizio_validita') || null}
                            onChange={(date) => versionForm.setValue('mos_data_inizio_validita', date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }} fullWidth
                        /> */}
                        <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Valido dal"
                                format="DD/MM/YYYY"
                                ref={versionForm.register({
                                    name: 'mos_data_inizio_validita'
                                })}
                                value={versionForm.watch('mos_data_inizio_validita') || null}
                                onChange={(date) => {
                                    const formattedDate = moment(date).format("YYYY-MM-DD");
                                    versionForm.setValue('mos_data_inizio_validita', formattedDate);
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }} fullWidth
                            />
                    </Grid>}
                    {!preview &&
                    <Grid item xs={6}>
                        {/*<KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Valido fino al"
                            format="DD/MM/YYYY"
                            ref={versionForm.register({
                                name: 'mos_data_fine_validita'
                            })}
                            value={versionForm.watch('mos_data_fine_validita') || null}
                            onChange={(date) => versionForm.setValue('mos_data_fine_validita', date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }} fullWidth
                        />*/}
                    </Grid>}
                    {!preview &&
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} gutterBottom>Note</Typography>
                        <ReactQuill defaultValue={versionForm.watch('mos_note')}
                                    ref={versionForm.register({
                                        name: 'mos_note'
                                    })}
                                    modules={ReactQuillModules}
                                    onChange={(html) => versionForm.setValue('mos_note', html)}
                                    readOnly={mode === 'read' || mode === 'delete'}
                                    placeholder={'Inserisci note'}/>
                        {versionForm.errors['mos_note'] &&
                        <Typography variant={"caption"}
                                    color={'error'}>{versionForm.errors['mos_note'].message}</Typography>}
                    </Grid>}
                    {!preview &&
                    <Grid item xs={12}>
                        {/* <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Data di approvazione"
                            format="DD/MM/YYYY"
                            ref={versionForm.register({
                                name: 'mos_data_approvazione'
                            })}
                            value={versionForm.watch('mos_data_approvazione') || null}
                            onChange={(date) => versionForm.setValue('mos_data_approvazione', date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }} fullWidth
                        /> */}
                        <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Data di approvazione"
                                format="DD/MM/YYYY"
                                ref={versionForm.register({
                                    name: 'mos_data_approvazione'
                                })}
                                value={versionForm.watch('mos_data_approvazione') || null}
                                onChange={(date) => {
                                    const formattedDate = moment(date).format("YYYY-MM-DD");
                                    versionForm.setValue('mos_data_approvazione', formattedDate);
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }} fullWidth
                            />
                    </Grid>}
                    {/*mode === 'create' &&
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Stampa modello organizzativo</FormLabel>
                            <RadioGroup aria-label="gender" name="order" value={versionForm.watch('order')}
                                        ref={versionForm.register({name: 'order'})}
                                        onChange={(e) => versionForm.setValue(e.target.name, e.target.value)}>
                                <FormControlLabel value="aree" control={<Radio/>} label="Ordina per Aree di rischio"/>
                                <FormControlLabel value="categorie" control={<Radio/>}
                                                  label="Ordina per Categorie Reati 231"/>
                            </RadioGroup>
                        </FormControl>
                        </Grid>*/}
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

SpecificModelComponent.modules = {
    toolbar: [
        [{'header': '1'}, {'header': '2'}, {'font': []}],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
};

SpecificModelComponent.defaultProps = {
    model: {}
};
SpecificModelComponent.propTypes = {
    model: PropTypes.object,
    preview: PropTypes.bool,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(SpecificModelComponent);
