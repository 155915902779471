import React from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import {connect} from "react-redux";
import {TextField} from "@material-ui/core";
import useForm from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import {SupportsApi} from "../../services/api.service";
import {handleToast} from "../../redux/actions";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";

const Supports = new SupportsApi();
const HelpModulePage = ({dispatch, history}) => {
    const {register, watch, setValue, errors, reset, handleSubmit} = useForm();
    const send = async (data) => {
        try {
            await Supports.create(data);
            dispatch(handleToast({
                message: 'Richiesta inviata'
            }));
            reset();
        }catch (message) {
            dispatch(handleToast({message}));
        }
    };
    return (
        <form onSubmit={handleSubmit(send)}>
            <Grid container justify={"center"} style={{margin: '3rem 0'}}>
                <Grid item xs={12} md={10} lg={8}>
                    <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                        <Grid item xs={12}>
                            <BreadcrumbComponent hideHelp/>
                        </Grid>
                        <Grid item xs={12}>
                            <PageSubtitleComponent back subtitle={'Modulo di aiuto'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle2"} gutterBottom>
                                Descrizione della rischiesta di supporto
                            </Typography>
                            <TextField fullWidth name={'descrizione'}
                                       helperText={errors['descrizione'] ? errors['descrizione'].message : ''}
                                       error={!!errors['descrizione']} onChange={(e)=> setValue(e.target.name, e.target.value)}
                                       inputRef={register({required: 'campo obbligatorio'})}
                                       placeholder={'Descrizione della rischiesta di supporto'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle2"} gutterBottom>
                                Metodo di contatto
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid xs={6} item>
                                    <TextField fullWidth name={'telefono'}
                                               onChange={(e)=> setValue(e.target.name, e.target.value)}
                                               helperText={errors['telefono'] ? errors['telefono'].message : ''}
                                               error={!!errors['telefono']}
                                               inputRef={register({name: 'telefono'}, {validate: (value) => watch('email') || value ? true : 'Inserisci almeno un contatto'})}
                                               label={'Telefono'}/>
                                </Grid>
                                <Grid xs={6} item>
                                    <TextField fullWidth name={'email'}
                                               onChange={(e)=> setValue(e.target.name, e.target.value)}
                                               helperText={errors['email'] ? errors['email'].message : ''}
                                               error={!!errors['email']}
                                               inputRef={register({name: 'email'}, {validate: (value) => watch('telefono') || value ? true : 'Inserisci almeno un contatto'})}
                                               label={'Email'}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle2"} gutterBottom>
                                Orario preferito di contatto
                            </Typography>
                            <TextField fullWidth
                                       name={'contatto'}
                                       onChange={(e)=> setValue(e.target.name, e.target.value)}
                                       helperText={errors['contatto'] ? errors['contatto'].message : ''}
                                       error={!!errors['contatto']}
                                       inputRef={register} placeholder={'Es. Orario pasti'}/>
                        </Grid>
                        <Grid item container justify={"center"} alignItems={"center"} spacing={4}>
                            <Grid item>
                                <Button onClick={()=>history.goBack()}>indietro</Button>
                            </Grid>
                            <Grid item>
                                <Fab color={"secondary"} variant={"extended"} type={'submit'}>
                                    invia richiesta
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default connect()(withRouter(HelpModulePage));
