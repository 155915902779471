import {TOAST_HANDLER} from "../types";

const toastReducer = (state = {}, {type, open, message, autoHideDuration = 0}) => {
    switch (type) {
        case TOAST_HANDLER:
            return {
                ...state,
                toast: {
                    open,
                    autoHideDuration,
                    message
                }
            };
        default:
            return state
    }
};

export default toastReducer;
