import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { connect } from 'react-redux'

const RiskAssessmentMatrixProtocolliFilters = ({ dispatch }) => {
  const { register, watch, setValue } = useFormContext()

  useEffect(() => {
    register('protocolsFilters')
  }, [])

  return (
    <Card style={{ marginBottom: "35px" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Filtri
            </Typography>
          </Grid>

          <Grid item style={{ display: "flex", "gap": "0 4px" }}>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'protocolsFilters.generico' })}
              selected={!!watch('protocolsFilters.generico') || false}
              onChange={(e, value) => setValue('protocolsFilters.generico', !watch('protocolsFilters.generico'))}
            >
              Generico
            </ToggleButton>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'protocolsFilters.specifico' })}
              selected={!!watch('protocolsFilters.specifico') || false}
              onChange={(e, value) => setValue('protocolsFilters.specifico', !watch('protocolsFilters.specifico'))}
            >
              Specifico
            </ToggleButton>
          </Grid>

          <Grid item style={{ marginLeft: '8px', display: "flex", "gap": "0 4px" }}>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'protocolsFilters.pro_formalizzato' })}
              selected={!!watch('protocolsFilters.pro_formalizzato') || false}
              onChange={(e, value) => setValue('protocolsFilters.pro_formalizzato', !watch('protocolsFilters.pro_formalizzato'))}
            >
              Formalizzato
            </ToggleButton>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'protocolsFilters.pro_operativo' })}
              selected={!!watch('protocolsFilters.pro_operativo') || false}
              onChange={(e, value) => setValue('protocolsFilters.pro_operativo', !watch('protocolsFilters.pro_operativo'))}
            >
              Operativo
            </ToggleButton>
          </Grid>

          <Grid xs={12} item style={{ display: "flex", "gap": "0 4px" }}>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'protocolsFilters.pro_procedure' })}
              selected={!!watch('protocolsFilters.pro_procedure') || false}
              onChange={(e, value) => setValue('protocolsFilters.pro_procedure', !watch('protocolsFilters.pro_procedure'))}
            >
              Procedure
            </ToggleButton>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'protocolsFilters.pro_segregazione' })}
              selected={!!watch('protocolsFilters.pro_segregazione') || false}
              onChange={(e, value) => setValue('protocolsFilters.pro_segregazione', !watch('protocolsFilters.pro_segregazione'))}
            >
              Segregazione
            </ToggleButton>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'protocolsFilters.pro_poteri' })}
              selected={!!watch('protocolsFilters.pro_poteri') || false}
              onChange={(e, value) => setValue('protocolsFilters.pro_poteri', !watch('protocolsFilters.pro_poteri'))}
            >
              Poteri
            </ToggleButton>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'protocolsFilters.pro_monitoraggio' })}
              selected={!!watch('protocolsFilters.pro_monitoraggio') || false}
              onChange={(e, value) => setValue('protocolsFilters.pro_monitoraggio', !watch('protocolsFilters.pro_monitoraggio'))}
            >
              Monitoraggio
            </ToggleButton>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'protocolsFilters.pro_tracciabilita' })}
              selected={!!watch('protocolsFilters.pro_tracciabilita') || false}
              onChange={(e, value) => setValue('protocolsFilters.pro_tracciabilita', !watch('protocolsFilters.pro_tracciabilita'))}
            >
              Tracciabilità
            </ToggleButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default connect()(RiskAssessmentMatrixProtocolliFilters)
