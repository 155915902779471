import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Typography} from "@material-ui/core";
import UnknownStepComponent from "../../ui/unknown-step/unknown-step.component";
import SelectUserForm from "../../hooks-forms/select-user-form/select-user.form";
import {handleToast} from "../../../redux/actions";
import {AccountsApi} from "../../../services/api.service";
import PageSubtitleComponent from "../../ui/page-subtitle/page-subtitle.component";
import {getRegistryLabel} from "../../../constant";
import {connect} from "react-redux";

const Accounts = new AccountsApi();

const AccountCancellationSteps = ({dispatch, activeStep, handleBack, handleNext, onComplete}) => {
    const [account, setAccountData] = useState({});

    const onSubmit = async data => {
        try {
            const {
                account: {
                    acc_fiscal_code,
                    acc_p_iva,
                    acc_address,
                    acc_admin_email,
                    acc_city,
                    acc_email,
                    acc_mobile,
                    acc_name,
                    acc_phone,
                    acc_province,
                    acc_zip
                }
            } = await Accounts.query(data);
            setAccountData({
                acc_fiscal_code,
                acc_p_iva,
                acc_address,
                acc_admin_email,
                acc_city,
                acc_email,
                acc_mobile,
                acc_name,
                acc_phone,
                acc_province,
                acc_zip,
            });
            handleNext();
        } catch (message) {
            dispatch(handleToast({message}));
        }
    };
    const save = () => {
        onComplete(account);
    };
    const back = () => {
        handleBack();
    };

    switch (activeStep) {
        case 0:
            return <SelectUserForm onSubmit={onSubmit}/>;
        case 1:
            return (
                <Grid container direction={"column"} alignContent={"center"} spacing={4}>
                    <Grid item container justify={"center"}>
                        <Grid item>
                            <img src={'/assets/images/warning.svg'} alt={'warning'}/>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography align={"center"} variant={"body1"}>
                            Attenzione, si sta per procedere alla disdetta della licenza dell'account selezionato.
                        </Typography>
                        <Typography align={"center"} variant={"subtitle2"}>
                            Si è scuri di voler proseguire?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={'DATI ANAGRAFICI'}/>
                    </Grid>
                    <Grid item container direction={"column"} spacing={1} alignItems={"center"}
                          style={{marginLeft: '2rem'}}>
                        {Object.keys(account).map((key, index) => {
                            return account[key] ? (
                                <Grid item container spacing={2} key={`summary-${index}`}>
                                    <Grid item>
                                        <Typography variant={"subtitle2"}>{getRegistryLabel(key)}:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body1"}>{account[key]}</Typography>
                                    </Grid>
                                </Grid>
                            ) : ''
                        })}
                    </Grid>
                    <Grid item>
                        <Grid container justify={"center"} spacing={2}>
                            <Grid item>
                                <Button variant={"outlined"} onClick={back}>
                                    Torna indietro
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant={"contained"} color={"secondary"}
                                        onClick={save}>
                                    Disdici utenza
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        default:
            return <UnknownStepComponent handleBack={handleBack}/>;
    }
};

export default connect()(AccountCancellationSteps);
