import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import {RiskAreaTypologyApi} from "../../../services/api.service";

const RiskAreaTypology = new RiskAreaTypologyApi();
const RiskAreaTypologyComponent = ({dispatch, mode, typology}) => {
    const [loading, setLoading] = useState(false);
    const addRiskAreaTypologyInputs = [
        {
            name: 'art_name',
            required: 'campo obbligatorio',
            label: 'Nome',
            placeholder: 'Inserisci nome tipologia',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete'
        }
    ];
    const riskAreaForm = useForm({
        mode: 'onSubmit',
        defaultValues: mode !== 'create' ? typology : {}
    });
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onRiskAreaTypologySave = async (data) => {
        try {
            setLoading(true);
            switch (mode) {
                case 'create':
                    await RiskAreaTypology.create(data);
                    dispatch(handleToast({
                        message: 'Tipologia creata'
                    }));
                    break;
                case 'update':
                    await RiskAreaTypology.update({
                        uuid: typology.uuid,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Tipologia modificata'
                    }));
                    break;
                case 'delete':
                    await RiskAreaTypology.delete(typology.uuid);
                    dispatch(handleToast({
                        message: 'Tipologia eliminata'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <FormContext {...riskAreaForm}>
            <form onSubmit={riskAreaForm.handleSubmit(onRiskAreaTypologySave)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm inputs={addRiskAreaTypologyInputs}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

RiskAreaTypologyComponent.defaultProps = {
    typology: {}
};
RiskAreaTypologyComponent.propTypes = {
    typology: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(RiskAreaTypologyComponent);
