import axios from "axios";
import { END_POINT } from "../constant";
import { GRC_SELECTED_COMPANY, GRC_USER } from "../redux/types";
import {CompanyApiNew, UserApi} from "./api.service";
import {saveCompanyId} from "../redux/actions/auth.action";
import myConfig from "../config.json";

const User = new UserApi();
const CompanyNew = new CompanyApiNew();
export default class AuthService extends UserApi {
  static login = async ({ username, password }) => {
    try {
      /**
       * This calle is made in order to recieve the xrsf token (Set-Cookie in headers response
       * WARNING: it requires being on the same top-level domain
       * @type {AxiosResponse<any>}
       */
      const csrfCall = await axios.get(`${END_POINT}/sanctum/csrf-cookie`).then(response => {
      });

      /**
       * Logging in to get the token we need
       * @type {AxiosResponse<any>}
       */
      const res = await axios({
        url: `${END_POINT}${User.url}`,
        method: "post",
        data: {
          "username": username,
          "password": password,
          "device_name": myConfig.ENV
        },
        headers: {
          "Content-Type": "application/json",
        }
      });

      /**
       * Saving the user Data
       */
      localStorage.setItem(GRC_USER, JSON.stringify(res.data));
      const rawPermissions = res.data.permissions;
      const permissions = [];

      /**
       * Permissions
       */
      if ( rawPermissions.length > 0 ) {
        for ( let i = 0; i < rawPermissions.length; i++ ) {
          permissions.push(rawPermissions[i].name);
        }
      } else {
        /**
         * We cannot have empty permissions
         */
        localStorage.clear();
        return [];
      }

      /**
       * If for some reasons we have permissions as an empty list we log out
       */
      if (permissions === []) {
        localStorage.clear();
        return [];
      }

      /**
       * Storing the permissions
       */
      localStorage.setItem('permessi_utente', JSON.stringify(permissions));

      /**
       * Working on special permissions
       * TODO: streamline
       */
      if ( localStorage.getItem('permessi_utente').indexOf('risk.referente') > 0 ) {
        const { id, com_name, uuid } = await CompanyNew.getFormUid(res.data.ref_company_id);
        saveCompanyId(id, com_name);
        localStorage.setItem(GRC_SELECTED_COMPANY, JSON.stringify({'id': id, 'com_name': com_name, 'uuid': uuid}));
      }

      if ( localStorage.getItem('permessi_utente').indexOf('odv.referente') > 0 ) {
        const { id, com_name, uuid } = await CompanyNew.getFormUid(res.data.odv_ref_company_id);
        saveCompanyId(id, com_name);
        localStorage.setItem(GRC_SELECTED_COMPANY, JSON.stringify({'id': id, 'com_name': com_name, 'uuid': uuid}));
      }

      return res.data;
    } catch (e) {
      throw e;
    }
  };

  static makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
    }
    return result;
  }

  static resetPassword = async ({ email, password, token }) => {
    try {

      const csrfCall = await axios.get(`${END_POINT}/sanctum/csrf-cookie`).then(response => {
      });
      const res = await axios({
        url: `${END_POINT}/api/${
          email ? "recover-password" : "reset-password"
        }`,
        method: "post",
        data: {
          email,
          password,
          token,
        },
          headers: {
          "Content-Type": "application/json",
        }
      });
      return res.data;
    } catch (e) {
      throw e;
    }
  };

  static changePassword = async (data) => {
    try {
      const res = await axios({
        url: `${END_POINT}${User.url}change-password`,
        method: "post",
        data,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return res.data;
    } catch (e) {
      throw e;
    }
  };

  static routePermissions = () => {
    if (AuthService.user && AuthService.user.utente && AuthService.user.utente.groups) {
      return AuthService.user.utente.groups.gro_name;
    }
    localStorage.clear();
    return "noAuth";
  };

  static isAdmin = () => {
    if (AuthService.user && AuthService.user.utente && AuthService.user.utente.groups) {
      return AuthService.user.utente.groups.gro_name === "super_admin";
    }
    return false;
  };

  static isAuth = () => {
    try {
      return AuthService.user;
    } catch (e) {
      AuthService.logout().then(() => {
        return false;
      });
    }
  };

  static logout = async () => {
    try {
      if ( localStorage && localStorage.getItem(GRC_USER) && JSON.parse(localStorage.getItem(GRC_USER)).token ) {
        /*await axios({
          url: `https://valore24php.echocreative.it/api/logout`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization:
                "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          }
        });*/
        localStorage.clear();
      } else {
        localStorage.clear();
      }
      return true;
    } catch (e) {
      localStorage.clear();
      return true;
    }
  };

  static getCompanies = () => {
    try {
      const {
        utente: {
          accounts: { companies },
        },
      } = AuthService.user;
      return companies;
    } catch (e) {
      return [];
    }
  };

  static setCompanies = (newCompanies) => {
    try {
      const { utente } = AuthService.user;
      AuthService.user = {
        ...AuthService.user,
        utente: {
          ...utente,
          accounts: {
            ...utente.accounts,
            companies: newCompanies,
          },
        },
      };
    } catch (e) {
      return [];
    }
  };

  static set company(company) {
    localStorage.setItem(GRC_SELECTED_COMPANY, JSON.stringify(company));
  }

  static get company() {
    try {
      const data = localStorage.getItem(GRC_SELECTED_COMPANY);
      return JSON.parse(data) || {};
    } catch (e) {
      return {};
    }
  }

  static set user(user) {
    localStorage.setItem(GRC_USER, JSON.stringify(user));
  }

  static get user() {
    try {
      const data = localStorage.getItem(GRC_USER);
      return JSON.parse(data);
    } catch (e) {
      AuthService.logout();
      return false;
    }
  }
}
