import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import {GeneralSectionsApi} from "../../../services/api.service";
import ReactQuill from "react-quill";
import {ReactQuillModules} from "../../../constant";
import {withRouter} from "react-router-dom";

const Section = new GeneralSectionsApi();
const GeneralSectionComponent = ({dispatch, mode, section: {uuid, pag_titolo, pag_ordinamento, pag_contenuto}, location:{search}}) => {
    const [loading, setLoading] = useState(false);
    const addSectionInputs = [
        {
            name: 'pag_titolo',
            required: mode!=='elimina' ? 'campo obbligatorio' : null,
            label: 'Titolo',
            placeholder: 'Inserisci titolo',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete'
        },
        {
            name: 'pag_ordinamento',            
            label: 'Ordinamento',
            required: mode!=='elimina' ? 'Inserisci un numero' : null,
            placeholder: 'Inserisci ordinamento',
            width: 12,
            margin: 'normal',
            type: 'number',
            disabled: mode === 'read' || mode === 'delete'
        }
    ];
    const sectionForm = useForm({
        mode: 'onSubmit',
        defaultValues: mode !== 'create' ? {
            pag_titolo,
            pag_ordinamento,
            pag_contenuto
        } : {}
    });
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onSectionSave = async (data) => {
        try {
            setLoading(true);
            search.replace('?', '').split('&').forEach(
                (param) => {
                    const keyValue = param.split('=');
                    if (keyValue[0] === 'parent_uuid' && keyValue[1]) {
                        data = {
                            ...data,
                            pag_parent_uuid: keyValue[1]
                        };
                    }
                });
            switch (mode) {
                case 'create':
                    await Section.create({
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Sezione creata'
                    }));
                    break;
                case 'update':
                    await Section.update({
                        uuid,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Sezione modificata'
                    }));
                    break;
                case 'delete':
                    await Section.delete(uuid);
                    dispatch(handleToast({
                        message: 'Sezione eliminata'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <FormContext {...sectionForm}>
            <form onSubmit={sectionForm.handleSubmit(onSectionSave)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm inputs={addSectionInputs}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} gutterBottom>Descrizione</Typography>
                        <ReactQuill defaultValue={sectionForm.watch('pag_contenuto')}
                                    ref={sectionForm.register({
                                        name: 'pag_contenuto'
                                    })}
                                    modules={ReactQuillModules}
                                    onChange={(html) => sectionForm.setValue('pag_contenuto', html)}
                                    readOnly={mode === 'read' || mode === 'delete'}
                                    placeholder={'Inserisci contenuto'}
                                />
                        {sectionForm.errors['pag_contenuto'] &&
                        <Typography variant={"caption"}
                                    color={'error'}>{sectionForm.errors['pag_contenuto'].message}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

GeneralSectionComponent.modules = {
    toolbar: [
        [{'header': '1'}, {'header': '2'}, {'font': []}],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
};

GeneralSectionComponent.defaultProps = {
    section: {}
};
GeneralSectionComponent.propTypes = {
    section: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(withRouter(GeneralSectionComponent));
