import React from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import {withRouter} from "react-router-dom";
import Violation231Component from "../../components/CRUD/violation-231/violation231.component";

const Violation231Page = ({match: {params: {mode}}}) => {
    const label = mode === 'crea' ? 'Aggiungi' : 'Modifica';
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`${label} reato 231 alla lista`}/>
                    </Grid>
                    <Grid item>
                        <Violation231Component mode={mode === 'crea' ? 'create' : 'update'}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

Violation231Page.propTypes = {};

export default withRouter(Violation231Page);
