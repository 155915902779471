import React from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ViolationCategoriesTable from "../../components/atomic-tables/violation-cateogories/violation-categories.table";
import CtaBoxComponent from "../../components/ui/cta-box/cta-box.component";
import {handleModal} from "../../redux/actions/modal.action";
import {connect} from "react-redux";
import CategoryComponent from "../../components/CRUD/violation-category/violation-category.component";


const ViolationCategoriesPage = ({dispatch, history}) => {
    const actions = [
        {
            label: 'Aggiungi reato',
            handler: () => history.push('/reati-231/elenco-reati-231/crea-reato')
        },
        {
            label: 'Aggiungi categoria',
            handler: () => createCategory()
        }
    ];
    const createCategory = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Aggiungi categoria',
            content: <CategoryComponent mode={'create'}/>,
        }));
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Elenco categorie reato 231'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"space-between"} alignItems={"center"} spacing={2} style={{margin: 0}}>
                            <Grid item xs>
                                <Typography variant={"subtitle2"}>Elenco Categorie Reati 231</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={createCategory}>Aggiungi categoria</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <ViolationCategoriesTable/>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>
                        <CtaBoxComponent title={'Aggiungi reato o categoria di reato'} actions={actions}/>
                    </Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    );
};

ViolationCategoriesPage.propTypes = {};

export default connect()(ViolationCategoriesPage);
