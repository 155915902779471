import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import AutoCompleteComponent from "../../components/ui/auto-complete/auto-complete.component";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import {FlowApi, FunctionApi} from "../../services/api.service";
import Fab from "@material-ui/core/Fab";
import {handleToast} from "../../redux/actions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import orderBy from "lodash/orderBy";

const Flow = new FlowApi();
const Function = new FunctionApi();
const MatrixFlowFunctionsPage = ({dispatch}) => {
    const [functions, setFunctions] = useState([]);
    const [flows, setFlows] = useState([]);
    const [filter, setFilter] = React.useState();
    useEffect(
        () => {
            const fetchData = async () => {
                const {flussi} = await Flow.query();
                let funzioni = await Function.query();
                funzioni = funzioni.map(
                    ({uuid, fun_funzione}) => {
                        return {
                            label: fun_funzione,
                            value: uuid
                        }
                    });
                setFunctions(orderBy(funzioni, ['label'], ['asc']));
                setFlows(flussi.filter(flusso => filter ? flusso.flu_titolo.toLowerCase().search(filter.toLowerCase()) !== -1 : true));

            };
            fetchData();
        }, [filter]
    );
    const updateFlows = (riskAreaId) => (functions) => {
        setFlows([
            ...flows.map((flusso) => {
                if (flusso.uuid === riskAreaId) {
                    flusso.funzioni_flussi = functions || [];
                }
                return flusso;
            })
        ]);
    };
    const save = async () => {
        try {
            await Promise.all(await flows.map(
                async ({funzioni_flussi, uuid}) => {
                    await Flow.updateFunctions({
                        uuid,
                        funzioni: funzioni_flussi.map(({value, funzioni}) => value || (funzioni ? funzioni.uuid : undefined))
                    })
                })
            );
            dispatch(handleToast({
                message: 'Funzioni per Flussi salvati'
            }));
        } catch (message) {
            dispatch(handleToast({
                message
            }))
        }
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0', position: 'relative'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Matrici - Funzioni per flussi'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Toolbar>
                            <TextField label={'Cerca'} fullWidth={true}
                                    onChange={(e) => setFilter(e.target.value)}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                    }}/>
                        </Toolbar>
                    </Grid>
                    {flows.map((flusso) => {
                        const {uuid, flu_titolo, funzioni_flussi} = flusso;
                        return (
                            <Grid item key={uuid} style={{minWidth: '100%'}}>
                                <Card style={{overflow: 'visible'}}>
                                    <CardContent>
                                        <AutoCompleteComponent suggestions={functions}
                                                               defaultValues={funzioni_flussi.map(({funzioni, label, value}) => {
                                                                   return {
                                                                       label: funzioni ? funzioni.fun_funzione : label,
                                                                       value: funzioni ? funzioni.uuid : value
                                                                   }
                                                               })}
                                                               onChange={updateFlows(flusso.uuid)} multiple
                                                               label={flu_titolo} placeholder={'Inserisci Funzione'}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                    <Grid item xs={12}>
                        <Fab
                            onClick={save}
                            style={{
                                position: 'fixed',
                                right: '70px',
                                top: '335px',
                                width: '120px',
                            }} aria-label={'Salva'} color={'secondary'} variant={"extended"}>Salva</Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

MatrixFlowFunctionsPage.propTypes = {};

export default connect()(MatrixFlowFunctionsPage);
