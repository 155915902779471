import React from 'react';
import PropTypes from 'prop-types';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";

const LicensesSummaryComponent = ({licenses}) => {
    return (
        <Table>
            <TableBody>
                {licenses.map(
                    ({uuid, lic_name, lic_description, quantity}, index) =>
                        <TableRow key={`lic-${index}`}>
                            <TableCell>{lic_name}</TableCell>                            
                            <TableCell>{lic_description}</TableCell>
                            <TableCell align={"right"}>Quantità <strong>{quantity}</strong></TableCell>
                        </TableRow>
                )}
                {!licenses.length &&
                <TableRow>
                    <TableCell colSpan={4}>Nessuna licenza selezionata</TableCell>
                </TableRow>}
            </TableBody>
        </Table>
    );
};

LicensesSummaryComponent.defaultProps = {
    licenses: []
};
LicensesSummaryComponent.propTypes = {
    licenses: PropTypes.array.isRequired
};

export default LicensesSummaryComponent;
