import React, { useState, useEffect, useMemo } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import grey from "@material-ui/core/colors/grey";
import {Link as RouteLink, withRouter} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import moment from 'moment'
import envConfig from "../../config.json";
import {FeedRssApi} from "../../services/api.service";

const FeedRss = new FeedRssApi();

const useStyles = makeStyles(theme => ({
    whiteText: {
        color: 'white'
    },
    lightGreen:{
        color: '#1f6a60'
    },
    mainButton: {
        width: '240px'
    },
    section: {
        height: '20vh',
        position: 'relative',
        backgroundSize: 'cover!important'
    },
    section_1: {
        //paddingTop: theme.spacing(4),
        height: '64px!important',
        background: 'url(assets/images/skyscrapers.jpeg) no-repeat center center'
    },
    section_2: {
        background: '#e2e2e2'
    },
    shadowLayer: {
        background: '#474B57',
        opacity: .5,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0
    },
    iframe: {
        width: '100%',
        height: '50vh'
    },
    section_3: {
        height: '100%',
        padding: theme.spacing(8)
    },
    section_divider: {
        background: grey[50],
        height: 10,
    },
    whiteAvatar: {
        backgroundColor: 'white',
    },
    card: {
        height: '100%',
    },
    cardHeader: {
        paddingBottom: 0
    },
    cardTitle:{
        fontWeight: 'bold',
    },
    cardContent: {
        overflow: 'hidden',
        paddingTop: 0
    },
    listItemIcon: {
        minWidth: 30
    }
}));

const HomePage = ({history}) => {
    const classes = useStyles();
    const [news, setNews] = useState([])
    const fetchFeed = async () => {
        const {feed} = await FeedRss.query();
        await setNews(feed)
    }
    useEffect(() => {
        fetchFeed()
    },[])

    const newsTitle = ((envConfig.CUSTOMER ?? "default") == "Sole24") ? "Osservatorio Top24 Diritto" : "231: Novità e approfondimenti";

    const newsSection = useMemo(() => {
        if(news.length===0) return (
            <Grid container justify="center">
                <Grid item xs={12} style={{marginTop: 10, textAlign: 'center'}}>
                    <Typography variant={"body1"}>
                        Non sono presenti news al momento
                    </Typography>
                </Grid>
            </Grid>
        )
        const newsList = []
        news.map((item,i) => {
            newsList.push(
                <Grid key={i} item xs={12} lg={3} className={classes.homeCards}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container justify="center">
                                <Grid item xs={12} md={12}>
                                    <Typography gutterBottom variant="subtitle1">Notizia del {item.date}</Typography>
                                    <Typography gutterBottom component="p" className={classes.cardTitle}>
                                        {item.title}
                                    </Typography>
                                    <Divider variant="middle" style={{marginTop: 15, marginBottom: 15}} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {item.content}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid container justify="center" alignItems="flex-end">
                                <Grid item xs={12} style={{marginTop: 30, textAlign: 'center'}}>
                                    <Button variant="contained" color="primary" href={item.link} target="_blank">
                                        Leggi Articolo
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            )
        })
        return newsList
    },[news])
    return (
        <Grid container direction={"column"}>
            <Grid item>
                <Grid container justify={"flex-start"} className={[classes.section_3].join(' ')} spacing={2} style={{width: '100%', minHeight: '800px'}} alignItems="stretch">
                    <Grid item xs={12} style={{marginBottom: 40}}>
                        <Typography align={"center"} variant={"h6"}>{ newsTitle } </Typography>
                    </Grid>
                    {newsSection}
                    <Grid container justify="center">
                        <Grid item xs={12} style={{marginTop: 100, textAlign: 'center'}}>
                            <Button variant="contained" color="primary" href={'/homepage'}>
                                <Icon>arrow_back</Icon> Torna alla Home
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

HomePage.propTypes = {};

export default withRouter(HomePage);
