import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import {ConfigApi} from "../services/api.service";

const useStyles = makeStyles(theme => ({
    absoluteBar: {
        background: theme.palette.primary[theme.palette.mode],
        boxShadow: 'none'
    },
    stickyBar: {
        backgroundColor: theme.palette.primary[theme.palette.mode],
        marginBottom: '2rem'
    },
    logo: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)'
    },
    body: {
        marginTop: 'auto',
        paddingTop: '64px',
        marginBottom: 'auto'
    }
}));

const ClearLayout = ({children, toolbarPosition, history}) => {
    const configApi = new ConfigApi();
    const classes = useStyles();
    const [logo, setLogo] = React.useState(false);

    useEffect(() => {
        const loadSetup = async () => {
            const setupData = await configApi.setup();
            if ( setupData && setupData.logo && setupData.logo !== '' ) {
                setLogo(setupData.logo);
            }
        };
        loadSetup();
    }, []);
    return (
        <Grid container direction={"column"} style={{position: 'relative', minHeight: '100vh'}}>
            <Grid item>
                <AppBar position={toolbarPosition}
                        className={toolbarPosition === 'absolute' ? classes.absoluteBar : classes.stickyBar}>
                    <Toolbar>
                        <Button onClick={() => history.push('/')} color={"inherit"} className={classes.logo}>
                            {logo && <img src={logo} alt={'valore24 logo'}
                                          height={'40px'}/>}
                            {!logo && <CircularProgress />}
                        </Button>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item className={classes.body}>
                {children}
            </Grid>
        </Grid>
    );
};

ClearLayout.defaultProps = {
    toolbarPosition: 'sticky'
};

ClearLayout.propTypes = {
    toolbarPosition: PropTypes.string
};


export default withRouter(ClearLayout);
