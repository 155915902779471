import React, { useEffect, useLayoutEffect } from "react";
import { Grid, CircularProgress, Typography } from "@material-ui/core";

import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Step0 from "../../components/risk-assessment-steps/step0";
import Step1 from "../../components/risk-assessment-steps/step1";
import Step2 from "../../components/risk-assessment-steps/step2";
import Step3 from "../../components/risk-assessment-steps/step3";
import RiskStepper from "../../components/risk-assessment-stepper/RiskStepper";
import {
  ImpattoApi,
  ProbabilitaApi,
  PresidiApi,
  ValutazioneApi,
  CompanyApiNew,
} from "../../services/api.service";
import {
  VALUTAZIONE,
  PRESIDI,
  PROBABILITA,
  IMPATTO,
  isImpatto,
  isProbabilita,
  isPresidi,
  isCompiledPresidi,
  isCompiledProbabilita,
  isCompiledImpatto,
  isValutazione,
} from "../../constants/graph";
import _ from "lodash";
import AuthService from "../../services/auth.service";

import "../../constants/styles.css";
import { connect } from "react-redux";
import RiskAreasSelect from "../../components/ui/risk-areas-select/risk-areas-select.component";
import ViolationSelect from "../../components/ui/violation-select/violation-select.component";

function getSteps() {
  return ["IMPATTO", "PROBABILITÀ", "PRESIDI", "VALUTAZIONE"];
}

const valutazioneApi = new ValutazioneApi();
const impattoApi = new ImpattoApi();
const probabilitaApi = new ProbabilitaApi();
const presidiApi = new PresidiApi();

const ViewRiskAssessment = ({ location }) => {
  const { reato, area, companyId } = location.state;

  const [isLoading, setLoading] = React.useState(false);
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(0);

  const truncate = (label) => {
    return label.substring(0, 40) + "...";
  };

  const impattoDefaultValues = {
    economico: 3,
    operativo: 3,
    immagine_rep: 3,
  };
  ///impatto
  const [impatto, setImpatto] = React.useState(impattoDefaultValues);

  const probabilitaDefaultValues = {
    incentivi_management: 3,
    rapporti_pa_terzi: 3,
    frequenza: 3,
    rilevanza: 3,
    prevedibilita: 3,
    outsourcing: 3,
  };
  //probabilita
  const [probabilita, setProbabilita] = React.useState(
    probabilitaDefaultValues
  );

  //presidi
  const [presidi, setPresidi] = React.useState({
    procedure: 3,
    segregazione: 3,
    deleghe: 3,
    tracciabilita: 3,
    monitoraggio: 3,
  });

  //valutazione
  const [valutazione, setValutazione] = React.useState(null);

  useLayoutEffect(() => {
    if (isImpatto(activeStep)) {
      const fetchImpatto = async () => {
        setLoading(true);
        const data = await impattoApi.fromCombination({
          area_id: area.uuid,
          reato_id: reato.uuid
        });
        setLoading(false);
        const { economico, operativo, immagine_rep } = data.data;
        setImpatto({
          economico,
          operativo,
          immagine_rep,
        });
      };
      fetchImpatto();
    } else if (isProbabilita(activeStep)) {
      const fetchProbabilita = async () => {
        setLoading(true);
        const data = await probabilitaApi.fromCombination({
          area_id: area.uuid,
          reato_id: reato.uuid
        });
        setLoading(false);
        const {
          outsourcing,
          incentivi_management,
          rapporti_pa_terzi,
          frequenza,
          rilevanza,
          prevedibilita,
        } = data.data;
        setProbabilita({
          incentivi_management,
          rapporti_pa_terzi,
          frequenza,
          rilevanza,
          prevedibilita,
          outsourcing,
        });
      };
      fetchProbabilita();
    } else if (isPresidi(activeStep)) {
      const fetchPresidi = async () => {
        setLoading(true);
        const data = await presidiApi.fromCombination({
          area_id: area.uuid,
          reato_id: reato.uuid
        });
        const {
          procedure,
          segregazione,
          deleghe,
          tracciabilita,
          monitoraggio,
        } = data.data;
        setPresidi({
          procedure,
          segregazione,
          deleghe,
          tracciabilita,
          monitoraggio,
        });
        setLoading(false);
      };
      fetchPresidi();
    } else if (isValutazione(activeStep)) {
      const fetchValutazione = async () => {
        setLoading(true);
        try {
          const data = await valutazioneApi.fromCombination({
            area_id: area.uuid,
            reato_id: reato.uuid
          });
          setValutazione(data);
        } catch (e) {
          setValutazione({});
        }
        setLoading(false);
      };
      fetchValutazione();
    }
  }, [activeStep, companyId]);

  const handleSaveStep = async () => {
    if (isImpatto(activeStep)) {
      setActiveStep(PROBABILITA);
    } else if (isProbabilita(activeStep)) {
      setActiveStep(PRESIDI);
    } else if (isPresidi(activeStep)) {
      setActiveStep(VALUTAZIONE);
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      ///save step
      setActiveStep(activeStep - 1);
    }
  };

  const renderStep = () => {
    if (isLoading) {
      return (
        <Grid container justify={"center"} spacing={4} xs={12}>
          <CircularProgress size={40} />
        </Grid>
      );
    }
    switch (activeStep) {
      case 0:
        return (
          <Step0
            disabled={true}
            reato={reato}
            impatto={impatto}
            setImpatto={setImpatto}
            handleSaveStep={handleSaveStep}
          />
        );
      case 1:
        return (
          <Step1
            disabled={true}
            probabilita={probabilita}
            setProbabilita={setProbabilita}
            handleSaveStep={handleSaveStep}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <Step2
            disabled={true}
            area={area}
            reato={reato}
            presidi={presidi}
            setPresidi={setPresidi}
            handleSaveStep={handleSaveStep}
            handleBack={handleBack}
          />
        );
      case 3:
        return <Step3 valutazione={valutazione} handleBack={handleBack} />;
    }
  };

  return (
    <>
      <Grid container className={"riskBigContainerWrapper"} justify={"center"}>
        <Grid item
          container
          className={"headerContainer"}
          justify={"center"}
          xs={12}
          md={12}
          spacing={4}
          lg={8}
        >
          <Grid item xs={12}>
            <BreadcrumbComponent />
          </Grid>
          <Grid item xs={12} md={6} className={"riskContainer"}>
            <RiskAreasSelect
              disabled={true}
              companyId={companyId}
              area={area}
              reato={reato}
              label={truncate(area.are_titolo)}
            />
          </Grid>
          <Grid item xs={12} md={6} className={"violationContainer"}>
            <ViolationSelect
              disabled={true}
              companyId={companyId}
              reato={reato}
              area={area}
              label={truncate(reato.rea_titolo)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justify={"center"}
          style={{ backgroundColor: "#adc0bd" }}
          xs={12}
        >
          <Grid item xs={12} md={12} lg={8}>
            <Grid
              className={"riskBigContainer"}
              container
              justify={"center"}
              spacing={4}
              style={{ margin: 0 }}
            >
              <Grid container xs={12} className={"riskWrapper"}>
                <Grid item xs={12} className={"risksStep"}>
                  <Stepper
                    className={"riskStepper"}
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<RiskStepper />}
                  >
                    {steps.map((label, index) => (
                      <Step
                        className={"riskStep"}
                        onClick={handleStep(index)}
                        key={label}
                      >
                        <Typography className={"riskStepLabel"}>
                          {label}
                        </Typography>
                        <StepLabel className={"riskStepNumber"} />
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid
                  container
                  className={"valueContainer"}
                  style={{ alignItems: "center" }}
                >
                  {renderStep()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={"gridFooterGray"} xs={12} />
    </>
  );
};

export default ViewRiskAssessment;
