import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import User from "../../../services/auth.service";

const PageTitleComponent = ({title, subtitle}) => {
    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid item>
                <Typography variant={"h4"} align={"center"} style={{fontWeight: 500}}>{title}</Typography>
            </Grid>
            <Grid item>
                <Typography variant={"body1"} align={"center"}>{subtitle || User.company.com_name}</Typography>
            </Grid>
        </Grid>
    );
};
PageTitleComponent.defaultProps = {
    title: '',
    subtitle: ''
};

PageTitleComponent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired
};

export default PageTitleComponent;
