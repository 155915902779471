import React, {useEffect} from 'react';
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {Link as RouteLink, withRouter} from 'react-router-dom';
import AuthService from "../../services/auth.service";
import {handleToast} from "../../redux/actions";
import {connect} from "react-redux";
import useForm from "react-hook-form";
import {ConfigApi} from "../../services/api.service";

const ResetPasswordPage = ({dispatch, history, location: {search}}) => {
    const configApi = new ConfigApi();
    const recoverPassword = useForm();
    const resetPassword = useForm();
    const token = search.replace('?token=', '');
    const [loginLogo, setLoginLogo] = React.useState(null);

    useEffect(() => {
        const loadPalette = async () => {
            const configData = await configApi.setup();
            if ( configData?.logo_login ) {
                setLoginLogo(configData?.logo_login);
            }
        };
        loadPalette();
    }, []);
    const recover = async (credentials) => {
        try {
            await AuthService.resetPassword(credentials);
            dispatch(handleToast({
                message: 'Se un utente corrisponde all\'email inserita, troverai un nuovo messaggio con le istruzioni per il reset della password'
            }));
        } catch (message) {
            dispatch(handleToast({
                message
            }));
        }
    };
    const reset = async (credentials) => {
        try {
            await AuthService.resetPassword({
                ...credentials,
                token
            });
            dispatch(handleToast({
                message: 'Nuova password salvata! Accedi al tuo account'
            }));
            history.push('/login')
        } catch (message) {
            dispatch(handleToast({
                message
            }));
        }
    };
    const samePassword = (value) => value === resetPassword.watch('password') ? true : 'Le due password non coincidono';
    return (
        <Container maxWidth={"sm"}>
            <Grid container direction={"column"} justify={"center"} spacing={4}>
                <Grid item>
                    {loginLogo && <img src={loginLogo} alt={'Logo'} width={'auto'}
                                       height={'60px'} style={{margin: '0 auto', display: 'block'}}/>}
                </Grid>
                {!token && <form onSubmit={recoverPassword.handleSubmit(recover)}>
                    <Grid item>
                        <TextField type="text" label="Email" name="email" margin="normal"
                                   error={!!recoverPassword.errors.email}
                                   helperText={recoverPassword.errors.email ? resetPassword.errors.email ? resetPassword.errors.email  : 'Campo obbligatorio' : ''}
                                   inputRef={recoverPassword.register({required: 'Campo obbligatorio'})} fullWidth={true}/>

                    </Grid>
                    <Grid item>
                        <Button type="submit" color={"primary"} variant={"contained"} fullWidth>
                            manda email
                        </Button>
                    </Grid>
                </form>}
                {token && <form onSubmit={resetPassword.handleSubmit(reset)}>
                    <Grid item>
                        <TextField type="password" label="Password" name="password" margin="normal"
                                   error={!!resetPassword.errors.password}
                                   helperText={resetPassword.errors.password ? resetPassword.errors.password.message : ''}
                                   inputRef={resetPassword.register({required: 'Campo obbligatorio'})} fullWidth={true}/>

                    </Grid>
                    <Grid item>
                        <TextField type="password" label="Ripeti password" name="passwordRepeat" margin="normal"
                                   error={!!resetPassword.errors.passwordRepeat}
                                   helperText={resetPassword.errors.passwordRepeat ? resetPassword.errors.passwordRepeat.message : ''}
                                   inputRef={resetPassword.register({validate: samePassword})}
                                   fullWidth={true}/>

                    </Grid>
                    <Grid item>
                        <Button type="submit" color={"primary"} variant={"contained"} fullWidth>
                            reset
                        </Button>
                    </Grid>
                </form>}
                <Grid item container justify={"center"}>
                    <Grid item>
                        <Link color={"primary"} align={"center"} component={RouteLink} to={'/login'}>
                            Torna al login
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default connect()(withRouter(ResetPasswordPage));
