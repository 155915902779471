const APP_ROUTES = [
  {
    component: require("./pages/home/changelog.page").default,
    routePath: "/changelog",
    name: "changelog",
    layout: "home-default",
    toolbarPosition: "absolute",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/safeguarding-policy/safeguarding-policy.page.js").default,
    routePath: "/safeguarding-policy",
    name: "safeguarding-policy",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/home/home.page").default,
    routePath: "/old-home",
    name: "old-home",
    layout: "default",
    toolbarPosition: "absolute",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/home/new-home.page").default,
    routePath: "/homepage",
    name: "homepage",
    layout: "home-default",
    toolbarPosition: "absolute",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/news/news.page").default,
    routePath: "/news",
    name: "news",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/profile/profile.page").default,
    routePath: "/profilo",
    name: "profilo",
    layout: "profile",
    showBackButton: true,
    toolbarPosition: "fixed",
    visibility: ["accounts"],
  },
  {
    component: require("./pages/help-module/help-module.page").default,
    routePath: "/faq/richiesta-supporto",
    name: "help-module",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/tutorial/tutorial.page").default,
    routePath: "/faq/tutorial",
    name: "tutorial",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/insert-risk-assessment/insert-risk-assessment.page")
      .default,
    routePath: "/risk-assessment/nuova-valutazione",
    name: "nuova-valutazione",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
    exact: true,
  },
  {
    component: require("./pages/edit-risk-assessment/modifica-valutazione")
      .default,
    routePath: "/risk-assessment/modifica-valutazione",
    name: "modifica-valutazione",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
    exact: true,
  },
  {
    component: require("./pages/view-risk-assessment/view-risk-assessment.page")
      .default,
    routePath: "/risk-assessment/visualizza-valutazione",
    name: "visualizza-valutazione",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
    exact: true,
  },
  {
    component: require("./pages/elenco-valutazioni/elenco-valutazioni.page")
      .default,
    routePath: "/risk-assessment/elenco-valutazioni",
    name: "violation-categories",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/violation-categories/violation-categories.page")
      .default,
    routePath: "/reati-231/categorie-reati-231",
    name: "violation-categories",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/violation-231-list/violation-231-list.page")
      .default,
    routePath: "/reati-231/elenco-reati-231",
    name: "violation-231-list",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
    exact: true,
  },
  {
    component: require("./pages/violation-231/violation-231.page").default,
    routePath: "/reati-231/elenco-reati-231/:mode-reato",
    name: "violation-231",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/select-company/select-company.page").default,
    routePath: "/seleziona-utenza",
    name: "select-company",
    layout: "no-toolbar",
    toolbarPosition: "fixed",
    visibility: ["accounts"],
  },
  {
    component: require("./pages/login/login.page").default,
    routePath: "/login",
    name: "login",
    layout: "no-toolbar",
    toolbarPosition: "fixed",
    visibility: ["noAuth"],
  },
  {
    component: require("./pages/reset-password/reset-password.page").default,
    routePath: "/reset-password",
    name: "reset-password",
    layout: "no-toolbar",
    toolbarPosition: "fixed",
    visibility: ["noAuth"],
  },
  {
    component: require("./pages/account-management/account-management.page")
      .default,
    routePath: "/gestione-account/",
    name: "account-management",
    layout: "clear",
    toolbarPosition: "fixed",
    visibility: ["admin"],
    exact: true,
  },
  {
    component: require("./pages/account-activation/account-activation.page")
      .default,
    routePath: "/gestione-account/attivazione",
    name: "account-activation",
    layout: "clear",
    toolbarPosition: "fixed",
    visibility: ["admin"],
  },
  {
    component: require("./pages/account-shopping/account-shopping.page")
      .default,
    routePath: "/gestione-account/acquisti",
    name: "account-shopping",
    layout: "clear",
    toolbarPosition: "fixed",
    visibility: ["admin"],
  },
  {
    component: require("./pages/account-cancellation/account-cancellation.page")
      .default,
    routePath: "/gestione-account/disdetta",
    name: "account-cancellation",
    layout: "clear",
    toolbarPosition: "fixed",
    visibility: ["admin"],
  },
  {
    component: require("./pages/account-suspend/account-suspend.page").default,
    routePath: "/gestione-account/sospensione",
    name: "account-suspend",
    layout: "clear",
    toolbarPosition: "fixed",
    visibility: ["admin"],
  },
  {
    component: require("./pages/account-reactivation/account-reactivation.page")
      .default,
    routePath: "/gestione-account/riattivazione",
    name: "account-reactivation",
    layout: "clear",
    toolbarPosition: "static",
    visibility: ["admin"],
  },
  {
    component: require("./pages/account-success/account-success.page").default,
    routePath: "/gestione-account/conferma-:action",
    name: "active-account",
    layout: "clear",
    toolbarPosition: "static",
    visibility: ["admin"],
  },
  {
    component: require("./pages/matrix-risk-area/risk-area-matrix.page")
      .default,
    routePath: "/matrici/aree-di-rischio-&-reati-231",
    name: "risk-area-matrix",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/matrix-area-functions/area-functions-matrix.page")
        .default,
    routePath: "/matrici/aree-di-rischio-&-funzioni",
    name: "risk-area-functions",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/risk-assessment-matrix/risk-assessment-matrix.page")
        .default,
    routePath: "/matrici/schemi-di-controllo-&-matrici",
    name: "risk-assessment-matrix",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/matrix-protocols-risk-area/matrix-protocols-risk-area.page")
      .default,
    routePath: "/matrici/protocolli-&-aree-di-rischio",
    name: "risk-area-protocols-matrix",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/matrix-behavior-indicator-risk-area/matrix-behavior-indicator-risk-area.page")
      .default,
    routePath: "/matrici/indicatori-comportamentali-&-aree-di-rischio",
    name: "matrix-behavior-indicator-risk-area",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/matrix-flow-risk-area/matrix-flow-risk-area.page")
      .default,
    routePath: "/matrici/flussi-&-aree-di-rischio",
    name: "matrix-flow-risk-area",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/matrix-functions-referents/matrix-functions-referents.page")
      .default,
    routePath: "/matrici/funzioni-&-referenti",
    name: "matrix-flow-referent",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/matrix-flow-functions/matrix-flow-functions.page")
      .default,
    routePath: "/matrici/flussi-&-funzioni",
    name: "matrix-flow-functions",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/risk-area/risk-area.page").default,
    routePath: "/schemi-di-controllo/aree-di-rischio",
    name: "risk-area",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/risk-area-typology/risk-area-typology.page")
      .default,
    routePath: "/schemi-di-controllo/tipologie-aree-di-rischio",
    name: "risk-area-typology",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/protocols/protocols.page").default,
    routePath: "/schemi-di-controllo/protocolli",
    name: "protocols",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/behavior-indicator/behavior-indicator.page")
      .default,
    routePath: "/schemi-di-controllo/indicatori-comportamentali",
    name: "protocols",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/functions/functions.page").default,
    routePath: "/flussi-OdV/funzioni",
    name: "functions",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/referents/referents.page").default,
    routePath: "/flussi-OdV/referenti",
    name: "referents",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/risk-referents/risk-referents.page").default,
    routePath: "/risk-assesment/referenti",
    name: "risk-referents",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/odv-referents/odv-referents.page").default,
    routePath: "/flussi-OdV/utenti",
    name: "odv-utenti",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/odv-referents/odv-referents.page").default,
    routePath: "/flussi-OdV/utenti",
    name: "odv-utenti",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/delegated-admins/delegated-admins.page").default,
    routePath: "/admin/utenti",
    name: "delegated-admins",
    layout: "default",
    showBackButton: true,
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/functions/functions.page").default,
    routePath: "/flussi-OdV/responsabilità",
    name: "functions",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/flow-structure/flow-structure.page").default,
    routePath: "/flussi-OdV/struttura-flussi",
    name: "flow-structure",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/flow-collection-new/flow-collection-new.page")
      .default,
    routePath: "/flussi-OdV/raccolta-flussi/crea-raccolta",
    name: "flow-collection",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/flow-collection-manage/flow-collection-manage.page")
      .default,
    routePath: "/flussi-OdV/raccolta-flussi/gestisci-raccolta/:uuid",
    name: "manage-flow-collection",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/flow-collection-close/flow-collection-close.page")
      .default,
    routePath: "/flussi-OdV/raccolta-flussi/chiudi-raccolta/:uuid",
    name: "close-flow-collection",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/flow-collection-detail/flow-collection-detail.page")
      .default,
    routePath: "/flussi-OdV/raccolta-flussi/riepilogo-raccolta/:uuid",
    name: "detail-closed-flow-collection",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/flow-collection/flow-collection.page").default,
    routePath: "/flussi-OdV/raccolta-flussi/",
    exact: true,
    name: "flow-collection",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/flow-structure-detail/flow-structure-detail.page")
      .default,
    routePath: "/flussi-OdV/flusso/:flowId?",
    name: "flow-collection",
    layout: "form-builder",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/compile-form/compile-form.page").default,
    routePath: "/flussi-OdV/raccolta-flussi/compila-form/:ref_uuid/:form_uuid",
    name: "flow-compile",
    layout: "clear",
    toolbarPosition: "fixed",
    visibility: ["all"],
  },
  {
    component: require("./pages/compiled-form/compiled-form.page").default,
    routePath: "/flussi-OdV/raccolta-flussi/visualizza-form/:uuid",
    name: "flow-compiled",
    layout: "clear",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/account-profile/account-profile.page").default,
    routePath: "/profilo-utenza/",
    name: "account-profile",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts"],
  },
  {
    component: require("./pages/organizational-model/generic-sections.page")
      .default,
    routePath: "/modello-organizzativo/parte-generale",
    name: "general-sections",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/organizational-model/specific-sections.page")
      .default,
    routePath: "/modello-organizzativo/parte-speciale",
    name: "specific-sections",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/organizational-model/ethical-code.page")
      .default,
    routePath: "/modello-organizzativo/codici-etici",
    name: "ethical-code",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/organizational-model/behaviour-principle.page")
      .default,
    routePath: "/modello-organizzativo/principi-comportamentali",
    name: "behaviour-principle",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/organizational-model/wis-procedure.page")
      .default,
    routePath: "/modello-organizzativo/procedura-whistleblowing",
    name: "procedura-whistleblowing",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/organizational-model/odv-terms.page").default,
    routePath: "/modello-organizzativo/regolamento-odv",
    name: "regolamento-odv",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/account-creation/account-creation.page")
      .default,
    routePath: "/aggiungi-utenza",
    name: "account-creation",
    layout: "clear",
    toolbarPosition: "fixed",
    visibility: ["accounts"],
  },
  {
    component: require("./pages/account-management/account-management.page")
      .default,
    routePath: "/",
    name: "account-management",
    layout: "clear",
    toolbarPosition: "fixed",
    visibility: ["admin"],
    exact: true,
  },
  {
    component: require("./pages/permission-error/permission-error.page").default,
    routePath: "/permission-error",
    name: "permission-error",
    layout: "default",
    toolbarPosition: "absolute",
    visibility: ["accounts", "super_admin"],
    exact: true,
  },
  {
    component: require("./pages/filesystem/valutazioni.page").default,
    routePath: "/filesystem/valutazioni",
    name: "filesystem-valutazioni",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/filesystem/parte-generale.page").default,
    routePath: "/filesystem/modelli-generali",
    name: "filesystem-modelli-generali",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/filesystem/parte-speciale.page").default,
    routePath: "/filesystem/modelli-speciali",
    name: "filesystem-modelli-speciali",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/filesystem/codici-etici.page").default,
    routePath: "/filesystem/codici-etici",
    name: "filesystem-codici-etici",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/filesystem/principi-comportamentali.page").default,
    routePath: "/filesystem/principi-comportamentali",
    name: "filesystem-principi-comportamentali",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/filesystem/procedure-whistleblowing.page").default,
    routePath: "/filesystem/procedure-whistleblowing",
    name: "filesystem-procedure-whistleblowing",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/filesystem/regolamento-odv.page").default,
    routePath: "/filesystem/regolamento-odv",
    name: "filesystem-regolamento-odv",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/filesystem/generale.page").default,
    routePath: "/filesystem/generale",
    name: "filesystem-generale",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/filesystem/filesystem.page").default,
    routePath: "/filesystem",
    name: "filesystem",
    layout: "default",
    toolbarPosition: "fixed",
    visibility: ["accounts", "super_admin"],
  },
  {
    component: require("./pages/select-company/select-company.page").default,
    routePath: "/",
    name: "select-company",
    layout: "profile",
    showBackButton: false,
    toolbarPosition: "fixed",
    visibility: ["accounts"],
  },
  {
    component: require("./pages/home/home.page").default,
    routePath: "/",
    name: "homepage",
    layout: "default",
    toolbarPosition: "absolute",
    visibility: ["super_admin"],
  },
];

export default APP_ROUTES;
