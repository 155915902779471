import React from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import CtaBoxComponent from "../../components/ui/cta-box/cta-box.component";
import {handleModal} from "../../redux/actions";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import RiskAreaComponent from "../../components/CRUD/risk-area/risk-area.component";
import RiskAreaTypologyComponent from "../../components/CRUD/risk-area-typology/risk-area-typology.component";
import RiskAreaTypologyTable from "../../components/atomic-tables/risk-area-typology/risk-area-typology.table";

const RiskAreaTypologyPage = ({dispatch}) => {
    const createRiskArea = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Aggiungi Area di Rischio',
            content: <RiskAreaComponent mode={'create'}/>
        }));
    };
    const createTypology = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Aggiungi tipologia',
            content: <RiskAreaTypologyComponent mode={'create'}/>,
        }));
    };
    const actions = [
        {
            label: 'Aggiungi tipologia area di rischio',
            handler: createTypology
        },
        {
            label: 'Aggiungi area di rischio',
            handler: createRiskArea
        }
    ];
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Tipologie aree di Rischio'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"space-between"} alignItems={"center"} spacing={2} style={{margin: 0}}>
                            <Grid item xs>
                                <Typography variant={"subtitle2"}>Elenco tipologie aree di rischio</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={createTypology}>Aggiungi tipologia</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <RiskAreaTypologyTable/>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>
                        <CtaBoxComponent title={'Aggiungi area di rischio o tipologia di area'} actions={actions}/>
                    </Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default connect()(RiskAreaTypologyPage);
