import StepConnector from "@material-ui/core/StepConnector";
import { withStyles } from "@material-ui/styles";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      // borderColor: "#154B44",
      border: "2px solid",
    },
  },
  completed: {
    "& $line": {
      // borderColor: "#154B44",
      border: "2px solid",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export default QontoConnector;
