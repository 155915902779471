export const GRC_USER = "GRC_USER";
export const GRC_SELECTED_COMPANY = "GRC_SELECTED_COMPANY";
export const SAVE_USER = "SAVE_USER";
export const SAVE_COMPANY = "SAVE_COMPANY";
export const TOAST_HANDLER = "TOAST_HANDLER";
export const MODAL_HANDLER = "MODAL_HANDLER";
export const DRAWER_HANDLER = "DRAWER_HANDLER";
export const TOGGLE_VIEW_MODE = "TOGGLE_VIEW_MODE";
export const FETCH_HANDLER = "FETCH_HANDLER";
export const EDIT_MODE_ON = "EDIT_MODE_ON";
export const EDIT_MODE_OFF = "EDIT_MODE_OFF";
