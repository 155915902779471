import React, {useState} from 'react';
import { Bar } from 'react-chartjs-2';
import {Typography} from "@material-ui/core";
import {HomePageApi} from "../../services/api.service";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import BigAreaProtocolliReatiChart from "./BigAreeProtocolliReatiChart";

const HomeApi = new HomePageApi();

const MODAL_STYLES = {
    position: "absolute",
    backgroundColor: "#FFF",
    padding: "15px",
    zIndex: "1000",
    width: "35%",
    borderRadius: ".5em"
};

const OVERLAY_STYLE = {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, .8)",
    zIndex: "1000",
    overflowY: "auto"
};

const useStyles = makeStyles((theme) => ({
    /*modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '90%',
        height: '90%'
    },*/
    modal: {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0, .8)",
        zIndex: "1000",
        overflowY: "scroll"
    },
    modalPaper: {
        position: "absolute",
        backgroundColor: "#FFF",
        padding: "16px",
        zIndex: "1000",
        width: "90%",
        borderRadius: ".5em",
        margin: "16px"
    },
    chartHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    openMoreButton: {
        padding: 0,
        fontSize: '1rem'
    }
}));

export default function AreaProtocolliReatiChart() {
    const [chartData, setChartData] = useState(null);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        const fetchData = async () => {
            const graphData = await HomeApi.pullGraphData();
            if ( graphData !== undefined && graphData.areeLabel !== undefined && graphData.areeProtocolliCount !== undefined && graphData.areeReatiCount !== undefined ) {
                let labels = graphData.areeLabel;
                let newCharData = {
                    labels,
                    datasets: [
                        {
                            label: 'Reati',
                            data: graphData.areeReatiCount,
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            label: 'Protocolli',
                            data: graphData.areeProtocolliCount,
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                    ],
                };

                setChartData(newCharData);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <div className={classes.chartHeader}>
                <Typography variant="h6">
                    Reati e Protocolli per Aree di rischio
                </Typography>
                <IconButton className={classes.openMoreButton} onClick={handleOpen}>
                    <Icon style={{ fontSize: '1rem' }}>open_in_full</Icon>
                </IconButton>
            </div>
                {chartData && <Bar
                    data={chartData}
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        legend: { display: true, position: "bottom" },
                        tooltips: {
                            callbacks: {
                                title: function(tooltipItem, data) {
                                    return data['labels'][tooltipItem[0]['index']];
                                }
                            }
                        },
                        scales: {
                            xAxes: [{
                                ticks: {
                                    callback: function(label) {
                                        return label.substring(0,10);
                                    }
                                }
                            }]
                        }
                    }}
                    height={285}
                />}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.modalPaper}>
                        <BigAreaProtocolliReatiChart />
                    </div>
                </Fade>
            </Modal>
        </>
    );
}
