import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardActions, CardContent, Grid, MenuItem, TextField, Typography } from '@material-ui/core'

import RiskAreaTypologiesSelectComponent from '../ui/risk-area-typologies-select/risk-area-typologies-select.component'
import { RiskAreaApi } from '../../services/api.service'
import { connect } from 'react-redux'
import { useFormContext } from 'react-hook-form'

const RiskArea = new RiskAreaApi()
const RiskAssessmentMatrixFilters = ({ api }) => {
  const {watch, errors, setValue, register} = useFormContext()
	const [riskAreas, setRiskAreas] = useState([])

  const filteredRiskAreas = useMemo(() => {
    const typology = watch('risk_typology')
    const areas = riskAreas.filter(area => !!area.active)

		if (!typology) return areas
    
		return areas.filter(({aree_rischio_tipologie}) => aree_rischio_tipologie?.uuid === typology)
	}, [riskAreas, watch('risk_typology')])

  const onClickResetFilters = useCallback(() => {
		setValue('risk_typology', null)
		setValue('risk_area', null)
	}, [])

  useEffect(() => {
    const fetchTypologies = async () => {
      const aree_rischio = await RiskArea.query()
      setRiskAreas(aree_rischio)
    }

    fetchTypologies()
  }, [api.fetch])

  useEffect(() => {
    setValue('risk_area', null)
  }, [watch('risk_typology')])

  return (
    <Grid item xs={12} md={10} lg={8} style={{ padding: '100px 0 100px 25px' }}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography variant="h5">
            Selezione
          </Typography>
          <RiskAreaTypologiesSelectComponent
            name="risk_typology"
          />

          <TextField
            select
            fullWidth
            style={{ marginTop: '15px' }}
            label="Area di rischio"
            placeholder="Seleziona area di rischio"
            inputRef={register({ name: 'risk_area' })}
            value={watch('risk_area') || ''}
            error={!!errors.risk_area}
            name="risk_area"
            helperText={errors?.risk_area?.message ?? ''}
            onChange={e => setValue('risk_area', e.target.value)}
          >
            {filteredRiskAreas.map(({uuid, are_titolo}) => (
              <MenuItem key={uuid} value={uuid}>
                {are_titolo}
              </MenuItem>
            ))}
            {!filteredRiskAreas.length && (
              <MenuItem>Nessuna tipologia ancora presente</MenuItem>
            )}
          </TextField>
        </CardContent>
        <CardActions style={{ padding: '15px' }}>
          <Button
            size="small"
            variant="outlined"
            disabled={!watch('risk_typology') && !watch('risk_area')}
            onClick={onClickResetFilters}
          >
            Ripristina selezione
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

const mapStateToProps = ({ fetchApiReducer }) => ({
	api: fetchApiReducer
})

export default connect(mapStateToProps)(RiskAssessmentMatrixFilters)
