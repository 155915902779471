import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import AutoCompleteComponent from "../../components/ui/auto-complete/auto-complete.component";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import {BehaviorIndicatorApi, RiskAreaApi, RiskAreaIndicatoriMatrixApi} from "../../services/api.service";
import Fab from "@material-ui/core/Fab";
import {handleToast} from "../../redux/actions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import orderBy from "lodash/orderBy";

const RiskArea = new RiskAreaApi();
const RiskAreaIndicatoriMatrix = new RiskAreaIndicatoriMatrixApi();
const BehaviorIndicator = new BehaviorIndicatorApi();
const MatrixBehaviorIndicatorRiskAreaPage = ({dispatch}) => {
    const [behaviourIndicators, setBehaviourIndicators] = useState([]);
    const [riskAreas, setRiskAreas] = useState([]);
    const [filter, setFilter] = React.useState();
    useEffect(
        () => {
            const fetchData = async () => {
                const aree_rischio = await RiskAreaIndicatoriMatrix.query({
                    matrice: 'indicatori_comportamentali'
                });
                let indicatori_comportamentali = await BehaviorIndicator.query();
                indicatori_comportamentali = indicatori_comportamentali.map(
                    ({uuid, ind_titolo}) => {
                        return {
                            label: ind_titolo,
                            value: uuid
                        }
                    });
                setBehaviourIndicators(orderBy(indicatori_comportamentali, ['label'], ['asc']));
                setRiskAreas(aree_rischio.filter(area => filter ? area.are_titolo.toLowerCase().search(filter.toLowerCase()) !== -1 : true));

            };
            fetchData();
        }, [filter]
    );
    const updateRiskAreas = (riskAreaId) => (behaviourIndicators) => {
        setRiskAreas([
            ...riskAreas.map((area) => {
                if (area.uuid === riskAreaId) {
                    area.aree_rischio_indic_comp = behaviourIndicators || [];
                }
                return area;
            })
        ]);
    };
    const save = async () => {
        try {
            await Promise.all(await riskAreas.map(
                async ({aree_rischio_indic_comp, uuid}) => {
                    await RiskArea.updateBehaviourIndicators({
                        uuid,
                        indicatori_comportamentali: aree_rischio_indic_comp.map(({value, indicatori_comportamentali}) => value || (indicatori_comportamentali ? indicatori_comportamentali.uuid : undefined))
                    })
                })
            );
            dispatch(handleToast({
                message: 'Ind. comportamentali salvati'
            }));
        } catch (message) {
            dispatch(handleToast({
                message
            }))
        }
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0', position: 'relative'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Matrici - Aree di rischio per ind. comportamentali'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Toolbar>
                            <TextField label={'Cerca'} fullWidth={true}
                                    onChange={(e) => setFilter(e.target.value)}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                    }}/>
                        </Toolbar>
                    </Grid>
                    {riskAreas.map(area => {
                        const {uuid,are_titolo, aree_rischio_indic_comp} = area;
                        return (
                            <Grid item key={uuid} style={{minWidth: '100%'}}>
                                <Card style={{overflow: 'visible'}}>
                                    <CardContent>
                                        <AutoCompleteComponent suggestions={behaviourIndicators}
                                                               defaultValues={aree_rischio_indic_comp.map(({indicatori_comportamentali, label, value}) => {
                                                                   return {
                                                                       label: indicatori_comportamentali ? indicatori_comportamentali.ind_titolo : label,
                                                                       value: indicatori_comportamentali ? indicatori_comportamentali.uuid : value
                                                                   }
                                                               })}
                                                               onChange={updateRiskAreas(area.uuid)} multiple
                                                               label={are_titolo} placeholder={'Inserisci indicatori comportamentali'}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                    <Grid item xs={12}>
                        <Fab
                            onClick={save}
                            style={{
                                position: 'fixed',
                                right: '70px',
                                top: '335px',
                                width: '120px',
                            }} aria-label={'Salva'} color={'secondary'} variant={"extended"}>Salva</Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

MatrixBehaviorIndicatorRiskAreaPage.propTypes = {};

export default connect()(MatrixBehaviorIndicatorRiskAreaPage);
