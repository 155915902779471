import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import CtaBoxComponent from "../../components/ui/cta-box/cta-box.component";
import {handleModal} from "../../redux/actions";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import RiskAreaTable from "../../components/atomic-tables/risk-area/risk-area.table";
import RiskAreaComponent from "../../components/CRUD/risk-area/risk-area.component";
import RiskAreaTypologiesSelectComponent
    from "../../components/ui/risk-area-typologies-select/risk-area-typologies-select.component";
import useForm, {FormContext} from "react-hook-form";
import RiskAreaTypologyTypologyComponent from "../../components/CRUD/risk-area-typology/risk-area-typology.component";

const RiskAreaPage = ({dispatch}) => {
    const [filterBy, setFilter] = useState(null);
    const typologiesForm = useForm({
        mode: "onChange"
    });
    const filterTable = (key, value) => {
        setFilter(value ? {key, value} : null)
    };
    const createRiskArea = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Aggiungi Area di Rischio',
            content: <RiskAreaComponent mode={'create'}/>
        }));
    };
    const createTypology = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Aggiungi tipologia',
            content: <RiskAreaTypologyTypologyComponent mode={'create'}/>,
        }));
    };
    const actions = [
        {
            label: 'Aggiungi tipologia area di rischio',
            handler: createTypology
        },
        {
            label: 'Aggiungi area di rischio',
            handler: createRiskArea
        }
    ];
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent
                            subtitle={'Seleziona la tipologia di area di rischio per visualizzare le aree di rischio associate'}/>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={8}>
                            <Typography variant={"h6"}>
                                Filtra per tipologia di aree di rischio
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant={"outlined"}  color={"primary"} fullWidth onClick={createTypology}>
                                Aggiungi tipologia area di rischio
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormContext {...typologiesForm}>
                                <form>
                                    <RiskAreaTypologiesSelectComponent onChange={filterTable}/>
                                </form>
                            </FormContext>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"space-between"} alignItems={"center"} spacing={2} style={{margin: 0}}>
                            <Grid item xs>
                                <Typography variant={"subtitle2"}>Elenco aree di rischio</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={createRiskArea}>Aggiungi area di rischio</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <RiskAreaTable filterBy={filterBy}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>
                        <CtaBoxComponent title={'Aggiungi area di rischio o tipologia di area'} actions={actions}/>
                    </Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default connect()(RiskAreaPage);
