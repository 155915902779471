import React, {useEffect} from 'react';
import './App.css';
import AppLayout from "./layout/app.layout";
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {defaultTheme} from "./themes/default.theme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import APP_ROUTES from './routes';
import AuthService from './services/auth.service';
import Snackbar from "@material-ui/core/Snackbar";
import {connect} from "react-redux";
import GenericModalComponent from "./components/modals/generic-modal/generic-modal.component";
import {handleToast} from "./redux/actions";
import HelpDrawerComponent from "./components/ui/help-drawer/help-drawer.component";
import {ConfigApi} from "./services/api.service";
import {createMuiTheme} from "@material-ui/core/styles";

function getFaviconEl() {
    return document.getElementById("favicon");
}

const App = ({toast, modal, helpDrawer, dispatch, location: {pathname}}) => {
    const configApi = new ConfigApi();
    const [dynamicTheme, setDynamicTheme] = React.useState(null);
    const [useDynamicTheme, setUseDynamicTheme] = React.useState(false);
    const [paletteLoaded, setPaletteLoaded] = React.useState(false);

    useEffect(
        ()=> window.scrollTo(0, 0),
        [pathname]
    );
    useEffect(() => {
        const loadPalette = async () => {
            const paletteData = await configApi.palette();
            setDynamicTheme(createMuiTheme(paletteData));
            setUseDynamicTheme(true);
            setPaletteLoaded(true);
        };
        const loadConfig = async () => {
            const configData = await configApi.setup();
            if ( configData?.icona ) {
                let icoElement = getFaviconEl();
                icoElement.href = configData.icona;
            }
        }
        loadConfig();
        loadPalette();
    }, []);
    const routes = APP_ROUTES
        .filter(r => r.visibility.indexOf(AuthService.routePermissions()) !== -1 || r.visibility.indexOf('all') !== -1)
        .map(r => {
                let DynamicComponent = r.component;
                return <Route key={r.name} path={r.routePath} exact={r.exact}
                              render={(props) => (
                                  <AppLayout layout={r.layout} showBackButton={r.showBackButton} toolbarPosition={r.toolbarPosition}>
                                      <DynamicComponent layout={r.layout} {...props}/>
                                  </AppLayout>
                              )}/>;
            }
        );
    return (<>
        {paletteLoaded && <ThemeProvider theme={useDynamicTheme ? dynamicTheme : defaultTheme}>
            <Switch>
                {routes}
                <Redirect to={AuthService.isAuth() ? '/' : '/login'}/>
            </Switch>
            {toast && toast.message &&
            <Snackbar open={toast.open}
                      message={typeof toast.message === 'string' ? toast.message : toast.message.toString()}
                      autoHideDuration={toast.autoHideDuration} onClose={() => dispatch(handleToast({
                open: false
            }))}/>}
            {modal && <GenericModalComponent open={!!modal} body={modal.body}/>}
            {helpDrawer && <HelpDrawerComponent open={!!helpDrawer} body={helpDrawer.body}/>}
        </ThemeProvider>}</>
    );
};

const mapStateToProps = ({toastReducer: {toast}, modalReducer: {modal}, helpDrawerReducer: {drawer}}) => ({
    toast,
    helpDrawer: drawer,
    modal
});

export default connect(
    mapStateToProps
)(withRouter(App));
