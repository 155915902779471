import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import {FunctionApi} from "../../../services/api.service";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const Functions = new FunctionApi();
const FunctionsComponent = ({dispatch, mode, functionItem}) => {
    const [loading, setLoading] = useState(false);
    const addFunctionInputs = [
        {
            name: 'fun_funzione',
            required: 'campo obbligatorio',
            label: 'Titolo',
            placeholder: 'Inserisci titolo funzione',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete'
        },
        {
            name: 'fun_responsabile',
            label: 'Nome responsabile',
            placeholder: 'Inserisci nome responsabile',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete',
            validate: (value) => (!value && (functionForm.watch('fun_email') || functionForm.watch('fun_telefono'))) ? 'Nome Responsabile richiesto' : true
        },
        {
            name: 'fun_email',
            label: 'Email responsabile',
            placeholder: 'Inserisci email responsabile',
            width: 12,
            margin: 'normal',
            type: 'email',
            disabled: mode === 'read' || mode === 'delete',
            validate: (value) => (!value && (functionForm.watch('fun_responsabile') || functionForm.watch('fun_telefono'))) ? 'Email Responsabile richiesto' : true
        },
        {
            name: 'fun_telefono',
            label: 'Telefono responsabile',
            placeholder: 'Inserisci telefono responsabile',
            width: 12,
            margin: 'normal',            
            disabled: mode === 'read' || mode === 'delete',
            validate: (value) => (!value && (functionForm.watch('fun_email') || functionForm.watch('fun_responsabile'))) ? 'Telefono Responsabile richiesto' : true
        }
    ];
    const functionForm = useForm({
        mode: 'onSubmit',
        defaultValues: mode !== 'create' ? functionItem : {}
    });
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onFunctionSave = async (data) => {
        try {
            setLoading(true);
            switch (mode) {
                case 'create':
                    await Functions.create(data);
                    dispatch(handleToast({
                        message: 'Funzione creata con successo'
                    }));
                    break;
                case 'update':
                    await Functions.update({
                        uuid: functionItem.uuid,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Funzione modificata con successo'
                    }));
                    break;
                case 'delete':
                    await Functions.delete(functionItem.uuid);
                    dispatch(handleToast({
                        message: 'Funzione eliminata con successo'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <FormContext {...functionForm}>
            <form onSubmit={functionForm.handleSubmit(onFunctionSave)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm inputs={addFunctionInputs}/>
                    </Grid>
                    {mode === 'create' &&
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox onChange={(e) => functionForm.setValue('referente', e.target.checked)}
                                          inputRef={functionForm.register({name: 'referente'})}
                                          color="secondary"/>
                            }
                            label="Imposta responsabile come referente per questa funzione"/>
                    </Grid>}
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

FunctionsComponent.defaultProps = {
    functionItem: {}
};
FunctionsComponent.propTypes = {
    functionItem: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(FunctionsComponent);
