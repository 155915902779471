import axios from "axios";
import {END_POINT} from "../constant";
import AuthService from './auth.service'
import {GRC_USER} from "../redux/types";

import {fileName} from "../functions/insert-risk-assessment.helper";

export class Api {
  url = "";
  permissionNeeded = "";
  genericErrorMessage = "Si è verificato un errore nella richiesta";

  constructor(url = "", permissionNeeded = "") {
    this.url = url;
    this.permissionNeeded = permissionNeeded;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Accept'] = 'application/json';
    axios.defaults.withCredentials = true;
    if ( localStorage.getItem(GRC_USER) && localStorage.getItem(GRC_USER) !== undefined && localStorage.getItem(GRC_USER).token) {
      axios.defaults.headers.post['Authorization'] = "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token;
      axios.defaults.headers.get['Authorization'] = "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token;
      axios.defaults.headers.put['Authorization'] = "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token;
      axios.defaults.headers.delete['Authorization'] = "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token;
    }
  }

  permessi(permissionName) {
    if (localStorage.getItem('permessi_utente') && permissionName != "" && !(localStorage.getItem('permessi_utente').indexOf(permissionName) > 0) ) {
      // window.location.href = "/permission-error";
      // window.location.replace("/permission-error");

    } else {

    }
  }

  query = async (params) => {
    try {
      this.permessi(this.permissionNeeded);
      let dataToPass = {};
      if ( localStorage.getItem(GRC_USER) !== undefined && JSON.parse(localStorage.getItem(GRC_USER)).token !== undefined ) {
        if ( params === undefined ) {
          dataToPass = {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          };
        } else {
          dataToPass = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
            params: params
          };
          }
        }
      const res = await axios.get(`${END_POINT}${this.url}`, dataToPass);
      return res.data.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response) {
          const {
            response: {
              data: { message },
            },
          } = e;
          throw message;
        } else {
          throw e
        }
      } else {
        throw e;
      }
    }
  };

  schemes = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/schemes`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  get = async (uuid) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/${uuid}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  create = async (data) => {
    try {
      const res = await axios.post(`${END_POINT}${this.url}`, data, {headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }});
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  update = async ({ uuid, ...data }) => {
    try {
      data['_method'] = 'PUT'; // fix for laravel
      const res = await axios.put(`${END_POINT}${this.url}/${uuid}`, data, {headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }});
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  delete = async (uuid) => {
    try {
      const res = await axios({
        url: `${END_POINT}${this.url}/${uuid}`,
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  downloadModels = async (uuid) => {
    try {
      const res = await axios({
        url: `${END_POINT}${this.url}download/${uuid}`,
        method: "get",
        encoding: null,
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
          Accept: "application/pdf",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class FeedRssApi extends Api {
  url = "/api/feed-rss";
  permissionNeeded= "permessi.utenza.base";
}

export class AtecoCodeApi extends Api {
  url = "/api/codiciAteco";
  permissionNeeded= "permessi.utenza.base";
}

export class ActivityCheckApi extends Api {
  url = "/processiapi/v1/indicazioni_comportamentali/";
  permissionNeeded= "permessi.utenza.base";
}

export class CompanyApiNew extends Api {
  url = "/api/companies";
  permissionNeeded= "permessi.utenza.base";

  getFormUid = async (uuid) => {

    try {
      if ( !JSON.parse(localStorage.getItem(GRC_USER)) ) {
        localStorage.clear();
        document.location = '/login';
        return;
      }
        const res = await axios.get(
            `${END_POINT}${this.url}/${uuid}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                    "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
              },
            }
        );
          
        // Reload permissions for the user in this company
        let permissions = res.data.data.permissions || [];
        permissions = permissions.map((permission) => permission.name);
        localStorage.setItem('permessi_utente', JSON.stringify(permissions));

        // Let's delete the permissions from the response
        delete res.data.data.permissions;
        return res.data.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class ImpattoApi extends Api {
  create = async (data) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/impatti/store`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  edit = async (data, id) => {
    try {
      const res = await axios.put(
        `${END_POINT}/api/impatti/${id}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  massUpdateReati = async (area, data) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/impatti/massUpdate/${area}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  massUpdateArea = async (reato, data) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/impatti/massUpdateArea/${reato}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  fromCombination = async (data) => {

    try {
      const res = await axios.get(
        `${END_POINT}/api/impatti/fromCombination/${data.area_id}/${data.reato_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class FilesystemApi extends Api {
  url = '/api/fs';

  getCollectionInfo = async (collection) => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/collectionInfo/${collection}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  fullGeneraleFS = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/fullGeneraleFS`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  fullValutazioniFS = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/fullValutazioniFS`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  fullModelliGeneraliFS = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/fullModelliGeneraliFS`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  fullModelliSpecialiFS = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/fullModelliSpecialiFS`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  fullCodiciEticiFS = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/fullCodiciEticiFS`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  fullPrincipiComportamentaliFS = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/fullPrincipiComportamentaliFS`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  fullProcedureWhistleblowingFS = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/fullProcedureWhistleblowingFS`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  fullRegolamentoOdvFS = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/fullRegolamentiOdvFS`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  procedureWhistleblowing = async (uuid) => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/procedureWhistleblowing/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  regolamentiOdv = async (uuid) => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/regolamentiOdv/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  safeguardingPolicy = async (uuid) => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/safeguardingPolicy/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  codiciEtici = async (uuid) => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/codiciEtici/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  principiComportamentali = async (uuid) => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/principiComportamentali/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  // Calls for a complete list for the 'modello organizzativo' section
  modelliGenerali = async (uuid) => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/modelliGenerali/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  modelliSpeciali = async (uuid) => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/modelliSpeciali/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  // Calls for a complete list for the 'valutazioni' section
  valutazioni = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}/valutazioni`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  storeMediaPC = async (formData, uuid) => {
    try {
      const res = await axios.post(`${END_POINT}${this.url}/principiComportamentali/${uuid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  storeMediaPW = async (formData, uuid) => {
    try {
      const res = await axios.post(`${END_POINT}${this.url}/procedureWhistleblowing/${uuid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  storeMediaSP = async (formData, uuid) => {
    try {
      const res = await axios.post(`${END_POINT}${this.url}/safeguardingPolicy/${uuid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  storeMediaRO = async (formData, uuid) => {
    try {
      const res = await axios.post(`${END_POINT}${this.url}/regolamentiOdv/${uuid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  storeMediaCE = async (formData, uuid) => {
    try {
      const res = await axios.post(`${END_POINT}${this.url}/codiciEtici/${uuid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  storeMediaMG = async (formData, uuid) => {
    try {
      //let formData = new FormData();
      //formData.append("file", media);
      const res = await axios.post(`${END_POINT}${this.url}/modelliGenerali/${uuid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  storeMediaMS = async (formData, uuid) => {
    try {
      //let formData = new FormData();
      //formData.append("file", media);
      const res = await axios.post(`${END_POINT}${this.url}/modelliSpeciali/${uuid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  deleteMedia = async (uuid) => {
    try {
      return await axios.delete(`${END_POINT}${this.url}/media/delete/${uuid}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw this.genericErrorMessage;
      }
    }
  };

  storeMediaGenerali = async (formData) => {
    try {
      //let formData = new FormData();
      //formData.append("file", media);
      const res = await axios.post(`${END_POINT}${this.url}/generali`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  storeMediaValutazioni = async (formData) => {
    try {
      //let formData = new FormData();
      //formData.append("file", media);
      const res = await axios.post(`${END_POINT}${this.url}/valutazioni`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  updateGeneraliMedia = async (uuid, mediaName) => {
    try {
      const res = await axios.put(
          `${END_POINT}${this.url}/generali/${uuid}`,
          {
            name: mediaName,
            _method: 'PUT'
          },
          {
            responseType: "blob",
            headers: {
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
            }
          }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  updateMedia = async (uuid, mediaName) => {
    try {
      const res = await axios.put(
          `${END_POINT}${this.url}/valutazioni/${uuid}`,
          {
            name: mediaName,
           _method: 'PUT'
          },
          {
            responseType: "blob",
            headers: {
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
            }
          }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  downloadMediaMG = async (uuid, mediaName) => {
    try {
      const res = await axios.get(
          `${END_POINT}${this.url}/media/downloadMG/${uuid}`, {
            responseType: "blob",
            headers: {
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
            }
          }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", mediaName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  downloadMediaMS = async (uuid, mediaName) => {
    try {
      const res = await axios.get(
          `${END_POINT}${this.url}/media/downloadMS/${uuid}`, {
            responseType: "blob",
            headers: {
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
            }
          }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", mediaName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  downloadMediaPW = async (uuid, mediaName) => {
    try {
      const res = await axios.get(
          `${END_POINT}${this.url}/media/downloadPW/${uuid}`, {
            responseType: "blob",
            headers: {
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
            }
          }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", mediaName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  downloadMediaRO = async (uuid, mediaName) => {
    try {
      const res = await axios.get(
          `${END_POINT}${this.url}/media/downloadRO/${uuid}`, {
            responseType: "blob",
            headers: {
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
            }
          }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", mediaName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  downloadMediaCE = async (uuid, mediaName) => {
    try {
      const res = await axios.get(
          `${END_POINT}${this.url}/media/downloadCE/${uuid}`, {
            responseType: "blob",
            headers: {
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
            }
          }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", mediaName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  downloadMediaPC = async (uuid, mediaName) => {
    try {
      const res = await axios.get(
          `${END_POINT}${this.url}/media/downloadPC/${uuid}`, {
            responseType: "blob",
            headers: {
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
            }
          }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", mediaName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  downloadMedia = async (uuid, mediaName) => {
    try {
      const res = await axios.get(
          `${END_POINT}${this.url}/media/download/${uuid}`, {
            responseType: "blob",
            headers: {
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
            }
          }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", mediaName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  deleteModelliSpecialiMedia = async (uuid) => {
    try {
      const res = await axios.delete(
          `${END_POINT}${this.url}/modelliSpeciali/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  deleteCodiciEticiMedia = async (uuid) => {
    try {
      const res = await axios.delete(
          `${END_POINT}${this.url}/codiciEtici/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  deletePrincipiComportamentaliMedia = async (uuid) => {
    try {
      const res = await axios.delete(
          `${END_POINT}${this.url}/principiComportamentali/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  deleteProcedureWhistleblowingMedia = async (uuid) => {
    try {
      const res = await axios.delete(
          `${END_POINT}${this.url}/procedureWhistleblowing/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  deleteRegolamentoOdvMedia = async (uuid) => {
    try {
      const res = await axios.delete(
          `${END_POINT}${this.url}/regolamentiOdv/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  deleteModelliGeneraliMedia = async (uuid) => {
    try {
      const res = await axios.delete(
          `${END_POINT}${this.url}/modelliGenerali/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  deleteValutazioniMedia = async (uuid) => {
    try {
      const res = await axios.delete(
          `${END_POINT}${this.url}/valutazioni/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }
  deleteGeneraliMedia = async (uuid) => {
    try {
      const res = await axios.delete(
          `${END_POINT}${this.url}/generali/${uuid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }
}

export class ExportApi extends Api {
  xlsx = async (companyId) => {
    try {
      const res = await axios({
        url: `${END_POINT}/api/export/valutazioni/xlsx`,
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName(".xlsx")); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  xlsxLimited = async (companyId, listOfIds) => {
    try {
      const res = await axios({
        url: `${END_POINT}/api/export/valutazioni/xlsx`,
        method: "POST",
        data: {
          ids: listOfIds
        },
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName(".xlsx")); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  pdf = async (companyId) => {
    try {
      const res = await axios({
        url: `${END_POINT}/api/export/valutazioni/pdf`,
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName(".pdf")); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  pdfLimited = async (companyId, listOfIds) => {
    try {
      const res = await axios({
        url: `${END_POINT}/api/export/valutazioni/pdf`,
        method: "POST",
        data: {
          ids: listOfIds
        },
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName(".pdf")); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  getNoteUrl = () => {
    return `${END_POINT}/valutazioni/downloadNote`;
  }
}

export class ProbabilitaApi extends Api {
  create = async (data) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/probabilita/store`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  edit = async (data, id) => {
    try {
      const res = await axios.put(
        `${END_POINT}/api/probabilita/${id}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  fromCombination = async (data) => {
    try {
      const res = await axios.get(
        `${END_POINT}/api/probabilita/fromCombination/${data.area_id}/${data.reato_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  massUpdateReati = async (area, data) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/probabilita/massUpdate/${area}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  massUpdateArea = async (reato, data) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/probabilita/massUpdateArea/${reato}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class PresidiApi extends Api {
  create = async (data) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/presidi/store`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  edit = async (data, id) => {
    try {
      const res = await axios.put(
        `${END_POINT}/api/presidi/${id}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  fromCombination = async (data) => {
    try {
      const res = await axios.get(
        `${END_POINT}/api/presidi/fromCombination/${data.area_id}/${data.reato_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  massUpdateReati = async (area, data) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/presidi/massUpdate/${area}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  massUpdateArea = async (reato, data) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/presidi/massUpdateArea/${reato}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class ValutazioneApi extends Api {
  url = '/api/valutazioni/all';
  queryNew = async (companyId) => {
    try {
      const res = await axios.get(
          `${END_POINT}${this.url}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  fromCombination = async (data) => {
    try {
      const res = await axios.get(
        `${END_POINT}/api/valutazioni/fromCombination/${data.area_id}/${data.reato_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  getNotes = async (id) => {
    try {
      const res = await axios.get(
        `${END_POINT}/api/valutazioni/getNotes/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  storeNote = async (data, id) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/valutazioni/storeNote/${id}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class CompanyApi extends Api {
  url = "/api/companies";
  permissionNeeded= "permessi.utenza.base";

  create = async (data) => {
    try {
      this.permessi(this.permissionNeeded);
      if (data.com_logo) {
        const formData = new FormData();
        for (const k in data) {
          formData.append(k, data[k]);
        }
        const res = await axios.post(`${END_POINT}${this.url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
                "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
          },
        });
        return res.data;
      } else {
        const res = await axios.post(`${END_POINT}${this.url}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
                "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
          },
        });
        return res.data;
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  update = async ({ uuid, ...data }) => {
    try {
      this.permessi(this.permissionNeeded);
      if (data.com_logo) {
        const formData = new FormData();
        formData.append('_method', 'PUT');
        for (const k in data) {
          formData.append(k, data[k]);
        }
        const res = await axios.post(`${END_POINT}${this.url}/${uuid}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
                "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
          },
        });
        return res.data;
      } else {
        const res = await axios.put(`${END_POINT}${this.url}/${uuid}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
                "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
          }
        });
        return res.data;
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class FieldApi extends Api {
  url = "/flussiapi/v1/campi/";
  permissionNeeded= "flussi.odv";
}

export class ChangelogApi extends Api {
  url = '/api/changelogs';
  permissionNeeded = 'permessi.utenza.base';

  getList = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class HomePageApi extends Api {
  url = '/api/home';
  permissionNeeded = "permessi.utenza.base";

  pullBoxData = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/boxes`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
              Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  sanzAmmMassime = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/sanzAmmMassime`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  sanzInterdittive = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/sanzInterdittive`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  pullGraphData = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/graphData`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  pullModalData = async (whatToPull) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/modalData/${whatToPull}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class FlowApi extends Api {
  url = "/api/flussi";
  permissionNeeded = "flussi.odv";
  updateFunctions = async ({ uuid, ...data }) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}funzioni/${uuid}`,
        method: "put",
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class PeriodiApi extends Api {
  url = "/api/periodi";
  permissionNeeded= "flussi.odv";
}

export class FunctionApi extends Api {
  url = "/api/funzioni";
  permissionNeeded = "struttura.organizzativa";
  queryNew = async (companyId) => {
    try {
      const res = await axios.get(
          `${END_POINT}/api/valutazioni/all/${companyId}`,
          {
            headers: {
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  updateReferents = async ({ uuid, ...data }) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}referenti/${uuid}`,
        method: "put",
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class FlowTrackApi extends Api {
  url = "/api/tracciati";
  permissionNeeded = "flussi.odv";
  downloadFile = async (uuid, filename) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/documento/${uuid}?filename=${filename}`,
        responseType: "arraybuffer",
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  uploadFile = async (uuid, file, formUuid) => {
    try {
      this.permessi(this.permissionNeeded);

      const form = new FormData();
      form.append("file", file);

      const res = await axios({
        url: `${END_POINT}${this.url}/documento/${uuid}/${formUuid}`,
        responseType: "application/json",
        method: "post", 
        data: form,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  send = async ({ uuid }) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/sollecito/${uuid}`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data.message;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class FunctionFlowApi extends Api {
  url = "/funzioniapi/v1/funzioni_flussi/";
  permissionNeeded = "flussi.odv";
}

export class GroupApi extends Api {
  url = "/utentiapi/v1/groups/";
  permissionNeeded= "permessi.utenza.base";
}

export class LicenseApi extends Api {
  url = "/utentiapi/v1/licenze/";
  permissionNeeded= "permessi.utenza.base";
}

export class OptionApi extends Api {
  url = "/flussiapi/v1/options/";
  permissionNeeded = "flussi.odv";
}

export class PeriodApi extends Api {
  url = "/flussiapi/v1/periodi/";
  permissionNeeded = "flussi.odv";
}

export class ProtocolApi extends Api {
  url = "/api/protocolli";
  permissionNeeded = "schemi.controllo";
}

export class ReferentApi extends Api {
  url = "/api/referenti/flussiOdv";
  permissionNeeded = "struttura.organizzativa";
}

export class RiskReferentApi extends Api {
  url = "/api/riskref";

  callRefList = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            }
          }
        );
      return res.data.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response) {
          const {
            response: {
              data: { message },
            },
          } = e;
          throw message;
        } else {
          throw e
        }
      } else {
        throw e;
      }
    }
  };

  storeRiskRef = async ({ ...data }) => {
    try {
      const res = await axios({
        url: `${END_POINT}${this.url}`,
        method: "post",
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  deleteRiskRef = async (referentUuid) => {
    try {
      const res = await axios({
        url: `${END_POINT}${this.url}/${referentUuid}`,
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class OdvReferentApi extends Api {
  url = "/api/odvref";

  callRefList = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            }
          }
      );
      return res.data.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response) {
          const {
            response: {
              data: { message },
            },
          } = e;
          throw message;
        } else {
          throw e
        }
      } else {
        throw e;
      }
    }
  };

  storeRiskRef = async ({ ...data }) => {
    try {
      const res = await axios({
        url: `${END_POINT}${this.url}`,
        method: "post",
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  deleteRiskRef = async (referentId) => {
    try {
      const res = await axios({
        url: `${END_POINT}${this.url}/${referentId}`,
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class RequestApi extends Api {
  url = "/flussiapi/v1/richieste/";
  permissionNeeded = "flussi.odv";
}

export class RiskAreaMatrixApi extends Api {
  url = "/api/areeProtocolli";
  permissionNeeded = "schemi.controllo";


}

export class RiskAreaIndicatoriMatrixApi extends Api {
  url = "/api/areeIndicatori";
  permissionNeeded = "schemi.controllo";
}

export class RiskAreaFunzioniMatrixApi extends Api {
  url = "/api/areeFunzioni";
  permissionNeeded = "schemi.controllo";

  updateFunzioni = async (uuid, { companyId, ...data }) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/${uuid}`,
        method: "put",
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class RiskAreaApi extends Api {
  url = "/api/aree";
  permissionNeeded = "schemi.controllo";

  queryV2 = async (params) => {
    try {
      this.permessi(this.permissionNeeded);
      let dataToPass = {};
      if ( localStorage.getItem(GRC_USER) !== undefined && JSON.parse(localStorage.getItem(GRC_USER)).token !== undefined ) {
        if ( params === undefined ) {
          dataToPass = {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
          };
        } else {
          dataToPass = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            },
            params: params
          };
        }
      }
      const res = await axios.get(`${END_POINT}/api/v2/aree`, dataToPass);
      return res.data.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response) {
          const {
            response: {
              data: { message },
            },
          } = e;
          throw message;
        } else {
          throw e
        }
      } else {
        throw e;
      }
    }
  };

  storeNote = async (data, areaId, companyId) => {
    try {
      const res = await axios.post(
        `${END_POINT}/api/aree/storeNote/${areaId}`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  getProtocols = async (uuid) => {
    try {
      const res = await axios.get(
        `${END_POINT}/api/aree/getProtocolliFromArea/${uuid}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  queryNew = async (companyId) => {
    try {
      const res = await axios.get(
        `${END_POINT}/api/aree/all/${companyId}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  updateFunzioni = async (id, { companyId, ...data }) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}/api/aree/storeSingleAreaFunzioni/${id}`,
        method: "put",
        data,
        headers: {
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  updateReati = async ({ uuid, ...data }) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}Reati/${uuid}`,
        method: "put",
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  updateProtocols = async ({ uuid, ...data }) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}Protocolli/${uuid}`,
        method: "put",
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  updateBehaviourIndicators = async ({ uuid, ...data }) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}Indicatori/${uuid}`,
        method: "put",
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  updateFlows = async ({ uuid, ...data }) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}flussi/${uuid}`,
        method: "put",
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  getNotes = async (areaId) => {
    try {
      const res = await axios.get(
        `${END_POINT}/api/aree/getNotes/${areaId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class RiskAreaTypologyApi extends Api {
  url = "/api/tipologieAreeRischio";
  permissionNeeded = "risk.assessment";
}

export class ServiceApi extends Api {
  url = "/utentiapi/v1/servizi/";
  permissionNeeded= "schemi.controllo";
}

export class SourceApi extends Api {
  url = "/api/fonti";
  permissionNeeded= "catalogo.reati.231";
}

export class UserApi extends Api {
  url = "/api/login/";
  permissionNeeded= "permessi.utenza.base";
}

export class AccountActivationApi extends Api {
  url = "/utentiapi/v1/accounts/attivazione/";
  permissionNeeded= "permessi.utenza.base";
}

export class AccountShoppingApi extends Api {
  url = "/utentiapi/v1/accounts/action/upgrade/";
  update = async (data) => {
    try {
      const res = await axios.put(`${END_POINT}${this.url}`, data,
      {
        headers: {
          "Content-Type": "application/json",
              Authorization:
          "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class AccountCancellationApi extends Api {
  permissionNeeded= "permessi.utenza.base";
  url = "/utentiapi/v1/accounts/action/disdetta/";
  update = async (data) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios.put(`${END_POINT}${this.url}`, data,{
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class AccountReactivationApi extends Api {
  url = "/utentiapi/v1/accounts/action/riattivazione/";
  update = async (data) => {
    try {
      const res = await axios.put(`${END_POINT}${this.url}`, data,{
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class AccountSuspendApi extends Api {
  url = "/utentiapi/v1/accounts/action/sospensione/";
  update = async ({ uuid, ...data }) => {
    try {
      const res = await axios.put(`${END_POINT}${this.url}`, data,{
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class RiskAreaFlussiApi extends Api {
  url = "/api/riskAreaFlussi";
}

export class AccountsApi extends Api {
  url = "/utentiapi/v1/accounts/active/all/";
  permissionNeeded= "permessi.utenza.base";
}

export class FunzioniMatriceApi extends Api {
  url = "/api/funzioniAmministrative";
  permissionNeeded= "permessi.utenza.base";
}

export class ConfigApi extends Api {
  url = "/api/configuration";

  setup = async () => {
    try {
      const res = await axios.get(
          `${END_POINT}${this.url}/setup`
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  palette = async () => {
    try {
      const res = await axios.get(
          `${END_POINT}${this.url}/palette`
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  menus = async () => {
    try {
      const res = await axios.get(
        `${END_POINT}${this.url}/menus`
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }
}

export class Violation231Api extends Api {
  url = "/api/reati";

  exportReato = async (reatoUuid, filename) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/exportFromReato/${reatoUuid}`,
        method: "get",
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      });

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }

  queryNew = async () => {
    try {
      const res = await axios.get(
        `${END_POINT}${this.url}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  singleReato = async (id) => {
    try {
      const res = await axios.get(
        `${END_POINT}/api/uberReato/${id}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class ViolationFromAreaApi extends Api {
  url = "/api/getReatiFromArea/";

  query = async (id) => {
    const token = JSON.parse(localStorage.getItem(GRC_USER)).token;
    try {
      const res = await axios.get(`${END_POINT}${this.url}${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class Violation231CategoryApi extends Api {
  url = "/api/categorieReati";
  permissionNeeded= "schemi.controllo";
}

export class BehaviorIndicatorApi extends Api {
  url = "/api/indicatoriComportamentali";
  permissionNeeded= "schemi.controllo";
}

export class InterdictSanctionApi extends Api {
  url = "/api/sanzioniInterdittive";
  permissionNeeded= "schemi.controllo";
}

export class RiskAreaCategoriesApi extends Api {
  url = "/controlloapi/v1/aree_rischio_reati231_cat/";
  permissionNeeded= "schemi.controllo";
}

export class SupportsApi extends Api {
  url = "/api/help";
}

export class GeneralSectionsApi extends Api {
  url = "/api/modelloOrganizzativo/partiGenerali/all";
  permissionNeeded= "modello.organizzativo";
}

export class GeneralTreeApi extends Api {
  url = "/api/modelloOrganizzativo/partiGenerali/tree";
  permissionNeeded= "modello.organizzativo";
}

export class GeneralModelsApi extends Api {
  permissionNeeded= "modello.organizzativo";
  url = "/api/modelloOrganizzativo/modelliGenerali";
  downloadPreview = async (type) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/preview`,
        method: "post",
        data: {
          'type': type
        },
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export-modelli-organizzativi-parte-generale." + type); //or any other extension
        document.body.appendChild(link);
        link.click();
      });

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }
  downloadPdf = async (uuid, filename) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/pdf/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/pdf',
          'Accept': 'application/pdf',
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "modello-generale.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadWord = async (uuid) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/doc/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "modello-generale.docx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadAnteprimaWord = async (version) => {
    try {
      const companyUuid = AuthService.company.uuid
      const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}download-word/${filename}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token
        }
      });
      return { filename, data: res.data };
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
}

export class EthicalCodeSectionsApi extends Api {
  url = "/api/partiCodiciEtici/all";
  permissionNeeded= "modello.organizzativo";
}

export class EthicalCodeTreeApi extends Api {
  url = "/api/partiCodiciEtici/tree";
  permissionNeeded= "modello.organizzativo";
}

export class EthicalCodeModelsApi extends Api {
  permissionNeeded= "modello.organizzativo";
  url = "/api/codiciEtici";
  downloadPreview = async (type) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/preview`,
        method: "post",
        data: {
          'type': type
        },
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export-codici-etici." + type); //or any other extension
        document.body.appendChild(link);
        link.click();
      });

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  copyDefault = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/clone`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });

      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  downloadPdf = async (uuid, filename) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/pdf/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/pdf',
          'Accept': 'application/pdf',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "codice-etico.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadWord = async (uuid) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/doc/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "codice-etico.docx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadAnteprimaWord = async (version) => {
    try {
      const companyUuid = AuthService.company.uuid
      const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}download-word/${filename}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return { filename, data: res.data };
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
}

export class BehaviourPrincipleSectionsApi extends Api {
  url = "/api/partiPrincipiComportamentali/all";
  permissionNeeded= "modello.organizzativo";
}

export class BehaviourPrincipleTreeApi extends Api {
  url = "/api/partiPrincipiComportamentali/tree";
  permissionNeeded= "modello.organizzativo";
}

export class BehaviourPrincipleModelsApi extends Api {
  permissionNeeded= "modello.organizzativo";
  url = "/api/principiComportamentali";
  copyDefault = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/clone`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });

      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  downloadPreview = async (type) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/preview`,
        method: "post",
        data: {
          'type': type
        },
        responseType: "blob",
        headers: {
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export-principi-comportamentali." + type); //or any other extension
        document.body.appendChild(link);
        link.click();
      });

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  downloadWord = async (uuid) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/doc/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "principio-comportamentale.docx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  };
  downloadPdf = async (uuid, filename) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/pdf/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/pdf',
          'Accept': 'application/pdf',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "codice-etico.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  };
  downloadAnteprimaWord = async (version) => {
    try {
      const companyUuid = AuthService.company.uuid
      const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}download-word/${filename}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return { filename, data: res.data };
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
}

export class WisProcedureSectionsApi extends Api {
  url = "/api/partiProceduraWhistleblowing/all";
  permissionNeeded= "modello.organizzativo";
}

export class WisProcedureTreeApi extends Api {
  url = "/api/partiProceduraWhistleblowing/tree";
  permissionNeeded= "modello.organizzativo";
}

export class WisProcedureModelsApi extends Api {
  permissionNeeded= "modello.organizzativo";
  url = "/api/proceduraWhistleblowing";
  downloadPreview = async (type) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/preview`,
        method: "post",
        data: {
          'type': type
        },
        responseType: "blob",
        headers: {
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export-procedure-whistleblowing." + type); //or any other extension
        document.body.appendChild(link);
        link.click();
      });

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  copyDefault = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/clone`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });

      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  downloadPdf = async (uuid, filename) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/pdf/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/pdf',
          'Accept': 'application/pdf',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "procedure-whistleblowing.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadWord = async (uuid) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/doc/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "procedure-whistleblowing.docx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadAnteprimaWord = async (version) => {
    try {
      const companyUuid = AuthService.company.uuid
      const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}download-word/${filename}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return { filename, data: res.data };
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
}

export class OdvTermsSectionsApi extends Api {
  url = "/api/partiRegolamentiOdv/all";
  permissionNeeded= "modello.organizzativo";
}

export class SafeguardingPolicySectionsApi extends Api {
  url = "/api/partiSafeguardingPolicy/all";
  permissionNeeded= "modello.organizzativo";
}

export class OdvTermsTreeApi extends Api {
  url = "/api/partiRegolamentiOdv/tree";
  permissionNeeded= "modello.organizzativo";
}

export class SafeguardingPolicyTreeApi extends Api {
  url = "/api/partiSafeguardingPolicy/tree";
  permissionNeeded= "modello.organizzativo";
}

export class OdvTermsModelsApi extends Api {
  permissionNeeded= "modello.organizzativo";
  url = "/api/regolamentiOdv";
  copyDefault = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/clone`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });

      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  downloadPreview = async (type) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/preview`,
        method: "post",
        data: {
          'type': type
        },
        responseType: "blob",
        headers: {
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export-regolamento-OdV." + type); //or any other extension
        document.body.appendChild(link);
        link.click();
      });

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  downloadPdf = async (uuid, filename) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/pdf/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/pdf',
          'Accept': 'application/pdf',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "regolamento-odv.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadWord = async (uuid) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/doc/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "regolamento-odv.docx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadAnteprimaWord = async (version) => {
    try {
      const companyUuid = AuthService.company.uuid
      const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}download-word/${filename}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return { filename, data: res.data };
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
}

export class SafeguardingPolicyModelsApi extends Api {
  permissionNeeded= "modello.organizzativo";
  url = "/api/safeguardingPolicy";
  copyDefault = async () => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/clone`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });

      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  downloadPreview = async (type) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/preview`,
        method: "post",
        data: {
          'type': type
        },
        responseType: "blob",
        headers: {
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export-safeguarding-Policy." + type); //or any other extension
        document.body.appendChild(link);
        link.click();
      });

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  downloadPdf = async (uuid, filename) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/pdf/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/pdf',
          'Accept': 'application/pdf',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "safeguarding-policy.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadWord = async (uuid) => {
    try {
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}/download/doc/${uuid}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "safeguarding-policy.docx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadAnteprimaWord = async (version) => {
    try {
      const companyUuid = AuthService.company.uuid
      const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}download-word/${filename}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return { filename, data: res.data };
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
}

export class SpecialModelsApi extends Api {
  url = "/api/modelloOrganizzativo/modelliSpeciali";
  permissionNeeded= "modello.organizzativo";
  downloadPreview = async (type) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/preview`,
        method: "post",
        data: {
          'type': type
        },
        responseType: "blob",
        headers: {
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export-modelli-organizzativi-parte-speciale." + type); //or any other extension
        document.body.appendChild(link);
        link.click();
      });

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  }
  downloadWord = async (filename) => {
    try {
      const companyUuid = AuthService.company.uuid
      //const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}download-word/${filename}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return { filename, data: res.data };
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
  downloadAnteprimaWord = async (version) => {
    try {
      const companyUuid = AuthService.company.uuid
      const { data: { filename } } = await axios.get(`${END_POINT}${this.url}export-word/${companyUuid}/${version || ''}`)
      const res = await axios({
        url: `${END_POINT}${this.url}download-word/${filename}`,
        method: 'get',
        encoding: null,
        responseType: "blob",
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return { filename, data: res.data };
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {response: {data: {message}}} = e;
        throw (message);
      } else {
        throw (e);
      }
    }
  }
}

export class FormBuilderApi extends Api {
  url = "/builderapi/v1/form/";
  compile = async ({ uuid, ...data }) => {
    try {
      const formData = new FormData();
      for (const k in data) {
        if (k === "form") {
          formData.append(k, JSON.stringify(data[k]));
        } else if (k === "docs") {
          data[k].forEach((doc, index) => {
            formData.append(`${k}[${index}]`, data[k][index]);
          });
        } else {
          formData.append(k, data[k]);
        }
      }
      formData.append('_method', 'PUT');
      const res = await axios.post(
        `${END_POINT}/api/forms/complete/${uuid}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization:
            //     "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
          },
        }
      );
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  collectFlow = async (ref_uuid, form_uuid) => {
    try {
      const res = await axios({
        url: `${END_POINT}/api/forms/collectFlow/${ref_uuid}/${form_uuid}`,
        method: "get",
        // headers: {
        //   Authorization:
        //       "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        // },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class FlowCollectionApi extends Api {
  permissionNeeded= "flussi.odv";
  url = "/api/raccolte";
  exportData = async (uuid) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/export/${uuid}`,
        method: "get",
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/zip, application/octet-stream",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
  closeFlowCollection = async ({ uuid, ...data }) => {
    try {
      this.permessi(this.permissionNeeded);
      const res = await axios({
        url: `${END_POINT}${this.url}/close/${uuid}`,
        method: "post",
        data,
        headers: {
          "Content-Type": "application/zip, application/octet-stream",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        },
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}

export class DelegatedAdminApi extends Api {
  url = "/api/delegated";

  callDelegatedAdminList = async () => {
    try {
      const res = await axios.get(`${END_POINT}${this.url}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
            }
          }
        );
      return res.data.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response) {
          const {
            response: {
              data: { message },
            },
          } = e;
          throw message;
        } else {
          throw e
        }
      } else {
        throw e;
      }
    }
  };

  storeDelegatedAdmin = async ({ ...data }) => {
    try {
      const res = await axios({
        url: `${END_POINT}${this.url}`,
        method: "post",
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };

  deleteDelegatedAdmin = async (referentUuid) => {
    try {
      const res = await axios({
        url: `${END_POINT}${this.url}/${referentUuid}`,
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Bearer " + JSON.parse(localStorage.getItem(GRC_USER)).token,
        }
      });
      return res.data;
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        const {
          response: {
            data: { message },
          },
        } = e;
        throw message;
      } else {
        throw e;
      }
    }
  };
}