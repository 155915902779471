import React, {useEffect} from "react";
import GridComponent from "../grid/grid.component";
import {useFormContext} from "react-hook-form";
import PropTypes from 'prop-types';

const SectionComponent = ({section, removeSection, viewMode, removeDuplicates, setInputList, save}) => {
    const {setValue} = useFormContext();
    useEffect(
        () => {
            setValue(section.gridName.name, section.gridName.initialValue);
            setValue(section.gridSizeSelect.name, section.gridSizeSelect.initialValue);
        }, []
    );
    return (
        <GridComponent removeSection={removeSection(section.id)} removeDuplicates={removeDuplicates(section.id)}
                       save={save}
                       setInputList={setInputList(section.id)} viewMode={viewMode} {...section}/>
    )

};

SectionComponent.defaultProps = {
    save: (component) => (config) => console.error('Missing save function to save', component, config)
};

SectionComponent.protoTypes = {
    section: PropTypes.object.isRequired,
    viewMode: PropTypes.number.isRequired,
    removeDuplicates: PropTypes.func.isRequired,
    removeSection: PropTypes.func.isRequired,
    setInputList: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired
};
export default SectionComponent;
