import React from "react";
import {HELPER_TEXT_CONFIG, LABEL_CONFIG, PLACEHOLDER_CONFIG, REQUIRED_CONFIG} from "../form-item.class";
import TextFieldCompile from "./text-field.compile";
import TextFieldTemplate from "./text-field.template";
import TextFieldConfigure from "./text-field.configure";
import EmptyItemComponent from "../empty-item/empty-item.component";
import {
    MODE_VIEW_COMPILE,
    MODE_VIEW_CONFIGURATION,
    MODE_VIEW_PREVIEW,
    MODE_VIEW_READONLY,
    MODE_VIEW_TEMPLATE
} from "../../../index.constants";
import PropTypes from "prop-types";

const TextFieldComponent = (props) => {
    const {viewMode, save} = props;

    const saveConfiguration = (config, select, selectOption, valid) => {
        // console.log('saveConfiguration text-field config', config, select, selectOption);
        let wrapper = {};
        config.forEach(({name, value}) => {
            if (name === 'required' && value === '') {
                value = false;
            }
            wrapper = {
                ...wrapper,
                [name]: value
            }
        });
        save({
            ...wrapper,
            select,
            valid,
            options: select ? selectOption : []
        });
    };

    const config = () => {
        return [
            LABEL_CONFIG(props),
            PLACEHOLDER_CONFIG(props),
            HELPER_TEXT_CONFIG(props),
            REQUIRED_CONFIG(props)
        ]
    };

    const render = () => {
        switch (viewMode) {
            case MODE_VIEW_PREVIEW:
            case MODE_VIEW_COMPILE:
                return <TextFieldCompile {...props}/>;
            case MODE_VIEW_TEMPLATE:
                return <TextFieldTemplate/>;
            case MODE_VIEW_READONLY:
                return <TextFieldCompile {...props} disabled={true} readOnly={true}/>;

            case MODE_VIEW_CONFIGURATION:
                return <TextFieldConfigure onSave={saveConfiguration}
                                           defaultConfig={config()} {...props}/>;
            default:
                return <EmptyItemComponent/>;
        }
    };
    return render();
};

TextFieldComponent.propTypes = {
    save: PropTypes.func.isRequired
};

export default TextFieldComponent;
