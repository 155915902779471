import React, {useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ConfigurationModuleComponent from "../configuration-module/configuration-module.component";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from 'prop-types';
import ValidButtonConfiguration from "../../generic-ui/valid-button-configuration";

const SliderConfigure = ({onSave, defaultConfig, displayName}) => {
    const [dialog, toggleDialog] = useState(false);
    const [rows, setRows] = useState(defaultConfig);
    const [error, setError] = useState(false);
    const [isValid, setIsValid] = useState(!defaultConfig.some(({required, value}) => required && !value));
    const handleChange = (e, row) => {
        rows[rows.indexOf(row)] = {
            ...row,
            [e.target.name]: e.target.value
        };
        setRows([...rows]);
        const min = rows.find(({name}) => name === 'min');
        const max = rows.find(({name}) => name === 'max');
        if (min && max) {
            if (parseInt(min.value) > parseInt(max.value)) {
                setIsValid(false);
                setError('Il valore minimo non può essere superiore a quello massimo');
            } else if (parseInt(max.value) === parseInt(min.value)) {
                setError('Minino e massimo non possono essere uguali');
                setIsValid(false);
            } else {
                setError(false);
                setIsValid(!rows.some(({required, value}) => required && !value))
            }
        } else {
            setError(false);
            setIsValid(!rows.some(({required, value}) => required && !value))
        }
    };
    const configure = () => {
        toggleDialog(!dialog);
    };
    const save = () => {
        onSave(rows, isValid);
        toggleDialog(false);
    };
    return (
        <FormControl component="fieldset" fullWidth={true}>
            <Grid container justify={"center"}>
                <Grid item xs={10}>
                    <Typography variant={"subtitle2"} gutterBottom={true}
                                align={"center"}>
                        {displayName}
                    </Typography>
                </Grid>
                <Grid item xs={10} md={6}>
                    <ValidButtonConfiguration isValid={isValid} configure={configure}/>
                </Grid>
            </Grid>
            <Dialog open={dialog} aria-labelledby="text-field-conf-dialog" onClose={() => toggleDialog(false)}
                    fullWidth={true} maxWidth={'lg'}>
                <DialogTitle id="text-field-conf-dialog">Configura {displayName}</DialogTitle>
                <DialogContent>
                    <ConfigurationModuleComponent rows={rows} handleChange={handleChange}/>
                    {error &&
                    <Typography align={"center"} variant={"caption"} color={"error"} component={'p'}
                                style={{marginTop: '2rem'}}>
                        {error}
                    </Typography>}
                </DialogContent>
                <DialogActions>
                    <Grid direction={"row"} justify={"flex-end"} container>
                        <Grid item>
                            <Button variant={"text"} color={"default"} onClick={() => toggleDialog(false)}>
                                indietro
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={save} color={"secondary"}
                                    disabled={!isValid}>salva</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </FormControl>
    );
};

SliderConfigure.defaultProps = {
    defaultConfig: [],
    onSave: () => console.error('Missing on saveConfiguration function!')
};

SliderConfigure.propTypes = {
    onSave: PropTypes.func.isRequired,
    defaultConfig: PropTypes.array
};

export default SliderConfigure;
