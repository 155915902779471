import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import AuthService from "../../../services/auth.service";
import {Link as RouterLink, withRouter} from 'react-router-dom';
import {SITE_MAP, ENV} from "../../../constant";
import { ConfigApi } from '../../../services/api.service';

const useStyles = makeStyles((theme) => ({
    menu: {
        /*width: 400,*/
        height: '100%',
        background: '#f5f5f5',
        marginTop: 40
    },
    scrollable: {
        flexGrow: 1,
        //paddingBottom: '150px!important',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            background: '#f5f5f5'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f5f5f5',
            borderRadius: '10px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'white'
        },
        marginTop: '5%'
    },
    whiteText: {
        color: 'black'
    },
    bottomButtons: {
        color: 'black',
        fontSize: theme.typography.pxToRem(13)
    },
    dividerText: {
        color: 'black',
        background: '#f5f5f5',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        width: 'fit-content',
        padding: '0 20px'
    },
    whiteDivider: {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        position: 'relative',
        margin: theme.spacing(4)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: 'black'
    },
    expansionPanel: {
        background: 'transparent',
        boxShadow: 'none',
        '&:before': {
            display: 'none'
        }
    },
    expansionSubPanel: {
        background: 'transparent',
        boxShadow: 'none',
        '&:before': {
            display: 'none'
        },
        '&.Mui-expanded': {
            margin: '0 0 !important',
            fontSize: '12px !important',
        }
    },
    list: {
        width: '100%',
        '& .MuiListItem-root': {
            width: '100%',
        }
    },
    subList: {
        width: '100%',
        '& .MuiListItem-root': {
            width: '100%',
        }
    },
    topGrid: {
        marginTop: '1rem',
        backgroundColor: '#f3f3f3'
    },
    gridContainerTopper: {
        margin: '2rem',
        flexWrap: 'nowrap',
        wordWrap: 'nowrap'
    },
    iconSmallerSize: {
        'font-size': '14px',
        color: theme.palette.primary[theme.palette.mode],
        marginBottom: -2,
        paddingRight: 3
    },
    textSmallerSize: {
        fontSize: '12px !important'
    },
    textSubSmallerSize:{
        fontSize: '11px !important'
    },
    divCenter: {
        textAlign: 'center',
        display: 'inline-block'
    },
    bottomDivProfile: {
        padding: '0 !important',
        display: 'flex'
    }
}));

const HomeDrawer24Component = ({open, toggleDrawer, history}) => {
    let MyHome = SITE_MAP;
    let myHomeFiltered = [];
    for(let i = 0; i<MyHome.length; i++) {
        if (MyHome[i].expansion.length > 0) {
            myHomeFiltered = [];
            for (let z = 0; z < MyHome[i].expansion.length; z++) {
                if (MyHome[i].expansion[z].permission !== undefined && localStorage.getItem('permessi_utente').includes(MyHome[i].expansion[z].permission)) {
                    //console.log("il permesso " + MyHome[i].expansion[z].permission + " è stato trovato");
                    //console.log('metto ' + MyHome[i].expansion[z]);
                    myHomeFiltered.push(MyHome[i].expansion[z]);
                } else {
                    //console.log("il permesso " + MyHome[i].expansion[z].permission + " non è stato trovato");
                }
            }
            MyHome[i].expansion = myHomeFiltered;
        }
    }


    if ( localStorage.getItem('permessi_utente').includes('risk.referente') ) {

        MyHome = [];

    }
    const classes = useStyles();
    const [expanded, setExpanded] = useState();
    const [expandedSub, setExpandedSub] = useState();

    const handleChange = (panelN) => (event, isExpanded) => {
        event.stopPropagation();
        setExpanded(isExpanded ? panelN : null);
    };
    const handleChangeSub = (panelN) => (event, isExpandedSub) => {
        event.stopPropagation();
        setExpandedSub(isExpandedSub ? panelN : null);
    };
    const logout = () => {
        AuthService.logout().then( () => {
            history.push('/login');
            /*setTimeout( function () {
                window.location.reload();
            }, 300 );*/
        });
    };
    const isExpanded = (path) => {
        return expanded === path;
    };
    const isExpandedSub = (path) => {
        return expandedSub === path;
    };
    const {com_name} = AuthService.company;

    const [menusConfig, setMenusConfig] = useState(null)

    const isOsservatorioEnabled = useMemo(() => {
        if (!menusConfig) return true
        return !!menusConfig.osservatorio_enabled
    }, [menusConfig])

    const isBancheDatiEnabled = useMemo(() => {
        if (!menusConfig) return true
        return !!menusConfig.banche_dati_enabled
    }, [menusConfig])

    const isServiziEditorialiEnabled = useMemo(() => {
        if (!menusConfig) return true
        return !!menusConfig.servizi_editoriali_enabled
    }, [menusConfig])

    const videoCallUrl = useMemo(() => {
        if (!menusConfig?.video_call_formazione_url) return `https://formazione-231.valore24.ilsole24ore.com/?uuid=${AuthService.user.utente.uuid}&env=${ENV}`
        return `${menusConfig.video_call_formazione_url}?uuid=${AuthService.user.utente.uuid}&env=${ENV}`
    }, [menusConfig, AuthService.user.utente.uuid, ENV])

    // Fetch menu configuration with useCallback
    const getMenusConfig = useCallback(async () => {
        const menus = await new ConfigApi().menus()
        setMenusConfig(menus)
    }, [])

    // Run getMenusConfig once on component mount
    useEffect(() => {
        getMenusConfig()
    }, [getMenusConfig])

    const cardContentLink = (label, link = false, externalLink = false, permessi = false, index, items) => {
        if(link && (!permessi || localStorage.getItem('permessi_utente').includes(permessi))) {
            return (
                <Link component={RouterLink} to={link} color={"inherit"} key={index}>
                    <ListItem button>
                        <ListItemText classes={{
                            root: classes.textSmallerSize,
                            multiline: classes.textSmallerSize,
                            primary: classes.textSmallerSize
                        }} primary={label}/>
                    </ListItem>
                </Link>
            )
        }
        if(externalLink && (!permessi || localStorage.getItem('permessi_utente').includes(permessi))) {
            return (
                <Link href={`${externalLink}?uuid=${AuthService.user.utente.uuid}&env=${ENV}`} target="_blank"
                      color={"inherit"} key={index}>
                    <ListItem button>
                        <ListItemText classes={{
                            root: classes.textSmallerSize,
                            multiline: classes.textSmallerSize
                        }} primary={label}/>
                    </ListItem>
                </Link>
            )
        }
         // Check if there are nested items but no link or externalLink
         if (!link && !externalLink && items?.length > 0 && (!permessi || localStorage.getItem('permessi_utente').includes(permessi))) {
            // Render an expandable panel for items that have nested items        
            return (
                <ExpansionPanel 
                    key={index} 
                    className={classes.expansionSubPanel}
                    expanded={isExpandedSub(index)}
                    onChange={handleChangeSub(index)}
                > 
                    <ExpansionPanelSummary
                        expandIcon={<Icon >expand_more</Icon>}
                        aria-controls={`panel-content-sub-${index}`}
                        id={`panel-header-sub-${index}`}
                    >
                        <Typography color={'inherit'} variant={"button"} className={classes.textSubSmallerSize}>
                            {label}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <List component="div" className={classes.subList} disablePadding>
                            {/* Recursively render only the nested items within this item */}
                            {items.map((subItem, subIndex) => (
                                cardContentLink(
                                    subItem.label,
                                    subItem.link,
                                    subItem.externalLink,
                                    subItem.permessi,
                                    `${index}-${subIndex}`,  // Unique key for each nested item
                                    subItem.items // Pass the nested items of each sub-item
                                )
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );
        }
        return ''
    }

    return (
        <Grid container direction={"column"}
              className={classes.menu} role="presentation" onClick={() => toggleDrawer(false)}
              onKeyDown={() => toggleDrawer(false)} spacing={2} wrap={"nowrap"}>
            <Grid item xs className={classes.scrollable} container direction={"column"} justify={"space-between"}
                  wrap={"nowrap"}>
                <Grid item style={{marginTop: 28}}>
                    {MyHome.map(({label, path, icon, expansion}, index) => (
                        <Grid container direction={"column"} wrap={"nowrap"}
                              key={index}>
                            <Grid item>
                                <Grid container direction={"column"}>
                                    {expansion.map(({label, path, items, icon}, index) => (
                                        <Grid item key={index}>
                                            {items && items.length > 0 && <ExpansionPanel className={classes.expansionPanel}
                                                            expanded={isExpanded(path)}
                                                            onChange={handleChange(path)}>
                                                <ExpansionPanelSummary
                                                    expandIcon={<Icon>expand_more</Icon>}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header">
                                                    <Typography color={'inherit'}
                                                                variant={"button"} className={classes.textSmallerSize}>
                                                        {" " + label}
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <List component="nav" className={classes.list} disablePadding>
                                                        {items.map(
                                                            ({label, link, externalLink, permessi ,items: nestedItems}, index) =>
                                                                cardContentLink(label, link, externalLink, permessi, index, nestedItems)
                                                        )}
                                                    </List>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>}
                                            {(!items || (items && items.length === 0)) &&
                                            <ExpansionPanelSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                                <Link component={RouterLink} to={path} color={"inherit"} key={index}>
                                                    <Typography color={'inherit'}
                                                                variant={"button"} className={classes.textSmallerSize}>
                                                        {" " + label}
                                                    </Typography>
                                                </Link>
                                            </ExpansionPanelSummary>}
                                        </Grid>
                                    ))}
                                    {!expansion.length &&
                                    <ExpansionPanelSummary id="panel1bh-header"
                                                           onClick={() => history.push(path)}>
                                        <Typography variant={"button"}>
                                            {" " + label}
                                        </Typography>
                                    </ExpansionPanelSummary>}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    {isOsservatorioEnabled && (
                        <Grid container direction={"column"} wrap={"nowrap"} >
                            <Grid item>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <ExpansionPanelSummary id="panel1bh-header" onClick={() => history.push('/news')}>
                                            <Typography className={classes.textSmallerSize} variant={"button"}>
                                                {" "}Osservatorio Top24 Diritto
                                            </Typography>
                                        </ExpansionPanelSummary>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {isBancheDatiEnabled && (
                        <Grid container direction={"column"} wrap={"nowrap"} >
                            <Grid item>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <ExpansionPanel className={classes.expansionPanel} expanded={isExpanded('banchedati')} onChange={handleChange('banchedati')}>
                                            <ExpansionPanelSummary
                                                expandIcon={<Icon>expand_more</Icon>}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header">
                                                <Typography color={'inherit'}
                                                            variant={"button"}
                                                            className={classes.textSmallerSize}>
                                                    {' banche dati'}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <List component="nav" className={classes.list} disablePadding>
                                                    <List component="nav" className={classes.list} disablePadding>
                                                        <ListItem button onClick={() => { window.open(`https://top24diritto.ilsole24ore.com`); } }>
                                                            <ListItemText classes={{
                                                                root: classes.textSmallerSize,
                                                                multiline: classes.textSmallerSize,
                                                                primary: classes.textSmallerSize
                                                            }} primary={'Top24 Diritto'}/>
                                                        </ListItem>
                                                        <ListItem button onClick={() => { window.open(`https://smartlex24.ilsole24ore.com`); } }>
                                                            <ListItemText classes={{
                                                                root: classes.textSmallerSize,
                                                                multiline: classes.textSmallerSize,
                                                                primary: classes.textSmallerSize
                                                            }} primary={'Smart24 Lex'}/>
                                                        </ListItem>
                                                        <ListItem button onClick={() => { window.open(`https://modulo24.ilsole24ore.com/compliance-landing/`); } }>
                                                            <ListItemText classes={{
                                                                root: classes.textSmallerSize,
                                                                multiline: classes.textSmallerSize,
                                                                primary: classes.textSmallerSize
                                                            }} primary={'Modulo24 Compliance'}/>
                                                        </ListItem>
                                                    </List>
                                                </List>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {isServiziEditorialiEnabled && (
                        <Grid container direction={"column"} wrap={"nowrap"} >
                            <Grid item>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <ExpansionPanel className={classes.expansionPanel} expanded={isExpanded('servizieditoriali')} onChange={handleChange('servizieditoriali')}>
                                            <ExpansionPanelSummary
                                                expandIcon={<Icon>expand_more</Icon>}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header">
                                                <Typography color={'inherit'}
                                                            variant={"button"}
                                                            className={classes.textSmallerSize}>
                                                    {' Servizi editoriali'}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <List component="nav" className={classes.list} disablePadding>
                                                    <List component="nav" className={classes.list} disablePadding>
                                                        <ListItem button onClick={() => { window.open(`https://www.ilsole24ore.com`); } }>
                                                            <ListItemText classes={{
                                                                root: classes.textSmallerSize,
                                                                multiline: classes.textSmallerSize,
                                                                primary: classes.textSmallerSize
                                                            }} primary={'Il Sole 24 Ore'}/>
                                                        </ListItem>
                                                        <ListItem button onClick={() => { window.open(`https://www.quotidiano.ilsole24ore.com`); } }>
                                                            <ListItemText classes={{
                                                                root: classes.textSmallerSize,
                                                                multiline: classes.textSmallerSize,
                                                                primary: classes.textSmallerSize
                                                            }} primary={'Sfoglia il quotidiano'}/>
                                                        </ListItem>
                                                        <ListItem button onClick={() => { window.open(`https://ntplusdiritto.ilsole24ore.com`); } }>
                                                            <ListItemText classes={{
                                                                root: classes.textSmallerSize,
                                                                multiline: classes.textSmallerSize,
                                                                primary: classes.textSmallerSize
                                                            }} primary={'NT + Diritto'}/>
                                                        </ListItem>
                                                        <ListItem button onClick={() => { window.open(`https://valore24.ilsole24ore.com`); } }>
                                                            <ListItemText classes={{
                                                                root: classes.textSmallerSize,
                                                                multiline: classes.textSmallerSize,
                                                                primary: classes.textSmallerSize
                                                            }} primary={'Valore24'}/>
                                                        </ListItem>
                                                        <ListItem button onClick={() => { window.open(`https://www.espertorisponde.ilsole24ore.com`); } }>
                                                            <ListItemText classes={{
                                                                root: classes.textSmallerSize,
                                                                multiline: classes.textSmallerSize,
                                                                primary: classes.textSmallerSize
                                                            }} primary={'L\'esperto risponde'}/>
                                                        </ListItem>
                                                        <ListItem button onClick={() => { window.open(`https://workshop.ilsole24ore.com`); } }>
                                                            <ListItemText classes={{
                                                                root: classes.textSmallerSize,
                                                                multiline: classes.textSmallerSize,
                                                                primary: classes.textSmallerSize
                                                            }} primary={'Workshop'}/>
                                                        </ListItem>
                                                    </List>
                                                </List>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container direction={"column"} wrap={"nowrap"} >
                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <ExpansionPanel className={classes.expansionPanel} expanded={isExpanded('exports')} onChange={handleChange('exports')}>
                                        <ExpansionPanelSummary
                                            expandIcon={<Icon>expand_more</Icon>}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header">
                                            <Typography color={'inherit'}
                                                        variant={"button"}
                                                        className={classes.textSmallerSize}>
                                                {' Exports'}
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List component="nav" className={classes.list} disablePadding>
                                                <List component="nav" className={classes.list} disablePadding>
                                                    <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati&companyID=${AuthService.company.uuid}`); } }>
                                                        <ListItemText classes={{
                                                            root: classes.textSmallerSize,
                                                            multiline: classes.textSmallerSize,
                                                            primary: classes.textSmallerSize
                                                        }} primary={'Reati 231'}/>
                                                    </ListItem>
                                                    <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-aree&companyID=${AuthService.company.uuid}`); } }>
                                                        <ListItemText classes={{
                                                            root: classes.textSmallerSize,
                                                            multiline: classes.textSmallerSize,
                                                            primary: classes.textSmallerSize
                                                        }} primary={'Aree di rischio – Reati 231'}/>
                                                    </ListItem>
                                                    <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-protocolli&companyID=${AuthService.company.uuid}`); } }>
                                                        <ListItemText classes={{
                                                            root: classes.textSmallerSize,
                                                            multiline: classes.textSmallerSize,
                                                            primary: classes.textSmallerSize
                                                        }} primary={'Aree di rischio – Protocolli'}/>
                                                    </ListItem>
                                                    <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-indicatori&companyID=${AuthService.company.uuid}`); } }>
                                                        <ListItemText classes={{
                                                            root: classes.textSmallerSize,
                                                            multiline: classes.textSmallerSize,
                                                            primary: classes.textSmallerSize
                                                        }} primary={'Aree di rischio – Indicatori comportamentali'}/>
                                                    </ListItem>
                                                    <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-flussi&companyID=${AuthService.company.uuid}`); } }>
                                                        <ListItemText classes={{
                                                            root: classes.textSmallerSize,
                                                            multiline: classes.textSmallerSize,
                                                            primary: classes.textSmallerSize
                                                        }} primary={'Aree di rischio – Flussi OdV'}/>
                                                    </ListItem>
                                                    {(localStorage.getItem('permessi_utente').includes('export.dati.flussi') || localStorage.getItem('permessi_utente').includes('odv.referente')) &&
                                                        <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=funzioni-flussi&companyID=${AuthService.company.uuid}`); } }>
                                                            <ListItemText classes={{
                                                                root: classes.textSmallerSize,
                                                                multiline: classes.textSmallerSize,
                                                                primary: classes.textSmallerSize
                                                            }} primary={'Funzioni - Referenti – Flussi OdV'}/>
                                                        </ListItem>
                                                    }
                                                </List>
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={"column"} wrap={"nowrap"} >
                        <Grid item>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <ExpansionPanel className={classes.expansionPanel} expanded={isExpanded('help')} onChange={handleChange('help')}>
                                        <ExpansionPanelSummary
                                            expandIcon={<Icon>expand_more</Icon>}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header">
                                            <Typography color={'inherit'}
                                                        variant={"button"}
                                                        className={classes.textSmallerSize}>
                                                {' Help'}
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List component="nav" className={classes.list} disablePadding>
                                                <Link component={RouterLink} to={'/faq/richiesta-supporto'} color={"inherit"}>
                                                    <ListItem button>
                                                        <ListItemText classes={{
                                                            root: classes.textSmallerSize,
                                                            multiline: classes.textSmallerSize,
                                                            primary: classes.textSmallerSize
                                                        }} primary={'Richiesta di supporto'}/>
                                                    </ListItem>
                                                </Link>
                                                <Link href={videoCallUrl} target="_blank" color={"inherit"} >
                                                    <ListItem button>
                                                        <ListItemText classes={{
                                                            root: classes.textSmallerSize,
                                                            multiline: classes.textSmallerSize,
                                                            primary: classes.textSmallerSize
                                                        }} primary={'Prenota video call formazione'}/>
                                                    </ListItem>
                                                </Link>
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.lastSection}>
                    <Divider component={'div'} className={classes.whiteDivider}>
                        <Typography className={classes.dividerText} align={"center"}>
                            Account
                        </Typography>
                    </Divider>
                    <Grid container direction={"column"} spacing={2}
                          style={{padding: '0 24px', marginBottom: '2rem'}}>
                        {/*<Grid item>
                            <Typography variant={"button"} className={classes.whiteText}>Need help?</Typography>
                        </Grid>*/}
                        <Grid item container justify={"space-around"} className={classes.bottomDivProfile}>
                            {com_name && <Grid item>
                                <Button onClick={() => history.push('/profilo')}>
                                    <Typography className={classes.bottomButtons}>
                                        profilo
                                    </Typography>
                                </Button>
                            </Grid>}
                            <Grid item>
                                <Button onClick={logout}>
                                    <Icon color={"error"} style={{fontSize: '1rem', marginRight: '1rem'}}>power_settings_new</Icon>
                                    <Typography className={classes.bottomButtons}>
                                        logout
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

HomeDrawer24Component.defaultProps = {
    open: false,
    toggleDrawer: () => console.warn('Missing toggleDrawer function')
};

HomeDrawer24Component.propTypes = {
    toggleDrawer: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default withRouter(HomeDrawer24Component);
