import React from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {handleModal} from "../../redux/actions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RiskReferentsTable from "../../components/atomic-tables/risk-referents/risk-referents.table";
import RiskReferentComponent from "../../components/CRUD/risk-referent/risk-referent.component";
import AuthService from "../../services/auth.service";

const RiskReferentsPage = ({dispatch}) => {
    const createRiskReferent = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Crea Utente Risk Assessment',
            content: <RiskReferentComponent mode={'create'}/>,
        }));
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Gestisci gli Utenti Risk Assessment dell'azienda ${AuthService.company.com_name}`}/>
                    </Grid>
                    <Grid item container alignItems={"center"} spacing={2}>
                        <Grid item xs>
                            <Typography variant={"h6"}>Elenco Utenti Risk Assessment</Typography>
                        </Grid>
                        <Grid item>
                            <Button variant={"outlined"} color={"primary"} onClick={createRiskReferent}>Nuovo Utente Risk Assessment</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <RiskReferentsTable/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};


export default connect()(withRouter(RiskReferentsPage));
