import React from 'react';
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import Button from "@material-ui/core/Button";
import CtaBoxComponent from "../../components/ui/cta-box/cta-box.component";
import {withRouter} from "react-router-dom";
import FlowsCollectionTable from "../../components/atomic-tables/flows-collection/flows-collection.table";

const FlowCollectionPage = props => {
    const createFlowCollection = () => props.history.push('/flussi-OdV/raccolta-flussi/crea-raccolta');
    const actions = [
        {
            label: 'Nuova raccolta',
            handler: createFlowCollection
        }
    ];
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Lista Raccolta Flussi'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"space-between"} alignItems={"center"} spacing={2} style={{margin: 0}}>
                            <Grid item xs>
                                <Typography variant={"subtitle2"}>Elenco raccolta flussi</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={createFlowCollection}>Avvia nuova raccolta</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FlowsCollectionTable/>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>
                        <CtaBoxComponent title={'Raccolta flussi'} actions={actions}/>
                    </Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(FlowCollectionPage);
