import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import NewFlowCollectionComponent from '../../components/CRUD/flow-collection/flow-collection.component';
import {withRouter} from "react-router-dom";

const FlowCollectionNewPage = () => {
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Raccolta Flussi`} back/>
                    </Grid>
                    <Grid item style={{margin: '2rem 0 1rem' }}>                        
                        <Typography variant="h5" gutterBottom>
                            Avvio della raccolta periodica dei Flussi O.d.V
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            Ad avvio raccolta, verranno inviate tramite email ai referenti di ogni funzione le richieste di compilazione dei flussi a loro associati.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={4}>
                            <Grid item xs>
                                <NewFlowCollectionComponent />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

FlowCollectionNewPage.propTypes = {};

export default withRouter(FlowCollectionNewPage);
