import {MODE_VIEW_TEMPLATE} from "../../../index.constants";
import FormItem from "../form-item.class";

export default class SliderClass extends FormItem {
    constructor({viewMode, displayName, values, min, max, ...props}) {
        super(props);
        this.type = 'slider';
        this.isEmpty = false;
        this.viewMode = viewMode || MODE_VIEW_TEMPLATE;
        this.displayName = displayName || 'Slider';
        this.values = values || {};
        this.min = parseInt(min) || 1;
        this.max = parseInt(max) || 10;

    }
}
