import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Input,
  Chip,
  FormControl,
  Select,
  makeStyles,
  MenuItem,
  Slider,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Violation231Api, CompanyApiNew } from "../../services/api.service";
import ReactQuill from "react-quill";
import AuthService from "../../services/auth.service";

const Violation231 = new Violation231Api();

const useStyles = makeStyles(() => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    "& .MuiChip-label": {
      textOverflow: "ellipsis",
      maxWidth: "250px",
      whiteSpace: "nowrap",
      display: "block",
      overflow: "hidden",
    },
  },
}));

const ViolationInfoModal = ({ api, id }) => {
  const [fetchedItem, setFetchedItem] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await Violation231.singleReato(id);
      setFetchedItem(data);
    };

    fetchData();
  }, [api.fetch]);

  if (!fetchedItem) {
    return (
      <Grid style={{ justifyContent: "center" }} container xs={12}>
        <CircularProgress size={30} />
      </Grid>
    );
  }
  return (
    <Grid container xs={12}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"}>Nome reato</Typography>
          <TextField
            fullWidth
            disabled={true}
            defaultValue={fetchedItem.titolo}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle1"}>Fonte</Typography>
          <TextField
            fullWidth
            disabled={true}
            defaultValue={fetchedItem.fonte.fon_fonte}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle1"}>Articolo di riferimento</Typography>
          <TextField
            fullWidth
            disabled={true}
            defaultValue={fetchedItem.articolo}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle1"}>Anno di introduzione</Typography>
          <TextField
            fullWidth
            disabled={true}
            defaultValue={fetchedItem.anno}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction={"column"}
            alignContent={"center"}
            spacing={4}
          >
            <Grid item>
              <Typography variant={"subtitle1"}>
                Quote sanzione amministrativa MIN - MAX
              </Typography>
              <Typography variant={"body2"} align={"center"}>
                Selezione:{" "}
                <strong>{fetchedItem.sanzione_amm_minima || 0}</strong> -{" "}
                <strong>{fetchedItem.sanzione_amm_massima || 1200}</strong>{" "}
                quote
              </Typography>
            </Grid>
            <Grid item>
              <Slider
                min={0}
                max={1200}
                defaultValue={[0, 1200]}
                disabled={true}
                defaultValue={[
                  fetchedItem.sanzione_amm_minima || 0,
                  fetchedItem.sanzione_amm_massima || 1200,
                ]}
                marks={[
                  { value: 0, label: "0€" },
                  { value: 1200, label: "1200€" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"subtitle1"} align={"center"}>
            Tipologia di sanzione interdittiva
          </Typography>
          <FormControl fullWidth>
            <Select
              multiple
              style={{ marginTop: "1rem" }}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value.san_sanzione}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              disabled={true}
              value={fetchedItem.sanzioni_interdittive}
            >
              {fetchedItem.sanzioni_interdittive.map(
                ({ san_sanzione, uuid }) => (
                  <MenuItem disabled={true} value={uuid} key={uuid}>
                    {san_sanzione}
                  </MenuItem>
                )
              )}
              {!fetchedItem.sanzioni_interdittive.length && (
                <MenuItem alignItems={"center"}>
                  Nessuna sanzione disponibile
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} container justify={"space-evenly"}>
          <Grid item xs={12}>
            <Typography variant={"subtitle1"} align={"center"}>
              Validità della sanzione interdittiva (in mesi)
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={"min"}
              type={"number"}
              fullWidth
              disabled={true}
              defaultValue={fetchedItem.sanzione_int_minima}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={"max"}
              type={"number"}
              fullWidth
              disabled={true}
              defaultValue={fetchedItem.sanzione_int_massima}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"} align={"center"} gutterBottom>
            Descrizione del reato
          </Typography>
          <ReactQuill
            defaultValue={fetchedItem.descrizione}
            readOnly={true}
            placeholder={"Descrizione mancante"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ fetchApiReducer }) => {
  return {
    api: fetchApiReducer,
  };
};

export default connect(mapStateToProps)(ViolationInfoModal);
