import React, {useEffect, useState} from 'react';
import {SwitchGroupComponent} from "../form-components/switch-group";
import {SliderComponent} from "../form-components/slider";
import {ChecksGroupComponent} from "../form-components/checks-group";
import {RadioGroupComponent} from "../form-components/radio-group";
import {DatePickerComponent} from "../form-components/date-picker";
import {FileInputComponent} from "../form-components/file-input";
import {TextFieldComponent} from "../form-components/text-field";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import Tooltip from "@material-ui/core/Tooltip";
import {CardContent} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {
    MODE_VIEW_COMPILE,
    MODE_VIEW_CONFIGURATION,
    MODE_VIEW_PREVIEW,
    MODE_VIEW_READONLY,
    styleGrid
} from "../../index.constants";
import PropTypes from 'prop-types';
import {useFormContext} from "react-hook-form";
import EmptyItemComponent, {EMPTY_ITEM} from "../form-components/empty-item/empty-item.component";
import {initComponentClass} from "../../index.utils";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'block',
        margin: theme.spacing(3, 0),
        position: 'relative'
    },
    dropWrapper: {
        border: '1px solid #D0D0D0',
        //cursor: 'move'
    },
    dragWrapper: {
        //cursor: 'move',
        padding: theme.spacing(4),
        position: 'relative'
    },
    removeButtonWrapper: {
        position: 'absolute',
        right: '0',
        top: '0'
    },
    submitButton: {}
}));

export default function GridComponent({id, inputList, gridName, gridSizeSelect, setInputList, viewMode, removeSection, save, removeDuplicates}) {
    const classes = useStyles();
    const calcRows = (componentsLength, cols) => {
        let _rows = Math.floor(componentsLength / cols);
        if (componentsLength > _rows * cols) {
            _rows++;
        }
        return _rows;
    };
    const toggleView = (index) => {
        let component = inputList[index];
        if (component) {
            component.viewMode = (component.viewMode === MODE_VIEW_PREVIEW ? MODE_VIEW_CONFIGURATION : MODE_VIEW_PREVIEW);
            setInputList([
                ...inputList
            ]);
        }
    };
    const {unregister, setValue, watch} = useFormContext();
    useEffect(() => {
        return setInputList(
            inputList.map((input) => {
                input.viewMode = viewMode;
                return input;
            })
        );
    }, [viewMode]);
    const [grid, setGrid] = useState(12);
    const [cols, setCols] = useState(12 / grid);
    const [rows, setRows] = useState(calcRows(inputList.length, cols));
    const [draggingIndex, setDraggingIndex] = useState();
    const fillGrid = () => {
        let validComponents = inputList.filter(({isEmpty}) => !isEmpty);
        let emptyBlocks = inputList.filter(({isEmpty}) => isEmpty);
        // console.log(`emptyBlocks -> ${emptyBlocks.length} validComponents -> ${validComponents.length}\n cols -> ${cols}  rows -> ${rows}`);
        const remainder = inputList.length % cols;
        if (inputList.length > rows * cols || emptyBlocks.length > cols) {
            // replace empty with valid or remove empty
            emptyBlocks = [];
        }
        // console.log(`add ${cols - remainder} block`);
        if (cols - remainder !== cols) {
            for (let i = 0; i < (cols - remainder); i++) {
                emptyBlocks.push(EMPTY_ITEM);
            }
        }
        if (cols === 1 || (rows === 1 && validComponents.length === cols)) {
            emptyBlocks = [];
        }
        if (!validComponents.length && !emptyBlocks.length) {
            emptyBlocks = [EMPTY_ITEM];
        }
        return setInputList([
            ...validComponents,
            ...emptyBlocks
        ]);
    };

    useEffect(() => {
        setValue(`col-${id}`, cols.toString());
        setRows(calcRows(inputList.length, cols));
        fillGrid();
    }, [inputList.length, cols]);

    useEffect(
        () => {
            try {
                const colNumber = parseInt(watch(gridSizeSelect.name));
                if (colNumber) {
                    let colWidth = 12 / colNumber;
                    setGrid(colWidth);
                    setCols(colNumber);
                    setRows(calcRows(inputList.length, colNumber));
                }
            } catch (e) {
                console.warn(e);
            }
        },
        [watch(gridSizeSelect.name)]
    );

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('move', JSON.stringify(inputList[index]));
        setDraggingIndex(index);
    };

    const handleDrop = (e, index) => {
        e.preventDefault();
        const template = e.dataTransfer.getData('new-template');
        const move = e.dataTransfer.getData('move');
        let component;
        if (template) {
            let viewMode = MODE_VIEW_CONFIGURATION;
            const name = `s-${id}-${template}-${Date.now()}`;
            component = initComponentClass(name, viewMode, template);
            if (component) {
                setInputList([...inputList, component].filter(({isEmpty}) => !isEmpty));
            }
        } else if (move) {
            /*component = JSON.parse(move);
            component = restoreComponent(component);
            if (inputList.find(({state: {id}}) => component.state.id === id)) {
                // reorder by drag&drop
                // console.log(inputList[index], inputList[draggingIndex], inputList, index, draggingIndex)
                const oldComponent = inputList[index];
                inputList[index] = inputList[draggingIndex];
                inputList[draggingIndex] = oldComponent;
                setInputList([...inputList]);
            } else {
                setInputList([...inputList, component].filter(({state: {isEmpty}}) => !isEmpty));
                //remove redundant component from other section
                console.log('Remove redundant', component, 'but keep', inputList);
                //removeDuplicates(component.state.id);
            }*/
        }
    };

    const removeComponent = (index) => {
        try {
            unregister(inputList[index].name);
            inputList.splice(index, 1);
            setInputList([...inputList]);
            setRows((calcRows(inputList.length, cols)));
        } catch (e) {
            console.warn(e);
        }
    };
    const renderComponent = (component) => {
        switch (component.type) {
            case 'slider':
                return <SliderComponent {...component} save={save(component)}/>;
            case 'radio-group':
                return <RadioGroupComponent {...component} save={save(component)}/>;
            case 'switch-group':
                return <SwitchGroupComponent {...component} save={save(component)}/>;
            case 'date-picker':
                return <DatePickerComponent {...component} save={save(component)}/>;
            case 'checks-group':
                return <ChecksGroupComponent {...component} save={save(component)}/>;
            case 'text-field':
                return <TextFieldComponent {...component} save={save(component)}/>;
            case 'file-input':
                return <FileInputComponent {...component} save={save(component)}/>;
            case 'empty-item':
                return <EmptyItemComponent {...component} save={save(component)}/>;
            default:
                console.log(`Invalid ${component} component!`);
                return <EmptyItemComponent {...component}/>;
        }
    };
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify={"space-between"} alignItems={"center"}>
                            <Grid item xs>
                                {viewMode !== MODE_VIEW_COMPILE && viewMode !== MODE_VIEW_READONLY &&
                                <TextFieldComponent {...gridName} save={save(gridName)}/>}
                                {(viewMode === MODE_VIEW_COMPILE || viewMode === MODE_VIEW_READONLY) &&
                                <Typography variant={"h6"}>{gridName.initialValue}</Typography>}
                            </Grid>
                            {/*<Grid item xs>
                                <Grid container justify={"flex-end"} alignItems={"center"} spacing={2}>
                                    <Grid item xs={8} md={2}>
                                        {gridSizeSelect.render()}
                                        <TextField value={getValues()[`col-${id}`] || cols.toString()}
                                                   label={'col'} fullWidth={true} select={true} name={`col-${id}`}
                                                   inputRef={register({name: `col-${id}`})}
                                                   onChange={handleGridColsChange}>
                                            {['1', '2', '3', '4'].map(option => (
                                                <MenuItem key={option} value={option} component={'li'} button={true}>
                                                    {option}
                                                </MenuItem>))}
                                        </TextField>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={'delete grid'}>
                                            <IconButton onClick={() => removeSection(id)}>
                                                <Icon color={"error"}>delete</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>*/}
                            {viewMode !== MODE_VIEW_COMPILE && viewMode !== MODE_VIEW_READONLY &&
                            <Grid item>
                                <Tooltip title={'Elimina gruppo'}>
                                    <IconButton onClick={() => removeSection(id)}>
                                        <Icon color={"error"}>delete</Icon>
                                    </IconButton>
                                </Tooltip>
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.dropWrapper}>
                            {inputList.map((component, index) =>
                                <Grid item xs={grid} key={`${component.id}-${index}`}
                                      style={styleGrid(viewMode, index, cols, rows)}
                                      draggable={viewMode === MODE_VIEW_CONFIGURATION}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => handleDrop(e, index)}
                                      onDragStart={(e) => handleDragStart(e, index)}
                                      className={classes.dragWrapper}>
                                    {!component.isEmpty && (viewMode === MODE_VIEW_CONFIGURATION || viewMode === MODE_VIEW_PREVIEW) &&
                                    <div className={classes.removeButtonWrapper}>
                                        <Tooltip title={'Elimina form'}>
                                            <IconButton onClick={() => removeComponent(index)}>
                                                <Icon color={"error"}>delete</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        {/*<Tooltip title={'preview'}>
                                            <IconButton color={"primary"} onClick={() => toggleView(index)}>
                                                <Icon>{component.state.viewMode === MODE_VIEW_CONFIGURATION ? 'visibility' : 'visibility_off'}</Icon>
                                            </IconButton>
                                        </Tooltip>*/}
                                    </div>}
                                    <Grid container style={{height: '100%'}}>
                                        <Grid item xs>
                                            <Grid container justify={"center"} alignItems={"center"}
                                                  style={{height: '100%'}}>
                                                <Grid item xs={10}>
                                                    {renderComponent(component)}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

GridComponent.defaultProps = {
    inputList: []
};

GridComponent.protoTypes = {
    inputList: PropTypes.array.isRequired,
    viewMode: PropTypes.number.isRequired,
    removeDuplicates: PropTypes.func.isRequired,
    removeSection: PropTypes.func.isRequired,
    setInputList: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired
};
