import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {FlowCollectionApi} from "../../services/api.service";
import {withRouter} from "react-router-dom";
import {handleToast} from "../../redux/actions";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button"
import Icon from "@material-ui/core/Icon"

import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import ManageFlowCollectionTable from '../../components/atomic-tables/manage-flow-collection/manage-flow-collection.table';

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    flowNoteContainer: {
        marginBottom: theme.spacing(2)
    },
    noteIcon: {
        color: "#3498db"
    },
    noteTitle: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(16)
    }
}))

const FlowCollection = new FlowCollectionApi();

const FlowCollectionDetailPage = ({history, match: {params: {uuid}}, dispatch, api}) => {

    const classes = useStyles()
    const [flowCollection, setFlowCollection] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const {raccolte} = await FlowCollection.get(uuid);
            setFlowCollection(raccolte);
        };
        fetchData();
    }, [api.fetch, uuid]);

    const exportData = async () => {
        const file = await FlowCollection.exportData(uuid);
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `raccolta-${flowCollection.rac_version}.zip`;
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
        dispatch(handleToast({
            message: 'File scaricato'
        }));
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Raccolta Flussi`} back/>
                    </Grid>
                    <Grid item style={{margin: '2rem 0 1rem'}}>
                        <Grid container justify={"space-between"} spacing={4}>
                            <Grid item>
                                <Typography variant="h5" gutterBottom>
                                    Raccolta
                                    flussi {flowCollection ? flowCollection.periodi.per_periodo : ''} {flowCollection ? flowCollection.rac_anno : ''}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button type={"button"} variant="outlined" color="primary" onClick={exportData}>
                                    Esporta risultati Excel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(flowCollection && flowCollection.rac_note) && (
                        <Grid item>
                            <Paper>
                                <Box p={2}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1} className={classes.flowNoteContainer}>
                                        <Grid item><Icon className={classes.noteIcon}>info</Icon></Grid>
                                        <Grid item><Typography className={classes.noteTitle}> Note relative alla Raccolta</Typography></Grid>
                                    </Grid>
                                    <Typography variant="body2">{flowCollection.rac_note}</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item style={{width: '100%'}}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <ManageFlowCollectionTable rac_uuid={uuid}/>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Grid container justify={"center"} spacing={4} style={{margin: '2rem 0'}}>
                                    <Grid item>
                                        <Button type={"button"} variant="outlined" color={"primary"} onClick={() => history.push('/flussi-OdV/raccolta-flussi')}>
                                            Torna alla lista
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

FlowCollectionDetailPage.propTypes = {};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};

export default withRouter(connect(mapStateToProps)(FlowCollectionDetailPage));
