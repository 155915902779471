import React from "react";
import SwitchGroupCompile from "./switch-group.compile";
import SwitchGroupTemplate from "./switch-group.template";
import EmptyItemComponent from "../empty-item/empty-item.component";
import SwitchGroupConfigure from "./switch-group.configure";
import {
    MODE_VIEW_COMPILE,
    MODE_VIEW_CONFIGURATION,
    MODE_VIEW_PREVIEW,
    MODE_VIEW_READONLY,
    MODE_VIEW_TEMPLATE
} from "../../../index.constants";
import PropTypes from "prop-types";

const SwitchGroupComponent = (props) => {
    const {viewMode, save} = props;

    const saveConfiguration = (switchGroup, valid) => {
        save({
            valid,
            ...switchGroup
        });
    };

    const render = () => {
        switch (viewMode) {
            case MODE_VIEW_READONLY:
                return <SwitchGroupCompile {...props} disabled={true} readOnly={true}/>;
            case MODE_VIEW_PREVIEW:
            case MODE_VIEW_COMPILE:
                return <SwitchGroupCompile {...props}/>;
            case MODE_VIEW_TEMPLATE:
                return <SwitchGroupTemplate/>;
            case MODE_VIEW_CONFIGURATION:
                return <SwitchGroupConfigure {...props} onSave={saveConfiguration}/>;
            default:
                return <EmptyItemComponent/>;
        }
    };
    return render();
};

SwitchGroupComponent.propTypes = {
    save: PropTypes.func.isRequired,
    viewMode: PropTypes.number.isRequired
};

export default SwitchGroupComponent;
