import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, Fab, FormControlLabel, FormGroup, Grid, Icon, Typography } from '@material-ui/core'
import { connect } from 'react-redux'

import { RiskAreaApi, Violation231Api } from '../../../services/api.service'
import { useFormContext, Controller } from 'react-hook-form'
import { _ } from 'core-js'

const RiskArea = new RiskAreaApi()
const Violation231 = new Violation231Api()

const RiskAssessmentMatrixReati = ({ dispatch, api, onSave }) => {
  const { watch, setValue, register } = useFormContext()

  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [riskAreas, setRiskAreas] = useState([])
  const [reati, setReati] = useState([])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const selectedRiskArea = useMemo(() => {
    if (!riskAreas?.length) return null

    return riskAreas?.find(({uuid}) => uuid === watch('risk_area'))
  }, [riskAreas, watch('risk_area')])

  const filteredCategories = useMemo(() => {
    if (!reati?.length || !riskAreas?.length) return []

    const categories = reati.reduce((acc, reato) => {
      return { 
        ...acc,
        [reato.reati231_cat?.uuid]: {
          ...reato.reati231_cat,
          reati: reati.filter(({ reati231_cat }) => reati231_cat?.uuid === reato.reati231_cat?.uuid)
        }
      }
    }, {})

    return Object.values(categories)
  }, [reati, riskAreas])

  const isReatoPreSelected = useCallback((reato) => {
    const ids = watch('selectedReati')
    return !!ids?.includes(reato.uuid)
  }, [watch('selectedReati')]) 

  const onCheckReato = useCallback((checked, reato) => {
    const ids = watch('selectedReati')

    return checked
      ? setValue('selectedReati', [...ids, reato.uuid])
      : setValue('selectedReati', ids.filter((id) => id !== reato.uuid))
  }, [watch('selectedReati')])

  const isAllReatiSelected = useCallback((category) => {
    const ids = watch('selectedReati')
    return category.reati?.every((reato) => ids.includes(reato.uuid))
  }, [watch('selectedReati')])

  const haveSomeSelectedReati = useCallback((category) => {
    const ids = watch('selectedReati')
    return category.reati?.some((reato) => ids.includes(reato.uuid))
  }, [watch('selectedReati')])
  
  const onCheckAllReati = useCallback((checked, category) => {
    const ids = watch('selectedReati')
    const reati_ids = category.reati?.map((reato) => reato.uuid)

    return checked
      ? setValue('selectedReati', [...ids, ...reati_ids])
      : setValue('selectedReati', ids.filter((id) => !reati_ids.includes(id)))
  }, [watch('selectedReati')])

  const fetchData = useCallback(async () => {
    setLoading(true)

    const aree_rischio = await RiskArea.query()
    const reati231 = await Violation231.query()

    setRiskAreas(aree_rischio)
    setReati(reati231)

    setLoading(false)
  }, [])

  const onClickSave = useCallback(async () => {
    await onSave()
    await fetchData()
  }, [onSave, fetchData])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    register({ name: 'selectedReati' })
  }, [])

  useEffect(() => {
    const reati_ids = selectedRiskArea?.aree_rischio_reati231?.map((reato) => reato?.reati231?.uuid) ?? []
    setValue('selectedReati', reati_ids ?? [])
  }, [selectedRiskArea])

  if (loading) return (
    <Grid item style={{minWidth: '100%', textAlign: 'center'}}>
      <CircularProgress size={150} />
    </Grid>
  )

  return (
    <Grid container justify={"center"} className="RiskAssessmentMatrixReati">
      <Grid item xs={12} md={10} lg={8} style={{ padding: '25px 0 100px 25px' }}>
        {filteredCategories.map((category) => (
          <Accordion key={category.uuid} expanded={expanded === category.uuid} onChange={handleChange(category.uuid)}>
            <AccordionSummary style={{ backgroundColor: '#f0f0f0' }} expandIcon={<Icon>keyboard_arrow_down</Icon>}>
              <FormControlLabel
                style={{ whiteSpace: 'nowrap' }}
                control={
                  <Checkbox
                    disabled={!selectedRiskArea}
                    checked={isAllReatiSelected(category)}
                    onChange={(e) => onCheckAllReati(e.target.checked, category)}
                  />
                }
                label={<></>}
              />
              <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <span style={{ flexShrink: 0, }}>
                  {category.cat_articolo?.trim()}
                </span>
                <span style={{ padding: '10px' }}>⋅</span>
                <span style={{ flex: 1, color: 'text-secondary', whiteSpace: 'normal' }}>
                  {category.cat_descrizione}
                </span>
                {haveSomeSelectedReati(category) && (
                  <Icon style={{ marginLeft: 'auto', paddingLeft: '10px' }}>checklist</Icon>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {category.reati?.map((reato, index) => (
                  <FormControlLabel
                    key={reato.uuid}
                    control={
                      <Checkbox
                        disabled={!selectedRiskArea}
                        checked={isReatoPreSelected(reato)}
                        onChange={(e) => onCheckReato(e.target.checked, reato)}
                      />
                    }
                    label={
                      <div style={{ width: '100%', padding: '10px 0 10px 15px', display: 'flex', alignItems: 'center', flexFlow: 'row wrap' }}>
                        <span style={{ flexShrink: 0, }}>
                          {reato.fonti?.fon_fonte?.trim()} 
                        </span>
                        <span style={{ padding: '0 10px' }}>⋅</span>
                        <span style={{ flexShrink: 0, }}>
                          Articolo {reato.rea_articolo?.trim()}
                        </span>
                        <span style={{ padding: '0 10px' }}>⋅</span>
                        <span style={{ flexGrow: '1', flexShrink: 0, flexBasis: '40%' }}>
                          {reato.rea_titolo?.trim()}
                        </span>
                      </div>
                    }
                    style={{ paddingLeft: '30px' }}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        ))}
        <Fab
          onClick={onClickSave}
          style={{
            position: 'fixed',
            right: '70px',
            top: '335px',
            width: '120px',
          }}
          aria-label={'Salva'}
          color={'secondary'}
          variant={"extended"}
          disabled={!selectedRiskArea}
        >
          Salva
        </Fab>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = ({ fetchApiReducer }) => ({
  api: fetchApiReducer
})

export default connect(mapStateToProps)(RiskAssessmentMatrixReati)
