import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import _ from "lodash";
import {searchFilter} from "../../../constant";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import {FlowApi} from "../../../services/api.service";
import {handleToast} from "../../../redux/actions";
import {withRouter} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const Flow = new FlowApi();
const columns = [
    {id: 'flu_titolo', label: 'Titolo', colSpan: 1, sortable: true},
    {id: 'flu_descrizione', label: 'Descrizione', colSpan: 2, sortable: true}
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {

        overflow: 'auto',
    },
});

const FlowsStructureTable = ({history, dispatch, api}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [flows, setFlow] = useState([]);
    const [deleteFlow, setDeleteFlow] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('flu_titolo');
    const [filter, setFilter] = React.useState();
    const [refresh, setRefresh] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const {flussi} = await Flow.query();
            setFlow(flussi);
            setTableRows(flussi);
        };
        fetchData();
    }, [api.fetch, refresh]);

    useEffect(() => {
        setPage(0);
        setTableRows(flows.filter(searchFilter(filter, columns)))
    }, [filter, flows]);

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    const handleClickOpen = (flow) => ()=> {
        setOpen(true);
        setDeleteFlow(flow)
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };
    const updateFunction = (flow) => () => {
        history.push(`/flussi-OdV/flusso/${flow.uuid}`)
    };

    const deleteFunction = (flow) => async () => {
        try {
            await Flow.delete(flow.uuid);
            dispatch(handleToast({
                message: 'Flusso eliminato correttamente'
            }));
            handleClose();
            setRefresh(!refresh);
        } catch (message) {
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <Paper className={classes.root}>
            <Toolbar>
                <TextField label={'Cerca'} fullWidth={true} style={{margin: '1rem 1rem 2rem'}}
                           onChange={(e) => setFilter(e.target.value)}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <Icon>search</Icon>
                                   </InputAdornment>
                           }}/>
            </Toolbar>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(
                                ({id, align, sortable, colSpan, label}) => (
                                    <TableCell key={id} align={align} colSpan={colSpan} style={{zIndex: 3}}>
                                        {sortable ?
                                            <TableSortLabel
                                                active={orderBy === id}
                                                direction={order}
                                                onClick={createSortHandler(id)}>
                                                {label}
                                            </TableSortLabel>
                                            : label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.orderBy(tableRows, [orderBy], [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(
                                (flow, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={index}>
                                            <TableCell align={"left"}>
                                                {flow.flu_titolo}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {flow.flu_descrizione}
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                <IconButton onClick={updateFunction(flow)}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={handleClickOpen(flow)}>
                                                    <Icon color={"error"}>delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        {!tableRows.length &&
                        <TableRow hover tabIndex={-1}>
                            <TableCell align={"center"} colSpan={columns.length}>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    nessun risultato
                                </Typography>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={(e, newPage) => setPage(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Vuoi davvero eliminare {deleteFlow.flu_titolo}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Annulla
                    </Button>
                    <Button onClick={deleteFunction(deleteFlow)} color={"secondary"} autoFocus>
                        Elimina
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>);
};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};
export default connect(mapStateToProps)(withRouter(FlowsStructureTable));
