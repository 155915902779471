import {connect} from "react-redux";
import React, {useReducer, useState} from "react";
import {FilesystemApi} from "../../../services/api.service";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useForm, {FormContext} from "react-hook-form";
import {handleModal, handleToast} from "../../../redux/actions";
import {FormControl, FormLabel, Radio, RadioGroup, TextField} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const FSApi = new FilesystemApi();
const useStyles = makeStyles({
    red: {
        'color': "#ff0000"
    }
});

const ProceduraWhistleblowingNewComponent = ({dispatch, proceduraWhistleblowingUuid}) => {
    const classes = useStyles();
    const [fileName, setFileName] = useState(null);
    const [fileNameError, setFileNameError] = useState(null);
    const [fileType, setFileType] = useState('doc');
    const [submitting, setSubmitting] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [hasErrors, setHasErrors] = useState(false);

    function humanFileSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        let hasErrors = false;
        if (!fileName) {
            setFileNameError('Inserire il nome file');
            hasErrors = true;
        }
        if(!fileType) {
            setFileType('doc');
            hasErrors = true;
        }
        if(!selectedFile) {
            setSelectedFileError('Selezionare un file');
            hasErrors = true;
        }

        if ( hasErrors ) {
            setSubmitting(false);
        } else {
            try {
                formData.append('name', fileName);
                formData.append('type', fileType);
                formData.append('media', selectedFile);
                setSubmitting(true);
                const sendCall = await FSApi.storeMediaPW(formData, proceduraWhistleblowingUuid);
                setSubmitting(false);
                dispatch(handleToast({
                    open:false,
                    message:'Documento caricato con successo'
                }));
                window.location.reload();
            } catch (message) {
                dispatch(handleToast({
                    message
                }));
            }
        }
    }

    const handleFileName = event => {
        setFileNameError(null);
        setFileName(event.target.value);
    }

    const handleFileType = event => {
        setSelectedFileError(null);
        setFileType(event.target.value);
    }

    const renderUploadButton = () => {
        if (!selectedFile) {
            return "Scegli un media da caricare";
        }

        return selectedFile.name + ", " + humanFileSize(selectedFile.size);
    };

    const buttonLabel = () => {
        return <Typography variant={"button"} color={'primary'}>{"Salva"}</Typography>
    };

    const handleFileSelect = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <FormLabel id="nome-documento-label">Nome documento</FormLabel>
                        <TextField name="name" onChange={handleFileName} id="standard-basic" placeholder={"Nome Documento"} variant="standard" />
                        {fileNameError && <div className={classes.red}>{fileNameError}</div>}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                        <FormLabel id="demo-radio-buttons-group-label">Tipo file</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="xlsx"
                            name="media-type"
                            onChange={handleFileType}
                            required={true}
                        >
                            <FormControlLabel value="pdf" control={<Radio />} label="Pdf" />
                            <FormControlLabel value="doc" control={<Radio />} label="Doc" />
                        </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <label htmlFor="upload-media">
                            <input
                                style={{ display: 'none' }}
                                id="upload-media"
                                name="media"
                                type="file"
                                onChange={(e) => handleFileSelect(e)}
                            />

                            <Button color="primary" variant="contained" component="span">
                                {renderUploadButton()}
                            </Button>
                            {selectedFileError && <div className={classes.red}>{selectedFileError}</div>}
                        </label>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {submitting &&
                        <div>Invio i dati...</div>
                    }
                    <Button type={"submit"} fullWidth>
                        {buttonLabel()}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
};

ProceduraWhistleblowingNewComponent.defaultProps = {
    media: {}
};
ProceduraWhistleblowingNewComponent.propTypes = {
    media: PropTypes.object,
};
export default connect()(ProceduraWhistleblowingNewComponent);
