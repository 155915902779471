import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import {handleModal} from "../../../redux/actions/modal.action";
import CategoryComponent from "../../CRUD/violation-category/violation-category.component";
import {Typography} from "@material-ui/core";
import {Violation231CategoryApi} from "../../../services/api.service";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import _ from "lodash";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {searchFilter} from "../../../constant";
import CrudCtaComponent from "../../ui/crud-cta/crud-cta.component";

const Violation231Category = new Violation231CategoryApi();
const columns = [
    {id: 'cat_descrizione', label: 'Categoria', colSpan: 1, sortable: true},
    {id: 'cat_articolo', label: 'D.Lgs 231', colSpan: 2, sortable: true}
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {
        overflow: 'auto',
    },
});

const ViolationCategoriesTable = ({dispatch, api}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [categories, setCategories] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('cat_description');
    const [filter, setFilter] = React.useState(null);
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };
    useEffect(() => {
        const fetchData = async () => {
            const reati231_cat = await Violation231Category.query();
            setCategories(reati231_cat);
            setTableRows(reati231_cat);
        };
        fetchData();
    }, [api.fetch]);
    useEffect(() => {
        setPage(0);
        setTableRows(categories.filter(searchFilter(filter, columns)))
    }, [filter,categories]);
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    const updateCategory = (category) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Modifica categoria',
            content: <CategoryComponent mode={'update'} category={category}/>,
        }));
    };

    const deleteCategory = (category) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Modifica categoria',
            content: <CategoryComponent mode={'delete'} category={category}/>,
        }));
    };

    const showCategory = (category) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'md',
            title: 'Informazioni categoria',
            content: <CategoryComponent mode={'read'} category={category}/>,
        }));
    };

    return (
        <Paper className={classes.root}>
            <Toolbar>
                <TextField label={'Cerca'} fullWidth={true} style={{margin: '1rem 1rem 2rem'}}
                           onChange={(e) => setFilter(e.target.value)}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <Icon>search</Icon>
                                   </InputAdornment>
                           }}/>
            </Toolbar>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(
                                ({id, align, sortable, colSpan, label}) => (
                                    <TableCell key={id} align={align} colSpan={colSpan} style={{zIndex: 3}}>
                                        {sortable ?
                                            <TableSortLabel
                                                active={orderBy === id}
                                                direction={order}
                                                onClick={createSortHandler(id)}>
                                                {label}
                                            </TableSortLabel>
                                            : label}

                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.orderBy(tableRows, [orderBy], [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(
                                (category, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <TableCell align={"left"}>
                                                {category.cat_descrizione}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {category.cat_articolo}
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                {!category.cat_com_id &&
                                                    <IconButton onClick={showCategory(category)}>
                                                        <Icon>info</Icon>
                                                    </IconButton>
                                                }
                                                <CrudCtaComponent com_id={category.cat_com_id}>
                                                    <IconButton onClick={updateCategory(category)}>
                                                        <Icon>edit</Icon>
                                                    </IconButton>
                                                    <IconButton onClick={deleteCategory(category)}>
                                                        <Icon color={"error"}>delete</Icon>
                                                    </IconButton>
                                                </CrudCtaComponent>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        {!categories.length &&
                        <TableRow hover tabIndex={-1}>
                            <TableCell align={"center"} colSpan={columns.length}>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    nessun dato
                                </Typography>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={(e, newPage) => setPage(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>);
};

ViolationCategoriesTable.propTypes = {};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};
export default connect(mapStateToProps)(ViolationCategoriesTable);
