import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import ToolbarComponent from "../components/ui/toolbar/toolbar.component";
import DrawerComponent from "../components/ui/drawer/drawer.component";
import Drawer24Component from "../components/ui/drawer/drawer-24.component";
import FooterComponent from "../components/ui/footer/footer.component";
import PropTypes from "prop-types";
import AuthService from "../services/auth.service";
import {withRouter} from "react-router-dom";
import envConfig from "../config.json";
import _ from 'lodash';

const DefaultLayout = (props) => {
    const {children, toolbarPosition,location: { pathname }} = props;
    const [open, toggleDrawer] = useState(false);
    const toggle = () => {
        toggleDrawer(!open);
    };
    const account = AuthService.user.utente.accounts;
    const selected_company = AuthService.company;
    const isAdmin = AuthService.isAdmin();
    const showDrawer =  isAdmin ? true : !_.isEmpty(selected_company) && (pathname.indexOf('/profilo')===-1 || (pathname.indexOf('/profilo')!==-1 && account.companies && account.companies.length > 0));

    var drawerComponentName = "HomeDrawerComponent";
    if(envConfig.CUSTOMER ?? "default" == "Sole24")
    {
        drawerComponentName = "HomeDrawer24Component";
    }


    return (
        <Grid container style={{position: 'relative', minHeight: '100vh'}}>
            <Grid item xs={12}>
                <ToolbarComponent showDrawer={showDrawer} position={toolbarPosition} toggleDrawer={toggle} hideMenu={isAdmin}/>
                {showDrawer && drawerComponentName == "HomeDrawerComponent" && <DrawerComponent open={open} toggleDrawer={toggle}/>}
                {showDrawer && drawerComponentName == "HomeDrawer24Component" && <Drawer24Component open={open} toggleDrawer={toggle}/>}

            </Grid>
            <Grid item xs={12} style={{marginTop: toolbarPosition === 'fixed' ? '64px' : 'inherit'}}>
                {children}
            </Grid>
            <Grid item xs={12}>
                <FooterComponent/>
            </Grid>
        </Grid>
    );
};

DefaultLayout.defaultProps = {
    toolbarPosition: 'absolute'
};

DefaultLayout.propTypes = {
    toolbarPosition: PropTypes.string
};

export default withRouter(DefaultLayout);
