import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import {handleModal} from "../../../redux/actions/modal.action";
import Violation231Component from "../../CRUD/violation-231/violation231.component";
import {Typography} from "@material-ui/core";
import {Violation231Api} from "../../../services/api.service";
import _ from "lodash";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
// import {searchFilter} from "../../../constant";
import PropTypes from "prop-types";
import CrudCtaComponent from "../../ui/crud-cta/crud-cta.component";
import CircularProgress from '@material-ui/core/CircularProgress'

const Violation231 = new Violation231Api();
const columns = [
    {id: 'fonti', label: 'Fonte', colSpan: 1, sortable: true},
    {id: 'rea_articolo', label: 'Articolo', colSpan: 1, sortable: true},
    {id: 'rea_titolo', label: 'Nome', colSpan: 1, sortable: true},
    {id: 'reati231_cat.cat_descrizione', label: 'Cat. Reato', colSpan: 1, sortable: true},
    {id: 'reati231_cat.export', label: 'Export', colSpan: 2, sortable: false},
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {

        overflow: 'auto',
    },
});

const Violation231ListTable = ({dispatch, api, filterBy}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [violations, setViolations] = useState(null);
    const [tableRows, setTableRows] = useState(null);
    const [loading, setLoading] = useState(true)
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('rea_titolo');
    const [filter, setFilter] = React.useState(null);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const reati231 = await Violation231.query();
            setTableRows(reati231);
            setViolations(reati231)
            setLoading(false)
        };
        fetchData();
    }, [api.fetch]);
    useEffect(() => {
        if(violations){
            setPage(0);
            let filteredRows = violations
            if(filterBy){
                filteredRows = violations.filter(({reati231_cat}) => {
                    return (reati231_cat && reati231_cat.uuid === filterBy.value) ? true : !filterBy
                })
            }

            if(filter){
                filteredRows = filteredRows.filter(({ fonti, rea_articolo, rea_titolo, reati231_cat }) => {
                    return (fonti && fonti.fon_fonte.toLowerCase().indexOf(filter.toLowerCase())!== -1) ||
                        (rea_articolo && rea_articolo.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
                        (rea_titolo && rea_titolo.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
                        (reati231_cat && reati231_cat.cat_descrizione.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                })
            }

            setTableRows(filteredRows);
        }
    }, [filter, filterBy,violations]);
    const handleFilter = event => {
        setFilter(event.target.value);
    };
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };

    const exportReato = async (reatoUuid) => {
        let d = new Date();
        let filename = JSON.parse(localStorage.GRC_SELECTED_COMPANY).id + '_dossier_reato_' + d.toLocaleDateString() + '.docx';
        const reatoDownload = await Violation231.exportReato(reatoUuid, filename);
    };

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    const updateViolation = (violation) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Modifica Reato',
            content: <Violation231Component mode={'update'} violation={violation}/>,
        }));
    };

    const deleteViolation = (violation) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Elimina Reato',
            content: <Violation231Component mode={'delete'} violation={violation}/>,
        }));
    };

    const showCategory = (violation) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Informazioni Reato',
            content: <Violation231Component mode={'read'} violation={violation}/>,
        }));
    };
    return (
        <Paper className={classes.root}>
            <Toolbar>
                <TextField label={'Cerca'} fullWidth={true} style={{margin: '1rem 1rem 2rem'}}
                           onChange={handleFilter}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <Icon>search</Icon>
                                   </InputAdornment>
                           }}/>
            </Toolbar>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(
                                ({id, align, sortable, colSpan, label}) => (
                                    <TableCell key={id} align={align} colSpan={colSpan} style={{zIndex: 3}}>
                                        {sortable ?
                                            <TableSortLabel
                                                active={orderBy === id}
                                                direction={order}
                                                onClick={createSortHandler(id)}>
                                                {label}
                                            </TableSortLabel>
                                            : label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows && _.orderBy(tableRows, [orderBy], [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(
                                (violation, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={index}>
                                            <TableCell align={"left"} style={{width: '30%'}}>
                                                {violation.fonti ? violation.fonti.fon_fonte : 'n.d.'}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {violation.rea_articolo}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {violation.rea_titolo}
                                            </TableCell>
                                            <TableCell align={"left"} style={{width: '30%'}}>
                                                {violation.reati231_cat ? violation.reati231_cat.cat_descrizione : ''}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                <IconButton onClick={() => exportReato(violation.uuid)}>
                                                    <Icon>download</Icon>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                {!violation.rea_com_id &&
                                                <IconButton onClick={showCategory(violation)}>
                                                    <Icon>info</Icon>
                                                </IconButton>}
                                                <CrudCtaComponent com_id={violation.rea_com_id}>
                                                    <IconButton onClick={updateViolation(violation)}>
                                                        <Icon>edit</Icon>
                                                    </IconButton>
                                                    <IconButton onClick={deleteViolation(violation)}>
                                                        <Icon color={"error"}>delete</Icon>
                                                    </IconButton>
                                                </CrudCtaComponent>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        {(violations && !violations.length) &&
                        <TableRow hover tabIndex={-1}>
                            <TableCell align={"center"} colSpan={columns.length}>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    nessun dato
                                </Typography>
                            </TableCell>
                        </TableRow>}
                        {(tableRows && !tableRows.length && (filter || filterBy)) &&
                            <TableRow hover tabIndex={-1}>
                                <TableCell align={"center"} colSpan={columns.length}>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        nessun risultato per la ricerca effettuata
                                    </Typography>
                                </TableCell>
                            </TableRow>}
                        {loading &&
                            <TableRow hover tabIndex={-1} style={{height: 300}}>
                                <TableCell align={"center"} colSpan={columns.length}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
            </div>
            {tableRows && <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={tableRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={(e, newPage) => setPage(newPage)}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            }
        </Paper>);
};

Violation231ListTable.propTypes = {
    filterBy: PropTypes.object
};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    };
};
export default connect(mapStateToProps)(Violation231ListTable);
