import React from "react";
import {LABEL_CONFIG, REQUIRED_CONFIG} from "../form-item.class";
import RadioGroupCompile from "./radio-group.compile";
import RadioGroupTemplate from "./radio-group.template";
import EmptyItemComponent from "../empty-item/empty-item.component";
import RadioGroupConfigure from "./radio-group.configure";
import {
    MODE_VIEW_COMPILE,
    MODE_VIEW_CONFIGURATION,
    MODE_VIEW_PREVIEW,
    MODE_VIEW_READONLY,
    MODE_VIEW_TEMPLATE
} from "../../../index.constants";
import PropTypes from "prop-types";

const RadioGroupComponent = (props) => {
    const {viewMode, save} = props;

    const config = () => {
        return [
            LABEL_CONFIG(props),
            REQUIRED_CONFIG(props)
        ]
    };

    const saveConfiguration = (config, radioGroup, valid) => {
        let wrapper = {};
        config.forEach(({name, value}) => {
            if (name === 'required' && value === '') {
                value = false;
            }
            wrapper = {
                ...wrapper,
                [name]: value
            }
        });
        save({
            ...wrapper,
            radioGroup,
            valid
        });
    };

    const render = () => {
        switch (viewMode) {
            case MODE_VIEW_PREVIEW:
            case MODE_VIEW_COMPILE:
                return <RadioGroupCompile {...props}/>;
            case MODE_VIEW_READONLY:
                return <RadioGroupCompile {...props} disabled={true}/>;
            case MODE_VIEW_TEMPLATE:
                return <RadioGroupTemplate/>;
            case MODE_VIEW_CONFIGURATION:
                return <RadioGroupConfigure {...props} defaultConfig={config()}
                                            onSave={saveConfiguration}/>;
            default:
                return <EmptyItemComponent/>;
        }
    };
    return render();
};

RadioGroupComponent.propTypes = {
    save: PropTypes.func.isRequired,
    viewMode: PropTypes.number.isRequired
};

export default RadioGroupComponent;
