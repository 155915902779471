import React, {useState} from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Typography} from "@material-ui/core";
import {HomePageApi} from "../../services/api.service";

const HomeApi = new HomePageApi();

const labels = [];

export const data = {
    labels,
    datasets: [
        {
            label: 'Sanzione Amministrativa Massima',
            data: [],
            backgroundColor: [
            ],
            borderColor: [
            ],
            borderWidth: 1,
        }
    ],
};

/** Rischio sanzioni amministrative masime per categoria di reato */
export default function RischioQuotePerArea() {
    const [chartData, setChartData] = useState(data);

    React.useEffect(() => {
        const fetchData = async () => {
            const graphData = await HomeApi.sanzAmmMassime();
            if ( graphData !== undefined && graphData.labels !== undefined && graphData.colors !== undefined && graphData.values !== undefined ) {
                let labels = graphData.labels;
                let newCharData = {
                    labels,
                    datasets: [
                        {
                            label: 'Sanzione Amministrativa Massima',
                            data: graphData.values,
                            // backgroundColor: graphData.colors,
                            backgroundColor: [
                                'rgba(255, 64, 105, 0.7)',
                                'rgba(241, 129, 80, 0.7)',
                                'rgba(255, 194, 53, 0.7)',
                                'rgba(5, 155, 255, 0.7)',
                                'rgba(28, 219, 139, 0.7)',
                            ],
                        }
                    ],
                };

                setChartData(newCharData);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <Typography variant="h6">Top 5 Sanzioni Amm. Massime</Typography>
            <div>
                <Doughnut
                    data={chartData}
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        title: {
                            display: true,
                            text: "Rischio Sanzioni Amministrative Massime per Area di rischio"
                            /* fontSize: 25 */
                        },
                        legend: { display: false, position: "top" }
                    }}
                    height={230}
                    width={230}
                />
            </div>
        </>
    );
}
