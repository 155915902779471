import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import axios from "axios";
import { Service } from "axios-middleware";
import AuthService from "./services/auth.service";
import "react-quill/dist/quill.snow.css";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import store from "./redux/store";
import TagManager from 'react-gtm-module';

const history = createBrowserHistory();
const tagManagerArgs = {
  gtmId: 'GTM-WZW28T7'
};
TagManager.initialize(tagManagerArgs)

const service = new Service(axios);

service.register({
  onRequest(config) {console.log(AuthService.company ? AuthService.company.id : "");
    // console.log('onRequest', config);
    return {
      ...config,
      withCredentials: true,
      headers: {
        ...config.headers,
        /*authorization: AuthService.user ? AuthService.user.token : "",*/
        "company-id": AuthService.company ? AuthService.company.id : "",
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
    };
  },
  onResponseError(error) {
    console.log(error);
    let res = { message: "Ops! Qualcosa è andato storto" };
    try {
      res = JSON.parse(error.response.data);
    } catch (e) {
      throw res.message;
    }
    if (res.message === "Token non valido" && AuthService.isAuth()) {
      AuthService.logout().then(r => {
        localStorage.clear();
        history.push('/');
      });
    }
    throw res.message;
  },
});

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <App />
      </Router>
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
