import React, {Fragment, useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ConfigurationModuleComponent from "../configuration-module/configuration-module.component";
import {TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from 'prop-types';
import {generateID} from "../../../index.utils";
import ValidButtonConfiguration from "../../generic-ui/valid-button-configuration";

const SelectOptionsRow = ({onSave, defaultSelect, defaultOptions}) => {
    const [select, toggleSelect] = useState(defaultSelect);
    const [options, setOptions] = useState(defaultOptions);
    useEffect(() => onSave(select, select ? options : defaultOptions), [onSave, select, options, defaultOptions]);
    const removeOption = () => {
        options.pop();
        setOptions([...options]);
    };
    const handleSelect = (e) => {
        toggleSelect(e.target.checked);
        onSave(select, select ? options : defaultOptions);
    };
    const handleChange = (e) => {
        setOptions([...options.map((option) => {
            if (option.value === e.target.name) {
                option.label = e.target.value;
            }
            return option;
        })]);
        onSave(select, options);
    };
    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    {select && options.some(({label}) => !label) ?
                        <Icon color={"error"}>warning</Icon> :
                        <Icon color={"primary"}>done</Icon>}
                </TableCell>
                <TableCell>
                    <FormControlLabel
                        control={
                            <Checkbox value={select} defaultChecked={select} onChange={handleSelect}/>
                        }
                        label={
                            <Typography variant={"subtitle2"}>seleziona</Typography>
                        }/>
                </TableCell>
                <TableCell>
                    <TextField disabled={true} fullWidth={true}/>
                </TableCell>
                <TableCell>
                    <Grid container alignItems={"center"}>
                        <Grid item>
                            <IconButton onClick={() => removeOption()} disabled={!select || options.length < 3}>
                                <Icon color={(!select || options.length < 3) ? "disabled" : "error"}>remove</Icon>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton color={"primary"} onClick={() => setOptions([...options, {
                                value: `option-${generateID(9)}`,
                                label: ''
                            }])} disabled={!select}>
                                <Icon>add</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            {select && options.map(({value, label}, index) => (
                <TableRow key={`option-${index}`}>
                    <TableCell colSpan={1}/>
                    <TableCell>
                        Opzione {index + 1}
                    </TableCell>
                    <TableCell>
                        <TextField fullWidth={true} value={label} placeholder={'Inserisci titolo'} name={value}
                                   onChange={handleChange}/>
                    </TableCell>
                </TableRow>
            ))}
        </Fragment>
    );
};

const TextFieldConfigure = ({onSave, defaultConfig, select, options, displayName}) => {
    const [dialog, toggleDialog] = useState(false);
    const [rows, setRows] = useState(defaultConfig);
    const [_select, setSelect] = useState(select);
    const [_options, setSelectOptions] = useState(options);
    const [isValid, setIsValid] = useState(!defaultConfig.some(({required, value}) => required && !value));
    const handleChange = (e, row) => {
        const row_index = rows.indexOf(row)        
        if(e.target.name==='boolean' && !e.target.value){            
            rows[row_index] = {
                ...row,
                value: '',            
                boolean: false
            };            
        }else{
            rows[row_index] = {
                ...row,
                [e.target.name]: e.target.value,            
            };        
        }
        setRows([...rows]);
        setIsValid(!rows.some((item) => {            
            const {required, value} = item
            if(item.hasOwnProperty('boolean')){
                return item.boolean && !value
            }else{
                return required && !value
            }            
        }) && (!(select && options.some(({label}) => !label)) || !select));
    };
    const handleSelectChange = (select, options) => {
        console.log(select,options)
        setIsValid(!rows.some(({required, value}) => required && !value) && (!(select && options.some(({label}) => !label)) || !select));
        setSelect(select);
        setSelectOptions(options);
        console.log(select)
    };
    const configure = () => {
        toggleDialog(!dialog);
    };
    const save = () => {
        onSave(rows, _select, _options, isValid);
        toggleDialog(false);
    };
    return (
        <FormControl component="fieldset" fullWidth={true}>
            <Grid container justify={"center"}>
                <Grid item xs={10}>
                    <Typography variant={"subtitle2"} gutterBottom={true}
                                align={"center"}>
                        {displayName}
                    </Typography>
                </Grid>
                <Grid item xs={10} md={6}>
                    <ValidButtonConfiguration configure={configure} isValid={isValid}/>
                </Grid>
            </Grid>
            <Dialog open={dialog} aria-labelledby="text-field-conf-dialog" onClose={() => toggleDialog(false)}
                    fullWidth={true} maxWidth={'lg'}>
                <DialogTitle id="text-field-conf-dialog">Configura {displayName}</DialogTitle>
                <DialogContent>
                    <ConfigurationModuleComponent rows={rows}
                                                  customRows={
                                                      <SelectOptionsRow onSave={handleSelectChange}
                                                                        defaultSelect={_select}
                                                                        defaultOptions={_options}/>
                                                  }
                                                  handleChange={handleChange}/>
                </DialogContent>
                <DialogActions>
                    <Grid direction={"row"} justify={"flex-end"} container>
                        <Grid item>
                            <Button variant={"text"} color={"default"} onClick={() => toggleDialog(false)}>
                                indietro
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={save} color={"secondary"}
                                    disabled={!isValid}>salva</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </FormControl>
    );
};

TextFieldConfigure.defaultProps = {
    defaultConfig: [],
    onSave: () => console.error('Missing on saveConfiguration function!')
};

TextFieldConfigure.propTypes = {
    onSave: PropTypes.func.isRequired,
    defaultConfig: PropTypes.array
};

export default TextFieldConfigure;
