import {MODE_VIEW_TEMPLATE} from "../../../index.constants";
import {generateID} from "../../../index.utils";
import FormItem from "../form-item.class";

export default class RadioGroupClass extends FormItem {
    constructor({viewMode, displayName, name, values, radioGroup, ...props}) {
        super(props);
        this.name = name || generateID(9);
        this.type = 'radio-group';
        this.isEmpty = false;
        this.viewMode = viewMode || MODE_VIEW_TEMPLATE;
        this.displayName = displayName || 'Radio Group';
        this.values = values || {};
        this.radioGroup = radioGroup || [
            {
                label: '',
                value: `radio-${generateID(9)}`
            },
            {
                label: '',
                value: `radio-${generateID(9)}`
            }
        ]
    }
}
