import React from 'react';
import StepperComponent from "../../components/ui/stepper/stepper.component";
import AccountReactivationSteps from "../../components/steps/account-reactivation/account-reactivation.steps";
import {handleToast} from "../../redux/actions";
import {AccountReactivationApi} from "../../services/api.service";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const AccountReactivation = new AccountReactivationApi();

const AccountReactivationPage = ({dispatch, history}) => {
    const steps = ['Dati account', 'Conferma riattivazione'];
    const save = async (data) => {
        try {
            await AccountReactivation.update(data);
            dispatch(handleToast({message: 'Account riattivato correttamente!'}));
            history.push('/gestione-account/conferma-riattivazione')
        } catch (message) {
            dispatch(handleToast({message}))
        }
    };
    return (
        <StepperComponent steps={steps} onComplete={save}
                          title={'Gestione account - Riattivazione'}>
            <AccountReactivationSteps/>
        </StepperComponent>
    );
};

export default connect()(withRouter(AccountReactivationPage));
