import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { connect } from 'react-redux'

const RiskAssessmentMatrixBehaviorIndicatorFilters = ({ dispatch }) => {
  const { register, watch, setValue } = useFormContext()

  useEffect(() => {
    register('indicatorsFilters')
  }, [])

  return (
    <Card style={{ marginBottom: "35px" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Filtri
            </Typography>
          </Grid>

          <Grid item style={{ display: "flex", "gap": "0 4px" }}>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'indicatorsFilters.generico' })}
              selected={!!watch('indicatorsFilters.generico') || false}
              onChange={(e, value) => setValue('indicatorsFilters.generico', !watch('indicatorsFilters.generico'))}
            >
              Generico
            </ToggleButton>
            <ToggleButton
              size="small"
              value={1}
              inputRef={register({ name: 'indicatorsFilters.specifico' })}
              selected={!!watch('indicatorsFilters.specifico') || false}
              onChange={(e, value) => setValue('indicatorsFilters.specifico', !watch('indicatorsFilters.specifico'))}
            >
              Specifico
            </ToggleButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default connect()(RiskAssessmentMatrixBehaviorIndicatorFilters)
