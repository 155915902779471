import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const UnknownStepComponent = ({handleBack}) => {
    return (
        <Grid container direction={"column"} alignItems={"center"} spacing={4}>
            <Grid item>
                <Typography variant={"body1"} color={"error"}>
                    Unknown step
                </Typography>
            </Grid>
            <Grid item>
                <Button onClick={handleBack}>indietro</Button>
            </Grid>
        </Grid>
    );
};

UnknownStepComponent.propTypes = {
    handleBack: PropTypes.func.isRequired
};

export default UnknownStepComponent;
