import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from '@material-ui/core/Typography'

const style = makeStyles(theme => ({
        wrapper: {
            position: 'relative',
            textAlign: 'center'
        },
        videoTitle:{
            marginBotttom: theme.spacing(2)
        }
    }));
const TutorialVideoComponent = ({ title, video }) => {
    const classes = style();    
    return (
        <div className={classes.wrapper}>
            <Typography variant="h6" color="primary" className={classes.videoTitle}>Stai guardando: {title}</Typography>
            <ReactPlayer 
                url={require(`../../../tutorials/video/${video}`)}                
                playing 
                controls
                width='100%'
                height='100%'
            />            
        </div>
    );
};

TutorialVideoComponent.propTypes = {
    video: PropTypes.string.isRequired
};

export default TutorialVideoComponent;
