import React, {Fragment, useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ConfigurationModuleComponent from "../configuration-module/configuration-module.component";
import {TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from 'prop-types';
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import ValidButtonConfiguration from "../../generic-ui/valid-button-configuration";

const InputFileOptionsRow = ({onSave, defaultOptions, key}) => {
    const handleChange = (value) => (e) => {
        onSave([...defaultOptions.map((option) => {
            if (option.value === value) {
                option.checked = e.target.checked;
            }
            return option;
        })]);
    };
    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    {!defaultOptions.some(({checked}) => checked) && <Icon color={"error"}>warning</Icon>}
                    {defaultOptions.some(({checked}) => checked) && <Icon color={"primary"}>done</Icon>}
                </TableCell>
                <TableCell>
                    <Typography variant={"subtitle2"}>Formato file</Typography>
                </TableCell>
                <TableCell>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Selezione Formati</FormLabel>
                        <FormGroup>
                            {defaultOptions.map(
                                ({label, checked, value}, index) =>
                                    <FormControlLabel key={`${key}-option-row-${index}`}
                                                      control={<Checkbox checked={checked}
                                                                         onChange={handleChange(value)}
                                                                         value={value}/>}
                                                      label={label}/>
                            )}
                        </FormGroup>
                    </FormControl>
                </TableCell>
                <TableCell/>
            </TableRow>
        </Fragment>
    );
};

const FileInputConfigure = ({onSave, defaultConfig, options, displayName, id}) => {
    const [dialog, toggleDialog] = useState(false);
    const [rows, setRows] = useState(defaultConfig);
    const [_options, setOptions] = useState(options);
    const [isValid, setIsValid] = useState(defaultConfig.some(({required, value}) => required && !value));
    useEffect(
        () => {
            setIsValid(!rows.some((item) => {
                const {required, value} = item
                if(item.hasOwnProperty('boolean')){
                    return item.boolean && !value
                }else{
                    return required && !value
                }
            }) && _options.some(({checked}) => checked));
        },
        [rows, _options]
    );
    const handleChange = (e, row) => {
        const row_index = rows.indexOf(row)
        if(e.target.name==='boolean' && !e.target.value){
            rows[row_index] = {
                ...row,
                value: '',
                boolean: false
            };
        }else{
            rows[row_index] = {
                ...row,
                [e.target.name]: e.target.value,
            };
        }
        setRows([...rows]);
    };
    const configure = () => {
        toggleDialog(!dialog);
    };
    const save = () => {
        onSave(rows, _options, isValid);
        toggleDialog(false);
    };
    return (
        <FormControl component="fieldset" fullWidth={true}>
            <Grid container justify={"center"} alignItems={"center"} spacing={1}>
                <Grid item xs={12}>
                    <Typography variant={"subtitle2"} gutterBottom={true}
                                align={"center"}>
                        {displayName}
                    </Typography>
                </Grid>
                <Grid item xs={10} md={6}>
                    <ValidButtonConfiguration configure={configure} isValid={isValid}/>
                </Grid>
            </Grid>
            <Dialog open={dialog} aria-labelledby="text-field-conf-dialog" onClose={() => toggleDialog(false)}
                    fullWidth={true} maxWidth={'lg'}>
                <DialogTitle id="text-field-conf-dialog">
                    Configura {displayName}
                </DialogTitle>
                <DialogContent>
                    <ConfigurationModuleComponent rows={rows}
                                                  customRows={
                                                      <InputFileOptionsRow onSave={setOptions}
                                                                           defaultOptions={_options}/>
                                                  }
                                                  handleChange={handleChange}/>
                </DialogContent>
                <DialogActions>
                    <Grid direction={"row"} justify={"flex-end"} container>
                        <Grid item>
                            <Button variant={"text"} color={"default"} onClick={() => toggleDialog(false)}>
                                indietro
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={save} color={"secondary"}
                                    disabled={!isValid}>salva</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </FormControl>
    );
};

FileInputConfigure.defaultProps = {
    defaultConfig: [],
    onSave: () => console.error('Missing on saveConfiguration function!')
};

FileInputConfigure.propTypes = {
    onSave: PropTypes.func.isRequired,
    defaultConfig: PropTypes.array
};

export default FileInputConfigure;
