import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {withRouter} from "react-router-dom";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    subtitle: {
        minHeight: theme.spacing(6),
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        background: '#F5F5F5',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    noPaddingSubtitle: {
        minHeight: theme.spacing(6),
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        background: '#F5F5F5'
    }
}));
const PageSubtitleComponent = ({subtitle, back, history}) => {
    const classes = useStyles();
    return (
        <Grid container className={back ? classes.noPaddingSubtitle : classes.subtitle} alignItems={"center"} spacing={1}>
            {back &&
            <Grid item>
               <Tooltip title={'Indietro'}>
                   <IconButton onClick={()=> history.goBack()}>
                       <Icon>arrow_back</Icon>
                   </IconButton>
               </Tooltip>
            </Grid>}
            <Grid item>
                <Typography variant={"subtitle1"}>
                    {subtitle}
                </Typography>
            </Grid>
        </Grid>
    );
};

PageSubtitleComponent.propTypes = {
    subtitle: PropTypes.string,
    back: PropTypes.bool
};

export default withRouter(PageSubtitleComponent);
