import React from 'react';
import StepperComponent from "../../components/ui/stepper/stepper.component";
import AccountCancellationSteps from "../../components/steps/account-cancellation/account-cancellation.steps";
import {handleToast} from "../../redux/actions";
import {AccountCancellationApi} from "../../services/api.service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const CancelAccount = new AccountCancellationApi();
const AccountCancellationPage = ({history, dispatch}) => {
    const steps = ['Dati account', 'Conferma disdetta'];
    const save = async (data) => {
        try {
            await CancelAccount.update(data);
            dispatch(handleToast({message: 'Account disdetto correttamente!'}));
            history.push('/gestione-account/conferma-disdetta')
        } catch (message) {
            dispatch(handleToast({message}))
        }
    };
    return (
        <StepperComponent steps={steps} onComplete={save} title={'Gestione account - Disdetta'}>
            <AccountCancellationSteps/>
        </StepperComponent>
    );
};

export default connect()(withRouter(AccountCancellationPage));
