import React from 'react';
import PropTypes from 'prop-types';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";

const ServicesSummaryComponent = ({services}) => {
    return (
        <Table>
            <TableBody>
                {services.map(
                    ({uuid, ser_name, ser_description, quantity}, index) =>
                        <TableRow key={`ser-${index}`}>
                            <TableCell>{ser_name}</TableCell>
                            <TableCell>{ser_description}</TableCell>
                            <TableCell align={"right"}>Quantità <strong>{quantity}</strong></TableCell>
                        </TableRow>
                )}
                {!services.length &&
                <TableRow>
                    <TableCell colSpan={4}>Nessun servizio selezionato</TableCell>
                </TableRow>}
            </TableBody>
        </Table>
    );
};

ServicesSummaryComponent.defaultProps = {
    services: []
};
ServicesSummaryComponent.propTypes = {
    services: PropTypes.array.isRequired
};

export default ServicesSummaryComponent;
