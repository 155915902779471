import React from 'react';
import PropTypes from 'prop-types';
import Table from "@material-ui/core/Table";

import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import {TableCell} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

const ConfigurationModuleComponent = ({rows, customRows, handleChange}) => (
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>
                    valid
                </TableCell>
                <TableCell>
                    item
                </TableCell>
                <TableCell>
                    value
                </TableCell>
                <TableCell/>
            </TableRow>
        </TableHead>
        <TableBody>
            {rows.map((row, index) => {
                const {name, value, placeholder, boolean, required, disabled, type = 'text', isBoolean, textAnchor} = row;
                // console.log(`name: ${name}, required: ${required}, value: ${value}, boolean: ${boolean}, textAnchor: ${textAnchor}`)
                return (
                    <TableRow key={`${name}-${index}`}>
                        <TableCell>
                            {(boolean && !value) && <Icon color={"error"}>warning</Icon>}
                            {(!boolean || (boolean && value)) && <Icon color={"primary"}>done</Icon>}
                        </TableCell>
                        <TableCell>
                            {!isBoolean && <Typography variant={"subtitle2"}>{name}</Typography>}
                            {isBoolean &&
                            <FormControlLabel
                                control={<Checkbox name={textAnchor ? 'boolean' : 'value'}
                                                   value={textAnchor ? boolean : value}
                                                   defaultChecked={textAnchor ? boolean : value}
                                                   onChange={(e) => handleChange({
                                                            target: {
                                                                name: e.target.name,
                                                                value: e.target.checked
                                                            }
                                                        }, row)                                                                                                                
                                                   }/>}
                                label={<Typography variant={"subtitle2"}>{name}</Typography>}/>}
                        </TableCell>
                        <TableCell>
                            {textAnchor &&
                            <TextField value={value} name={'value'} fullWidth={true} placeholder={placeholder}
                                       onChange={(e) => handleChange(e, row)} type={type}
                                       required={required} disabled={disabled || (isBoolean && !boolean)}/>}
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                )
            })}
            {customRows}
        </TableBody>
    </Table>
);

ConfigurationModuleComponent.defaultProps = {
    rows: [],
    handleChange: () => console.warn('Missing handleChange function!')
};

ConfigurationModuleComponent.propTypes = {
    handleChange: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    customRows: PropTypes.node
};

export default ConfigurationModuleComponent;
