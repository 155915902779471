import React from "react";
import {
  Typography,
  FormControl,
  InputLabel,
  Input,
  CircularProgress,
  Grid,
  Button,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { ValutazioneApi, RiskAreaApi } from "../../../services/api.service";
import { parseNotes } from "../../../functions/insert-risk-assessment.helper";
import { handleModal } from "../../../redux/actions";

const spacer = <div style={{ height: "2em" }}></div>;

const inputStyle = {
  width: "100%",
  border: "1px solid grey",

  borderRadius: 2,
  padding: 1,
};

const valutazione = new ValutazioneApi();
const riskArea = new RiskAreaApi();

const NotesModal = ({
  requiresReload = false,
  areaId,
  companyId,
  id,
  api,
  setFatherNotes,
}) => {
  const [notes, setNotes] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchReatoNotes = await valutazione.getNotes(id);
      const fetchAreaNotes = await riskArea.getNotes(areaId);
      setNotes(parseNotes(fetchReatoNotes, fetchAreaNotes.data));
    };
    fetchData();
  }, [api.fetch]);

  const consulenteAreaNotes = () => {
      if ( localStorage.getItem('permessi_utente').indexOf('risk.referente') > 0 ) {
          return (
              <FormControl style={{ width: "100%" }}>
                  <InputLabel htmlFor="my-input">Nota Area</InputLabel>
                  <Input
                      multiline={true}
                      style={inputStyle}
                      value={notes.consulente.area}
                      onChange={(val) =>
                          setNotes({
                              ...notes,
                              consulente: {
                                  ...notes.consulente,
                                  area: val.target.value,
                              },
                          })
                      }
                      id="my-input"
                      disableUnderline={true}
                      rows={3}
                      aria-describedby="my-helper-text"
                      disabled="true"
                  />
              </FormControl>
          );
      }
      return (
          <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="my-input">Nota Area</InputLabel>
              <Input
                  multiline={true}
                  style={inputStyle}
                  value={notes.consulente.area}
                  onChange={(val) =>
                      setNotes({
                          ...notes,
                          consulente: {
                              ...notes.consulente,
                              area: val.target.value,
                          },
                      })
                  }
                  id="my-input"
                  disableUnderline={true}
                  rows={3}
                  aria-describedby="my-helper-text"
              />
          </FormControl>
      );
  };

  const consulenteReatoNotes = () => {
      if ( localStorage.getItem('permessi_utente').indexOf('risk.referente') > 0 ) {
          return (
              <FormControl style={{width: "100%"}}>
                  <InputLabel htmlFor="my-input">Nota Reato</InputLabel>
                  <Input
                      multiline={true}
                      style={inputStyle}
                      value={notes.consulente.reato}
                      rows={3}
                      onChange={(val) =>
                          setNotes({
                              ...notes,
                              consulente: {
                                  ...notes.consulente,
                                  reato: val.target.value,
                              },
                          })
                      }
                      disableUnderline={true}
                      id="outlined-basic"
                      aria-describedby="my-helper-text"
                      disabled="true"
                  />
              </FormControl>
          );
      }
      return (
          <FormControl style={{width: "100%"}}>
              <InputLabel htmlFor="my-input">Nota Reato</InputLabel>
              <Input
                  multiline={true}
                  style={inputStyle}
                  value={notes.consulente.reato}
                  rows={3}
                  onChange={(val) =>
                      setNotes({
                          ...notes,
                          consulente: {
                              ...notes.consulente,
                              reato: val.target.value,
                          },
                      })
                  }
                  disableUnderline={true}
                  id="outlined-basic"
                  aria-describedby="my-helper-text"
              />
          </FormControl>
      );
  };

    const aziendaAreaNotes = () => {
        if ( localStorage.getItem('permessi_utente').indexOf('risk.referente') > 0 ) {
            return (
                <FormControl style={{ width: "100%" }}>
                    <InputLabel htmlFor="my-input">Nota Area</InputLabel>
                    <Input
                        multiline={true}
                        style={inputStyle}
                        rows={3}
                        onChange={(val) =>
                            setNotes({
                                ...notes,
                                azienda: {
                                    ...notes.azienda,
                                    area: val.target.value,
                                },
                            })
                        }
                        value={notes.azienda.area}
                        disableUnderline={true}
                        id="my-input"
                        aria-describedby="my-helper-text"
                    />
                </FormControl>
            );
        }
        return (
            <FormControl style={{ width: "100%" }}>
                <InputLabel htmlFor="my-input">Nota Area</InputLabel>
                <Input
                    multiline={true}
                    style={inputStyle}
                    rows={3}
                    onChange={(val) =>
                        setNotes({
                            ...notes,
                            azienda: {
                                ...notes.azienda,
                                area: val.target.value,
                            },
                        })
                    }
                    value={notes.azienda.area}
                    disableUnderline={true}
                    id="my-input"
                    aria-describedby="my-helper-text"
                    disabled="true"
                />
            </FormControl>
        );
    };

    const aziendaReatoNotes = () => {
        if ( localStorage.getItem('permessi_utente').indexOf('risk.referente') > 0 ) {
            return (
                <FormControl style={{ width: "100%" }}>
                    <InputLabel htmlFor="my-input">Nota Reato</InputLabel>
                    <Input
                        multiline={true}
                        style={inputStyle}
                        value={notes.azienda.reato}
                        disableUnderline={true}
                        id="my-input"
                        onChange={(val) =>
                            setNotes({
                                ...notes,
                                azienda: {
                                    ...notes.azienda,
                                    reato: val.target.value,
                                },
                            })
                        }
                        rows={3}
                        aria-describedby="my-helper-text"
                    />
                </FormControl>
            );
        }
        return (
            <FormControl style={{ width: "100%" }}>
                <InputLabel htmlFor="my-input">Nota Reato</InputLabel>
                <Input
                    multiline={true}
                    style={inputStyle}
                    value={notes.azienda.reato}
                    disableUnderline={true}
                    id="my-input"
                    onChange={(val) =>
                        setNotes({
                            ...notes,
                            azienda: {
                                ...notes.azienda,
                                reato: val.target.value,
                            },
                        })
                    }
                    rows={3}
                    aria-describedby="my-helper-text"
                    disabled="true"
                />
            </FormControl>
        );
    };

  const storeNotes = async () => {

    setLoading(true);
    notes.consulente.reato.length &&
      (await valutazione.storeNote(
        {
          from: "consulente",
          nota: notes.consulente.reato || "",
        },
        id
      ));
    notes.azienda.reato.length &&
      (await valutazione.storeNote(
        {
          from: "azienda",
          nota: notes.azienda.reato || "",
        },
        id
      ));
    notes.consulente.area.length &&
      (await riskArea.storeNote(
        {
          from: "consulente",
          nota: notes.consulente.area || "",
        },
        areaId
      ));
    notes.azienda.area.length &&
      (await riskArea.storeNote(
        {
          from: "azienda",
          nota: notes.azienda.area || "",
        },
        areaId
      ));
    setFatherNotes(notes);
    setLoading(false);
    dispatch(handleModal({ open: false }));
    requiresReload && window.location.reload();
  };

  if (!notes || loading) {
    return (
      <Grid container xs={12} style={{ justifyContent: "center" }}>
        <CircularProgress size={25}></CircularProgress>
      </Grid>
    );
  }

  return (
    <Grid container xs={12} className={"modalNote"}>
      <Typography>Nota del Valutatore</Typography>
        {consulenteAreaNotes()}
      {spacer}
        {consulenteReatoNotes()}
      {spacer}
      <Typography>Nota del Valutato</Typography>
        {aziendaAreaNotes()}
      {spacer}
        {aziendaReatoNotes()}
      <Grid container xs={12} className={"notesModalButtonsContainer"}>
        <Button
          className={"notesModalBackButton"}
          variant={"outlined"}
          color={"primary"}
          style={{ width: 200 }}
          onClick={() => dispatch(handleModal({ open: false }))}
        >
          Indietro
        </Button>
        <Button
          className={"notesModalSaveButton"}
          onClick={storeNotes}
          style={{ width: 200 }}
          variant={"contained"}
          color={"primary"}
        >
          Salva
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ fetchApiReducer }) => {
  return {
    api: fetchApiReducer,
  };
};

export default connect(mapStateToProps)(NotesModal);
