import React, {Fragment, useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ConfigurationModuleComponent from "../configuration-module/configuration-module.component";
import {TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from 'prop-types';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {generateID} from "../../../index.utils";
import ValidButtonConfiguration from "../../generic-ui/valid-button-configuration";

const _valid = (group) => group ? !group.some(({label}) => !label) : false;

const SwitchGroup = ({onSave, switchGroup}) => {
    const [group, setGroup] = useState(switchGroup);
    useEffect(() => onSave(group), [onSave, group]);
    const removeSwitch = () => {
        group.pop();
        setGroup([...group]);
    };
    const handleChange = (e, key) => {
        setGroup([...group.map((_switch) => {
            if (_switch.name === e.target.name) {
                _switch[key] = e.target.value;
            }
            return _switch;
        })]);
    };
    return (
        <Fragment>
            <TableRow>
                <TableCell colSpan={1}/>
                <TableCell>
                    <Typography variant={"subtitle2"}>Switch Group</Typography>
                </TableCell>
                <TableCell>
                    <TextField disabled={true} fullWidth={true}/>
                </TableCell>
                <TableCell>
                    <Grid container alignItems={"center"}>
                        <Grid item>
                            <IconButton onClick={() => removeSwitch()} disabled={group.length < 2}>
                                <Icon color={(group.length < 2) ? "disabled" : "error"}>remove</Icon>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton color={"primary"} onClick={() => setGroup([...group, {
                                name: `switch-${generateID(9)}`,
                                label: ''
                            }])}>
                                <Icon>add</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            {group.map(({name, label, helperText, required}, index) => (
                <Fragment key={`${name}-${index}`}>
                    <TableRow>
                        <TableCell rowSpan={4}>
                            <Grid container alignItems={"center"} spacing={2}>
                                <Grid item>
                                    {(!label || (required === true)) && <Icon color={"error"}>warning</Icon>}
                                    {(label && (required !== true || required === false)) &&
                                    <Icon color={"primary"}>done</Icon>}
                                </Grid>
                                <Grid item>
                                    <Typography variant={"subtitle2"}>Switch {index + 1}</Typography>
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant={"subtitle2"}>Titolo</Typography>
                        </TableCell>
                        <TableCell>
                            <TextField fullWidth={true} value={label} placeholder={'Inserisci titolo'}
                                       name={name} onChange={(e) => handleChange(e, 'label')}/>
                        </TableCell>

                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant={"subtitle2"}>Testo informativo</Typography>
                        </TableCell>
                        <TableCell>
                            <TextField fullWidth={true} value={helperText} placeholder={'Testo informativo'}
                                       name={name} onChange={(e) => handleChange(e, 'helperText')}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FormControlLabel
                                control={<Checkbox checked={!!required} name={name}
                                                   onChange={(e) => handleChange({
                                                       target: {
                                                           name: e.target.name,
                                                           value: e.target.checked
                                                       }
                                                   }, 'required')}/>}
                                label={<Typography variant={"subtitle2"}>Campo obbligatorio</Typography>}/>
                        </TableCell>
                        <TableCell>
                            <TextField value={required && required !== true ? required : ''} name={name}
                                       fullWidth={true} placeholder={'Testo dell\'errore'}
                                       onChange={(e) => handleChange({
                                           target: {
                                               name: e.target.name,
                                               value: e.target.value
                                           }
                                       }, 'required')}
                                       required={!!required} disabled={!required}/>
                        </TableCell>

                    </TableRow>
                </Fragment>
            ))}
        </Fragment>
    );
};

SwitchGroup.defaultProps = {
    switchGroup: [],
    onSave: () => console.error('Missing on saveConfiguration function!')
};

const SwitchGroupConfigure = ({onSave, switchGroup, displayName}) => {
    const [dialog, toggleDialog] = useState(false);
    const [isValid, setValidity] = useState(_valid(switchGroup));
    const [group, setGroup] = useState(switchGroup);
    const handleChange = (group) => {
        setGroup(group);
        setValidity(_valid(group));
    };
    const configure = () => {
        toggleDialog(!dialog);
    };
    const save = () => {
        onSave(group, isValid);
        toggleDialog(false);
    };
    return (
        <FormControl component="fieldset" fullWidth={true}>
            <Grid container justify={"center"}>
                <Grid item xs={10}>
                    <Typography variant={"subtitle2"} gutterBottom={true} align={"center"}>
                        {displayName}
                    </Typography>
                </Grid>
                <Grid item xs={10} md={6}>
                    <ValidButtonConfiguration configure={configure} isValid={isValid}/>
                </Grid>
            </Grid>
            <Dialog open={dialog} aria-labelledby="text-field-conf-dialog" onClose={() => toggleDialog(false)}
                    fullWidth={true} maxWidth={'lg'}>
                <DialogTitle id="text-field-conf-dialog">Configura {displayName}</DialogTitle>
                <DialogContent>
                    <ConfigurationModuleComponent customRows={
                        <SwitchGroup switchGroup={switchGroup} onSave={handleChange}/>
                    }/>
                </DialogContent>
                <DialogActions>
                    <Grid direction={"row"} justify={"flex-end"} container>
                        <Grid item>
                            <Button variant={"text"} color={"default"} onClick={() => toggleDialog(false)}>
                                indietro
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={save} color={"secondary"} disabled={!isValid}>salva</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </FormControl>
    );
};

SwitchGroupConfigure.defaultProps = {
    defaultConfig: [],
    onSave: () => console.error('Missing on saveConfiguration function!')
};

SwitchGroupConfigure.propTypes = {
    onSave: PropTypes.func.isRequired,
    defaultConfig: PropTypes.array
};

export default SwitchGroupConfigure;
