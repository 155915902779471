import React, {useEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {handleToast} from "../../../redux/actions";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {TableBody} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from "@material-ui/core/TextField";
import {LicenseApi} from "../../../services/api.service";
import PropTypes from "prop-types";

const License = new LicenseApi();
const CheckLicensesForm = ({dispatch, defaultLicenses}) => {    
    const {register, errors,watch, setValue, triggerValidation} = useFormContext();
    const [licenses, setLicenses] = useState(defaultLicenses || []);
    useEffect(() => {
            const fetchApi = async () => {
                try {
                    const {licenze} = await License.query();
                    setLicenses(licenze);
                    if (defaultLicenses) {
                        Object.keys(defaultLicenses).forEach(
                            (key) => {
                                const index = licenze.indexOf(licenze.find(({uuid}) => uuid === key));
                                setValue(`licenses[${index}].uuid`, key);
                                setValue(`licenses[${index}].quantity`, defaultLicenses[key].quantity);
                                setValue(`licenses[${index}].lic_name`, defaultLicenses[key].ser_name);
                                setValue(`licenses[${index}].lic_description`, defaultLicenses[key].ser_description);
                            }
                        )
                    }
                } catch (message) {
                    dispatch(handleToast({
                        message
                    }));
                }
            };
            fetchApi();
    }, [defaultLicenses, dispatch, setValue]);
    const isRequired = () => {
        const bools = licenses.map(({uuid}, index) => watch(`licenses[${index}].uuid`));
        return !!bools.find((bool) => bool !== false) || 'Seleziona almeno una licenza';
    };
    const handleQuantityChange = (index) => (e) => {
        setValue(`licenses[${index}].quantity`, e.target.value);
        triggerValidation();
    };
    const toggleCheck = (index, uuid) => () => {
        setValue(`licenses[${index}].uuid`, watch(`licenses[${index}].uuid`) === false ? uuid : false);
        triggerValidation();
        //console.log(getValues(), Object.values(errors));
    };
    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid item style={{width: '100%'}}>
                <Table>
                    <TableBody>
                        {(!!licenses.length && licenses.map(({uuid, lic_name,lic_sap_product_id, lic_description}, index) => (
                            <TableRow
                                hover onClick={toggleCheck(index, uuid)}
                                role="checkbox" aria-checked={watch(uuid)}
                                tabIndex={-1} key={uuid}
                                selected={watch(uuid)}>
                                <TableCell padding="checkbox">
                                    <Checkbox name={`licenses[${index}].uuid`} value={uuid}
                                              onClick={toggleCheck(index, uuid)}
                                              checked={!!watch(`licenses[${index}].uuid`)}
                                              inputRef={register({
                                                  validate: () => isRequired()
                                              })}
                                              onChange={(e) => setValue(`licenses[${index}].uuid`, e.target.checked)}/>
                                    <input type={'hidden'} name={`licenses[${index}].lic_name`} value={lic_name}
                                           ref={register}/>
                                    <input type={'hidden'} name={`licenses[${index}].lic_description`}
                                           value={lic_description} ref={register}/>
                                </TableCell>
                                <TableCell component="th" id={uuid} scope="row" padding="none">
                                    {lic_name}
                                    {lic_sap_product_id && (
                                        <Typography  variant="body2">Cod. SAP #: {lic_sap_product_id}</Typography>
                                    )}
                                </TableCell>
                                <TableCell align="left">{lic_description}</TableCell>
                                <TableCell align="right">
                                    <TextField type={'number'} name={`licenses[${index}].quantity`}
                                               onClick={(e) => {
                                                   if (watch(`licenses[${index}].uuid`)) e.stopPropagation()
                                               }}
                                               value={watch(`licenses[${index}].quantity`) || 1}
                                               label={'Quantità'} placeholder={'0'}
                                               disabled={!watch(`licenses[${index}].uuid`)}
                                               error={!!errors[`licenses[${index}].quantity`]}
                                               onChange={handleQuantityChange(index)}
                                               inputRef={register({
                                                   validate: (value) => watch(`licenses[${index}].uuid`) && !value ? 'Quantità' : true
                                               })}/>
                                </TableCell>
                            </TableRow>
                        ))) || (
                            <TableRow>
                                <TableCell>
                                    <Skeleton variant="rect" height={'300px'}/>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Grid>
            {(!!licenses.length && !!Object.keys(errors).length) &&
            <Grid item>
                <Typography color={"error"} variant={"caption"} align={"center"} component={'p'}>
                    {Object.values(errors)[0].message}
                </Typography>
            </Grid>}
        </Grid>
    );
};

CheckLicensesForm.propTypes = {
    defaultLicenses: PropTypes.object
};

export default connect()(CheckLicensesForm);
