import React, {useCallback, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {handleToast} from "../../redux/actions";
import Grid from "@material-ui/core/Grid";
import {CircularProgress} from "@material-ui/core";
import {MODE_VIEW_READONLY} from "../../form-builder/index.constants";
import FormBuilderComponent from "../../form-builder/components/form-builder/form-builder.component";
import {FlowTrackApi} from "../../services/api.service";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Section} from "../../form-builder/components/section/section.class";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";

const Sollecito = new FlowTrackApi();
const CompiledFormPage = ({dispatch, match: {params: {uuid}}}) => {
    const [forms, setForms] = useState([]);
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState();
    const [value, setValue] = React.useState(0);
    useEffect(
        () => {
            const fetchForm = async () => {
                try {
                    setLoading(true);
                    const {form} = await Sollecito.get(uuid);
                    setForms(form);
                } catch (message) {
                    dispatch(handleToast({
                        message
                    }));
                }
                setLoading(false);
            };
            fetchForm();
        },
        [uuid]
    );
    useEffect(() => {
        if (forms[value] && forms[value].for_sections) {
            setSections(forms[value].for_sections.map((section) => new Section(section, {viewMode: MODE_VIEW_READONLY})));
        }
    }, [value, forms]);

    const onSubmit = async (data) => {
        console.log('data', data);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const uploadFile = useCallback((input, section) => {
        const form = forms.find(form => {
            return form.for_sections.find(fs => fs.uuid === section.uuid)
        })

        Sollecito.uploadFile(uuid, input.file, form.uuid).then(({ data }) => {
            setForms(prev => {
                const index = prev.findIndex(t => t.uuid === form.uuid);
                prev[index] = data;

                return [...prev];
            })
        }).catch((message) => {
            dispatch(handleToast({
                message
            }));
        })
    }, [uuid, forms, sections]);

    const handleUploadFiles = useCallback((data) => {
        return data[0].inputList
            .filter(input => input.file && input.file instanceof File)
            .forEach(input => uploadFile(input, data[0]))
    }, [uploadFile]);

    return (
        <Grid container justify={"center"} style={{marginTop: '64px'}}>
            <Grid item xs={10}>
                {!loading && !!forms.length &&
                <Grid container justify={"center"} spacing={4}>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={`Riepilogo Raccolta`} back/>
                    </Grid>
                    <Grid item xs={12}>
                        <AppBar position={"static"} color={"default"} elevation={0}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary">
                                {forms.map(({for_titolo_flusso}, index) =>
                                    <Tab key={`tab-${index}`} label={for_titolo_flusso}/>)}
                            </Tabs>
                        </AppBar>
                        {forms[value] && !!sections.length &&
                        <FormBuilderComponent
                            setSections={handleUploadFiles}
                            setFlow={() => {}}
                            flow={{
                                flu_titolo: forms[value].for_titolo_flusso,
                                flu_descrizione: forms[value].for_descrizione_flusso
                            }}
                            sections={sections}
                            saveForm={onSubmit}
                            defaultViewMode={MODE_VIEW_READONLY}
                        />}
                        {(!forms[value] || (forms[value] && !sections.length)) &&
                        <Typography color={"error"} variant={"subtitle1"} align={"center"} style={{margin: '2rem'}}>
                            Raccolta flussi corrotta!
                        </Typography>}
                    </Grid>
                </Grid>}
                {loading &&
                <Grid item container justify={"center"}>
                    <Grid item>
                        <CircularProgress size={40}/>
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
    );
};

export default connect()(withRouter(CompiledFormPage));
