import AuthService from "../services/auth.service";

export function getBiggerValue(array) {
  const applyToArray = (func, array) => func.apply(Math, array);
  return applyToArray(Math.max, array);
}

export function getAverage(array) {
  const num =
    array.reduce(function (a, b) {
      return a + b;
    }) / array.length;
  return (Math.round(num * 100) / 100).toFixed(2).toString().replace(".", ",");
}

export const getRiskInerenteColor = (val) => {
  const fontWeight = { fontWeight: 500 };
  if (val < 8.33) {
    return { ...fontWeight, color: "#4EAD5B" };
  } else if (val >= 8.33 && val < 12.66) {
    return { ...fontWeight, color: "#F4BB4E" };
  } else {
    return { ...fontWeight, color: "#E55C5D" };
  }
};

export const getRiskResiduoColor = (val) => {
  const fontWeight = { fontWeight: 500 };
  if (val >= 8) {
    return { ...fontWeight, color: "#E55C5D" };
  } else if (val < 8 && val > 6.44) {
    return { ...fontWeight, color: "#F4BB4E" };
  } else {
    return { ...fontWeight, color: "#4EAD5B" };
  }
};

export const fileName = (extension, companyName) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yy = today.getFullYear();
  today = dd + "-" + mm + "-" + yy;
  return (
    "RiskAssessment-" + AuthService.company.com_name + "-" + today + extension
  );
};

export const parseNotes = (reato = [], area = []) => {

  let newNotes = {
    consulente: {
      reato: "",
      area: "",
    },
    azienda: {
      reato: "",
      area: "",
    },
  };

  reato.forEach((note) => {
    if (note.from == "azienda") {
      newNotes.azienda.reato = note.nota;
    } else if (note.from == "consulente") {
      newNotes.consulente.reato = note.nota;
    }
  });

  area.forEach((note) => {
    if (note.from == "azienda") {
      newNotes.azienda.area = note.nota;
    } else if (note.from == "consulente") {
      newNotes.consulente.area = note.nota;
    }
  });

  return newNotes;
};
