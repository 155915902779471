import React, {useEffect, useState} from 'react';
import {Section} from "../../form-builder/components/section/section.class";
import {handleToast} from "../../redux/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {FlowApi} from "../../services/api.service";
import MomentUtils from "@date-io/moment";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormTemplatesListComponent
    from "../../form-builder/components/form-templates-list/form-templates-list.component";
import {Container} from "@material-ui/core";
import FormBuilderComponent from "../../form-builder/components/form-builder/form-builder.component";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const Flow = new FlowApi();
var drawerWidth = 240;
if (localStorage.getItem('permessi_utente') && !localStorage.getItem('permessi_utente').includes('flow.complete.form')) {
    drawerWidth = 0;
}
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        marginTop: '64px',
        display: 'flex',
        alignItems: 'center',
        padding: '16px 16px',
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));


const FlowStructureDetailPage = ({dispatch, history, match: {params: {flowId}}}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [drawer, setDrawer] = React.useState(true);
    const toggleDrawer = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawer(open);
    };
    const [sections, setSections] = useState([]);
    const [flow, setFlow] = useState();
    const [version, setVersion] = useState();
    useEffect(
        () => {
            const fetchFlow = async () => {
                try {
                    const callResponse = await Flow.get(flowId);console.log(callResponse);
                    const {flussi, form} = await Flow.get(flowId);console.log(flussi);
                    setFlow(flussi);
                    if (form) {
                        const {for_sections, for_version} = form;
                        setVersion(for_version);
                        setSections([
                            ...for_sections.map(
                                ({gridName, gridSizeSelect, inputList}) => new Section({
                                    gridName,
                                    gridSizeSelect,
                                    inputList
                                })
                            )
                        ]);
                    }
                } catch (message) {
                    dispatch(handleToast({
                        message
                    }));
                }
            };
            if (flowId) {
                fetchFlow();
            } else {
                setSections([new Section()]);
                setFlow({
                    flu_descrizione: '',
                    flu_titolo: ''
                });
            }
        },
        [flowId]
    );
    const saveForm = async (for_sections) => {
        if (!flow.uuid) {
            try {
                const {flussi} = await Flow.create({
                    ...flow,
                    form: {
                        for_version: version,
                        for_sections
                    }
                });
                setFlow(flussi);
                dispatch(handleToast({
                    message: 'Flusso creato'
                }));
            } catch (message) {
                dispatch(handleToast({
                    message
                }));
            }
        } else {
            try {
               const {form} = await Flow.update({
                    uuid: flow.uuid,
                    ...flow,
                    form: {
                        for_version: version,
                        for_sections
                    }
                });
                setVersion(form.for_version);
                dispatch(handleToast({
                    message: 'Flusso aggiornato'
                }));
            } catch (message) {
                dispatch(handleToast({
                    message
                }));
            }
        }

    };
    const drawerSideBar = () => {
        if (localStorage.getItem('permessi_utente').includes('flow.complete.form')) {
            return (
                <Drawer open={drawer} variant={"persistent"}
                        anchor={"left"}
                        className={classes.drawer}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        onClose={toggleDrawer(false)}>
                    <div className={classes.drawerHeader}>
                        <Grid container justify={"space-between"} alignItems={"center"}>
                            <Grid item xs>
                                <Typography>Template</Typography>
                                <Typography variant={"caption"}>
                                    Trascina gli elementi nelle sezioni a destra</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => setDrawer(false)}>
                                    {theme.direction === 'ltr' ? <Icon>chevron_left</Icon> : <Icon>chevron_right</Icon>}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider/>
                    <FormTemplatesListComponent/>
                </Drawer>
            );
        }
    }
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
                <CssBaseline/>
                <AppBar position={"relative"} color={"default"}
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: drawer,
                        })}>
                    <Toolbar variant={"dense"}>
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid item>
                                <IconButton onClick={toggleDrawer(true)}
                                            edge="start"
                                            className={clsx(classes.menuButton, drawer && classes.hide)}>
                                    <Icon>dashboard</Icon>
                                </IconButton>
                                <IconButton onClick={() => history.goBack()}
                                            edge="start">
                                    <Icon>arrow_back</Icon>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                Creazione flusso di raccolta
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                { drawerSideBar() }
                <main className={clsx(classes.content, {
                    [classes.contentShift]: !drawer,
                })}>
                    <Container maxWidth={"lg"}>
                        {flow &&
                        <FormBuilderComponent sections={sections} flow={flow} saveForm={saveForm}
                                              setFlow={setFlow} setSections={setSections}/>}
                        {!flow &&
                        <Grid container justify={"center"}>
                            <Grid item>
                                <CircularProgress size={40}/>
                            </Grid>
                        </Grid>}
                    </Container>
                </main>
        </MuiPickersUtilsProvider>
    );
};

export default connect()(withRouter(FlowStructureDetailPage));
