import get from "lodash/get";
import moment from "moment";
import envConfig from "./config.json";

//export const ENV = "dev";
// export const ENV = "is24o";
export const ENV = envConfig.ENV;
export const END_POINT = envConfig.API_URL;
//export const END_POINT = "http://api.v24redo.tfl"; //valore24php-echocreative.it
//export const END_POINT = "https://valore24node.echocreative.it:3571"; //valore24php-echocreative.it
//export const END_POINT = "https://231.valore24.ilsole24ore.com:3575"; //api-valore24.ablex.io
export const requiredHtml = (value) => {
  // Create a new div element
  const temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = value;
  // Retrieve the text property of the element (cross-browser support)
  //console.log('requiredHtml', temporalDivElement.innerText, temporalDivElement.textContent);
  return !temporalDivElement.textContent ||
    !temporalDivElement.innerText ||
    temporalDivElement.innerText === "undefined"
    ? "campo obbligatorio"
    : true;
};
export const ReactQuillModules = {
  toolbar: [
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ size: ["small", false, "large"] }], // custom dropdown
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ script: "super" }], // superscript/subscript
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ align: [] }],
    ["clean"],
  ],
};
export const requiredHtmlYup = (value) => {
  // Create a new div element
  const temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = value;
  // Retrieve the text property of the element (cross-browser support)
  //console.log('requiredHtml', temporalDivElement.innerText, temporalDivElement.textContent);
  return !(
    !temporalDivElement.textContent ||
    !temporalDivElement.innerText ||
    temporalDivElement.innerText === "undefined"
  );
};

export const searchFilter = (filter, columns) => (item) => {
  try {
    if (filter) {
      filter = filter.toString().toLowerCase();
      return columns
        .map(({ id }) => {
          let itemValue = get(item, id);
          if (itemValue) {
            itemValue = itemValue.toString().toLowerCase();
            if (!isNaN(Date.parse(itemValue))) {
              const date = moment(itemValue).format("DD MM YYYY");
              return (
                date.search(filter) !== -1 || itemValue.search(filter) !== -1
              );
            }
            return itemValue.search(filter) !== -1;
          } else return false;
        })
        .reduce((arr, result) => arr || result);
    } else return true;
  } catch (e) {
    console.error(e);
    return true;
  }
};
export const getRegistryLabel = (key) => {
  switch (key) {
    case "agent_code":
      return "Codice Agente";
    case "fiscal_code":
    case "acc_fiscal_code":
      return "Codice Fiscale";
    case "p_iva":
    case "acc_p_iva":
      return "P. IVA";
    case "acc_address":
      return "Indirizzo";
    case "acc_admin_email":
      return "Email amministrativa";
    case "acc_city":
      return "Città";
    case "acc_email":
      return "Email";
    case "acc_mobile":
      return "Cellulare";
    case "acc_name":
      return "Ragione sociale";
    case "acc_phone":
      return "Telefono";
    case "acc_province":
      return "Provinxia";
    case "acc_zip":
      return "Cap";
    default:
      return "";
  }
};

export const SITE_MAP = [
  {
    label: "Dashboard",
    path: "/homepage",
    icon: "dashboard",
    expansion: [],
  },
  {
    label: "Gestione utenza",
    expansion: [
      {
        label: "Catalogo Reati 231",
        icon: "account_balance",
        path: "reati-231",
        permission: "catalogo.reati.231",
        items: [
          {
            label: "Categorie",
            link: "/reati-231/categorie-reati-231",
          },
          {
            label: "Reati",
            link: "/reati-231/elenco-reati-231",
          },
        ],
      },
      {
        label: "Schemi di controllo",
        icon: "control_camera",
        path: "schemi-di-controllo",
        permission: "schemi.controllo",
        items: [
          
          {
            label: "Aree di rischio",
            items: [
              {
                label: "Tipologie aree di rischio",
                link: "/schemi-di-controllo/tipologie-aree-di-rischio",
              },
              { 
                label: "Aree di rischio",
                link: "/schemi-di-controllo/aree-di-rischio",
              },
            ],
          },
          {
            label: "Protocolli",
            link: "/schemi-di-controllo/protocolli",
          },
          {
            label: "Indicatori comportamentali",
            link: "/schemi-di-controllo/indicatori-comportamentali",
          },
          {
            label: "Funzioni Aziendali",
            link: "/flussi-OdV/funzioni",
          },
          {
            label: "Matrici (nuova vista)",
            link: "/matrici/schemi-di-controllo-&-matrici",
          },
          {
            label: "Matrici (versione precedente)",
            items: [
              {
                label: "Aree di rischio / Reati 231",
                link: "/matrici/aree-di-rischio-&-reati-231",
              },
              {
                label: "Aree di rischio / Protocolli",
                link: "/matrici/protocolli-&-aree-di-rischio",
              },
              {
                label: "Aree di rischio / Ind. Comportamentali",
                link: "/matrici/indicatori-comportamentali-&-aree-di-rischio",
              },
            ]
          },
         
          /* {
            label: "Aree di rischio / Funzioni Aziendali",
            link: "/matrici/aree-di-rischio-&-funzioni",
          } */
        ],
      },
      {
        label: "Documenti MOGC",
        path: "modello-organizzativo",
        icon: "description",
        permission: "modello.organizzativo",
        items: [
          {
            label: "Parte generale",
            link: "/modello-organizzativo/parte-generale",
          },
          {
            label: "Parte speciale",
            link: "/modello-organizzativo/parte-speciale",
          },
          {
            label: "Codice Etico",
            link: "/modello-organizzativo/codici-etici",
          },
          {
            label: "Principi Comportamentali",
            link: "/modello-organizzativo/principi-comportamentali",
          },
          {
            label: "Procedura Whistleblowing",
            link: "/modello-organizzativo/procedura-whistleblowing",
          },
          {
            label: "Regolamento OdV",
            link: "/modello-organizzativo/regolamento-odv",
          },
        ],
      },
      {
        label: "Safeguarding Policy",
        path: "/safeguarding-policy",
        icon: "warning",
        permission: "modello.organizzativo",
        expansion: []
      },
      {
        label: "Risk Assessment",
        icon: "warning",
        path: "risk-assessment",
        permission: "risk.assessment",
        items: [
          {
            label: "Nuova valutazione",
            refresh: true,
            link: "/risk-assessment/nuova-valutazione",
          },
          {
            label: "Elenco valutazioni",
            link: "/risk-assessment/elenco-valutazioni",
          },
        ],
      },
      {
        label: "Org. e utenze",
        path: "matrici",
        icon: "apps",
        permission: "struttura.organizzativa",
        items: [
         /*  {
            label: "Funzioni Aziendali",
            link: "/flussi-OdV/funzioni",
          }, */
          {
            label: "Referenti raccolta flussi OdV",
            link: "/flussi-OdV/referenti",
          },
          {
            label: "Matrice Funzioni/Referenti",
            link: "/matrici/funzioni-&-referenti",
          },
          {
            label: "Utenti Risk Assessment",
            link: "/risk-assesment/referenti",
          },
          {
            label: "Utenti Flussi OdV",
            link: "/flussi-OdV/utenti",
            permessi:"flow.complete.form",
          },
          {
            label: "Utenti admin delegati",
            link: "/admin/utenti",
            permessi:"aggiunge.admin",
          },
        ],
      },
      {
        label: "Flussi OdV",
        path: "flussi-OdV",
        icon: "ballot",
        permission: "flussi.odv",
        items: [
          {
            label: "Struttura flussi",
            link: "/flussi-OdV/struttura-flussi",
          },
          {
            label: "Matrice Aree di rischio/Flussi",
            link: "/matrici/flussi-&-aree-di-rischio",
          },
          {
            label: "Matrice Flussi/Funzioni",
            link: "/matrici/flussi-&-funzioni",
            permessi: "flussi.odv.matrici",
          },
          {
            label: "Raccolta flussi",
            link: "/flussi-OdV/raccolta-flussi",
            permessi:"flussi.odv.raccolta",
          },
        ],
      },
      {
        label: "Repository",
        path: "/filesystem",
        icon: "folder",
        permission: "catalogo.reati.231",
        expansion: []
      },
      {
        label: "Registro Aggiornamenti",
        path: "/changelog",
        icon: "list",
        permission: "catalogo.reati.231",
        expansion: []
      },
    ],
  },
  // {
  //     label: 'Help',
  //     expansion: [
  //         {
  //             label: 'Help',
  //             path: 'faq',
  //             icon: 'live_help',
  //             items: [
  //                 {
  //                     label: 'Tutorial',
  //                     link: '/faq/tutorial'
  //                 },
  //                 {
  //                     label: 'Richiesta di supporto',
  //                     link: '/faq/richiesta-supporto'
  //                 },
  //                 {
  //                     label: 'Prenota video call formazione',
  //                     externalLink: 'https://www.teamrs.biz/assistenza/valore24-231/'
  //                 }
  //             ]
  //         }
  //     ]
  // }
];
