import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import ToolbarComponent from "../components/ui/toolbar/toolbar.component";
import FooterComponent from "../components/ui/footer/footer.component";
import PropTypes from "prop-types";
import AuthService from "../services/auth.service";
import {withRouter} from "react-router-dom";
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import {SITE_MAP} from "../constant";
import Icon from "@material-ui/core/Icon";
import NewDrawerComponent from "../components/ui/drawer/new-drawer.component";
import { createBrowserHistory } from "history";
import Button from "@material-ui/core/Button";
import HomeDrawerComponent from "../components/ui/drawer/home-drawer.component";
import HomeDrawer24Component from "../components/ui/drawer/home-drawer-24.component";
import DrawerComponent from "../components/ui/drawer/drawer.component";
import Drawer24Component from "../components/ui/drawer/drawer-24.component";
import envConfig from "../config.json";

const history = createBrowserHistory();

const drawerWidth = 240;
const bottomWithMargin = 3;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: '100%',
        flexShrink: 0,
        height: '100%'
    },
    drawerPaper: {
        width: '99%',
        position: 'inherit',
        height: '100%',
        backgroundColor: '#f3f3f3'
    },
    drawerContainer: {
        overflow: 'hidden',
        height: '95%',
        backgroundColor: '#f3f3f3'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    footerDrawerDiv: {
        marginTop: 50,
        width: (drawerWidth - (bottomWithMargin * 2)),
        marginLeft: bottomWithMargin,
        marginRight: bottomWithMargin
    },
    fullHeight: {
        height: '100%',
        backgroundColor: '#f3f3f3'
    },
    accountTitle: {
        alignContent: 'center',
        textAlign: 'center'
    },
    rightAlign: {
        textAlign: 'right'
    },
    iconSmallerSize: {
        'font-size': '14px',
    },
    mainGridComponent: {
        backgroundColor: '#f3f3f3',
        minHeight: '90vh',
        width: '82%'
    },
    drawerComponent: {
        height: '100%',
        backgroundColor: '#f3f3f3',
        width: '18%'
    },
    mainGridComponentFull: {
        backgroundColor: '#f3f3f3',
        minHeight: '90vh',
        width: '100%'
    },
}));

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const HomeDefaultLayout = (props) => {
    const classes = useStyles();
    const {children, toolbarPosition,location: { pathname }} = props;
    const [open, toggleDrawer] = useState(false);
    const toggle = () => {
        toggleDrawer(!open);
    };
    const account = AuthService.user.utente.accounts;
    const selected_company = AuthService.company;
    const isAdmin = AuthService.isAdmin();
    const { height, width } = useWindowDimensions();
    const showDrawer =  isAdmin ? true : !_.isEmpty(selected_company) && (pathname.indexOf('/profilo')===-1 || (pathname.indexOf('/profilo')!==-1 && account.companies && account.companies.length > 0));
    let MyHome = SITE_MAP;
    let myHomeFiltered = [];
    for(let i = 0; i<MyHome.length; i++) {
        if (MyHome[i].expansion.length > 0) {
            myHomeFiltered = [];
            for (let z = 0; z < MyHome[i].expansion.length; z++) {
                if (MyHome[i].expansion[z].permission !== undefined && localStorage.getItem('permessi_utente').includes(MyHome[i].expansion[z].permission)) {
                    myHomeFiltered.push(MyHome[i].expansion[z]);
                }
            }
            MyHome[i].expansion = myHomeFiltered;
        }
    }

    const logout = () => {
        AuthService.logout().then( () => {
            history.push('/login');
            /*setTimeout( function () {
                window.location.reload();
            }, 300 );*/
        });
    };

    if ( localStorage.getItem('permessi_utente').includes('risk.referente') ) {

        MyHome = [];

    }
    var drawerComponentName = "HomeDrawerComponent";
    if(envConfig.CUSTOMER ?? "default" == "Sole24")
    {
        drawerComponentName = "HomeDrawer24Component";
    }
    return (
        <Grid container style={{position: 'relative', minHeight: '100%'}}>
                <Grid item xs={12}>
                    <ToolbarComponent inHome={true} pageWidth={width} showDrawer={showDrawer} position={toolbarPosition} toggleDrawer={toggle} hideMenu={isAdmin}/>
                    {showDrawer && (width < 1000) && drawerComponentName == "HomeDrawerComponent" && <DrawerComponent open={open} toggleDrawer={toggle}/>}
                    {showDrawer && (width < 1000) && drawerComponentName == "HomeDrawer24Component" && <Drawer24Component open={open} toggleDrawer={toggle}/>}
                </Grid>
            { width > 1000 && <Grid container style={{width: '100%', marginTop: toolbarPosition === 'fixed' ? '64px' : 'inherit'}}>
                <Grid item className={classes.drawerComponent}>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerContainer}>
                            {drawerComponentName == "HomeDrawerComponent" && <HomeDrawerComponent open={false} toggleDrawer={toggle}/>}
                            {drawerComponentName == "HomeDrawer24Component" && <HomeDrawer24Component open={false} toggleDrawer={toggle}/>}
                            <div className={classes.footerDrawerDiv}>
                                <Typography className={classes.accountTitle}><b>Account</b></Typography>
                                <Divider />
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button onClick={() => history.push(`/profilo-utenza/`)}><Icon style={{marginRight: '1rem'}} color={"primary"}>settings</Icon> Profilo</Button>
                                    </Grid>
                                    <Grid item xs={6} className={classes.rightAlign}>
                                        <Button onClick={logout}><Icon style={{marginRight: '1rem'}} color={"error"}>exit_to_app</Icon> Logout</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Drawer>
                </Grid>
                <Grid item className={classes.mainGridComponent}>
                    {children}
                </Grid>
            </Grid>}
            { width <= 1000 && <Grid container style={{width: '100%', marginTop: toolbarPosition === 'fixed' ? '64px' : 'inherit'}}>
                <Grid item className={classes.mainGridComponentFull}>
                    {children}
                </Grid>
            </Grid>}
            <Grid item xs={12} className={classes.appBar}>
                <FooterComponent/>
            </Grid>
        </Grid>
    );
};

HomeDefaultLayout.defaultProps = {
    toolbarPosition: 'absolute'
};

HomeDefaultLayout.propTypes = {
    toolbarPosition: PropTypes.string
};

export default withRouter(HomeDefaultLayout);
