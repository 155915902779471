import {connect} from "react-redux";
import React, {useState} from "react";
import {FilesystemApi} from "../../../services/api.service";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import useForm, {FormContext} from "react-hook-form";
import {handleModal, handleToast} from "../../../redux/actions";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";

const FSApi = new FilesystemApi();

const ValutazioniMediaComponent = ({dispatch, media}) => {
    // Constants
    const addFunctionInputs = [
        {
            name: 'name',
            required: 'campo obbligatorio',
            label: 'Nome Riferimento',
            placeholder: 'Nome Riferimento',
            width: 12,
            margin: 'normal'
        },
        {
            name: 'file_name',
            required: false,
            label: 'Nome File',
            placeholder: 'Nome File',
            width: 12,
            margin: 'normal',
            disabled: true
        },
        {
            name: 'nice_last_update',
            required: false,
            label: 'Ultimo Aggiornamento',
            placeholder: 'Ultimo Aggiornamento',
            width: 12,
            margin: 'normal',
            disabled: true
        }
    ];

    const functionForm = useForm({
        mode: 'onSubmit',
        defaultValues: media
    });

    const onFunctionSave = async (data) => {
        try {
            const updateCall = await FSApi.updateMedia(media.uuid, data.name);
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            dispatch(handleToast({
                message
            }));
        }
    };

    const buttonLabel = () => {
        return <Typography variant={"button"} color={'primary'}>{"Salva"}</Typography>
    };

    return (
        <FormContext {...functionForm}>
            <form onSubmit={functionForm.handleSubmit(onFunctionSave)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm inputs={addFunctionInputs}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {buttonLabel()}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

ValutazioniMediaComponent.defaultProps = {
    media: {}
};
ValutazioniMediaComponent.propTypes = {
    media: PropTypes.object,
};
export default connect()(ValutazioniMediaComponent);
