import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Icon from "@material-ui/core/Icon";
import { makeStyles } from '@material-ui/core/styles';
import {FilesystemApi} from "../../services/api.service";
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import {Tooltip} from "@material-ui/core";
import Link from "@material-ui/core/Link";

const FSApi = new FilesystemApi();

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        color: theme.palette.primary[theme.palette.mode],
        marginBottom: 20,
        fontWeight: 'bold'
    },
    root: {
        width: '100%',
    },
    rightDiv: {
        width: '100%',
        textAlign: 'right',
    },
    containerGrid: {
        marginLeft: "2em",
        marginRight: "2em"
    },
    iconfolder: {
        color: theme.palette.primary[theme.palette.mode],
        marginBottom: "-5px"
    }
}));

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const defaultObject = {
    totals: {
        num_files: 0,
        human_size: '0 KB',
        last_update: 'Mai',
        percentage_full: 0,
        human_max_size: '0 KB'
    }
};

const FilesystemPage = ({dispatch, history}) => {
    const classes = useStyles();
    const [valutazioni, setValutazioni] = useState(defaultObject);
    const [modelliGenerali, setModelliGenerali] = useState(defaultObject);
    const [modelliSpeciali, setModelliSpeciali] = useState(defaultObject);
    const [codiciEtici, setCodiciEtici] = useState(defaultObject);
    const [principiComportamentali, setPrincipiComportamentali] = useState(defaultObject);
    const [procedureWhistleblowing, setProcedureWhistleblowing] = useState(defaultObject);
    const [regolamentiOdv, setRegolamentiOdv] = useState(defaultObject);
    const [generale, setGenerale] = useState(defaultObject);

    React.useEffect(() => {
        getValutazioniInfo();
        getModelliGeneraliInfo();
        getModelliSpecialiInfo();
        getCodiciEticiInfo();
        getPrincipiComportamentaliInfo();
        getProcedureWhistleblowingInfo();
        getRegolamentiOdvInfo();
        getGeneraleInfo();
    }, []);

    const openFSValutazioni = () => {
        history.push('/filesystem/valutazioni');
    };

    async function getValutazioniInfo() {
        const valutazioni = await getCollectionInfo('valutazioni');
        setValutazioni(valutazioni);
    }

    const openFSModelliGenerali = () => {
        history.push('/filesystem/modelli-generali');
    };

    async function getModelliGeneraliInfo() {
        const modelliGenerali = await getCollectionInfo('modelliGenerali');
        setModelliGenerali(modelliGenerali);
    }

    const openFSModelliSpeciali = () => {
        history.push('/filesystem/modelli-speciali');
    };

    async function getModelliSpecialiInfo() {
        const modelliSpeciali = await getCollectionInfo('modelliSpeciali');
        setModelliSpeciali(modelliSpeciali);
    }

    const openFSCodiciEtici = () => {
        history.push('/filesystem/codici-etici');
    };

    async function getCodiciEticiInfo() {
        const codiciEtici = await getCollectionInfo('codiciEtici');
        setCodiciEtici(codiciEtici);
    }

    const openFSPrincipiComportamentali = () => {
        history.push('/filesystem/principi-comportamentali');
    };

    async function getPrincipiComportamentaliInfo() {
        const principiComportamentali = await getCollectionInfo('principiComportamentali');
        setPrincipiComportamentali(principiComportamentali);
    }

    const openFSProcedureWhistleblowing = () => {
        history.push('/filesystem/procedure-whistleblowing');
    };

    async function getProcedureWhistleblowingInfo() {
        const procedureWhistleblowing = await getCollectionInfo('procedureWhistleblowing');
        setProcedureWhistleblowing(procedureWhistleblowing);
    }

    const openFSRegolamentiOdv = () => {
        history.push('/filesystem/regolamento-odv');
    };

    async function getRegolamentiOdvInfo() {
        const regolamentiOdv = await getCollectionInfo('regolamentiOdv');
        setRegolamentiOdv(regolamentiOdv);
    }

    async function getGeneraleInfo() {
        const generali = await getCollectionInfo('generali');
        setGenerale(generali);
    }

    const openFSGenerale = () => {
        history.push('/filesystem/generale');
    };

    const getCollectionInfo = async (collection) => {
        const res = await FSApi.getCollectionInfo(collection);

        return res;
    };

    const definePercentage = (perc, max, actual) => {
        return perc + "% (" + actual + " di " + max + ")";
    };

    return (
       
        <Box className={classes.containerGrid} sx={{ flexGrow: 1 }}>
            
            <Grid container spacing={2} alignItems={"center"}>
                <Typography variant="h5" align={"left"} className={classes.title} component={"div"}>Repository</Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                                <Icon className={classes.iconfolder}>
                                    folder
                                </Icon>
                                <Button onClick={openFSValutazioni}>Valutazioni</Button>
                            </Typography>
                            <Typography variant={"subtitle1"} component="div">
                                File relativi agli export delle valutazioni
                            </Typography>
                            <Tooltip title={definePercentage(valutazioni.totals?.percentage_full, valutazioni.totals?.human_max_size, valutazioni.totals?.human_size)}>
                                <div className={classes.root}>
                                    <LinearProgressWithLabel value={valutazioni.totals?.percentage_full} />
                                </div>
                            </Tooltip>
                            <Typography paragraph={false} variant={"caption"} align={"right"}>
                                <div className={classes.rightDiv}>
                                    {valutazioni.totals?.num_files} files, {valutazioni.totals?.human_size} Ultimo update: {valutazioni.totals?.last_update}
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                                <Icon className={classes.iconfolder}>
                                    folder
                                </Icon>
                                <Button onClick={openFSModelliGenerali}>Parte Generale</Button>
                            </Typography>
                            <Typography variant={"subtitle1"} component="div">
                                File relativi ai Modelli Organizzativi Parte Generale
                            </Typography>
                            <Tooltip title={definePercentage(modelliGenerali.totals?.percentage_full, modelliGenerali.totals?.human_max_size, modelliGenerali.totals?.human_size)}>
                                <div className={classes.root}>
                                    <LinearProgressWithLabel value={modelliGenerali.totals?.percentage_full} />
                                </div>
                            </Tooltip>
                            <Typography paragraph={false} variant={"caption"} align={"right"}>
                                <div className={classes.rightDiv}>
                                    {modelliGenerali.totals?.num_files} files, {modelliGenerali.totals?.human_size} Ultimo update: {modelliGenerali.totals?.last_update}
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                                <Icon className={classes.iconfolder}>
                                    folder
                                </Icon>
                                <Button onClick={openFSModelliSpeciali}>Parte Speciale</Button>
                            </Typography>
                            <Typography variant={"subtitle1"} component="div">
                            File relativi ai Modelli Organizzativi Parte Speciale
                            </Typography>
                            <Tooltip title={definePercentage(modelliSpeciali.totals?.percentage_full, modelliSpeciali.totals?.human_max_size, modelliSpeciali.totals?.human_size)}>
                                <div className={classes.root}>
                                    <LinearProgressWithLabel value={modelliSpeciali.totals?.percentage_full} />
                                </div>
                            </Tooltip>
                            <Typography paragraph={false} variant={"caption"} align={"right"}>
                                <div className={classes.rightDiv}>
                                    {modelliSpeciali.totals?.num_files} files, {modelliSpeciali.totals?.human_size} Ultimo update: {modelliSpeciali.totals?.last_update}
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                                <Icon className={classes.iconfolder}>
                                    folder
                                </Icon>
                                <Button onClick={openFSCodiciEtici}>Codice Etico</Button>
                            </Typography>
                            <Typography variant={"subtitle1"} component="div">
                                File relativi ai Codici Etici
                            </Typography>
                            <Tooltip title={definePercentage(codiciEtici.totals?.percentage_full, codiciEtici.totals?.human_max_size, codiciEtici.totals?.human_size)}>
                                <div className={classes.root}>
                                    <LinearProgressWithLabel value={codiciEtici.totals?.percentage_full} />
                                </div>
                            </Tooltip>
                            <Typography paragraph={false} variant={"caption"} align={"right"}>
                                <div className={classes.rightDiv}>
                                    {codiciEtici.totals?.num_files} files, {codiciEtici.totals?.human_size} Ultimo update: {codiciEtici.totals?.last_update}
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                                <Icon className={classes.iconfolder}>
                                    folder
                                </Icon>
                                <Button onClick={openFSPrincipiComportamentali}>Principi Comportamentali</Button>
                            </Typography>
                            <Typography variant={"subtitle1"} component="div">
                                File relativi ai Principi Comportamentali
                            </Typography>
                            <Tooltip title={definePercentage(principiComportamentali.totals?.percentage_full, principiComportamentali.totals?.human_max_size, principiComportamentali.totals?.human_size)}>
                                <div className={classes.root}>
                                    <LinearProgressWithLabel value={principiComportamentali.totals?.percentage_full} />
                                </div>
                            </Tooltip>
                            <Typography paragraph={false} variant={"caption"} align={"right"}>
                                <div className={classes.rightDiv}>
                                    {principiComportamentali.totals?.num_files} files, {principiComportamentali.totals?.human_size} Ultimo update: {principiComportamentali.totals?.last_update}
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                                <Icon className={classes.iconfolder}>
                                    folder
                                </Icon>
                                <Button onClick={openFSProcedureWhistleblowing}>Procedura Whistleblowing</Button>
                            </Typography>
                            <Typography variant={"subtitle1"} component="div">
                                File relativi alle Procedure Whistleblowing
                            </Typography>
                            <Tooltip title={definePercentage(procedureWhistleblowing.totals?.percentage_full, procedureWhistleblowing.totals?.human_max_size, procedureWhistleblowing.totals?.human_size)}>
                                <div className={classes.root}>
                                    <LinearProgressWithLabel value={procedureWhistleblowing.totals?.percentage_full} />
                                </div>
                            </Tooltip>
                            <Typography paragraph={false} variant={"caption"} align={"right"}>
                                <div className={classes.rightDiv}>
                                    {procedureWhistleblowing.totals?.num_files} files, {procedureWhistleblowing.totals?.human_size} Ultimo update: {procedureWhistleblowing.totals?.last_update}
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                                <Icon className={classes.iconfolder}>
                                    folder
                                </Icon>
                                <Button onClick={openFSRegolamentiOdv}>Regolamento OdV</Button>
                            </Typography>
                            <Typography variant={"subtitle1"} component="div">
                                File relativi ai Regolamenti OdV
                            </Typography>
                            <Tooltip title={definePercentage(regolamentiOdv.totals?.percentage_full, regolamentiOdv.totals?.human_max_size, regolamentiOdv.totals?.human_size)}>
                                <div className={classes.root}>
                                    <LinearProgressWithLabel value={regolamentiOdv.totals?.percentage_full} />
                                </div>
                            </Tooltip>
                            <Typography paragraph={false} variant={"caption"} align={"right"}>
                                <div className={classes.rightDiv}>
                                    {regolamentiOdv.totals?.num_files} files, {regolamentiOdv.totals?.human_size} Ultimo update: {regolamentiOdv.totals?.last_update}
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                                <Icon className={classes.iconfolder}>
                                    folder
                                </Icon>
                                <Button onClick={openFSGenerale}>Repository Generico</Button>
                            </Typography>
                            <Typography variant={"subtitle1"} component="div">
                                File di storage generici
                            </Typography>
                            <Tooltip title={definePercentage(generale.totals?.percentage_full, generale.totals?.human_max_size, generale.totals?.human_size)}>
                                <div className={classes.root}>
                                    <LinearProgressWithLabel value={generale.totals?.percentage_full} />
                                </div>
                            </Tooltip>
                            <Typography paragraph={false} variant={"caption"} align={"right"}>
                                <div className={classes.rightDiv}>
                                    {generale.totals?.num_files} files, {generale.totals?.human_size} Ultimo update: {generale.totals?.last_update}
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default connect()(FilesystemPage);
