import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import Container from "@material-ui/core/Container";

const AccountSuccessPage = ({history, match: {params: {action}}}) => {
    let title = 'Salvataggio riuscito';
    switch (action) {
        case 'attivazione':
            title = 'Account attivato con successo';
            break;
        case 'disdetta':
            title = 'Account disdetto con successo';
            break;
        case 'sospensione':
            title = 'Account sospeso con successo';
            break;
        case 'riattivazione':
            title = 'Account riattivato con successo';
            break;
        default:
            title = 'Salvataggio riuscito';
            break;
    }
    return (
        <Container maxWidth={"md"}>
            <Grid container direction={"column"} alignItems={"center"} spacing={6}>
                <Grid item>
                    <Typography variant={"h5"} gutterBottom align={"center"}>{title}</Typography>
                    <Typography variant={"subtitle1"} align={"center"} color={"textSecondary"}>
                        una mail di conferma è stata inviata all'indirizzo e-mail dell'utente selezionato
                    </Typography>
                </Grid>
                <Grid item>
                    <img src={'/assets/images/success.svg'} alt={'success'}/>
                </Grid>
                <Grid item>
                    <Button variant={"outlined"} onClick={() => history.push('/')}>
                        torna alla home
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default withRouter(AccountSuccessPage);
