import {handleModal, handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import {TextField} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import MenuItem from "@material-ui/core/MenuItem";
import {InterdictSanctionApi, SourceApi, Violation231Api, Violation231CategoryApi} from "../../../services/api.service";
import * as yup from "yup";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import {withRouter} from "react-router-dom";
import ReactQuill from "react-quill";
import {ReactQuillModules, requiredHtml, requiredHtmlYup} from "../../../constant";
import envConfig from "../../../config.json";

const useStyles = makeStyles(() => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
        '& .MuiChip-label': {
            textOverflow: 'ellipsis',
            maxWidth: '250px',
            whiteSpace: 'nowrap',
            display: 'block',
            overflow: 'hidden',
        }
    }
}));
const ViolationSchema = yup.object().shape({
    rea_titolo: yup.string().required('Inserisci nome reato'),
    fon_uuid: yup.string().required('Seleziona fonte'),
    rea_anno: yup.number().required('Inserisci anno di riferimento'),
    rea_articolo: yup.string().required('Inserisci articolo di riferimento'),
    cat_uuid: yup.string().required('Seleziona categoria'),
    sanzioni_interdittive: yup.array(),
    rea_descrizione: yup.string().required('Inserisci la descrizione').test('required', 'Inserisci la descrizione', requiredHtmlYup),
});

const hasNormattiva = ((envConfig.CUSTOMER ?? "default") == "Sole24") ? false : true;

const Source = new SourceApi();
const Categories231 = new Violation231CategoryApi();
const Violation231 = new Violation231Api();
const InterdictSanction = new InterdictSanctionApi();
const Violation231Component = ({dispatch, mode, match, history, violation}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [sources, setSources] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sanctions, setSanctions] = useState([]);
    useEffect(() => {
        const fetchSources = async () => {
            setApiLoading(true);
            const fonti = await Source.query();
            const reati231_cat = await Categories231.query();
            const sanz_interd = await InterdictSanction.query();
            setSources(fonti);
            setCategories(reati231_cat);
            setSanctions(sanz_interd);
            setApiLoading(false);
        };
        fetchSources();
    }, []);
    const violationForm = useForm({
        mode: 'onSubmit',
        validationSchema: ViolationSchema,
        defaultValues: mode !== 'create' ? {
            ...violation,
            sanzioni_interdittive: [
                ...violation.reati231_sanz_interd.map(({sanz_interd: {uuid}}) => uuid)
            ],
            fon_uuid: violation.fonti.uuid,
            cat_uuid: violation.reati231_cat ? violation.reati231_cat.uuid : undefined
        } : {}
    });

    useEffect(() => {
        violationForm.register({
            name: 'rea_sanzione_amministrativa_min'
        });
        violationForm.register({
            name: 'rea_sanzione_amministrativa_max'
        });
    }, [violationForm]);
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onViolationSave = async (data) => {
        try {
            setLoading(true);
            switch (mode) {
                case 'create':
                    await Violation231.create(data);
                    dispatch(handleToast({
                        message: 'Reato creato'
                    }));
                    break;
                case 'update':
                    await Violation231.update({
                        uuid: violation.uuid,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Reato modificato'
                    }));
                    break;
                case 'delete':
                    await Violation231.delete(violation.uuid);
                    dispatch(handleToast({
                        message: 'Reato eliminato'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
            if (match.path === '/reati-231/elenco-reati-231/:mode-reato') {
                history.goBack();
            }
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };
    const forceRequired = (value) => {
        return (value === undefined || value === '') ? 'campo obbligatorio' : true;
    };
    const getChipLabel = (value) => {
        const s = sanctions.find(({uuid}) => uuid === value);
        return s ? s.san_sanzione : '';
    };
    const disabled = mode === 'delete' || mode === 'read';
    if (apiLoading) {
        return (
            <Grid container justify={"center"}>
                <Grid item>
                    <CircularProgress/>
                </Grid>
            </Grid>
        );
    }
    return (
        <FormContext {...violationForm}>
            <form onSubmit={violationForm.handleSubmit(onViolationSave)}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"}>Nome reato</Typography>
                        <TextField disabled={disabled}
                                   defaultValue={violationForm.watch('rea_titolo') || ''}
                                   inputRef={violationForm.register({
                                       name: 'rea_titolo'
                                   })}
                                   onChange={(e) => violationForm.setValue('rea_titolo', e.target.value)}
                                   helperText={violationForm.errors['rea_titolo'] ? violationForm.errors['rea_titolo'].message : ''}
                                   error={!!violationForm.errors['rea_titolo']} fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant={"subtitle1"}>Fonte</Typography>
                        <TextField select fullWidth
                                   disabled={disabled}
                                   value={violationForm.watch('fon_uuid') || ''}
                                   inputRef={violationForm.register({
                                       name: 'fon_uuid'
                                   }, {validate: forceRequired})}
                                   onChange={(e) => violationForm.setValue('fon_uuid', e.target.value)}
                                   error={!!violationForm.errors['fon_uuid']}
                                   helperText={violationForm.errors['fon_uuid'] ? violationForm.errors['fon_uuid'].message : ''}>
                            {sources.map(
                                ({fon_fonte, uuid}) => <MenuItem disabled={disabled}
                                                                 value={uuid} key={uuid}>{fon_fonte}</MenuItem>
                            )}
                            {!sources.length &&
                            <MenuItem alignItems={"center"}>Nessuna fonte disponibile</MenuItem>}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant={"subtitle1"}>Articolo di riferimento</Typography>
                        <TextField placeholder={'Articolo'} fullWidth
                                   disabled={disabled}
                                   defaultValue={violationForm.watch('rea_articolo') || ''}
                                   onChange={(e) => violationForm.setValue('rea_articolo', e.target.value)}
                                   inputRef={violationForm.register({
                                       name: 'rea_articolo'
                                   })}
                                   helperText={violationForm.errors['rea_articolo'] ? violationForm.errors['rea_articolo'].message : ''}
                                   error={!!violationForm.errors['rea_articolo']}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant={"subtitle1"}>Anno di introduzione</Typography>
                        <TextField type={'number'} placeholder={'Anno'} fullWidth
                                   disabled={disabled}
                                   defaultValue={violationForm.watch('rea_anno') || ''}
                                   onChange={(e) => violationForm.setValue('rea_anno', e.target.value)}
                                   inputRef={violationForm.register({
                                       name: 'rea_anno'
                                   })}
                                   helperText={violationForm.errors['rea_anno'] ? violationForm.errors['rea_anno'].message : ''}
                                   error={!!violationForm.errors['rea_anno']}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"}>Seleziona la categoria reato 231 di riferimento</Typography>
                        <TextField select fullWidth
                                   disabled={disabled}
                                   value={violationForm.watch('cat_uuid') || ''}
                                   inputRef={violationForm.register({
                                       name: 'cat_uuid'
                                   }, {validate: forceRequired})}
                                   onChange={(e) => violationForm.setValue('cat_uuid', e.target.value)}
                                   error={!!violationForm.errors['cat_uuid']}
                                   helperText={violationForm.errors['cat_uuid'] ? violationForm.errors['cat_uuid'].message : ''}>
                            {categories.map(
                                ({cat_descrizione, uuid}) => <MenuItem disabled={disabled}
                                                                       value={uuid}
                                                                       key={uuid}>{cat_descrizione}</MenuItem>
                            )}
                            {!categories.length &&
                            <MenuItem alignItems={"center"}>Nessuna categoria disponibile</MenuItem>}
                        </TextField>
                    </Grid>
                    { hasNormattiva &&
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"}>Collegamento al portale Normattiva</Typography>
                        {mode !== 'read' && <TextField disabled={disabled}
                                   defaultValue={violationForm.watch('rea_normativa') || ''}
                                   inputRef={violationForm.register({
                                       name: 'rea_normativa'
                                   })}
                                   onChange={(e) => violationForm.setValue('rea_normativa', e.target.value)}
                                   helperText={violationForm.errors['rea_normativa'] ? violationForm.errors['rea_normativa'].message : ''}
                                   error={!!violationForm.errors['rea_normativa']} fullWidth/> }
                        {mode === 'read' && violation.rea_normativa && <a href={violation.rea_normativa} target={'_blank'}>{violation.rea_normativa}</a>}
                        {mode === 'read' && !violation.rea_normativa && <i>Collegamento non disponibile</i>}
                    </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container direction={"column"} alignContent={"center"} spacing={4}>
                            <Grid item>
                                <Typography variant={"subtitle1"}>
                                    Quote sanzione amministrativa MIN - MAX
                                </Typography>
                                <Typography variant={"body2"} align={"center"}>
                                    Selezione: <strong>{violationForm.watch('rea_sanzione_amministrativa_min') || 0}</strong> - <strong>{violationForm.watch('rea_sanzione_amministrativa_max') || 1200}</strong> quote
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Slider
                                    min={0}
                                    max={1200}
                                    disabled={disabled}
                                    defaultValue={[
                                        violationForm.watch('rea_sanzione_amministrativa_min') || 0,
                                        violationForm.watch('rea_sanzione_amministrativa_max') || 1200
                                    ]}
                                    onChange={(e, value) => {
                                        violationForm.setValue('rea_sanzione_amministrativa_min', value[0]);
                                        violationForm.setValue('rea_sanzione_amministrativa_max', value[1])
                                    }}
                                    marks={[{value: 0, label: '0€'}, {value: 1200, label: '1200€'}]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={"subtitle1"} align={"center"}>
                            Tipologia di sanzione interdittiva
                        </Typography>

                        {mode!=='read' ? (
                                <FormControl fullWidth>
                                    <Select multiple style={{marginTop: '1rem'}}
                                            input={<Input id="select-multiple-chip"/>}
                                            renderValue={selected => (
                                                <div className={classes.chips}>
                                                    {selected.map(value => (
                                                        <Chip key={value}
                                                              label={getChipLabel(value)}
                                                              className={classes.chip}/>
                                                    ))}
                                                </div>
                                            )}
                                            disabled={disabled}
                                            value={violationForm.watch('sanzioni_interdittive') || []}
                                            inputRef={violationForm.register({
                                                name: 'sanzioni_interdittive'
                                            }, {validate: forceRequired})}
                                            onChange={(e) => {
                                                violationForm.setValue('sanzioni_interdittive', e.target.value)
                                            }}
                                            error={!!violationForm.errors['sanzioni_interdittive']}>
                                        {sanctions.map(({san_sanzione, uuid}) =>
                                            <MenuItem disabled={disabled} value={uuid} key={uuid}>
                                                {san_sanzione}
                                            </MenuItem>)}
                                        {!sanctions.length &&
                                        <MenuItem alignItems={"center"}>Nessuna sanzione disponibile</MenuItem>}
                                    </Select>
                                    {violationForm.errors['sanzioni_interdittive'] &&
                                    <Typography variant={"caption"} color={"error"}>
                                        {violationForm.errors['sanzioni_interdittive'].message}
                                    </Typography>}
                                </FormControl>
                            ) :
                            violation.reati231_sanz_interd.filter(sanz_interd => sanz_interd.rsi_com_id===null).map((sanz_interd) => {
                                return (
                                    <Tooltip key={sanz_interd.uuid} placement="top" title={sanz_interd.sanz_interd.san_sanzione}>
                                        <Chip label={sanz_interd.sanz_interd.san_sanzione} className={classes.chip}/>
                                    </Tooltip>
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={6} container justify={"space-evenly"}>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle1"} align={"center"}>
                                Validità della sanzione interdittiva (in mesi)
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label={'min'} type={'number'} fullWidth
                                       disabled={disabled}
                                       defaultValue={violationForm.watch('rea_sanzione_interdittiva_min')}
                                       inputRef={violationForm.register({
                                           name: 'rea_sanzione_interdittiva_min'
                                       })}
                                       onChange={(e) => violationForm.setValue('rea_sanzione_interdittiva_min', e.target.value)}
                                       helperText={violationForm.errors['rea_sanzione_interdittiva_min'] ? violationForm.errors['rea_sanzione_interdittiva_min'].message : ''}
                                       error={!!violationForm.errors['rea_sanzione_interdittiva_min']}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label={'max'} type={'number'} fullWidth
                                       disabled={disabled}
                                       defaultValue={violationForm.watch('rea_sanzione_interdittiva_max')}
                                       inputRef={violationForm.register({
                                           name: 'rea_sanzione_interdittiva_max'
                                       })}
                                       onChange={(e) => violationForm.setValue('rea_sanzione_interdittiva_max', e.target.value)}
                                       helperText={violationForm.errors['rea_sanzione_interdittiva_max'] ? violationForm.errors['rea_sanzione_interdittiva_max'].message : ''}
                                       error={!!violationForm.errors['rea_sanzione_interdittiva_max']}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} align={"center"} gutterBottom>Descrizione del reato</Typography>
                        <ReactQuill defaultValue={violationForm.watch('rea_descrizione')}
                                    ref={violationForm.register({
                                        name: 'rea_descrizione'
                                    }, {validate: requiredHtml})}
                                    modules={ReactQuillModules}
                                    onChange={(html) => violationForm.setValue('rea_descrizione', html)}
                                    readOnly={mode === 'read' || mode === 'delete'}
                                    placeholder={'Inserisci descrizione'}/>
                        {violationForm.errors['rea_descrizione'] &&
                        <Typography variant={"caption"} color={'error'}>
                            {violationForm.errors['rea_descrizione'].message}
                        </Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} align={"center"} gutterBottom>Commento Legale</Typography>
                        <ReactQuill defaultValue={violationForm.watch('rea_commento')}
                                    ref={violationForm.register({
                                        name: 'rea_commento'
                                    })}
                                    modules={ReactQuillModules}
                                    onChange={(html) => violationForm.setValue('rea_commento', html)}
                                    readOnly={mode === 'read' || mode === 'delete'}
                                    placeholder={'Inserisci descrizione'}/>
                        {violationForm.errors['rea_commento'] &&
                        <Typography variant={"caption"} color={'error'}>
                            {violationForm.errors['rea_commento'].message}
                        </Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && mode!=='read' && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

Violation231Component.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
};

Violation231Component.defaultProps = {
    violation: {}
};
Violation231Component.propTypes = {
    violation: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(withRouter(Violation231Component));
