import React from 'react';
import {connect} from "react-redux";
import {handleModal} from "../../redux/actions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import CtaBoxComponent from "../../components/ui/cta-box/cta-box.component";
import FunctionsTable from "../../components/atomic-tables/functions/functions.table";
import FunctionsComponent from '../../components/CRUD/functions/functions.component';


const FunctionsPage = ({ dispatch }) => {    
    const createFunction = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Aggiungi Funzione',
            content: <FunctionsComponent mode={'create'}/>
        }));
    };
    
    const actions = [
        {
            label: 'Aggiungi funzione',
            handler: createFunction
        }
    ];
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Funzioni'}/>
                    </Grid>                    
                    <Grid item xs={12}>
                        <Grid container justify={"space-between"} alignItems={"center"} spacing={2} style={{margin: 0}}>
                            <Grid item xs>
                                <Typography variant={"subtitle2"}>Elenco funzioni</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={createFunction}>Aggiungi funzione</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FunctionsTable />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>
                        <CtaBoxComponent title={'Funzione'} actions={actions}/>
                    </Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    );
};

FunctionsPage.propTypes = {

};

export default connect()(FunctionsPage);
