import {MODAL_HANDLER} from "../types";

const modalReducer = (state = {}, {type, ...modal}) => {
    switch (type) {
        case MODAL_HANDLER:
            return {
                ...state,
                modal
            };
        default:
            return state
    }
};

export default modalReducer;
