import React, {useEffect, useState} from 'react';
import {handleModal, handleToast} from "../../redux/actions";
import {GeneralModelsApi} from "../../services/api.service";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography';

import SectionComponent from "../../components/CRUD/general-section/general-section.component";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import GenericSectionsTable from "../../components/atomic-tables/general-sections/general-sections.table";
import GenericModelsTable from "../../components/atomic-tables/general-models/general-models.table";
import GenericModelsTree from '../../components/tree/generic-sections.tree'
import GenericModelComponent from "../../components/CRUD/general-model/general-model.component";

const GeneralModels = new GeneralModelsApi();
const GenericSections = ({dispatch, location: {search}, history}) => {
    const [parentTitle, setParentTitle] = useState();
    const [previewLoading, setPreviewLoading] = useState();
    const [previewWordLoading, setPreviewWordLoading] = useState();
    const createSection = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Aggiungi sezione',
            content: <SectionComponent mode={'create'}/>,
        }));
    };
    const preview = async () => {
        try {
            setPreviewLoading(true);
            const file = await GeneralModels.downloadPreview('pdf');
        } catch (e) {
            console.error(e);
            dispatch(handleToast({
                message: 'Impossibile scaricare l\'anteprima'
            }));
        }
        setPreviewLoading(false);
    };
    const previewWord = async () => {
        try {
            setPreviewWordLoading(true);
            const file = await GeneralModels.downloadPreview('docx');
        } catch (e) {
            console.error(e);
            dispatch(handleToast({
                message: 'Impossibile scaricare l\'anteprima'
            }));
        }
        setPreviewWordLoading(false);
    };
    const createGeneralModel = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Crea versione',
            content: <GenericModelComponent mode={'create'}/>,
        }));
    };
    useEffect(
        () => {
            const params = search.replace('?', '').split('&').map((param) => {
                const keyValue = param.split('=');
                return {
                    [keyValue[0]]: keyValue[1]
                }
            });
            const parent = params.find(({parent_title}) => parent_title);
            if (parent) {
                setParentTitle(decodeURI(parent.parent_title));
            } else {
                setParentTitle();
            }
        },
        [search]
    );
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Parte generale - Elenco Sezioni`} back/>
                    </Grid>
                    <Grid item container alignItems={"center"} spacing={2}>
                        <Grid item xs={12}>
                            <Grid item container alignItems={"flex-start"} spacing={2}>
                                <Grid item xs={3}>
                                    <Grid item container style={{marginBottom: 15 }}>
                                        <Grid item>
                                            <Typography variant={"h6"}>Naviga sezioni</Typography>
                                            <Typography variant={"subtitle1"}>Doppio click per aprire sezione</Typography>
                                        </Grid>
                                    </Grid>
                                    <GenericModelsTree />
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid item container alignItems={"flex-start"} style={{marginBottom: 15 }}>
                                        {/*parentTitle && (
                                            <Grid item>
                                                <IconButton onClick={() => history.goBack()}>
                                                    <Icon>arrow_back</Icon>
                                                </IconButton>
                                            </Grid>
                                        )*/}
                                        <Grid item xs>
                                            <Typography variant={"h6"}>{parentTitle ? parentTitle : 'Elenco Sezioni'}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button variant={"outlined"} color={"primary"} onClick={createSection}>Nuova sezione</Button>
                                        </Grid>
                                    </Grid>
                                    <GenericSectionsTable/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Parte generale - Elenco Versioni`}/>
                    </Grid>
                    <Grid item container alignItems={"center"} spacing={2}>
                        <Grid item container alignItems={"center"} spacing={2}>
                            <Grid item xs>
                                <Typography variant={"h6"}>Elenco versioni</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={previewWord} disabled={previewWordLoading}>
                                    <Grid container alignItems={"center"} spacing={1}>
                                        <Grid item>
                                            {!!previewWordLoading ? 'Download' : 'Anteprima Word'}
                                        </Grid>
                                        {!!previewWordLoading &&
                                        <Grid item style={{display: 'block'}}>
                                            <CircularProgress size={15} color={"inherit"} style={{display: 'block', margin: 'auto'}}/>
                                        </Grid>}
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={createGeneralModel}>Nuova versione</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <GenericModelsTable/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default connect()(withRouter(GenericSections));
