import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {TextFieldTemplate} from "../form-components/text-field";
import {Grid} from "@material-ui/core";
import {SliderTemplate} from "../form-components/slider";
import {RadioGroupTemplate} from "../form-components/radio-group";
import {ChecksGroupTemplate} from "../form-components/checks-group";
import {DatePickerTemplate} from "../form-components/date-picker";
import {FileInputTemplate} from "../form-components/file-input";
import {SwitchGroupTemplate} from "../form-components/switch-group";

const FormTemplatesListComponent = () => {
    const templates = [
        <SwitchGroupTemplate/>,
        <RadioGroupTemplate/>,
        <ChecksGroupTemplate/>,
        <DatePickerTemplate/>,
        <TextFieldTemplate/>,
        <SliderTemplate/>,
        <FileInputTemplate/>
    ];
    return (
        <Grid container justify={"center"}>
            <Grid item xs={10}>
                <List>
                    {templates.map((component, index) => (
                        <ListItem key={index} style={{borderBottom: '1px dashed gray'}}>
                            {component}
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    );
};

FormTemplatesListComponent.propTypes = {};

export default FormTemplatesListComponent;
