import React from "react";
import { parseNotes } from "../../functions/insert-risk-assessment.helper";

import { connect } from "react-redux";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { ValutazioneApi, RiskAreaApi } from "../../services/api.service";

const NotesAccordionDetails = ({ notes }) => {
  if (!notes) {
    return (
      <Grid container style={{ justifyContent: "center" }}>
        <CircularProgress size={25}></CircularProgress>
      </Grid>
    );
  }
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant={"h6"}>Note del Valutatore</Typography>
        <Typography variant={"subtitle1"} className={"consulenteArea"}>
          Area:
        </Typography>
        <Typography variant={"caption"}>{notes.consulente.area}</Typography>
        <Typography variant={"subtitle1"} className={"consulenteReato"}>
          Reato:
        </Typography>
        <Typography variant={"caption"}>{notes.consulente.reato}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"h6"}>Note del Valutato</Typography>
        <Typography variant={"subtitle1"} className={"aziendaArea"}>
          Area:
        </Typography>
        <Typography variant={"caption"}>{notes.azienda.area}</Typography>
        <Typography variant={"subtitle1"} className={"reatoArea"}>
          Reato:
        </Typography>
        <Typography variant={"caption"}>{notes.azienda.reato}</Typography>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ fetchApiReducer }) => {
  return {
    api: fetchApiReducer,
  };
};
export default connect(mapStateToProps)(NotesAccordionDetails);
