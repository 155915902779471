import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {handleHelpDrawer} from "../../../redux/actions";
import Drawer from "@material-ui/core/Drawer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Link from '@material-ui/core/Link'
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import {withRouter} from "react-router-dom";
import tutorialPDF from '../../../tutorials/Valore_24–231_Manuale_uso.pdf'

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 400,
        height: '100%',
        background: '#3A3C48'
    },
    dividerText: {
        color: 'white',
        background: '#3A3C48',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        width: 'fit-content',
        padding: '0 20px'
    },
    whiteDivider: {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        position: 'relative',
        margin: theme.spacing(4)
    },
    whiteText: {
        color: 'white'
    },
    tutorialLink:{
        color: 'white',
        textDecoration: 'underline'
    }
}));
const HelpDrawerComponent = ({drawer: {open}, dispatch, history}) => {
    const classes = useStyles();
    const onClose = () => {
        dispatch(handleHelpDrawer({open: false}));
    };
    return (
        <Drawer
            anchor={"right"}
            open={open} variant={"temporary"}
            onClose={onClose}>
            <Grid container className={classes.drawer} justify={"center"} alignItems={"center"}>
                <Grid item xs={10}>
                    <Divider component={'div'} className={classes.whiteDivider}>
                        <Typography className={classes.dividerText} align={"center"}>
                            SEZIONE DI AIUTO
                        </Typography>
                    </Divider>
                </Grid>
                <Grid item xs={10}>
                    <Typography className={classes.whiteText} variant={"body1"}>
                        Nella sezione Tutorial trovate i video- tutorial che illustrano le funzionalità e le modalità di utilizzo, di ogni area operativa dell’applicativo.<br />
                        Per un ulteriore supporto tecnico, potete richiedere assistenza mediante il modulo di "Richiesta Supporto”.<br />
                        {/* Infine è disponibile <Link href={tutorialPDF} target={'_blank'} className={classes.tutorialLink}>qui il manuale d’uso</Link> dell’applicazione. */}
                    </Typography>
                </Grid>
                <Grid item xs={12} container justify={"center"} spacing={4}>
                    <Grid item>
                        <Fab variant={"extended"} color={"secondary"}
                             onClick={() => {
                                 onClose();
                                 history.push('/faq/tutorial');
                             }}>
                            accedi al tutorial
                        </Fab>
                    </Grid>
                    <Grid item>
                        <Fab variant={"extended"} color={"secondary"}
                             onClick={() => {
                                 onClose();
                                 history.push('/faq/richiesta-supporto');
                             }}>
                            rischiesta di supporto
                        </Fab>
                    </Grid>                    
                </Grid>
            </Grid>
        </Drawer>
    );
};

HelpDrawerComponent.propTypes = {
    open: PropTypes.bool.isRequired
};
const mapStateToProps = ({helpDrawerReducer: {drawer}}) => {
    return drawer
};

export default connect(
    mapStateToProps
)(withRouter(HelpDrawerComponent));
