import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import ManageFlowCollectionTable
    from '../../components/atomic-tables/manage-flow-collection/manage-flow-collection.table';
import {withRouter} from "react-router-dom";

const FlowCollectionManagePage = ({history, match}) => {
    const rac_uuid = match.params.uuid;
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Raccolta Flussi`} back/>
                    </Grid>
                    <Grid item style={{margin: '2rem 0 1rem'}}>
                        <Grid container>
                            <Grid item xs>
                                <Typography variant="h5" gutterBottom>
                                    Monitoraggio avanzamento raccolta flussi
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                    Controlla lo stato di raccolta dei flussi inviati e gestisci eventuali solleciti
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => window.location.reload()} variant={"outlined"} color={"primary"}>Aggiorna Dati</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={4}>
                            <Grid item xs>
                                <ManageFlowCollectionTable rac_uuid={rac_uuid}/>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Grid container justify={"center"} spacing={4} style={{margin: '2rem 0'}}>
                                    <Grid item>
                                        <Button type={"button"} variant="outlined" color={"primary"} onClick={() => history.push('/flussi-OdV/raccolta-flussi')}>
                                            Torna alla lista
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button type={"button"} variant="contained" color="primary" onClick={() => history.push(`/flussi-OdV/raccolta-flussi/chiudi-raccolta/${rac_uuid}`)}>
                                            Chiudi e completa la raccolta
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

FlowCollectionManagePage.propTypes = {};

export default withRouter(FlowCollectionManagePage);
