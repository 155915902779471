import React from 'react';
import {connect} from 'react-redux';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Link, Link as RouteLink} from "react-router-dom";


function PermissionPage() {
    const useStyles = makeStyles(theme => ({
        errorText: {
            color: 'black',
            fontSize: '1.4rem',
            textAlign: 'center',
        },
        centerDiv:{
            marginLeft: 'auto',
            marginRight: 'auto',
            width:'50%',
            '& a': {
                color: 'black',
                webkitTransition: 'all .2s ease',
                mozTransition: 'all .2s ease',
                transition:'all .2s ease',
            },
            '& a:visited': {
                color: 'black',
            },
            '& a:hover': {
                color: 'black',
                fontSize: '1.8rem',
            }
        },
    }));
    const classes = useStyles();
    return (
        <div>
            <p className={[classes.errorText, classes.centerDiv].join (' ')}>Non hai i permessi per visualizzare la pagina!</p>
            <p className={[classes.errorText, classes.centerDiv].join (' ')}>Per favore torna alla <Link className={[classes.textLink]} color={"primary"} align={"center"}
                                  to={'/'}>home</Link></p>
        </div>
    )
}

PermissionPage.propTypes = {};

export default connect()(PermissionPage);
