import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import Icon from "@material-ui/core/Icon";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import {withRouter} from "react-router-dom";
import {FlowCollectionApi} from "../../services/api.service";
import {handleToast} from "../../redux/actions";
import red from "@material-ui/core/colors/red";
import indigo from "@material-ui/core/colors/indigo";
import {TextField} from "@material-ui/core";

const FlowCollection = new FlowCollectionApi();
const FlowCollectionClosePage = ({history, match, dispatch}) => {
    const rac_uuid = match.params.uuid;
    const [flowNotes, setFlowNotes] = useState();
    const [completedFlow, setCompletedFlow] = useState();
    useEffect(
        () => {
            const fetchData = async () => {
                try {
                    const {raccolte} = await FlowCollection.get(rac_uuid);
                    setCompletedFlow(!raccolte.tracciati.some(
                        ({completed}) => completed !== 100
                    ))
                } catch (message) {
                    dispatch(handleToast({message}));
                }
            };
            fetchData();
        }, []
    );
    const closeFlowCollection = async () => {
        try {
            await FlowCollection.closeFlowCollection({
                uuid: rac_uuid,
                rac_note: flowNotes
            });
            dispatch(handleToast({
                message: 'Raccolta chiusa con successo'
            }));
            history.push(`/flussi-OdV/raccolta-flussi/riepilogo-raccolta/${rac_uuid}`);
        } catch (message) {
            dispatch(handleToast({message}));
        }
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Raccolta Flussi`} back/>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={4}>
                            {completedFlow === true &&
                            <Grid item xs={12}>
                                <Paper style={{backgroundColor: indigo[50], padding: '1rem'}}>
                                    <Grid container justify="center" alignItems="center">
                                        <Grid item xs={10} container justify="center" alignItems="center"
                                              spacing={4}>
                                            <Grid item>
                                                <Icon style={{fontSize: 120}}
                                                      color="primary">check_circle_outline</Icon>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h6" color="primary" gutterBottom>
                                                    Completa la raccolta
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    È possibile procedere con la chiusura della raccolta e la produzione
                                                    del report.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>}
                            {completedFlow === false &&
                            <Grid item xs={12}>
                                <Paper style={{backgroundColor: red[50], padding: '1rem'}}>
                                    <Grid container justify="center" alignItems="center">
                                        <Grid item xs={10} container justify="center" alignItems="center"
                                              spacing={4}>
                                            <Grid item>
                                                <Icon style={{fontSize: 120}} color="error">warning</Icon>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="h6" color="error" gutterBottom>
                                                    Raccolta non ancora completata
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    È possibile comunque procedere con la chiusura della raccolta ma si
                                                    consiglia di aggiungere nelle note il perché non sia stata
                                                    completata.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>}
                            {completedFlow === false &&
                            <Grid item xs={12}>
                                <TextField multiline fullWidth label={'Note relative alla raccolta'}
                                           placeholder={'Inserisci note'} value={flowNotes}
                                           onChange={(e) => setFlowNotes(e.target.value)}/>
                            </Grid>
                            }
                            <Grid item xs={12} align="center">
                                <Grid container justify={"center"} spacing={4} style={{margin: '2rem 0'}}>
                                    <Grid item>
                                        <Button type={"button"}
                                                onClick={() => history.push(`/flussi-OdV/raccolta-flussi/gestisci-raccolta/${rac_uuid}`)}>
                                            Torna Indietro
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button type={"button"} variant="contained" color="primary"
                                                onClick={closeFlowCollection}>
                                            Procedi con la chiusura
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

FlowCollectionClosePage.propTypes = {};

export default withRouter(connect()(FlowCollectionClosePage));
