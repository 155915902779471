import React, {useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ConfigurationModuleComponent from "../configuration-module/configuration-module.component";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from 'prop-types';
import ValidButtonConfiguration from "../../generic-ui/valid-button-configuration";

/*
const DatePickerRow = ({onSave, range}) => {
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const handleChange = (e) => {
        onSave();
    };
    return (
        <TableRow>
            <TableCell>
                {range && (!from || !to) && <Icon color={"error"}>warning</Icon>}
                {(!range || (range && !from || !to)) && <Icon color={"primary"}>done</Icon>}
            </TableCell>
            <TableCell>
                <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">da</FormLabel>
                    <DatePicker label={'Seleziona data'} disabled={!range} name={'from-date-picker'}
                                onChange={handleChange} value={from} margin={'normal'} maxDate={to}
                                emptyLabel={' -- seleziona data --'}/>
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">fino a</FormLabel>
                    <DatePicker label={'Seleziona data'} disabled={!range} name={'to-date-picker'} onChange={handleChange}
                                value={to} margin={'normal'} minDate={from} emptyLabel={' -- seleziona data --'}/>
                </FormControl>
            </TableCell>
        </TableRow>
    );
};
*/

const DatePickerConfigure = ({onSave, defaultConfig, select, options, displayName}) => {
    const [dialog, toggleDialog] = useState(false);
    const [rows, setRows] = useState(defaultConfig);
    // const [_select, setSelect] = useState(select);
    const [isValid, setIsValid] = useState(!defaultConfig.some(({required, value}) => required && !value));
    // const [_options, setSelectOptions] = useState(options);
    const handleChange = (e, row) => {
        const row_index = rows.indexOf(row)        
        if(e.target.name==='boolean' && !e.target.value){            
            rows[row_index] = {
                ...row,
                value: '',            
                boolean: false
            };            
        }else{
            rows[row_index] = {
                ...row,
                [e.target.name]: e.target.value,            
            };        
        }
        setRows([...rows]);
        setIsValid(!rows.some((item) => {            
            const {required, value} = item
            if(item.hasOwnProperty('boolean')){
                return item.boolean && !value
            }else{
                return required && !value
            }            
        }))
    };
    // const saveCustomRows = (valid, select, options) => {
    //     setSelect(select);
    //     setSelectOptions(options);
    // };
    const configure = () => {
        toggleDialog(!dialog);
    };
    const save = () => {
        onSave(rows, isValid);
        toggleDialog(false);
    };
    // const range = defaultConfig.find(({name})=> name === 'range');
    return (
        <FormControl component="fieldset" fullWidth={true}>
            <Grid container justify={"center"}>
                <Grid item xs={10}>
                    <Typography variant={"subtitle2"} gutterBottom={true}
                                align={"center"}>
                        {displayName}
                    </Typography>
                </Grid>
                <Grid item xs={10} md={6}>
                    <ValidButtonConfiguration configure={configure} isValid={isValid}/>
                </Grid>
            </Grid>
            <Dialog open={dialog} aria-labelledby="text-field-conf-dialog" onClose={() => toggleDialog(false)}
                    fullWidth={true} maxWidth={'lg'}>
                <DialogTitle id="text-field-conf-dialog">Configura {displayName}</DialogTitle>
                <DialogContent>
                    {/*<DatePickerRow onSave={saveCustomRows} range={range.value}/> customRows*/}
                    <ConfigurationModuleComponent rows={rows}
                                                  handleChange={handleChange}/>
                </DialogContent>
                <DialogActions>
                    <Grid direction={"row"} justify={"flex-end"} container>
                        <Grid item>
                            <Button variant={"text"} color={"default"} onClick={() => toggleDialog(false)}>
                                indietro
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={save} color={"secondary"} disabled={!isValid}>salva</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </FormControl>
    );
};

DatePickerConfigure.defaultProps = {
    defaultConfig: [],
    onSave: () => console.error('Missing on saveConfiguration function!')
};

DatePickerConfigure.propTypes = {
    onSave: PropTypes.func.isRequired,
    defaultConfig: PropTypes.array
};

export default DatePickerConfigure;
