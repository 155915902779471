import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import toastReducer from "./toast.reducer";
import modalReducer from "./modal.reducer";
import helpDrawerReducer from "./help-drawer.reducer";
import fetchApiReducer from "./fetch-api.reducer";
import violationReducer from "./violation.reducer";

export default combineReducers({
  fetchApiReducer,
  authReducer,
  helpDrawerReducer,
  toastReducer,
  modalReducer,
  violationReducer,
});
