import React, { useState } from 'react';
import {handleModal} from "../../redux/actions";
import {connect} from "react-redux";
import {SpecialModelsApi} from "../../services/api.service";
import {handleToast} from "../../redux/actions";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from "@material-ui/core/Button";

import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import SpecificSectionsTable from "../../components/atomic-tables/specific-models/specific-models.table";
import SpecificModelComponent from "../../components/CRUD/specific-model/specific-model.component";

const SpecialModel = new SpecialModelsApi();

const SpecificSections = ({dispatch}) => {
    const [previewWordLoading, setPreviewWordLoading] = useState(false);
    const createVersion = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Conferma Nuova versione',
            content: <SpecificModelComponent mode={'create'}/>,
        }));
    };

    const preview = async (type) => {
        try {
            const file = await SpecialModel.downloadPreview(type);
        } catch (e) {
            console.error(e);
            dispatch(handleToast({
                message: 'Impossibile scaricare l\'anteprima'
            }));
        }
    };

    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Parte speciale - Gestione versioni`}/>
                    </Grid>
                    <Grid item container alignItems={"center"} spacing={2}>
                        <Grid item container alignItems={"center"} spacing={2}>
                            <Grid item xs>
                                <Typography variant={"h6"}>Elenco versioni</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={() => preview('doc')}>
                                    <Grid container alignItems={"center"} spacing={1}>
                                        <Grid item>
                                            {!!previewWordLoading ? 'Download' : 'Anteprima Word'}
                                        </Grid>
                                        {!!previewWordLoading &&
                                        <Grid item style={{display: 'block'}}>
                                            <CircularProgress size={15} color={"inherit"} style={{display: 'block', margin: 'auto'}}/>
                                        </Grid>}
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={createVersion}>Nuova versione</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <SpecificSectionsTable/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default connect()(SpecificSections);
