import React, {Fragment, useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ConfigurationModuleComponent from "../configuration-module/configuration-module.component";
import {TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from 'prop-types';
import {generateID} from "../../../index.utils";
import ValidButtonConfiguration from "../../generic-ui/valid-button-configuration";

const _valid = (group) => group ? !group.some(({label}) => !label) : false;

const RadioGroup = ({onSave, radioGroup}) => {
    const [group, setGroup] = useState(radioGroup);
    useEffect(() => onSave(group), [onSave, group]);
    const removeRadio = () => {
        group.pop();
        setGroup([...group]);
    };
    const handleChange = (e) => {
        setGroup([...group.map((_radio) => {
            if (_radio.value === e.target.name) {
                _radio.label = e.target.value;
            }
            return _radio;
        })]);
    };
    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    {!_valid(group) && <Icon color={"error"}>warning</Icon>}
                    {_valid(group) && <Icon color={"primary"}>done</Icon>}
                </TableCell>
                <TableCell>
                    <Typography variant={"subtitle2"}>Options group</Typography>
                </TableCell>
                <TableCell>
                    <TextField disabled={true} fullWidth={true}/>
                </TableCell>
                <TableCell>
                    <Grid container alignItems={"center"}>
                        <Grid item>
                            <IconButton onClick={() => removeRadio()} disabled={group.length < 2}>
                                <Icon color={(group.length < 2) ? "disabled" : "error"}>remove</Icon>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton color={"primary"} onClick={() => setGroup([...group, {
                                value: `radio-${generateID(9)}`,
                                label: ''
                            }])}>
                                <Icon>add</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            {group.map(({value, label, helperText, required}, index) => (
                <Fragment key={`radio-${index}`}>
                    <TableRow>
                        <TableCell colSpan={1}/>
                        <TableCell>
                            <Typography variant={"subtitle2"}>Opzione {index + 1}</Typography>
                        </TableCell>
                        <TableCell>
                            <TextField fullWidth={true} value={label} placeholder={'Inserisci titolo'}
                                       name={value} onChange={handleChange}/>
                        </TableCell>


                    </TableRow>
                </Fragment>
            ))}
        </Fragment>
    );
};

RadioGroup.defaultProps = {
    radioGroup: [],
    onSave: () => console.error('Missing on saveConfiguration function!')
};

const RadioGroupConfigure = ({onSave, radioGroup, defaultConfig, displayName}) => {
    const [dialog, toggleDialog] = useState(false);
    const [rows, setRows] = useState(defaultConfig);
    const [group, setGroup] = useState(radioGroup);
    const [isValid, setIsValid] = useState(_valid(group) && !rows.find(({required, value}) => required && !value));
    const handleGroupChange = (group) => {
        setIsValid(_valid(group) && !rows.some(({required, value}) => required && !value));
        setGroup(group);
    };
    const handleChange = (e, row) => {
        rows[rows.indexOf(row)] = {
            ...row,
            [e.target.name]: e.target.value
        };
        setRows([...rows]);
    };
    const configure = () => {
        toggleDialog(!dialog);
    };
    const save = () => {
        toggleDialog(false);
        onSave(rows, group, isValid);
    };
    return (
        <FormControl component="fieldset" fullWidth={true}>
            <Grid container justify={"center"}>
                <Grid item xs={10}>
                    <Typography variant={"subtitle2"} gutterBottom={true} align={"center"}>
                        {displayName}
                    </Typography>
                </Grid>
                <Grid item xs={10} md={6}>
                    <ValidButtonConfiguration configure={configure} isValid={isValid}/>
                </Grid>
            </Grid>
            <Dialog open={dialog} aria-labelledby="text-field-conf-dialog" onClose={() => toggleDialog(false)}
                    fullWidth={true} maxWidth={'lg'}>
                <DialogTitle id="text-field-conf-dialog">Configura {displayName}</DialogTitle>
                <DialogContent>
                    <ConfigurationModuleComponent rows={rows} handleChange={handleChange} customRows={
                        <RadioGroup radioGroup={radioGroup} onSave={handleGroupChange}/>
                    }/>
                </DialogContent>
                <DialogActions>
                    <Grid direction={"row"} justify={"flex-end"} container>
                        <Grid item>
                            <Button variant={"text"} color={"default"} onClick={() => toggleDialog(false)}>
                                indietro
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={save} color={"secondary"}
                                    disabled={!isValid}>salva</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </FormControl>
    );
};

RadioGroupConfigure.defaultProps = {
    defaultConfig: [],
    onSave: () => console.error('Missing on saveConfiguration function!')
};

RadioGroupConfigure.propTypes = {
    onSave: PropTypes.func.isRequired,
    defaultConfig: PropTypes.array
};

export default RadioGroupConfigure;
