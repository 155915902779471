import {DRAWER_HANDLER} from "../types";

const helpDrawerReducer = (state = {}, {type, ...drawer}) => {
    switch (type) {
        case DRAWER_HANDLER:
            return {
                ...state,
                drawer
            };
        default:
            return state
    }
};

export default helpDrawerReducer;
