import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Container from "@material-ui/core/Container";
import {withRouter} from "react-router-dom";

const StepperComponent = ({steps, defaultStep = 0, children, title, onComplete, history}) => {
    const [activeStep, setActiveStep] = useState(defaultStep);
    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep === 0 ? history.goBack() : prevActiveStep - 1);
    };
    const handleNext = () => {
        if (activeStep !== steps.length - 1) {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    return (
        <Container maxWidth={"md"}>
            <Grid container direction={"column"} spacing={4}>
                <Grid item>
                    <Typography variant={"h5"} align={"center"}>{title}</Typography>
                </Grid>
                <Grid item>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>
                <Grid item>
                    {React.Children.map(children, child => React.cloneElement(child, {
                        handleBack,
                        handleNext,
                        onComplete,
                        steps,
                        activeStep
                    }))}
                </Grid>
            </Grid>
        </Container>
    );
};

StepperComponent.propTypes = {
    title: PropTypes.string,
    defaultStep: PropTypes.number,
    steps: PropTypes.array.isRequired,
    onComplete: PropTypes.func.isRequired,
};

export default withRouter(StepperComponent);
