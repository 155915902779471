import React, {useEffect, useState} from 'react';
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {MODE_VIEW_COMPILE, MODE_VIEW_CONFIGURATION, MODE_VIEW_PREVIEW, MODE_VIEW_READONLY} from "../../index.constants";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import SectionComponent from "../section/section.component";
import {CardContent} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import Card from "@material-ui/core/Card";
import {withRouter} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import {Section} from "../section/section.class";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    form: {
        width: 'auto%',
        height: '100%',
        display: 'block',
        padding: theme.spacing(3),
        position: 'relative',
        backgroundColor: grey[50]
    },
    btn_validate: {}
}));

const FormBuilderComponent = ({saveForm, setSections, sections, flow, setFlow, defaultViewMode}) => {
    const classes = useStyles();
    const [viewMode, setViewMode] = useState(defaultViewMode || MODE_VIEW_CONFIGURATION);
    let defaultValues = {};
    try {
        sections.forEach(
            ({inputList, gridName, gridSizeSelect}) => {
                defaultValues = {
                    ...defaultValues,
                    ...gridName.initialValue,
                    ...gridSizeSelect.initialValue
                };
                inputList.forEach((component) => {
                    defaultValues = {
                        ...defaultValues,
                        ...component.initialValue
                    }
                });
            });
    } catch (e) {
        console.warn(e);
    }
    let formBuilder = useForm({
        mode: 'onSubmit',
        nativeValidation: false,
        defaultValues
    });
    const removeDuplicates = (sectionID) => (componentID) => {
        setSections(sections.map((section) => {
            if (section.id !== sectionID) {
                return {
                    ...section,
                    inputList: section.inputList.filter((input) => input.id !== componentID)
                }
            }
            return section;
        }));
    };
    const removeSection = (_id) => () => {
        if (sections.length === 1) {
            return;
        }
        try {
            let _s = sections.find(({id}) => id === _id);
            formBuilder.unregister(_s.gridName.name);
            formBuilder.unregister(_s.gridSizeSelect.name);
            _s.inputList.forEach(({name}) => {
                formBuilder.unregister(name);
            });
            sections.splice(sections.indexOf(_s), 1);
            setSections([
                ...sections
            ]);
        } catch (e) {
            console.warn(e);
        }
    };
    const mapSections = () => {
        return sections.map(
            (section) => {
                const {gridName: {name}} = section;
                const gridName = formBuilder.watch(name);
                if (formBuilder.watch(name)) {
                    section.gridName.initialValue = gridName;
                }
                return section;
            }
        ).map(
            (section) => {
                const {gridSizeSelect: {name}} = section;
                const gridSizeSelect = formBuilder.watch(name);
                if (formBuilder.watch(name)) {
                    section.gridSizeSelect.initialValue = gridSizeSelect;
                }
                return section;
            }
        )
    };
    const setInputList = (id) => (inputList) => {
        // console.log('Set input list', inputList, 'for section', id);
        setSections(sections.map(
            (section) => {
                if (section.id === id) {
                    return {
                        ...section,
                        inputList: inputList
                    }
                }
                return section;
            }
        ));
        // console.log('Result sections', sections);
    };
    const saveInputConfig = (component) => (config) => {
        // console.log('Save input config', component, config);
        setSections(sections.map(
            (section) => {
                if (section.inputList.some(({id}) => id === component.id)) {
                    return {
                        ...section,
                        inputList: section.inputList.map(
                            (c) => {
                                if (c.id === component.id) {
                                    c = {
                                        ...c,
                                        ...config
                                    }
                                }
                                return c;
                            }
                        )
                    }
                }
                return section;
            }
        ));

    };
    const onSubmit = data => {
        console.log(data, JSON.stringify(data));
    };
    const addSection = () => {
        setSections([...sections, new Section()]);
    };
    const toggleView = () => {
        setViewMode(viewMode === MODE_VIEW_PREVIEW ? MODE_VIEW_CONFIGURATION : MODE_VIEW_PREVIEW);
        setSections(sections.map((section) => {
            return {
                ...section,
                inputList: section.inputList.map(
                    (component) => {
                        component.viewMode = viewMode;
                        return component;
                    })
            }
        }))
    };
    useEffect(() => {
        if (viewMode !== MODE_VIEW_COMPILE && viewMode !== MODE_VIEW_READONLY) {
            const inputs = sections.reduce((_inputs, {inputList}) => [..._inputs, ...inputList], []);
            if (!inputs.some(({viewMode}) => viewMode === MODE_VIEW_PREVIEW)) {
                // console.log('All component are in configuration mode');
                setViewMode(MODE_VIEW_CONFIGURATION);
            } else if (!inputs.some(({viewMode}) => viewMode === MODE_VIEW_CONFIGURATION)) {
                // console.log('All component are in preview mode');
                setViewMode(MODE_VIEW_PREVIEW);
            }
        }else if(defaultViewMode===MODE_VIEW_READONLY){
            setSections(sections.map((section) => {
                return {
                    ...section,
                    inputList: section.inputList.map(
                    (component) => {
                        component.viewMode = MODE_VIEW_READONLY;
                        return component;
                    })
                }
            }))
        }
    }, []);
    const save = async () => {
        console.log(sections, formBuilder.getValues(), JSON.stringify(sections));
        // console.log('viewMode',viewMode);
        if (viewMode === MODE_VIEW_COMPILE) {
            saveForm(mapSections(), formBuilder.getValues());
        } else {
            saveForm(mapSections());
        }
    };
    return (
        <Grid className={classes.container} container>
            <Grid item xs={12}>
                <FormContext {...formBuilder}>
                    <form
                        onSubmit={viewMode === MODE_VIEW_COMPILE ? formBuilder.handleSubmit(saveForm) : formBuilder.handleSubmit(onSubmit)}
                        className={classes.form}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                {viewMode !== MODE_VIEW_COMPILE && viewMode !== MODE_VIEW_READONLY && flow &&
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <InputLabel>Titolo flusso</InputLabel>
                                                <TextField name={'form-group-title'} multiline
                                                           placeholder={'Es. Raccolta flusso...'}
                                                           value={flow.flu_titolo}
                                                           onChange={(e) => setFlow({
                                                               ...flow,
                                                               flu_titolo: e.target.value
                                                           })} fullWidth/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputLabel>Descrizione flusso</InputLabel>
                                                <TextField name={'form-group-description'}
                                                           multiline placeholder={'Es. Flusso organizzativo...'}
                                                           value={flow.flu_descrizione}
                                                           onChange={(e) => setFlow({
                                                               ...flow,
                                                               flu_descrizione: e.target.value
                                                           })} fullWidth/>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>}
                                {(viewMode === MODE_VIEW_COMPILE || viewMode === MODE_VIEW_READONLY) &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant={"subtitle2"} align={"center"}>
                                            Raccolta flussi - {flow.period} {flow.year}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={"h6"} align={"center"}>{flow.flu_titolo}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={"subtitle1"}
                                                    align={"center"}>{flow.flu_descrizione}</Typography>
                                    </Grid>
                                </Grid>}
                            </Grid>
                            {viewMode !== MODE_VIEW_COMPILE && viewMode !== MODE_VIEW_READONLY &&
                            <Grid item xs={12}>
                                <AppBar color={'default'} position={"sticky"}>
                                    <Toolbar variant={"dense"}>
                                        <Grid container>
                                            <Grid item>
                                                <Typography variant={"body1"}>Composizione form</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify={"flex-end"}>
                                            <Grid item>
                                                <Tooltip title={'Visualizza anteprima'}>
                                                    <IconButton edge={"end"} color={"primary"}
                                                                onClick={toggleView}>
                                                        <Icon>{viewMode === MODE_VIEW_CONFIGURATION ? 'visibility' : 'visibility_off'}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title={'Aggiungi gruppo'}>
                                                    <IconButton edge={"end"} color={"primary"}
                                                                onClick={addSection}>
                                                        <Icon>add</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Toolbar>
                                </AppBar>
                            </Grid>}
                            {sections.map((section, index) =>
                                <Grid item xs={12} key={index}>
                                    <SectionComponent removeSection={removeSection} removeDuplicates={removeDuplicates}
                                                      setInputList={setInputList} viewMode={viewMode}
                                                      save={saveInputConfig} section={section}/>
                                </Grid>)}
                            {viewMode !== MODE_VIEW_READONLY &&
                            <Grid item xs={12}>
                                <Grid container justify={"center"} spacing={4}>
                                    {viewMode !== MODE_VIEW_COMPILE &&
                                    <Grid item xs={4}>
                                        <Button className={classes.btn_validate} variant={"outlined"}
                                                color={"primary"} fullWidth={true} type={'submit'}>valida</Button>
                                    </Grid>}
                                    <Grid item xs={4}>
                                        <Button className={classes.btn_validate} variant={"contained"}
                                                disabled={!flow || sections.some(({inputList}) => inputList.some(({valid, isEmpty}) => !valid && !isEmpty))}
                                                type={viewMode === MODE_VIEW_COMPILE ? 'submit' : 'button'}
                                                color={"primary"} fullWidth={true}
                                                onClick={viewMode === MODE_VIEW_COMPILE ? () => {
                                                } : save}>salva</Button>
                                    </Grid>
                                </Grid>
                            </Grid>}
                            {viewMode !== MODE_VIEW_COMPILE &&
                            <Grid item xs={12}>
                                {(!flow || !flow.flu_titolo || !flow.flu_descrizione) &&
                                <Typography variant={"caption"} color={"error"} align={"center"} component={'div'}>
                                    Inserisci il titolo e descrizione del flusso
                                </Typography>}
                                {sections.some(({inputList}) => inputList.some(({valid, isEmpty}) => !valid && !isEmpty)) &&
                                <Typography variant={"caption"} color={"error"} align={"center"} component={'div'}>
                                   Alcuni form necessitano una configurazione
                                </Typography>}
                            </Grid>}
                        </Grid>
                    </form>
                </FormContext>
            </Grid>
        </Grid>
    );
};

FormBuilderComponent.propTypes = {
    setSections: PropTypes.func.isRequired,
    sections: PropTypes.array.isRequired,
    flow: PropTypes.object.isRequired,
    setFlow: PropTypes.func.isRequired,
    defaultViewMode: PropTypes.number,
    saveForm: PropTypes.func.isRequired
};
export default withRouter(FormBuilderComponent);
