import React from 'react';
import AccountComponent from "../../components/CRUD/account/account.component";
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageTitleComponent from "../../components/ui/page-title/page-title.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import AuthService from "../../services/auth.service";
import {Typography} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";

const AccountCreationPage = ({history}) => {
    let {acc_max_accounts} = AuthService.user.utente.accounts;
    const userAccounts = AuthService.getCompanies().length;
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageTitleComponent title={'Aggiungi utenza'}
                                            subtitle={`Account Attivi: ${userAccounts} - Account ancora disponibili: ${acc_max_accounts - userAccounts}`}/>
                    </Grid>
                    {acc_max_accounts - userAccounts <= 0 &&
                    <Grid item>
                        <Typography align={"center"} variant={"h6"} color={"error"}>
                            Limite di Utenze raggiunto
                        </Typography>
                        <Typography align={"center"} variant={"subtitle1"} color={"error"}>
                            Contattare il vostro agente di vendita per modificare le licenze associate al tuo account
                        </Typography>
                    </Grid>}
                    {acc_max_accounts - userAccounts > 0 &&
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Aggiungi i dati relativi alla nuova utenza`}/>
                    </Grid>}
                    {acc_max_accounts - userAccounts > 0 &&
                    <Grid item>
                        <AccountComponent mode={"create"}/>
                    </Grid>}
                    <Grid item container justify={"center"}>
                        <Grid item>
                            <Button onClick={()=> history.goBack()}>
                                indietro
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(AccountCreationPage);
