import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    box: {
        background: '#FAFAFA',
        margin: 0
    }
}));
const CtaBoxComponent = ({title, actions}) => {
    const classes = useStyles();
    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid item xs={12}>
                <Typography variant={"subtitle1"}>
                    {title}
                </Typography>
            </Grid>
            <Grid item container spacing={2} className={classes.box}>
                {actions.map(({label, handler}, index)=> (
                    <Grid item key={index}>
                        <Fab variant={"extended"} onClick={handler} size={"small"} color={"primary"}>
                            <Typography variant={"caption"}>
                                {label}
                            </Typography>
                        </Fab>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

CtaBoxComponent.defaultProps = {
    actions: []
};

CtaBoxComponent.propTypes = {
    title: PropTypes.string.isRequired,
    actions: PropTypes.array.isRequired
};

export default CtaBoxComponent;
