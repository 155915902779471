import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slider from "@material-ui/core/Slider";

const style = makeStyles(theme => ({
    templateWrapper: {
        cursor: 'grab',
        width: '200px'
    },
    template: {
        padding: theme.spacing(1)
    }
}));

const SliderTemplate = props => {
    const classes = style();
    const handleDragStart = (e) => {
        e.dataTransfer.setData('new-template', 'slider');
    };
    return (
        <div className={classes.templateWrapper} draggable onDragStart={handleDragStart}
             onDragOver={(e) => e.preventDefault()}>
            <Grid container direction={"column"} className={classes.template}>
                <Grid item>
                    <Typography variant={"subtitle2"} gutterBottom>Slider</Typography>
                </Grid>
                <Grid item>
                    <Slider disabled={true}/>
                </Grid>
            </Grid>
        </div>
    );
};

SliderTemplate.propTypes = {};

export default SliderTemplate;
