import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

const ValidButtonConfiguration = ({isValid, configure}) => {
    return (
        <Button variant={"outlined"} fullWidth={true}
                color={!isValid ? 'secondary' : 'primary'} onClick={configure}>
            <Grid container justify={"center"} alignItems={"center"} spacing={1}>
                <Grid item>
                    {isValid &&
                    <Icon color={"primary"} style={{margin: 'auto', display: 'block'}}>done</Icon>}
                    {!isValid &&
                    <Icon color={"error"} style={{margin: 'auto', display: 'block'}}>warning</Icon>}
                </Grid>
                <Grid item>
                    <Typography variant={"button"}>
                        {isValid ? 'Configurazione completata' : 'Configura'}
                    </Typography>
                </Grid>
            </Grid>
        </Button>);
};

ValidButtonConfiguration.propTypes = {
    isValid: PropTypes.bool.isRequired,
    configure: PropTypes.func.isRequired
};

export default ValidButtonConfiguration;
