import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const defaultTheme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    palette: {
        toolbar: {
            light: '#154B44',
            main: '#154B44',
            dark: '#154B44',
            contrastText: '#fff',
        },
        actions:{
            hover: '#154B44'
        },
        secondary: {
            light: '#154B44',
            main: '#154B44',
            hover:'#154B44',
            dark: '#154B44',
            contrastText: '#fff',
        },
        primary: {
            light: '#154B44',
            main: '#154B44',
            dark: '#154B44',
            contrastText: '#fff',
        },
    },
});
