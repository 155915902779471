import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import {handleModal} from "../../../redux/actions/modal.action";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {ReferentApi} from "../../../services/api.service";
import ReferentComponent from "../../CRUD/referent/referent.component";
import _ from "lodash";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {searchFilter} from "../../../constant";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";

const referentAPI = new ReferentApi();
const columns = [
    {id: 'ref_referente', label: 'Nome', colSpan: 1, sortable: true},
    {id: 'ref_email', label: 'Email', colSpan: 2, sortable: true},
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {

        overflow: 'auto',
    },
});

const ReferentsTable = ({dispatch, api, filterBy}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [referents, setReferents] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('ref_referente');
    const [filter, setFilter] = React.useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const {referenti} = await referentAPI.query();
            setReferents(referenti);
            setTableRows(referenti);
        };
        fetchData();
    }, [api.fetch]);
    useEffect(() => {
        setPage(0);
        setTableRows(referents.filter(searchFilter(filter, columns)))
    }, [filter,referents]);

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };
    const updateProtocol = (referent) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Modifica referente',
            content: <ReferentComponent mode={'update'} referent={referent}/>,
        }));
    };

    const deleteProtocol = (referent) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Elimina referente',
            content: <ReferentComponent mode={'delete'} referent={referent}/>,
        }));
    };

    return (
        <Paper className={classes.root}>
            <Toolbar>
                <TextField label={'Cerca'} fullWidth={true} style={{margin: '1rem 1rem 2rem'}}
                           onChange={(e) => setFilter(e.target.value)}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <Icon>search</Icon>
                                   </InputAdornment>
                           }}/>
            </Toolbar>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(
                                ({id, align, sortable, colSpan, label}) => (
                                    <TableCell key={id} align={align} colSpan={colSpan} style={{zIndex: 3}}>
                                        {sortable ?
                                            <TableSortLabel
                                                active={orderBy === id}
                                                direction={order}
                                                onClick={createSortHandler(id)}>
                                                {label}
                                            </TableSortLabel>
                                            : label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.orderBy(tableRows, [orderBy], [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(
                                (referent, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={index}>
                                            <TableCell align={"left"}>
                                                {referent.ref_referente}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {referent.ref_email}
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                <IconButton onClick={updateProtocol(referent)}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={deleteProtocol(referent)}>
                                                    <Icon color={"error"}>delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        {!referents.length &&
                        <TableRow hover tabIndex={-1}>
                            <TableCell align={"center"} colSpan={columns.length}>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    nessun dato
                                </Typography>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={(e, newPage) => setPage(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>);
};

ReferentsTable.propTypes = {
    filterBy: PropTypes.object
};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};
export default connect(mapStateToProps)(ReferentsTable);
