import React, { useEffect, useState } from "react";
import { RiskAreaApi } from "../../../services/api.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Grid, IconButton, Icon } from "@material-ui/core";
import { connect } from "react-redux";
import { handleModal } from "../../../redux/actions";
import ProtocolliModal from "../../protocolli-modal/ProtocolliModal";
import { red } from "@material-ui/core/colors";

const RiskArea = new RiskAreaApi();
const RiskAreaSelect = ({
  table = false,
  dispatch,
  label,
  setArea,
  api,
  area,
  reato,
  disabled,
  refetch,
  companyId,
  funzione,
}) => {
  const [fetchedItems, setFetchedItems] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await RiskArea.queryV2();

      setFetchedItems(data);
    };
    fetchData();
  }, [api.fetch]);

  const showInfo = (area) => () => {

    dispatch(
      handleModal({
        open: true,
        maxWidth: "lg",
        title: area.are_titolo,
        content: <ProtocolliModal id={area.uuid} />,
      })
    );
  };

  const getOptionsArray = () => {
    let arrayOfAreas = fetchedItems;
    if (funzione) {
      arrayOfAreas = arrayOfAreas.filter((val) => {
        if (val.funzioni.length > 0 ) {
          for ( let n = 0; n < val.funzioni.length; n++ ) {
            if ( parseInt(val.funzioni[n].id) === parseInt(funzione.id) ) {
              return true;
            }
          }
        }
      });
    }
    if ( reato ) {
      arrayOfAreas = arrayOfAreas.filter((val) => {
        if (val.reati.length > 0 ) {
          for ( let n = 0; n < val.reati.length; n++ ) {
            if ( parseInt(val.reati[n].id) === parseInt(reato.id) ) {
              return true;
            }
          }
        }
      });
    }
    if (reato?.id == 0 || table) {
      return [
        ...arrayOfAreas.map((item, index) => ({
          title: item.are_titolo,
          id: item.id,
          compiled: item.compiled,
          ...item,
        })),
      ];
    } else {
      return [
        { title: "Tutte le aree", id: 0 },
        ...arrayOfAreas.map((item, index) => ({
          title: item.are_titolo,
          id: item.id,
          compiled: item.compiled,
          ...item,
        })),
      ];
    }
  };

  return (
    <Grid container style={{ justifyContent: table ? "flex-end" : "center" }}>
      <Grid item xs={10}>
        <Autocomplete
          disabled={disabled}
          id="combo-box-demo"
          options={getOptionsArray()}
          getOptionLabel={(option) => option.title}
          style={{ width: "100%" }}
          variant="outlined"
          value={area}
          onChange={(event, newValue) => {
            setArea(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label={label} variant="outlined" />
          )}
        />
      </Grid>

      {!table && (
        <Grid item xs={2}>
          {area && area.id != 0 && (
            <IconButton style={{ height: "100%" }} onClick={showInfo(area)}>
              <Icon>info</Icon>
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ fetchApiReducer }) => {
  return {
    api: fetchApiReducer,
  };
};
export default connect(mapStateToProps)(RiskAreaSelect);
