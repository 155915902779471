import {TOAST_HANDLER} from "../types";

export const handleToast = ({open = true, message, autoHideDuration = 5000}) => {
    return {
        type: TOAST_HANDLER,
        open,
        message,
        autoHideDuration
    }
};
