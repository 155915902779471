import React from "react";
import {LABEL_CONFIG} from "../form-item.class";
import ChecksGroupCompile from "./checks-group.compile";
import ChecksGroupTemplate from "./checks-group.template";
import EmptyItemComponent from "../empty-item/empty-item.component";
import ChecksGroupConfigure from "./checks-group.configure";
import {
    MODE_VIEW_COMPILE,
    MODE_VIEW_CONFIGURATION,
    MODE_VIEW_PREVIEW,
    MODE_VIEW_READONLY,
    MODE_VIEW_TEMPLATE
} from "../../../index.constants";
import PropTypes from "prop-types";

const ChecksGroupComponent = (props) => {
    const {viewMode, save} = props;
    /*constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            ,
            ...props
        };
    }*/
    const saveConfiguration = (rows, checksGroup, valid) => {
        let wrapper = {};
        rows.forEach(({name, value}) => {
            if (name === 'required' && value === '') {
                value = false;
            }
            wrapper = {
                ...wrapper,
                [name]: value
            }
        });
        save({
            ...wrapper,
            checksGroup,
            valid
        });
    };

    const config = () => {
        return [
            LABEL_CONFIG(props)
        ]
    };

    /* const defaultValues = () => {
         if (this.state.checksGroup && this.state.checksGroup.length) {
             this.state.checksGroup.forEach(
                 ({name, initialValue}) => {
                     this.state.values = {
                         ...this.state.values,
                         [name]: initialValue
                     }
                 }
             );
         }
         return this.state.values;
     };*/
    const render = () => {
        switch (viewMode) {
            case MODE_VIEW_PREVIEW:
            case MODE_VIEW_COMPILE:
                return <ChecksGroupCompile {...props}/>;
            case MODE_VIEW_READONLY:
                return <ChecksGroupCompile {...props} disabled={true}/>;
            case MODE_VIEW_TEMPLATE:
                return <ChecksGroupTemplate/>;
            case MODE_VIEW_CONFIGURATION:
                return <ChecksGroupConfigure {...props} defaultConfig={config()}
                                             onSave={saveConfiguration}/>;
            default:
                return <EmptyItemComponent/>;
        }
    };

    return render();
};

ChecksGroupComponent.propTypes = {
    save: PropTypes.func.isRequired,
    viewMode: PropTypes.number.isRequired
};

export default ChecksGroupComponent;
