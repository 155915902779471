import React, { useEffect, useState } from "react";
import {CompanyApiNew, FunzioniMatriceApi} from "../../../services/api.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import AuthService from "../../../services/auth.service";

const FunzioniAmministrative = new FunzioniMatriceApi();
const FunctionsSelect = ({ label, setFunzione, funzione, api }) => {
  const [fetchedItems, setFetchedItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await FunzioniAmministrative.query();
      setFetchedItems(data);
    };
    fetchData();
  }, [api.fetch]);

  return (
      <Grid container style={{ justifyContent: "flex-end" }}>
        <Grid item xs={10}>
          <Autocomplete
              id="combo-box-demo"
              value={funzione}
              options={[
                ...fetchedItems.map((item, index) => ({
                  title: item.fun_funzione,
                  id: item.id,
                  compiled: item.compiled,
                })),
              ]}
              onChange={(event, newValue) => {
                setFunzione(newValue);
              }}
              getOptionLabel={(option) => option.title}
              style={{ width: "100%" }}
              renderInput={(params) => (
                  <TextField {...params} label={label} variant="outlined" />
              )}
          />
        </Grid>
      </Grid>
  );
};

const mapStateToProps = ({ fetchApiReducer }) => {
  return {
    api: fetchApiReducer,
  };
};
export default connect(mapStateToProps)(FunctionsSelect);
