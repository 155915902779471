import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const style = makeStyles(theme => ({
    templateWrapper: {
        cursor: 'grab',
        width: '200px'
    },
    template: {
        padding: theme.spacing(1)
    }
}));

const RadioGroupTemplate = props => {
    const classes = style();
    const handleDragStart = (e) => {
        e.dataTransfer.setData('new-template', 'radio-group');
    };
    return (
        <div className={classes.templateWrapper} draggable onDragStart={handleDragStart}
             onDragOver={(e) => e.preventDefault()}>
            <Grid container direction={"column"} className={classes.template}>
                <Grid item>
                    <Typography variant={"subtitle2"} gutterBottom>Radio Group</Typography>
                </Grid>
                <Grid item>
                    <RadioGroup>
                        <FormControlLabel label={'quick select'} control={<Radio/>} disabled={true}/>
                    </RadioGroup>
                </Grid>
            </Grid>
        </div>
    );
};

RadioGroupTemplate.propTypes = {};

export default RadioGroupTemplate;
