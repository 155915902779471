import React from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {handleModal} from "../../redux/actions";
import {connect} from "react-redux";
import ProtocolsTable from "../../components/atomic-tables/protocols/protocols.table";
import ProtocolComponent from "../../components/CRUD/protocol/protocol.component";

const ProtocolsPage = ({dispatch}) => {
    const createProtocol = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'xl',
            title: 'Aggiungi protocollo',
            content: <ProtocolComponent mode={'create'}/>,
        }));
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Protocolli'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"space-between"} alignItems={"center"} spacing={2} style={{margin: 0}}>
                            <Grid item xs>
                                <Typography variant={"subtitle2"}>Elenco protocolli</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={createProtocol}>Aggiungi protocollo</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <ProtocolsTable/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default connect()(ProtocolsPage);
