import React, { useState, useEffect, useMemo } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import grey from "@material-ui/core/colors/grey";
import {Link as RouteLink, withRouter} from "react-router-dom";
import {ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AuthService from "../../services/auth.service";
import {SITE_MAP, ENV} from "../../constant";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import moment from 'moment'
import {FeedRssApi} from "../../services/api.service";

const FeedRss = new FeedRssApi();

const useStyles = makeStyles(theme => ({
    whiteText: {
        color: 'white'
    },
    lightGreen:{
        color: '#1f6a60'
    },
    mainButton: {
        width: '240px'
    },
    section: {
        height: '20vh',
        position: 'relative',
        backgroundSize: 'cover!important'
    },
    section_1: {
        //paddingTop: theme.spacing(4),
        height: '64px!important',
        background: 'url(assets/images/skyscrapers.jpeg) no-repeat center center'
    },
    section_2: {
        background: '#e2e2e2'
    },
    shadowLayer: {
        background: '#474B57',
        opacity: .5,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0
    },
    iframe: {
        width: '100%',
        height: '50vh'
    },
    section_3: {
        background: grey[50],
        height: '100%',
        padding: theme.spacing(8)
    },
    section_divider: {
        background: grey[50],
        height: 10,
    },
    whiteAvatar: {
        backgroundColor: 'white',
    },
    card: {
        height: '100%',
    },
    cardHeader: {
        paddingBottom: 0
    },
    cardTitle:{
        fontWeight: 'bold',
    },
    cardContent: {
        overflow: 'hidden',
        paddingTop: 0
    },
    listItemIcon: {
        minWidth: 30
    }
}));


function HomePage({ history }) {
    let MyHome = SITE_MAP;
    let myHomeFiltered = [];

     for(let i = 0; i<MyHome.length; i++) {
         if (MyHome[i].expansion.length > 0) {
             myHomeFiltered = [];
             for (let z = 0; z < MyHome[i].expansion.length; z++) {
                 if (MyHome[i].expansion[z].permission !== undefined && localStorage.getItem('permessi_utente').includes(MyHome[i].expansion[z].permission)) {
                     //console.log("il permesso " + MyHome[i].expansion[z].permission + " è stato trovato");
                     myHomeFiltered.push(MyHome[i].expansion[z]);
                 } else {
                    //console.log("il permesso " + MyHome[i].expansion[z].permission + " non è stato trovato");
                 }
             }
             MyHome[i].expansion = myHomeFiltered;
         }
     }
     const renderSpecificExportGrid = () => {
         if (localStorage.getItem('permessi_utente').includes('export.dati.flussi') || localStorage.getItem('permessi_utente').includes('odv.referente')) {
             return (
                 <ListItem button onClick={() => {
                     window.open(`/s231-excel.php?env=${ENV}&elab=funzioni-flussi&companyID=${AuthService.company.uuid}`);
                 }}>
                     <ListItemIcon className={classes.listItemIcon}>
                         <Icon fontSize="small">label_important</Icon>
                     </ListItemIcon>
                     <ListItemText primary={<Typography className={classes.lightGreen}>Funzioni-Referenti-Flussi
                         OdV</Typography>}/>
                 </ListItem>
             )
         }
     }
    const renderExportGrid = () => {
        if (localStorage.getItem('permessi_utente').includes('export.dati')) {
            return (
                <Grid item xs={12} lg={4}>
                            <Card className={classes.card}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title={'Export Dati'}
                                    titleTypographyProps={{ variant: 'subtitle1' }}
                                    avatar={<Avatar className={classes.whiteAvatar}>
                                        <Icon color={'secondary'}>{'import_export'}</Icon>
                                    </Avatar>} />
                                <CardContent className={classes.cardContent}>
                                    <List>
                                        <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati&companyID=${AuthService.company.uuid}`); } }>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <Icon fontSize="small">label_important</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography className={classes.lightGreen}>Reati 231</Typography>} />
                                        </ListItem>
                                        <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-aree&companyID=${AuthService.company.uuid}`); } }>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <Icon fontSize="small">label_important</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography className={classes.lightGreen}>Aree di rischio – Reati 231</Typography>} />
                                        </ListItem>
                                        <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-protocolli&companyID=${AuthService.company.uuid}`); } }>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <Icon fontSize="small">label_important</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography className={classes.lightGreen}>Aree di rischio – Protocolli</Typography>} />
                                        </ListItem>
                                        <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-indicatori&companyID=${AuthService.company.uuid}`); } }>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <Icon fontSize="small">label_important</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography className={classes.lightGreen}>Aree di rischio – Indicatori comportamentali</Typography>} />
                                        </ListItem>
                                        <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-flussi&companyID=${AuthService.company.uuid}`); } }>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <Icon fontSize="small">label_important</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography className={classes.lightGreen}>Aree di rischio – Flussi OdV</Typography>} />
                                        </ListItem>
                                        {renderSpecificExportGrid()}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
            );
        }
        if (localStorage.getItem('permessi_utente').includes('odv.referente')) {
            return (
                <Grid item xs={12} lg={4}>
                    <Card className={classes.card}>
                        <CardHeader
                            className={classes.cardHeader}
                            title={'Export Dati'}
                            titleTypographyProps={{ variant: 'subtitle1' }}
                            avatar={<Avatar className={classes.whiteAvatar}>
                                <Icon color={'secondary'}>{'import_export'}</Icon>
                            </Avatar>} />
                        <CardContent className={classes.cardContent}>
                            <List>
                                <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-flussi&companyID=${AuthService.company.uuid}`); } }>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <Icon fontSize="small">label_important</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography className={classes.lightGreen}>Aree di rischio – Flussi OdV</Typography>} />
                                </ListItem>
                                {renderSpecificExportGrid()}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            );
        }
    };
    const cardContentLink = (label, link = false, externalLink = false, permessi = false) => {
        if (!permessi || localStorage.getItem('permessi_utente').includes(permessi)) {
            return (
                <ListItem key={link ? link : externalLink} button onClick={() => { link ? history.push(link) : window.open(`${externalLink}?uuid=${AuthService.user.utente.uuid}&env=${ENV}`, '_blank'); } }>
                    <ListItemIcon className={classes.listItemIcon}>
                        <Icon fontSize="small">label_important</Icon>
                    </ListItemIcon>
                    <ListItemText primary={<Typography className={classes.lightGreen}>
                        {label}
                    </Typography>} />
                </ListItem>
            );
        }
    }
    const classes = useStyles();
    const [news, setNews] = useState([]);
    const fetchFeed = async () => {
        const { feed } = await FeedRss.query();
        await setNews(feed.items.slice(0, 3));
    };
    useEffect(() => {
        fetchFeed();
    }, []);
    //quifilter(({label})
    const newsSection = useMemo(() => {
        if (news.length === 0)
            return (
                <Grid container justify="center">
                    <Grid item xs={12} style={{ marginTop: 10, marginBottom: 50, textAlign: 'center' }}>
                        <Typography variant={"body1"}>
                            Non sono presenti news in evidenza al momento
                        </Typography>
                    </Grid>
                </Grid>
            );
        const newsList = [];
        news.map((item, i) => {
            newsList.push(
                <Grid key={i} item xs={12} lg={3} className={classes.homeCards}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container justify="center">
                                <Grid item xs={12} md={10}>
                                    <Typography gutterBottom variant="subtitle1">Notizia del  {moment(item.isoDate).format("DD MMM YYYY")}</Typography>
                                    <Typography gutterBottom component="p" className={classes.cardTitle}>
                                        {item.title}
                                    </Typography>
                                    <Divider variant="middle" style={{ marginTop: 15, marginBottom: 15 }} />
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {item.contentSnippet}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid container justify="center" alignItems="flex-end">
                                <Grid item xs={12} style={{ marginTop: 30, textAlign: 'center' }}>
                                    <Button variant="contained" color="primary" href={item.link} target="_blank">
                                        Leggi Articolo
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            );
        });
        return newsList;
    }, [news]);
    return (
        <Grid container direction={"column"}>
            <Grid item className={[classes.section, classes.section_1].join(' ')}>
                <div className={classes.shadowLayer} />
            </Grid>
            <Grid item>
                <Grid container justify="center" className={[classes.section, classes.section_3].join(' ')}>
                    <Grid item container md={10} xs={12} justify={"space-around"} alignItems="stretch" spacing={2}>
                        {MyHome.filter(({ label }) => label !== 'Home').map(
                            ({ label, path, expansion, icon }) => (
                                (expansion.length) ?
                                    expansion.map(({ path, label, items, icon }) => (
                                        <Grid key={path} item xs={12} lg={4}>
                                            <Card className={classes.card}>
                                                <CardHeader
                                                    className={classes.cardHeader}
                                                    title={label}
                                                    titleTypographyProps={{ variant: 'subtitle1' }}
                                                    avatar={icon ?
                                                        <Avatar className={classes.whiteAvatar}>
                                                            <Icon color={'secondary'}>{icon}</Icon>
                                                        </Avatar>
                                                        : null} />
                                                <CardContent className={classes.cardContent}>
                                                    <List dense>
                                                        {items.map(
                                                            ({ label, link, externalLink, permessi }, i) =>
                                                            cardContentLink(label, link, externalLink, permessi)
                                                        )}
                                                    </List>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))
                                    :
                                    <Grid key={path} item xs={12} lg={4}>
                                        <Card className={classes.card}>
                                            <CardHeader
                                                className={classes.cardHeader}
                                                title={label}
                                                titleTypographyProps={{ variant: 'subtitle1' }}
                                                avatar={icon ?
                                                    <Avatar className={classes.whiteAvatar}>
                                                        <Icon color={'secondary'}>{icon}</Icon>
                                                    </Avatar>
                                                    : null} />
                                                <CardContent className={classes.cardContent}>
                                                    <List>
                                                        <ListItem>
                                                            <ListItemIcon className={classes.listItemIcon}>
                                                                <Icon fontSize="small">label_important</Icon>
                                                            </ListItemIcon>
                                                            <ListItemText primary={<Link component={RouteLink} color={"secondary"}
                                                                to={path}>
                                                                {label}
                                                            </Link>} />
                                                        </ListItem>
                                                    </List>
                                                </CardContent>
                                        </Card>
                                    </Grid>
                            ))}
                            { renderExportGrid() }

                    </Grid>
                </Grid>
                <Grid container justify="center" className={classes.section_divider}>
                    <Grid item xs={6}><Divider variant="middle" /></Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container justify={"center"} className={[classes.section_3].join(' ')} spacing={2} style={{ width: '100%' }} alignItems="stretch">
                    <Grid item xs={12} style={{ marginBottom: 40 }}>
                        <Typography align={"center"} variant={"h6"}>231: Novità e approfondimenti</Typography>
                    </Grid>
                    {newsSection}
                    {news.length > 0 && (
                        <Grid container justify="center">
                            <Grid item xs={12} style={{ marginTop: 60, textAlign: 'center' }}>
                                <Button variant="contained" color="primary" href={'/news'}>
                                                            Vedi tutti gli articoli
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>

        </Grid>
    );
}

HomePage.propTypes = {};

export default withRouter(HomePage);
