import React from 'react';
import StepperComponent from "../../components/ui/stepper/stepper.component";
import {AccountActivationApi} from "../../services/api.service";
import {connect} from "react-redux";
import {handleToast} from "../../redux/actions";
import AccountActivationSteps from "../../components/steps/account-activation/account-activation.steps";
import {withRouter} from "react-router-dom";

const ActivateAccount = new AccountActivationApi();


const AccountActivationPage = ({dispatch, history}) => {
    const steps = ['Dati account', 'Licenze e servizi', 'Riepilogo ordine'];
    const save = async (data) => {
        try {
            await ActivateAccount.create(data);
            dispatch(handleToast({message: 'Account attivato con successo!'}));
            history.push('/gestione-account/conferma-attivazione')
        } catch (message) {
            dispatch(handleToast({message}))
        }
    };
    return (
        <StepperComponent steps={steps} defaultStep={0} title={'Gestione account - Attivazione'} onComplete={save}>
            <AccountActivationSteps/>
        </StepperComponent>
    );
};

export default connect()(withRouter(AccountActivationPage));
