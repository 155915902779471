export const MODE_VIEW_TEMPLATE = 1;
export const MODE_VIEW_CONFIGURATION = 2;
export const MODE_VIEW_PREVIEW = 3;
export const MODE_VIEW_COMPILE = 4;
export const MODE_VIEW_READONLY = 5;
export const styleGrid = (viewMode, index, cols, rows, style = {}) => {
    if (viewMode === MODE_VIEW_COMPILE) {
        return {
            ...style,
            border: 'none'
        }
    }

    index = index + 1;
    const y = index % cols;
    // const x = index % rows;
    //console.log(index, 'y', y, 'x', x);
    style = {
        borderRight: '1px dashed gray',
        borderBottom: '1px dashed gray',
        ...style
    };
    if (y === 0 && index >= cols) {
        //console.log(`item ${index} is the last of row`);
        style = {
            ...style,
            borderRight: 'none'
        }
    }
    // console.log('index > (rows * cols) - cols', index, (rows * cols), cols)
    if (index > (rows * cols) - cols || rows === 1) {
        //console.log(`item ${index} is the last of col`);
        style = {
            ...style,
            borderBottom: 'none'
        }
    }
    return style;
};
