import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { connect } from "react-redux";
import { handleModal } from "../../../redux/actions/modal.action";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
const GenericModalComponent = ({
  modal: { open, title, content, actions, maxWidth, noActions },
  dispatch,
}) => {
  const classes = useStyle();
  const handleClose = () => {
    dispatch(
      handleModal({
        open: false,
      })
    );
  };
  const onClose = () => {
    dispatch(handleModal({ open: false }));
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={maxWidth || "md"}
      fullWidth
    >
      {title && (
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent dividers>{content}</DialogContent>
      {!noActions && (
        <DialogActions>
          {actions ? (
            actions
          ) : (
            <Grid container justify={"center"}>
              <Grid item>
                <Button onClick={onClose}>chiudi</Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

GenericModalComponent.propTypes = {};

const mapStateToProps = ({ modalReducer: { modal } }) => {
  return modal;
};

export default connect(mapStateToProps)(GenericModalComponent);
