import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Input,
  Chip,
  FormControl,
  Select,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { RiskAreaApi } from "../../services/api.service";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from "@material-ui/core/Icon";

const RiskArea = new RiskAreaApi();

const useStyles = makeStyles(() => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    "& .MuiChip-label": {
      textOverflow: "ellipsis",
      maxWidth: "400px",
      whiteSpace: "nowrap",
      display: "block",
      overflow: "hidden",
    },
    table: {}
  },
}));

const ProtocolliModal = ({ api, id, title }) => {
  const [fetchedItems, setFetchedItem] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await RiskArea.getProtocols(id);
      setFetchedItem(data);
    };
    fetchData();
  }, [api.fetch]);

  console.log("SONO QUI",fetchedItems);
  

  if (!fetchedItems) {
    return (
      <Grid style={{ justifyContent: "center" }} container xs={12}>
        <CircularProgress size={30} />
      </Grid>
    );
  }
  return (
    <Grid container xs={12}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"} align={"center"}>
            {title}
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="protocolli table">
              <TableHead>
                <TableRow>
                  <TableCell>Protocollo</TableCell>
                  <TableCell align="right">Formalizzato</TableCell>
                  <TableCell align="right">Operativo</TableCell>
                  <TableCell align="right">Procedure</TableCell>
                  <TableCell align="right">Segregazione</TableCell>
                  <TableCell align="right">Poteri</TableCell>
                  <TableCell align="right">Tracciabilita</TableCell>
                  <TableCell align="right">Monitoraggio</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fetchedItems && fetchedItems.length > 0 && fetchedItems.map((row) => (
                    <TableRow key={row.uuid}>
                      <TableCell component="th" scope="row">
                        {row.titolo}
                      </TableCell>
                      <TableCell align="center">
                        {row.formalizzato ?
                          <Icon style={{color: '#27ae60'}}>done</Icon> :
                          <Icon color={"error"}>block</Icon>}
                      </TableCell>
                      <TableCell align="center">
                        {row.operativo ?
                          <Icon style={{color: '#27ae60'}}>done</Icon> :
                          <Icon color={"error"}>block</Icon>}
                      </TableCell>
                      <TableCell align="center">
                        {row.procedure ?
                          <Icon style={{color: '#27ae60'}}>done</Icon> :
                          <Icon color={"error"}>block</Icon>}
                      </TableCell>
                      <TableCell align="center">
                        {row.segregazione ?
                          <Icon style={{color: '#27ae60'}}>done</Icon> :
                          <Icon color={"error"}>block</Icon>}
                      </TableCell>
                      <TableCell align="center">
                        {row.poteri ?
                          <Icon style={{color: '#27ae60'}}>done</Icon> :
                          <Icon color={"error"}>block</Icon>}
                      </TableCell>
                      <TableCell align="center">
                        {row.tracciabilita ?
                          <Icon style={{color: '#27ae60'}}>done</Icon> :
                          <Icon color={"error"}>block</Icon>}
                      </TableCell>
                      <TableCell align="center">
                        {row.monitoraggio ?
                          <Icon style={{color: '#27ae60'}}>done</Icon> :
                          <Icon color={"error"}>block</Icon>}
                      </TableCell>
                    </TableRow>
                ))}
                {(!fetchedItems || fetchedItems.length < 1) &&
                    <TableRow key={'no-protocols'}>
                      <TableCell colSpan={3} component="th" scope="row" align={'start'}>
                        Non sono stati trovati protocolli attivi collegati
                      </TableCell>
                    </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ fetchApiReducer }) => {
  return {
    api: fetchApiReducer,
  };
};

export default connect(mapStateToProps)(ProtocolliModal);
