import React from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {handleModal} from "../../redux/actions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AuthService from "../../services/auth.service";
import DelegatedAdminsTable from '../../components/atomic-tables/delegated-admins/delegated-admins.table';
import DelegatedAdminComponent from '../../components/CRUD/delegated-admin/delegated-admin.component';

const DelegatedAdminsPage = ({dispatch}) => {
    const createAdmin = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Nuovo utente admin delegato',
            content: <DelegatedAdminComponent mode={'create'}/>,
        }));
    };

    const associateAdmin = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Associa utente esiste come admin delegato',
            content: <DelegatedAdminComponent mode={'associate'}/>,
        }));
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Gestisci gli Utenti Admin delegati dell'azienda ${AuthService.company.com_name}`}/>
                    </Grid>
                    <Grid item container alignItems={"center"} spacing={2}>
                        <Grid item xs>
                            <Typography variant={"h6"}>Elenco Utenti Admin delegati</Typography>
                        </Grid>
                        <Grid item container alignItems={"center"} spacing={2}>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={associateAdmin}>Associa esistente come Admin delegato</Button>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={createAdmin}>Nuovo Utente Admin delegato</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DelegatedAdminsTable/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};


export default connect()(withRouter(DelegatedAdminsPage));
