import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import AutoCompleteComponent from "../../components/ui/auto-complete/auto-complete.component";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import {FlowApi, RiskAreaApi, RiskAreaFlussiApi} from "../../services/api.service";
import Fab from "@material-ui/core/Fab";
import {handleToast} from "../../redux/actions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import orderBy from "lodash/orderBy";

const RiskArea = new RiskAreaApi();
const RiskAreaFlussiCall = new RiskAreaFlussiApi();
const Flow = new FlowApi();
const MatrixFlowRiskAreaPage = ({dispatch}) => {
    const [flows, setFlows] = useState([]);
    const [riskAreas, setRiskAreas] = useState([]);
    const [filter, setFilter] = React.useState();
    useEffect(
        () => {
            const fetchData = async () => {
                const aree_rischio = await RiskAreaFlussiCall.query();
                let {flussi} = await Flow.query();
                flussi = flussi.map(
                    ({uuid, flu_titolo}) => {
                        return {
                            label: flu_titolo,
                            value: uuid
                        }
                    });
                setFlows(orderBy(flussi, ['label'], ['asc']));
                setRiskAreas(aree_rischio.filter(area => filter ? area.are_titolo.toLowerCase().search(filter.toLowerCase()) !== -1 : true));

            };
            fetchData();
        }, [filter]
    );
    const updateRiskAreas = (riskAreaId) => (flows) => {
        setRiskAreas([
            ...riskAreas.map((area) => {
                if (area.uuid === riskAreaId) {
                    area.aree_rischio_flussi = flows || [];
                }
                return area;
            })
        ]);
    };
    const save = async () => {
        try {
            await Promise.all(await riskAreas.map(
                async ({aree_rischio_flussi, uuid}) => {
                    await RiskArea.updateFlows({
                        uuid,
                        flussi: aree_rischio_flussi.map(({value, flussi}) => value || (flussi ? flussi.uuid : undefined))
                    })
                })
            );
            dispatch(handleToast({
                message: 'Flussi salvati'
            }));
        } catch (message) {
            dispatch(handleToast({
                message
            }))
        }
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0', position: 'relative'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Matrici - Aree di rischio per Flussi'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Toolbar>
                            <TextField label={'Cerca'} fullWidth={true}
                                    onChange={(e) => setFilter(e.target.value)}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                    }}/>
                        </Toolbar>
                    </Grid>
                    {riskAreas.map(area => {
                        const {uuid, are_titolo, aree_rischio_flussi} = area;
                        return (
                            <Grid item key={uuid} style={{minWidth: '100%'}}>
                                <Card style={{overflow: 'visible'}}>
                                    <CardContent>
                                        <AutoCompleteComponent suggestions={flows}
                                                               defaultValues={aree_rischio_flussi.map(({flussi, label, value}) => {
                                                                   return {
                                                                       label: flussi ? flussi.flu_titolo : label,
                                                                       value: flussi ? flussi.uuid : value
                                                                   }
                                                               })}
                                                               onChange={updateRiskAreas(area.uuid)} multiple
                                                               label={are_titolo} placeholder={'Inserisci flussi'}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                    <Grid item xs={12}>
                        <Fab
                            onClick={save}
                            style={{
                                position: 'fixed',
                                right: '70px',
                                top: '335px',
                                width: '120px',
                            }} aria-label={'Salva'} color={'secondary'} variant={"extended"}>Salva</Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

MatrixFlowRiskAreaPage.propTypes = {};

export default connect()(MatrixFlowRiskAreaPage);
