import React from "react";
import { Grid, Typography, Icon, IconButton } from "@material-ui/core";
import styles from "../../constants/styles";

const RiskResiduoLevel = ({ riskAmount = 1, width }) => {
  return (
    <>
      <div style={styles.wrapperStyle}>
        {riskAmount <= 6.44 && (
          <div style={{ ...styles.movingStyle, width: `${100 - width}%` }}>
            <div style={styles.indicatorText}>
              <Typography
                className={"valutazioneValueNumber"}
                style={{ color: "#4EAD5B" }}
                variant={"h4"}
              >
                {riskAmount.toFixed(2)}
              </Typography>
            </div>
            <div style={styles.indicatorDiv}>
              <Icon>arrow_drop_down</Icon>
            </div>
          </div>
        )}
      </div>
      <div style={styles.wrapperStyle}>
        {riskAmount < 8 && riskAmount > 6.44 && (
          <div style={{ ...styles.movingStyle, width: `${100 - width}%` }}>
            <div style={styles.indicatorText}>
              <Typography
                style={{ color: "#F4BB4E" }}
                className={"valutazioneValueNumber"}
                variant={"h4"}
              >
                {riskAmount.toFixed(2)}
              </Typography>
            </div>
            <div style={styles.indicatorDiv}>
              <Icon>arrow_drop_down</Icon>
            </div>
          </div>
        )}
      </div>
      <div style={styles.wrapperStyle}>
        {riskAmount >= 8 && (
          <div style={{ ...styles.movingStyle, width: `${100 - width}%` }}>
            <div style={styles.indicatorText}>
              <Typography
                className={"valutazioneValueNumber"}
                style={{ color: "#E55C5D" }}
                variant={"h4"}
              >
                {riskAmount.toFixed(2)}
              </Typography>
            </div>
            <div style={styles.indicatorDiv}>
              <Icon>arrow_drop_down</Icon>
            </div>
          </div>
        )}
      </div>
      <Grid container className={"barContainer"} style={{ height: 50 }} xs={12}>
        <Grid
          className={"leftBar"}
          item
          style={styles.containerFirstStyle}
          xs={4}
        >
          <Typography style={styles.textStyle} variant={"subtitle1"}>
            BASSO
          </Typography>
        </Grid>
        <Grid
          item
          className={"centerBar"}
          style={styles.containerMiddleStyle}
          xs={4}
        >
          <Typography style={styles.textStyle} variant={"subtitle1"}>
            MEDIO
          </Typography>
        </Grid>
        <Grid
          item
          className={"rightBar"}
          style={styles.containerLastStyle}
          xs={4}
        >
          <Typography style={styles.textStyle} variant={"subtitle1"}>
            ALTO
          </Typography>
        </Grid>
        <Grid item style={styles.containerBaseStyle} xs={4}>
          <Typography style={styles.textGreyStyle} variant={"subtitle1"}>
            {"<= 6,44"}
          </Typography>
        </Grid>
        <Grid item style={styles.containerBaseStyle} xs={4}>
          <Typography style={styles.textGreyStyle} variant={"subtitle1"}>
            6,44 - 8
          </Typography>
        </Grid>
        <Grid item style={styles.containerBaseStyle} xs={4}>
          <Typography style={styles.textGreyStyle} variant={"subtitle1"}>
            {">= 8"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RiskResiduoLevel;
