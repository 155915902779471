import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import {ProtocolApi} from "../../../services/api.service";
import Checkbox from "@material-ui/core/Checkbox";
import ReactQuill from "react-quill";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {ReactQuillModules, requiredHtml} from "../../../constant";

const Protocol = new ProtocolApi();
const ProtocolComponent = ({dispatch, mode, protocol: {uuid, pro_titolo, pro_priorita, pro_descrizione, aree_rischio_protocolli, pro_attivo, pro_operativo, pro_formalizzato, pro_specifico, pro_procedure, pro_segregazione, pro_poteri, pro_tracciabilita, pro_monitoraggio }}) => {
    const [loading, setLoading] = useState(false);
    const addProtocolInputs = [
        {
            name: 'pro_titolo',
            required: 'campo obbligatorio',
            label: 'Titolo',
            placeholder: 'Inserisci titolo',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete'
        },
        {
            type: 'number',
            name: 'pro_priorita',
            required: 'Inserisci un numero',
            label: 'Ordinamento (maggiore il numero, più alta la priorità di visualizzazione nel M.O.)',
            placeholder: 'Inserisci ordinamento',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete'
        }
    ];
    const protocolForm = useForm({
        mode: 'onSubmit',
        defaultValues: mode !== 'create' ? {
            pro_titolo,
            pro_priorita,
            pro_descrizione,
            area_rischio: aree_rischio_protocolli.uuid
        } : {}
    });
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onProtocolSave = async (data) => {
        try {
            setLoading(true);
            switch (mode) {
                case 'create':
                    await Protocol.create({
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Protocollo creato'
                    }));
                    break;
                case 'update':
                    await Protocol.update({
                        uuid,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Protocollo modificato'
                    }));
                    break;
                case 'delete':
                    await Protocol.delete(uuid);
                    dispatch(handleToast({
                        message: 'Protocollo eliminato'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <FormContext {...protocolForm}>
            <form onSubmit={protocolForm.handleSubmit(onProtocolSave)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm inputs={addProtocolInputs}/>
                    </Grid>
                    <Grid item xs={12} className="protocolli">
                        <Typography variant={"subtitle1"} gutterBottom>Descrizione</Typography>
                        <ReactQuill defaultValue={protocolForm.watch('pro_descrizione')}
                                    ref={protocolForm.register({
                                        name: 'pro_descrizione'
                                    }, {validate: requiredHtml})}
                                    modules={ReactQuillModules}
                                    onChange={(html) => protocolForm.setValue('pro_descrizione', html)}
                                    readOnly={mode === 'read' || mode === 'delete'}
                                    placeholder={'Inserisci descrizione'}/>
                        {protocolForm.errors['pro_descrizione'] &&
                        <Typography variant={"caption"}
                                    color={'error'}>{protocolForm.errors['pro_descrizione'].message}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox defaultChecked={mode !== 'create' ? pro_attivo : false}
                                          disabled={mode === 'read' || mode === 'delete'}
                                          onChange={(e) => protocolForm.setValue('pro_attivo', e.target.checked)}
                                          inputRef={protocolForm.register({name: 'pro_attivo'})}
                                          color="secondary"/>
                            }
                            label="Incluso"/>
                        <FormControlLabel
                            control={
                                <Checkbox defaultChecked={mode !== 'create' ? pro_operativo : false}
                                          disabled={mode === 'read' || mode === 'delete'}
                                          onChange={(e) => protocolForm.setValue('pro_operativo', e.target.checked)}
                                          inputRef={protocolForm.register({name: 'pro_operativo'})}
                                          color="secondary"/>
                            }
                            label="Operativo"/>
                        <FormControlLabel
                            control={
                                <Checkbox defaultChecked={mode !== 'create' ? pro_formalizzato : false}
                                          disabled={mode === 'read' || mode === 'delete'}
                                          onChange={(e) => protocolForm.setValue('pro_formalizzato', e.target.checked)}
                                          inputRef={protocolForm.register({name: 'pro_formalizzato'})}
                                          color="secondary"/>
                            }
                            label="Formalizzato"/>
                        <FormControlLabel
                            control={
                                <Checkbox defaultChecked={mode !== 'create' ? pro_specifico : false}
                                          disabled={mode === 'read' || mode === 'delete'}
                                          onChange={(e) => protocolForm.setValue('pro_specifico', e.target.checked)}
                                          inputRef={protocolForm.register({name: 'pro_specifico'})}
                                          color="secondary"/>
                            }
                            label="Specifico"/>
                        <FormControlLabel
                            control={
                                <Checkbox defaultChecked={mode !== 'create' ? pro_procedure : false}
                                          disabled={mode === 'read' || mode === 'delete'}
                                          onChange={(e) => protocolForm.setValue('pro_procedure', e.target.checked)}
                                          inputRef={protocolForm.register({name: 'pro_procedure'})}
                                          color="secondary"/>
                            }
                            label="Procedure"/>
                        <FormControlLabel
                            control={
                                <Checkbox defaultChecked={mode !== 'create' ? pro_segregazione : false}
                                          disabled={mode === 'read' || mode === 'delete'}
                                          onChange={(e) => protocolForm.setValue('pro_segregazione', e.target.checked)}
                                          inputRef={protocolForm.register({name: 'pro_segregazione'})}
                                          color="secondary"/>
                            }
                            label="Segregazione"/>
                        <FormControlLabel
                            control={
                                <Checkbox defaultChecked={mode !== 'create' ? pro_poteri : false}
                                          disabled={mode === 'read' || mode === 'delete'}
                                          onChange={(e) => protocolForm.setValue('pro_poteri', e.target.checked)}
                                          inputRef={protocolForm.register({name: 'pro_poteri'})}
                                          color="secondary"/>
                            }
                            label="Poteri"/>
                        <FormControlLabel
                            control={
                                <Checkbox defaultChecked={mode !== 'create' ? pro_tracciabilita : false}
                                          disabled={mode === 'read' || mode === 'delete'}
                                          onChange={(e) => protocolForm.setValue('pro_tracciabilita', e.target.checked)}
                                          inputRef={protocolForm.register({name: 'pro_tracciabilita'})}
                                          color="secondary"/>
                            }
                            label="Tracciabilità"/>
                        <FormControlLabel
                            control={
                                <Checkbox defaultChecked={mode !== 'create' ? pro_monitoraggio : false}
                                          disabled={mode === 'read' || mode === 'delete'}
                                          onChange={(e) => protocolForm.setValue('pro_monitoraggio', e.target.checked)}
                                          inputRef={protocolForm.register({name: 'pro_monitoraggio'})}
                                          color="secondary"/>
                            }
                            label="Monitoraggio"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

ProtocolComponent.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
        {'indent': '-1'}, {'indent': '+1'}],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
};

ProtocolComponent.defaultProps = {
    protocol: {}
};
ProtocolComponent.propTypes = {
    protocol: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(ProtocolComponent);
