import React from 'react';
import StepperComponent from "../../components/ui/stepper/stepper.component";
import {AccountSuspendApi} from "../../services/api.service";
import {handleToast} from "../../redux/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import AccountSuspendSteps from "../../components/steps/account-suspend/account-suspend.steps";

const SuspendAccount = new AccountSuspendApi();

const AccountSuspendPage = ({dispatch, history}) => {
    const steps = ['Dati account', 'Conferma sospensione'];
    const save = async (data) => {
        try {
            await SuspendAccount.update(data);
            dispatch(handleToast({message: 'Account sospeso correttamente!'}));
            history.push('/gestione-account/conferma-sospensione')
        } catch (message) {
            dispatch(handleToast({message}))
        }
    };
    return (
        <StepperComponent title={'Gestione account - Sospensione'} steps={steps} onComplete={save}>
            <AccountSuspendSteps/>
        </StepperComponent>
    );
};

export default connect()(withRouter(AccountSuspendPage));
