import React from "react";

import ValutazioniTable from "../../components/atomic-tables/valutazioni-table/valutazioni.table";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import AuthService from "../../services/auth.service";
import { CompanyApiNew, ExportApi, FilesystemApi } from "../../services/api.service";
import ExportButton from "../../components/export-button/ExportButton";
import {handleModal} from "../../redux/actions";
import ValutazioniFsComponent from "../../components/CRUD/filesystem/valutazioni-fs.component";
import {connect} from "react-redux";

const CompanyApi = new CompanyApiNew();
const exportApi = new ExportApi();

const ElencoValutazioni = ({ history, dispatch }) => {
  const [companyId, setCompanyId] = React.useState(null);
  const [companyUuid, setCompanyUuid] = React.useState(null);
  const [selectedIds, setSelectedIds] = React.useState([]);
  React.useEffect(() => {
    const fetchCompanyId = async () => {
      const company = await CompanyApi.getFormUid(AuthService.company.uuid);
      setCompanyId(company.id);
      setCompanyUuid(company.uuid);
    };
    fetchCompanyId();
  }, []);
  const [loading, setLoading] = React.useState(false);

  const downloadpdf = async () => {
    setLoading(true);
    if ( selectedIds.length > 0 ) {
      const data = await exportApi.pdfLimited(companyUuid, selectedIds);
    } else {
      const data = await exportApi.pdf(companyId);
    }
    setLoading(false);
  };

  const downloadxls = async () => {
    setLoading(true);
    if ( selectedIds.length > 0 ) {
      const data = await exportApi.xlsxLimited(companyUuid, selectedIds);
    } else {
      const data = await exportApi.xlsx(companyUuid);
    }
    setLoading(false);
  };

  const loadFileSystem = () => {
    dispatch(handleModal({
      open: true,
      maxWidth: 'lg',
      title: 'Filesystem Valutazioni',
      content: <ValutazioniFsComponent/>
    }));
  };

  const downloadNotaMetodo = () => {
    let notaMetodoUrl = exportApi.getNoteUrl();
    window.open(notaMetodoUrl, '_blank', 'noopener,noreferrer');
  };

  const fullWidthStyle = {
    margin: 0,
    width: '100%'
  };

  if (!companyId) {
    return (
      <Grid
        container
        justify={"center"}
        spacing={8}
        style={ fullWidthStyle }
      >
        <CircularProgress size={25}></CircularProgress>
      </Grid>
    );
  }
  return (
    <Grid
      container
      justify={"center"}
      spacing={8}
      style={fullWidthStyle}
    >
      <Grid item xs={12}>
        <BreadcrumbComponent />
        <Grid
          container
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Grid item>
            <Typography
              variant={"subtitle1"}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              Elenco Valutazioni
            </Typography>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item style={{ margin: 5 }}>
                <Button
                    variant={"contained"}
                    style={{ height: 30 }}
                    color={"primary"}
                    onClick={() =>
                        history.push("/risk-assessment/nuova-valutazione")
                    }
                >
                  NUOVA VALUTAZIONE
                </Button>
              </Grid>
              <Grid item style={{ margin: 5 }}>
                <Button
                    variant={"contained"}
                    style={{ height: 30 }}
                    color={"primary"}
                    onClick={loadFileSystem}
                >
                  Lista Export
                </Button>
              </Grid>
              <Grid item style={{ margin: 5 }}>
                <Button
                    variant={"contained"}
                    style={{ height: 30 }}
                    color={"primary"}
                    onClick={downloadNotaMetodo}
                >
                  NOTA METODO
                </Button>
              </Grid>
              <Grid item style={{ margin: 5, zIndex: 200 }}>
                <ExportButton
                  isLoading={loading}
                  xlsx={downloadxls}
                  pdf={downloadpdf}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ValutazioniTable selectedRowsHandler={setSelectedIds} history={history} companyId={companyId} />
      </Grid>
    </Grid>
  );
};

export default connect()(ElencoValutazioni);
