import React, {useEffect, useMemo, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import {CompanyApiNew, FunzioniMatriceApi, RiskAreaApi, RiskAreaFunzioniMatrixApi} from "../../services/api.service";
import Fab from "@material-ui/core/Fab";
import {handleToast} from "../../redux/actions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import orderBy from 'lodash/orderBy';
import AuthService from "../../services/auth.service";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {emphasize} from "@material-ui/core/styles";
import NoSsr from "@material-ui/core/NoSsr";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'auto',
        maxHeight: 250
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipLabel: {
        '& .MuiChip-label': {
            textOverflow: 'ellipsis',
            maxWidth: '50vw',
            whiteSpace: 'nowrap',
            display: 'block',
            overflow: 'hidden',
        }
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

const RiskArea = new RiskAreaFunzioniMatrixApi();
const RiskAreaV2 = new RiskAreaApi();
const FunzioniAmministrative = new FunzioniMatriceApi();
const CompanyNew = new CompanyApiNew();
//const { com_id, com_name } = CompanyNew.getFormUid(company.uuid);
const AreaFunctionsMatrixPage = ({dispatch}) => {
    const classes = useStyles();
    const [tuttefunzioni, setTuttefunzioni] = useState([]);
    const [loading, setLoading] = useState(true)
    const [areaFunzioni, setAreaFunzioni] = useState([]);
    const [filter, setFilter] = React.useState();
    const [cid, setCid] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            const resp = await CompanyNew.getFormUid(AuthService.company.uuid);
            const aree_rischio = await RiskAreaV2.queryV2();
            const listaFunzioniCall = await FunzioniAmministrative.query();
            setCid(resp.id);
            let listaFunzioni = listaFunzioniCall.map(
                ({uuid, fun_funzione}) => {
                    return {
                        label: `${fun_funzione}`,
                        value: `${uuid}`
                    };
                });
            setTuttefunzioni(orderBy(listaFunzioni, ['label'], ['asc']));
            setAreaFunzioni(aree_rischio.filter(area => {
                if (filter) {
                    return area.are_titolo.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                }
                return true;
            }));
            setLoading(false)

        };
        fetchData();
    }, [filter]);
    const updateAreaFunzioni = (riskAreaId) => (event) => {console.log(event.target);
        setAreaFunzioni([
            ...areaFunzioni.map((area) => {
                if (area.id === riskAreaId) {
                    area.funzioni = [{uuid: event.target.value}];
                }
                return area;
            })
        ]);
    };
    const save = async () => {
        try {
            await Promise.all(areaFunzioni.map(
                async ({uuid, funzioni}) => {console.log(funzioni);
                    await RiskArea.updateFunzioni(uuid, {
                        funzioni: (funzioni.length > 0 && funzioni[0] && funzioni[0].uuid) ? [funzioni[0].uuid] : []
                    });
                })
            );
            dispatch(handleToast({
                message: 'Matrice salvata'
            }));
        } catch (message) {
            dispatch(handleToast({
                message
            }));
        }
    };
    const renderMatrix = useMemo(() => {
        if(loading) return (
            <Grid item style={{minWidth: '100%', textAlign: 'center'}}>
                <CircularProgress size={150} />
            </Grid>
        )

        if(!loading && areaFunzioni){
            return areaFunzioni.map(area => {
                const {id, uuid, are_titolo, funzioni} = area;
                let myValue = '';
                if ( funzioni.length > 0 && funzioni[0] && funzioni[0].uuid ) {
                    myValue = funzioni[0].uuid;
                }
                return (
                    <Grid item key={uuid} style={{minWidth: '100%'}}>
                        <Card style={{overflow: 'visible'}}>
                            <CardContent>
                                <div className={classes.root}>
                                    <NoSsr>
                                        <Typography variant={"subtitle2"} gutterBottom>{are_titolo}</Typography>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={myValue}
                                            onChange={updateAreaFunzioni(id)}
                                            placeholder={'Seleziona una funzione Organizzativa'}
                                            fullWidth
                                        >
                                            {tuttefunzioni.map(({label, value}) => {
                                                return (<MenuItem value={value} key={uuid + '-' + value}>{label}</MenuItem>);
                                            })}
                                        </Select>
                                    </NoSsr>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                );
            })
        }
    },[areaFunzioni,loading])
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0', position: 'relative'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Matrice Aree di rischio per Funzioni Organizzative'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Toolbar>
                            <TextField label={'Cerca'} fullWidth={true}
                                       onChange={(e) => setFilter(e.target.value)}
                                       InputProps={{
                                           endAdornment:
                                               <InputAdornment position="end">
                                                   <Icon>search</Icon>
                                               </InputAdornment>
                                       }}/>
                        </Toolbar>
                    </Grid>
                    {renderMatrix}
                    <Grid item xs={12}>
                        <Fab
                            onClick={save}
                            style={{
                                position: 'fixed',
                                right: '70px',
                                top: '335px',
                                width: '120px',
                            }} aria-label={'Salva'} color={'secondary'} variant={"extended"}>Salva</Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

AreaFunctionsMatrixPage.propTypes = {};

export default connect()(AreaFunctionsMatrixPage);
