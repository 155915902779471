import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import React, {useState} from "react";
import {FilesystemApi} from "../../../services/api.service";
import {parseNotes} from "../../../functions/insert-risk-assessment.helper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {handleModal, handleToast} from "../../../redux/actions";
import FunctionsComponent from "../functions/functions.component";
import ValutazioniMediaComponent from "./valutazioni-media.component";
import ValutazioniNewComponent from "./valutazioni-new.component";
import ModelloGeneraleNewComponent from "./modello-generale-new.component";
import CodiceEticoNewComponent from "./codice-etico-new.component";
import RegolamentoOdvNewComponent from "./regolamento-odv-new.component";

const FSApi = new FilesystemApi();
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {

        overflow: 'auto',
    },
});
const columns = [
    {id: 'name', label: 'Nome', colSpan: 1, sortable: true},
    {id: 'updated_at', label: 'Ultimo Update', colSpan: 2, sortable: true}
];

const RegolamentoOdvFSComponent = ({dispatch, regolamentoOdvUuid, approved}) => {
    // Constants
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loading, setLoading] = useState(false);
    const [doc, setDoc] = useState(null);
    const [pdf, setPdf] = useState(null);
    const [currentlyLoaded, setCurrentlyLoaded] = useState('doc');
    const [tableRows, setTableRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('fun_funzione');

    // Base Call
    React.useEffect(() => {
        const fetchData = async () => {
            const fetchExports = await FSApi.regolamentiOdv(regolamentoOdvUuid);
            setPdf(fetchExports.pdf);
            setDoc(fetchExports.doc)
            setTableRows(fetchExports.doc);
        };
        fetchData();
    }, []);

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };

    function getColorForButton(mode) {
        if ( mode === currentlyLoaded ) {
            return 'secondary';
        }

        return 'primary';
    }

    function getVariantForButton(mode) {
        if ( mode === currentlyLoaded ) {
            return 'contained';
        }

        return 'outlined';
    }

    function getButtonDisabled(mode) {
        if ( mode === currentlyLoaded ) {
            return true;
        }

        return false;
    }

    const setTableListAs = (type) => {
        // 1 => definisco il nuovo tipo caricato
        setCurrentlyLoaded(type);

        // 2 => pagina 1
        setPage(0);

        // 3 => nuova lista
        if ( type === 'pdf' ) {
            setTableRows(pdf);
        }

        if ( type === 'doc' ) {
            setTableRows(doc);
        }
    }

    const downloadMedia = async (media) => {
        await FSApi.downloadMediaRO(media.uuid, media.file_name);
    };

    const addMedia = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'sm',
            title: 'Carica un nuovo file',
            content: <RegolamentoOdvNewComponent regolamentoOdvUuid={regolamentoOdvUuid} />,
        }));
    };

    const deleteMedia = async (media) => {
        try {
            if ( window.confirm('Confermi di voler eliminare il media?') ) {
                const res = await FSApi.deleteMedia(media.uuid);
                dispatch(handleToast({
                    message: 'File eliminato correttamente'
                }));
                const fetchExports = await FSApi.regolamentiOdv(regolamentoOdvUuid);
                setPdf(fetchExports.pdf);
                setDoc(fetchExports.doc)
                setTableRows(fetchExports.doc);
            }
        } catch (message) {
            dispatch(handleToast({
                message
            }));
        }
    };

    return (
        <Paper className={classes.root}>
            <Button variant={getVariantForButton('doc')} color={getColorForButton('doc')} onClick={() => setTableListAs('doc')} disabled={getButtonDisabled('doc')}>DOC</Button>
            &nbsp;
            <Button variant={getVariantForButton('pdf')} color={getColorForButton('pdf')} onClick={() => setTableListAs('pdf')} disabled={getButtonDisabled('pdf')}>PDF</Button>
            &nbsp;
            <Button variant={"contained"} color={"primary"} onClick={() => addMedia()}>Aggiungi un File</Button>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(
                                ({id, align, sortable, colSpan, label}) => (
                                    <TableCell key={id} align={align} colSpan={colSpan} style={{zIndex: 3}}>
                                        {sortable ?
                                            <TableSortLabel
                                                active={orderBy === id}
                                                direction={order}
                                                onClick={createSortHandler(id)}>
                                                {label}
                                            </TableSortLabel>
                                            : label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.orderBy(tableRows, [orderBy], [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(
                                (r, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={index}>
                                            <TableCell align={"left"}>
                                                {r.name}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {r.nice_last_update}
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                <IconButton onClick={() => downloadMedia(r)} >
                                                    <Icon>download</Icon>
                                                </IconButton>
                                                {!approved && <IconButton onClick={() => deleteMedia(r)} >
                                                    <Icon color={"error"}>delete</Icon>
                                                </IconButton>}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        {!tableRows.length &&
                        <TableRow hover tabIndex={-1}>
                            <TableCell align={"center"} colSpan={columns.length}>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    nessun risultato
                                </Typography>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={(e, newPage) => setPage(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    )
};

RegolamentoOdvFSComponent.defaultProps = {
};
RegolamentoOdvFSComponent.propTypes = {
};
export default connect()(RegolamentoOdvFSComponent);
