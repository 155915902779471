import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
// import {searchFilter} from "../../../constant";
import {handleModal} from "../../../redux/actions";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import {EthicalCodeModelsApi} from "../../../services/api.service";
import makeStyles from "@material-ui/core/styles/makeStyles";
import EthicalCodeModelComponent from "../../CRUD/ethical-code-models/ethical-code-models.component";
import moment from "moment";
import Button from "@material-ui/core/Button";
import {Tooltip} from "@material-ui/core";
import ModelloGeneraleFsComponent from "../../CRUD/filesystem/modello-generale-fs.component";
import CodiceEticoFSComponent from "../../CRUD/filesystem/codice-etico-fs.component";

const EthicalCodeModels = new EthicalCodeModelsApi();
const columns = [
    {id: 'coe_numero_versione', label: 'Numero versione', colSpan: 1, sortable: true},
    {id: 'coe_data_inizio_validita', label: 'Valida da', colSpan: 1, sortable: true},
    {id: 'coe_data_fine_validita', label: 'Valida fino a', colSpan: 1, sortable: true},
    {id: 'coe_data_approvazione', label: 'Approvata il', colSpan: 1, sortable: true},
    {id: 'coe_total_files', label: 'Files', colSpan: 2, sortable: false},
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {

        overflow: 'auto',
    },
});
const EthicalCodeModelsTable = ({dispatch, api}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [versions, setVersions] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('titolo');
    const [filter, setFilter] = React.useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const {codici_etici} = await EthicalCodeModels.query();
            setVersions(codici_etici);
            setTableRows(codici_etici);
        };
        fetchData();
    }, [api.fetch]);
    useEffect(() => {
        setPage(0);
        if(filter){
            const rows = versions.filter(version => {
                const matchRow = (
                    (version.coe_data_inizio_validita && moment(version.coe_data_inizio_validita).format('DD/MM/YYYY').indexOf(filter.toLowerCase()) !== -1) ||
                    (version.coe_data_fine_validita && moment(version.coe_data_fine_validita).format('DD/MM/YYYY').indexOf(filter.toLowerCase()) !== -1) ||
                    (version.coe_note && version.coe_note.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
                    ('v.'+version.coe_numero_versione.toString()).indexOf(filter.toLowerCase()) !== -1
                )
                return matchRow
            })
            setTableRows(rows)
        }else{
            setTableRows(versions)
        }
    }, [filter,versions]);

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };
    const updateSection = (model) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: `Modifica versione ${model.coe_numero_versione}`,
            content: <EthicalCodeModelComponent mode={'update'} model={model}/>,
        }));
    };

    const deleteSection = (model) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: `Elimina versione ${model.coe_numero_versione}`,
            content: <EthicalCodeModelComponent mode={'delete'} model={model}/>,
        }));
    };

    const showWordDownloadButton = (row) => {
        if ( row.coe_doc_uuid !== undefined && row.coe_doc_uuid !== null ) {
            return (
                <Button variant="text" onClick={() => downloadWordVersion(row.coe_doc_uuid)}>
                    <Icon className={classes.downloadIcon}>cloud_download</Icon>
                    word
                </Button>
            )
        } else {
            const adjustedButtonProps = {
                component: "div",
                onClick: undefined
            };
            const tooltipText = "Nessun documento Word disponibile per la versione";
            return (
                <Tooltip title={tooltipText}>
                    <Button style={{color: "#999999"}} {...adjustedButtonProps}>
                        <Icon className={classes.downloadIcon}>cancel</Icon>
                        word
                    </Button>
                </Tooltip>
            )
        }
    }

    const showPdfDownloadButton = (row) => {
        if ( row.coe_pdf_uuid !== undefined && row.coe_pdf_uuid !== null ) {
            return (
                <Button variant="text" onClick={() => downloadPdfVersion(row.coe_pdf_uuid)}>
                    <Icon className={classes.downloadIcon}>cloud_download</Icon>
                    pdf
                </Button>
            )
        } else {
            const adjustedButtonProps = {
                component: "div",
                onClick: undefined
            };
            const tooltipText = "Nessun documento Pdf disponibile per la versione";
            return (
                <Tooltip title={tooltipText}>
                    <Button style={{color: "#999999"}} {...adjustedButtonProps}>
                        <Icon className={classes.downloadIcon}>cancel</Icon>
                        pdf
                    </Button>
                </Tooltip>
            )
        }
    }

    const openFS = (row) => () => {
        let titolo = 'Files - ';
            titolo += 'Codice Etico Versione ' + row.coe_numero_versione
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: titolo,
            content: <CodiceEticoFSComponent codiceEticoUuid={row.uuid} approved={row.coe_data_approvazione}/>,
        }));
    };

    const downloadWordVersion = async (coe_doc_uuid) => {
        try {
            const data = await EthicalCodeModels.downloadWord(coe_doc_uuid);
        } catch (e) {
            console.error(e);
        }
    };
    const downloadPdfVersion = async (coe_pdf_uuid) => {
        try {
            const data = await EthicalCodeModels.downloadPdf(coe_pdf_uuid);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Paper className={classes.root}>
            <Toolbar>
                <TextField label={'Cerca'} fullWidth={true} style={{margin: '1rem 1rem 2rem'}}
                           onChange={(e) => setFilter(e.target.value)}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <Icon>search</Icon>
                                   </InputAdornment>
                           }}/>
            </Toolbar>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(
                                ({id, align, sortable, colSpan, label}) => (
                                    <TableCell key={id} align={align} colSpan={colSpan} style={{zIndex: 3}}>
                                        {sortable ?
                                            <TableSortLabel
                                                active={orderBy === id}
                                                direction={order}
                                                onClick={createSortHandler(id)}>
                                                {label}
                                            </TableSortLabel>
                                            : label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.orderBy(tableRows, [orderBy], [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(
                                (row, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={index}>
                                            <TableCell align={"left"}>
                                                V. {row.coe_numero_versione}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {row.coe_data_inizio_validita ? moment(row.coe_data_inizio_validita).format('DD/MM/YYYY') : 'N/D'}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {row.coe_data_fine_validita ? moment(row.coe_data_fine_validita).format('DD/MM/YYYY') : 'N/D'}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {row.coe_data_approvazione ? moment(row.coe_data_approvazione).format('DD/MM/YYYY') : 'N/D'}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                <IconButton onClick={openFS(row)}>
                                                    {row.coe_total_files} <Icon>saved_search</Icon>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                {showWordDownloadButton(row)}
                                                {showPdfDownloadButton(row)}
                                                {!row.coe_data_approvazione && (
                                                <IconButton onClick={updateSection(row)}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                                )}
                                                {index === 0 && !row.coe_data_approvazione && (
                                                    <IconButton onClick={deleteSection(row)}>
                                                        <Icon color={"error"}>delete</Icon>
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        {!versions.length &&
                        <TableRow hover tabIndex={-1}>
                            <TableCell align={"center"} colSpan={columns.length}>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    nessun dato
                                </Typography>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={(e, newPage) => setPage(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>);
};

EthicalCodeModelsTable.propTypes = {
    filterBy: PropTypes.object
};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};
export default connect(mapStateToProps)(EthicalCodeModelsTable);
