import React from "react";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { Grid, Input } from "@material-ui/core";

const DiscreteSlider = ({ label, value, setValue, disabled, max = 5 }) => {
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 1) {
      setValue(1);
    } else if (value > max) {
      setValue(max);
    }
  };

  const moveCharAtEnd = (e) => {
    var temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  };

  return (
    <Grid
      xs={12}
      style={{ margin: "1rem 0" }}
      container
      justify={"flex-start"}
      alignContent={"center"}
      alignItems={"center"}
    >
      <Grid item xs={3} md={3}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={3} md={3}>
        <Input
          disabled={disabled}
          value={value}
          margin="dense"
          onChange={handleInputChange}
          onBlur={handleBlur}
          onFocus={moveCharAtEnd}
          inputProps={{
            step: 1,
            min: 1,
            max,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
        />
      </Grid>
      <Grid item xs={4} md={6}>
        <Slider
          disabled={disabled}
          max={max}
          min={1}
          valueLabelDisplay="auto"
          value={typeof value === "number" ? value : 1}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
        />
      </Grid>
    </Grid>
  );
};

export default DiscreteSlider;
