import { SAVE_USER, SAVE_COMPANY } from "../types";

export const saveUser = (user) => {
  return {
    type: SAVE_USER,
    user,
  };
};

export const saveCompanyId = (companyId, companyName) => {
  return {
    type: SAVE_COMPANY,
    companyId,
    companyName
  };
};
