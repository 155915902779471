import React from 'react';
import {handleModal} from "../../redux/actions";
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import BehaviorIndicatorTable from "../../components/atomic-tables/behavior-indicator/behavior-indicator.table";
import BehaviorIndicatorComponent from "../../components/CRUD/behavior-indicator/behavior-indicator.component";

const BehaviorIndicatorPage = ({dispatch}) => {
    const createIndicator = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Aggiungi indicatore comportamentale',
            content: <BehaviorIndicatorComponent mode={'create'}/>,
        }));
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Indicatori comportamentali'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"space-between"} alignItems={"center"} spacing={2} style={{margin: 0}}>
                            <Grid item xs>
                                <Typography variant={"subtitle2"}>Elenco indicatori comportamentali</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={createIndicator}>Aggiungi indicatore</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <BehaviorIndicatorTable/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default connect()(BehaviorIndicatorPage);
