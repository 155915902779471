import React from 'react';
import Grid from "@material-ui/core/Grid";
import PageSubtitleComponent from "../../ui/page-subtitle/page-subtitle.component";
import {useFormContext} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const GenericTextFieldsForm = ({title, inputs}) => {
    const {register, setValue, watch, errors} = useFormContext(); // retrieve all hook methods    
    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid item>
                {title && <PageSubtitleComponent subtitle={title}/>}
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    {inputs.map(({name, required, validate, type = 'text', multiline = false, label, placeholder, disabled, width, margin, autoComplete}) => (
                        <Grid item xs={width} key={name}>
                            <TextField label={`${label} ${required ? '*' : ''}`} placeholder={placeholder} fullWidth
                                       defaultValue={watch(name)} error={!!errors[name]} name={name}
                                       type={type} onChange={(e) => setValue(name, e.target.value)} disabled={disabled}
                                       inputRef={register(validate ? {name} : {required}, validate ? {validate} : null)}
                                       margin={margin}
                                       autoComplete={autoComplete}
                                       multiline={multiline}/>
                            {errors[name] &&
                            <Typography color={"error"} variant={"caption"}>{errors[name].message}</Typography>}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

GenericTextFieldsForm.propTypes = {
    title: PropTypes.string
};

GenericTextFieldsForm.defaultProps = {
    inputs: []
};

export default GenericTextFieldsForm;
