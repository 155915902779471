import React, {useState} from "react";
import {HELPER_TEXT_CONFIG, LABEL_CONFIG, REQUIRED_CONFIG} from "../form-item.class";
import FileInputTemplate from "./file-input.template";
import FileInputConfigure from "./file-input.configure";
import FileInputCompile from "./file-input.compile";
import EmptyItemComponent from "../empty-item/empty-item.component";
import {
    MODE_VIEW_COMPILE,
    MODE_VIEW_CONFIGURATION,
    MODE_VIEW_PREVIEW,
    MODE_VIEW_READONLY,
    MODE_VIEW_TEMPLATE
} from "../../../index.constants";
import PropTypes from "prop-types";

const FileInputComponent = (props) => {
    const {viewMode, save} = props;
    const [file, setFile] = useState(props.initialValue);

    const config = () => {
        return [
            LABEL_CONFIG(props),
            HELPER_TEXT_CONFIG(props),
            REQUIRED_CONFIG(props)
        ]
    };

    const saveConfiguration = (config, fileOptions, valid) => {
        // console.log('saveConfiguration text-field config', config, select, selectOption);
        let wrapper = {};
        config.forEach(({name, value}) => {
            if (name === 'required' && value === '') {
                value = false;
            }
            wrapper = {
                ...wrapper,
                [name]: value
            }
        });
        save({
            ...wrapper,
            valid,
            options: fileOptions
        });
    };

    // const defaultValues = () => {
    //     if (this.state.initialValue) {
    //         return {
    //             [this.state.name]: this.state.initialValue
    //         };
    //     }
    //     return {};
    // };

    const render = () => {
        switch (viewMode) {
            case MODE_VIEW_PREVIEW:
            case MODE_VIEW_COMPILE:
                return <FileInputCompile setFile={(file) => save({
                    file
                })} {...props} file={file}/>;
            case MODE_VIEW_TEMPLATE:
                return <FileInputTemplate/>;
            case MODE_VIEW_READONLY:
                return <FileInputCompile setFile={setFile} {...props} disabled={true} readOnly={true}/>;
            case MODE_VIEW_CONFIGURATION:
                return <FileInputConfigure onSave={saveConfiguration}
                                           defaultConfig={config()} {...props}/>;
            default:
                return <EmptyItemComponent/>;
        }
    };
    return render();
};

FileInputComponent.propTypes = {
    save: PropTypes.func.isRequired,
    viewMode: PropTypes.number.isRequired
};

export default FileInputComponent;
