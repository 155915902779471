import React from "react";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import InerenteLevel from "../risk-inerente-level/risk-inerente-level";
import ResiduoLevel from "../risk-residuo-level/risk-residuo-level";
import { useHistory } from "react-router-dom";
import NotesAccordion from "../notes-accordion/notesAccordion";

const completed = (data) => {
  if (!data) return false;
  const { impatto, presidio, probabilita } = data;
  return impatto && presidio && probabilita;
};

const Step3 = ({ valutazione, handleBack, showModal }) => {
  const history = useHistory();
  if (!valutazione || !completed(valutazione?.data)) {
    return (
      <Grid
        container
        style={{
          justifyContent: "center",
          flex: 1,
          alignSelf: "flex-start",
          paddingTop: 20,
        }}
        xs={12}
      >
        <Typography style={{ textAlign: "center" }}>
          Non è possibile mostrare la valutazione con il filtro al momento
          selezionato. Per visualizzare una valutazione, seleziona un area di
          rischio e un reato{" "}
        </Typography>
        <Grid
          container
          style={{
            borderTop: "1px solid #AAAAAA",
            paddingTop: "2rem",
            marginTop: 80,
            justifyContent: "flex-end",
            alignSelf: "flex-end",
          }}
          xs={12}
        >
          <Grid item>
            <Button
              style={{ fontWeight: "bold" }}
              onClick={handleBack}
              color={"primary"}
              variant={"outlined"}
            >
              Indietro
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{ fontWeight: "bold", marginLeft: 20 }}
              onClick={() =>
                history.push("/risk-assessment/elenco-valutazioni")
              }
              color={"primary"}
              variant={"contained"}
            >
              Elenco valutazioni
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const { data } = valutazione;
  return (
    <Grid container xs={12}>
      <Grid
        xs={12}
        className={"riepilogoContainer"}
        style={{ borderBottom: "1px solid grey", textAlign: "center" }}
        container
      >
        <Grid className={"impattoContainer"} container xs={4}>
          <Grid className={"impattoStringContainer"} item xs={6}>
            <Typography> IMPATTO : </Typography>
          </Grid>
          <Grid className={"impattoValueContainer"} item xs={6}>
            <Typography>{data.impatto.valore_massimo.toFixed(2)}</Typography>
          </Grid>
        </Grid>
        <Grid className={"probabilitaContainer"} container xs={4}>
          <Grid className={"probabilitaStringContainer"} item xs={6}>
            <Typography> PROBABILITÀ : </Typography>
          </Grid>
          <Grid className={"probabilitaValueContainer"} item xs={6}>
            <Typography>{data.probabilita.valore_medio.toFixed(2)}</Typography>
          </Grid>
        </Grid>
        <Grid container className={"presidiContainer"} xs={4}>
          <Grid item className={"presidiStringContainer"} xs={6}>
            <Typography> PRESIDI : </Typography>
          </Grid>
          <Grid item className={"presidiValueContainer"} xs={6}>
            <Typography>{data.presidio.valore_medio.toFixed(2)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} style={{ marginTop: 20 }} item>
        {" "}
        <Typography variant={"h6"} className={"titleRiskResult"}>
          Rischio Inerente:
        </Typography>
      </Grid>
      <InerenteLevel
        width={valutazione.data.ri_bar_perc}
        riskAmount={valutazione.data.rischio_inerente}
      />
      <Grid xs={12} style={{ marginTop: 40 }} item>
        {" "}
        <Typography variant={"h6"} className={"titleRiskResult"}>
          Rischio residuo:
        </Typography>
      </Grid>
      <ResiduoLevel
        width={valutazione.data.rr_bar_perc}
        riskAmount={valutazione.data.rischio_residuo}
      />
      {<NotesAccordion
        areaId={valutazione.data.area.uuid}
        companyId={valutazione.data.company.id}
        id={valutazione.data.id}
        showModal={showModal}
      />}
      <Grid
        container
        style={{
          borderTop: "1px solid #AAAAAA",
          paddingTop: "2rem",
          marginTop: 80,
          justifyContent: "flex-end",
        }}
        xs={12}
      >
        <Grid item>
          <Button
            style={{ fontWeight: "bold" }}
            onClick={handleBack}
            color={"primary"}
            variant={"outlined"}
          >
            Indietro
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ fontWeight: "bold", marginLeft: 20 }}
            onClick={() => history.push("/risk-assessment/elenco-valutazioni")}
            color={"primary"}
            variant={"contained"}
          >
            Elenco valutazioni
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Step3;
