
import {createMuiTheme} from "@material-ui/core/styles";
import React, { useEffect, useLayoutEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import RiskAreaSelect from "../../components/ui/risk-areas-select/risk-areas-select.component";
import ViolationSelect from "../../components/ui/violation-select/violation-select.component";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Step0 from "../../components/risk-assessment-steps/step0";
import Step1 from "../../components/risk-assessment-steps/step1";
import Step2 from "../../components/risk-assessment-steps/step2";
import Step3 from "../../components/risk-assessment-steps/step3";
import RiskStepper from "../../components/risk-assessment-stepper/RiskStepper";
import {
  ImpattoApi,
  ProbabilitaApi,
  PresidiApi,
  ValutazioneApi,
  CompanyApiNew,
  ConfigApi,
} from "../../services/api.service";
import {
  VALUTAZIONE,
  PRESIDI,
  PROBABILITA,
  IMPATTO,
  isImpatto,
  isProbabilita,
  isPresidi,
  isCompiledPresidi,
  isCompiledProbabilita,
  isCompiledImpatto,
  isValutazione,
} from "../../constants/graph";
import _ from "lodash";
import AuthService from "../../services/auth.service";

import "../../constants/styles.css";
import { connect } from "react-redux";

function getSteps() {
  return ["IMPATTO", "PROBABILITÀ", "PRESIDI", "VALUTAZIONE"];
}

const CompanyApi = new CompanyApiNew();
const valutazioneApi = new ValutazioneApi();
const impattoApi = new ImpattoApi();
const probabilitaApi = new ProbabilitaApi();
const presidiApi = new PresidiApi();

const InsertRiskAssessment = ({ location }) => {
  const [dynamicTheme, setDynamicTheme] = React.useState(null);
  const configApi = new ConfigApi();

  const [refetch, setRefetch] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [alertText, setAlertText] = React.useState("");
  const alertFunction = React.useRef(null);
  const [isLoading, setLoading] = React.useState(false);
  const [reato, setReato] = React.useState(null);
  const [area, setArea] = React.useState(null);
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(0);
  const [companyId, setCompanyId] = React.useState(null);

  const impattoDefaultValues = {
    economico: 3,
    operativo: 3,
    immagine_rep: 3,
  };
  ///impatto
  const [impatto, setImpatto] = React.useState(impattoDefaultValues);

  const probabilitaDefaultValues = {
    incentivi_management: 3,
    rapporti_pa_terzi: 3,
    frequenza: 3,
    rilevanza: 3,
    prevedibilita: 3,
    outsourcing: 3,
  };
  //probabilita
  const [probabilita, setProbabilita] = React.useState(
    probabilitaDefaultValues
  );

  //presidi
  const [presidi, setPresidi] = React.useState({
    procedure: 2,
    segregazione: 2,
    deleghe: 2,
    tracciabilita: 2,
    monitoraggio: 2,
  });

  //valutazione
  const [valutazione, setValutazione] = React.useState(null);

  useEffect(() => {
    const fetchCompanyId = async () => {
      setLoading(true);
      const company = await CompanyApi.getFormUid(AuthService.company.uuid);
      setCompanyId(company.id);

      setLoading(false);
    };
    const loadPalette = async () => {
      const paletteData = await configApi.palette();
      setDynamicTheme(createMuiTheme(paletteData));
    };
    loadPalette();
    fetchCompanyId();
  }, []);

  useEffect(() => {
    setArea(null);
  }, [location.key]);

  useEffect(() => {
    if (
      isCompiledImpatto(reato) &&
      isCompiledProbabilita(reato) &&
      isCompiledPresidi(reato)
    ) {
      setActiveStep(VALUTAZIONE);
    } else if (isCompiledImpatto(reato) && isCompiledProbabilita(reato)) {
      setActiveStep(PRESIDI);
    } else if (isCompiledImpatto(reato)) {
      setActiveStep(PROBABILITA);
    } else {
      setActiveStep(IMPATTO);
    }
  }, [reato]);

  useLayoutEffect(() => {
    if (isImpatto(activeStep) && isCompiledImpatto(reato)) {
      const fetchImpatto = async () => {
        setLoading(true);
        const data = await impattoApi.fromCombination({
          area_id: area.uuid,
          reato_id: reato.uuid
        });
        setLoading(false);
        const { economico, operativo, immagine_rep } = data.data;
        setImpatto({
          economico,
          operativo,
          immagine_rep,
        });
      };
      fetchImpatto();
    } else if (isImpatto(activeStep)) {
      setImpatto(impattoDefaultValues);
    } else if (isProbabilita(activeStep) && isCompiledProbabilita(reato)) {
      const fetchProbabilita = async () => {
        setLoading(true);
        const data = await probabilitaApi.fromCombination({
          area_id: area.uuid,
          reato_id: reato.uuid
        });
        setLoading(false);
        const {
          outsourcing,
          incentivi_management,
          rapporti_pa_terzi,
          frequenza,
          rilevanza,
          prevedibilita,
        } = data.data;
        setProbabilita({
          outsourcing,
          incentivi_management,
          rapporti_pa_terzi,
          frequenza,
          rilevanza,
          prevedibilita,
        });
      };
      fetchProbabilita();
    } else if (isProbabilita(activeStep)) {
      setProbabilita(probabilitaDefaultValues);
    } else if (isPresidi(activeStep) && isCompiledPresidi(reato)) {
      const fetchPresidi = async () => {
        setLoading(true);
        const data = await presidiApi.fromCombination({
          area_id: area.uuid,
          reato_id: reato.uuid
        });
        const {
          procedure,
          segregazione,
          deleghe,
          tracciabilita,
          monitoraggio,
        } = data.data;
        setPresidi({
          procedure,
          segregazione,
          deleghe,
          tracciabilita,
          monitoraggio,
        });
        setLoading(false);
      };
      fetchPresidi();
    } else if (isValutazione(activeStep) && area && reato) {
      const fetchValutazione = async () => {
        setLoading(true);
        try {
          const data = await valutazioneApi.fromCombination({
            area_id: area.uuid,
            reato_id: reato.uuid
          });
          setValutazione(data);
        } catch (e) {
          setValutazione({});
        }
        setLoading(false);
      };
      fetchValutazione();
    }
  }, [activeStep, reato]);

  const handleSaveStep = async () => {
    if (!reato || !area) {
      alertFunction.current = () => {
        setOpen(false);
      };
      setAlertText("Selezionare almeno un reato o tutti i reati");
      setOpen(true);
      return;
    }
    if (reato.id == 0) {
      let data;
      if (activeStep === 0) {
        alertFunction.current = async () => {
          setOpen(false);
          setLoading(true);
          data = await impattoApi.massUpdateReati(area.uuid, impatto);
          setLoading(false);
          setActiveStep(activeStep + 1);

        };
      } else if (activeStep === 1) {
        alertFunction.current = async () => {
          setOpen(false);
          setLoading(true);
          data = await probabilitaApi.massUpdateReati(
            area.uuid,
            probabilita
          );
          setLoading(false);
          setActiveStep(activeStep + 1);

        };
      } else if (activeStep === 2) {
        alertFunction.current = async () => {
          setOpen(false);
          setLoading(true);
          data = await presidiApi.massUpdateReati(area.uuid, presidi);
          setLoading(false);
          setActiveStep(activeStep + 1);

        };
      }
      setAlertText(
        "Stai sovrascrivendo i valori su più reati, sei sicuro di voler continuare?"
      );
      setOpen(true);
      return;
    } else if (area.id == 0) {
      let data;
      if (activeStep === 0) {
        alertFunction.current = async () => {
          setOpen(false);
          setLoading(true);
          data = await impattoApi.massUpdateArea(reato.uuid, impatto);
          setLoading(false);
          setActiveStep(activeStep + 1);

        };
      } else if (activeStep === 1) {
        alertFunction.current = async () => {
          setOpen(false);
          setLoading(true);
          data = await probabilitaApi.massUpdateArea(
            reato.uuid,
            probabilita
          );
          setLoading(false);
          setActiveStep(activeStep + 1);

        };
      } else if (activeStep === 2) {
        alertFunction.current = async () => {
          setOpen(false);
          setLoading(true);
          data = await presidiApi.massUpdateArea(reato.uuid, presidi);
          setLoading(false);
          setActiveStep(activeStep + 1);

        };
      }
      setAlertText(
        "Stai sovrascrivendo i valori su più reati, sicuro di voler continuare?"
      );
      setOpen(true);
      return;
    }
    if (isImpatto(activeStep)) {

      let data;
      if (isCompiledImpatto(reato)) {
        setLoading(true);
        const impattoId = await impattoApi.fromCombination({
          area_id: area.uuid,
          reato_id: reato.uuid,
        });

        //abort if we have the same value

        if (_.isMatch(impattoId.data, impatto)) {
          setActiveStep(PROBABILITA);
          setLoading(false);
          return;
        }
        data = await impattoApi.edit(
          {
            ...impatto,
          },
          impattoId.data.id
        );
        setLoading(false);

      } else {
        setLoading(true);

        data = await impattoApi.create({
          area_id: area.id,
          reato_id: reato.id,
          ...impatto,
        });
        setLoading(false);
      }
      if (data.data) {
        setRefetch(!refetch);
        setActiveStep(PROBABILITA);
      }
    } else if (isProbabilita(activeStep)) {
      let data;
      if (isCompiledProbabilita(reato)) {
        setLoading(true);
        const probabilitaId = await probabilitaApi.fromCombination({
          area_id: area.uuid,
          reato_id: reato.uuid
        });

        //check if we have the same value
        if (_.isMatch(probabilitaId.data, probabilita)) {
          setActiveStep(PRESIDI);
          setLoading(false);
          return;
        }
        data = await probabilitaApi.edit(
          {
            ...probabilita,
          },
          probabilitaId.data.uuid
        );
        setLoading(false);
      } else {
        setLoading(true);
        data = await probabilitaApi.create({
          area_id: area.id,
          reato_id: reato.id,
          ...probabilita,
        });
        setLoading(false);
      }
      if (data.data) {
        setRefetch(!refetch);
        setActiveStep(PRESIDI);
      }
    } else if (isPresidi(activeStep)) {
      let data;
      if (isCompiledPresidi(reato)) {
        setLoading(true);
        const presidiId = await presidiApi.fromCombination({
          area_id: area.uuid,
          reato_id: reato.uuid,
        });
        //abort if we have the same value
        if (_.isMatch(presidiId.data, presidi)) {
          setActiveStep(VALUTAZIONE);
          setLoading(false);
          return;
        }
        data = await presidiApi.edit(
          {
            ...presidi,
          },
          presidiId.data.uuid
        );
        setLoading(false);
      } else {
        setLoading(true);
        data = await presidiApi.create({
          area_id: area.id,
          reato_id: reato.id,
          ...presidi,
        });
        setLoading(false);
      }
      if (data.data) {
        setRefetch(!refetch);
        setActiveStep(VALUTAZIONE);
      }
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      ///save step
      setActiveStep(activeStep - 1);
    }
  };

  const renderStep = () => {
    if (isLoading) {
      return (
        <Grid container justify={"center"} spacing={4}>
          <CircularProgress size={40} />
        </Grid>
      );
    }
    switch (activeStep) {
      case 0:
        return (
          <Step0
            reato={reato}
            impatto={impatto}
            setImpatto={setImpatto}
            handleSaveStep={handleSaveStep}
          />
        );
      case 1:
        return (
          <Step1
            probabilita={probabilita}
            setProbabilita={setProbabilita}
            handleSaveStep={handleSaveStep}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <Step2
            area={area}
            reato={reato}
            presidi={presidi}
            setPresidi={setPresidi}
            handleSaveStep={handleSaveStep}
            handleBack={handleBack}
          />
        );
      case 3:
        return <Step3 valutazione={valutazione} handleBack={handleBack} />;
    }
  };

  if (!companyId) {
    return (
      <Grid container justify={"center"} spacing={4}>
        <CircularProgress size={40} />
      </Grid>
    );
  }
  return (
    <>
      <Grid container className={"riskBigContainerWrapper"} justify={"center"}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid container className={"alertRisk"}>
            <Grid container className={"titoloAlert"}>
              <WarningIcon color={"yellow"} />
              <Typography variant={"h6"}>ATTENZIONE</Typography>
            </Grid>
            <Grid item xs={12}>
              <DialogTitle id="alert-dialog-title">{alertText}</DialogTitle>
            </Grid>
            <Grid item xs={12}>
              <DialogActions>
                <Button
                  onClick={() => setOpen(false)}
                  color="default"
                  variant={"outlined"}
                >
                  Indietro
                </Button>
                {reato && area && (
                  <Button
                    onClick={() => alertFunction.current()}
                    color={"primary"}
                    variant={"contained"}
                    autoFocus
                  >
                    Salva e prosegui
                  </Button>
                )}
              </DialogActions>
            </Grid>
          </Grid>
        </Dialog>
        <Grid
          container
          className={"headerContainer"}
          justify={"center"}
          spacing={4}
        >
          <Grid item xs={12}>
            <BreadcrumbComponent />
          </Grid>
          <Grid item xs={12} md={6} className={"riskContainer"}>
            <RiskAreaSelect
              companyId={companyId}
              area={area}
              refetch={refetch}
              reato={reato}
              setArea={setArea}
              label="Seleziona l'area di rischio"
            />
          </Grid>
          <Grid item xs={12} md={6} className={"violationContainer"}>
            <ViolationSelect
              setReato={setReato}
              refetch={refetch}
              companyId={companyId}
              reato={reato}
              area={area}
              label="Seleziona il reato"
            />
          </Grid>
        </Grid>
        <Grid
          container
          justify={"center"}
          style={{ backgroundColor: dynamicTheme?.palette.primary[dynamicTheme?.palette.mode]+"50"}}
        >
          <Grid item xs={12} md={12} lg={8}>
            <Grid
              className={"riskBigContainer"}
              container
              justify={"center"}
              spacing={4}
              style={{ margin: 0 }}
            >
              <Grid container className={"riskWrapper"}>
                <Grid item xs={12} className={"risksStep"}>
                  <Stepper
                    className={"riskStepper"}
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<RiskStepper />}
                  >
                    {steps.map((label, index) => (
                      <Step
                        className={"riskStep"}
                        onClick={handleStep(index)}
                        key={label}
                      >
                        <Typography className={"riskStepLabel"}>
                          {label}
                        </Typography>
                        <StepLabel className={"riskStepNumber"} />
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid
                  container
                  className={"valueContainer"}
                  style={{ alignItems: "center", minHeight: 300 }}
                >
                  {renderStep()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={"gridFooterGray"} item xs={12} />
    </>
  );
};

const mapStateToProps = ({ violationReducer }) => {
  return {
    passedReato: violationReducer.reato,
    passedArea: violationReducer.area,
    editMode: violationReducer.editMode,
    viewMode: violationReducer.viewMode,
  };
};

export default connect(mapStateToProps)(InsertRiskAssessment);
