import {MODE_VIEW_TEMPLATE} from "../../../index.constants";
import {generateID} from "../../../index.utils";
import FormItem from "../form-item.class";

export default class SwitchGroupClass  extends FormItem {
    constructor({viewMode, name, displayName, switchGroup, ...props}) {
        super(props);
        this.name = name || generateID(9);
        this.type = 'switch-group';
        this.isEmpty = false;
        this.viewMode = viewMode || MODE_VIEW_TEMPLATE;
        this.displayName = displayName || 'Switch Group';
        this.switchGroup = switchGroup || [
            {
                label: '',
                name: `switch-${generateID(9)}`,
                required: false,
                initialValue: false,
                labelPlacement: 'end',
                helperText: ''
            }
        ];
    }
}
