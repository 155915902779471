import React, {useState} from 'react';
import {HorizontalBar} from "react-chartjs-2";
import {Typography} from "@material-ui/core";
import {HomePageApi} from "../../services/api.service";
import makeStyles from "@material-ui/core/styles/makeStyles";

const HomeApi = new HomePageApi();

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        overflowY: 'scroll'
    },
}));

const formatLabel = function(str, maxwidth){
    var sections = [];
    var words = str.split(" ");
    var temp = "";

    words.forEach(function(item, index){
        if(temp.length > 0)
        {
            var concat = temp + ' ' + item;

            if(concat.length > maxwidth){
                sections.push(temp);
                temp = "";
            }
            else{
                if(index == (words.length-1)) {
                    sections.push(concat);
                    return;
                }
                else {
                    temp = concat;
                    return;
                }
            }
        }

        if(index == (words.length-1)) {
            sections.push(item);
            return;
        }

        if(item.length < maxwidth) {
            temp = item;
        }
        else {
            sections.push(item);
        }

    });

    return sections;
}

export default function BigAreaProtocolliReatiChart() {
    const [chartData, setChartData] = useState(null);
    const classes = useStyles();

    const options = {
        elements: {
            bar: {
                borderWidth: 2,
            }
        },
        plugins: {
            legend: {
                position: 'right',
            }
        },
        legend: { display: true, position: "top" },
        tooltips: {
            callbacks: {
                title: function(tooltipItem, data) {
                    return data['labels'][tooltipItem[0]['index']];
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    callback: function(label) {
                        if ( label.length > 60 ) {
                            return label.substring(0,60) + "...";
                        }
                        return label.substring(0,60);
                    }
                }
            }]
        }
    }

    React.useEffect(() => {
        const fetchData = async () => {
            const graphData = await HomeApi.pullGraphData();
            if ( graphData !== undefined && graphData.areeLabel !== undefined && graphData.areeProtocolliCount !== undefined && graphData.areeReatiCount !== undefined ) {
                let labels = graphData.areeLabel;
                let newCharData = {
                    labels,
                    datasets: [
                        {
                            label: 'Reati',
                            data: graphData.areeReatiCount,
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            label: 'Protocolli',
                            data: graphData.areeProtocolliCount,
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                    ],
                };

                setChartData(newCharData);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <div className={classes.fullWidth}>
            <Typography variant="h6">Reati e Protocolli per Aree di rischio</Typography>
                <small>Passa il mouse sopra il grafico per vedere il nome delle aree</small>
                {chartData && <HorizontalBar
                    data={chartData}
                    options={options}
                />}
            </div>
        </>
    );
}
