import React from 'react';
import {useFormContext} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const TextFieldCompile = (props) => {
    const {
        select, label, width, rowsMax, placeholder, multiline, type,
        name, helperText, variant, options, disabled, required,
        initialValue, margin, startAdornment, endAdornment, readOnly
    } = props;
    const classes = makeStyles(theme => ({
        formControl: {
            margin: theme.spacing(0, 3),
        },
        textField: {
            width
        },
        menu: {
            width
        }
    }))();
    const {register, watch, setValue, errors} = useFormContext();
    const handleChange = (e) => {
        setValue(e.target.name, e.target.value);
    };
    const getHelperText = () => {
        if (!errors[name]) {
            return helperText;
        }
        return errors[name].message;
    };
    const forceRequired = (value) => {
        return (required && (value === undefined || value === '')) ? `${required}` : true;
    };
    return (
        <FormControl component="fieldset" fullWidth={true}>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <TextField
                name={name} type={type} label={placeholder} select={select} defaultValue={initialValue}
                multiline={multiline} rowsMax={rowsMax} value={select ? watch(name) || initialValue : undefined}
                className={classes.textField} disabled={disabled}
                onChange={handleChange} fullWidth={width === 'auto'}
                inputRef={register({name: name}, {validate: forceRequired})}
                InputProps={{
                    readOnly,
                    startAdornment,
                    endAdornment
                }}
                SelectProps={{
                    fullWidth: width === 'auto',
                    MenuProps: {
                        className: classes.menu,
                    },
                }}
                error={!!errors[name]} variant={variant}
                helperText={getHelperText()} margin={margin}>
                {select && options.map(option => (
                    <MenuItem key={option.value} value={option.value} component={'li'} button={true}>
                        {option.label}
                    </MenuItem>
                ))}
                {select && !options.length &&
                <MenuItem value={''} component={'li'} button={true}>
                    Invalid options list
                </MenuItem>}
            </TextField>
        </FormControl>
    );
};

TextFieldCompile.defaultProps = {
    disabled: false,
    label: '',
    placeholder: 'placeholder',
    required: false,
    initialValue: '',
    labelPlacement: 'end',
    width: 'auto',
    readOnly: false,
    multiline: false,
    select: false,
    margin: 'none',
    options: [],
    type: 'text',
    variant: 'standard'
};

TextFieldCompile.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    initialValue: PropTypes.string,
    helperText: PropTypes.string,
    readOnly: PropTypes.bool,
    multiline: PropTypes.bool,
    rowsMax: PropTypes.number,
    disabled: PropTypes.bool,
    required: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    margin: PropTypes.oneOf(['none', 'normal', 'dense']),
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    select: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.exact({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    )
};

export default TextFieldCompile;
