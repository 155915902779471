import {MODE_VIEW_TEMPLATE} from "../../../index.constants";
import {generateID} from "../../../index.utils";
import FormItem from "../form-item.class";

export default class SwitchGroupClass extends FormItem {
    constructor({viewMode, displayName, name, select, options, ...props}) {
        super(props);
        this.id = generateID(9);
        this.name = name || generateID(9);
        this.type = 'text-field';
        this.isEmpty = false;
        this.viewMode = viewMode || MODE_VIEW_TEMPLATE;
        this.displayName = displayName || 'Text Field/Select';
        this.select = select || false;
        this.options = options || [
            {
                value: `option-${generateID(9)}`,
                label: ''
            },
            {
                value: `option-${generateID(9)}`,
                label: ''
            }
        ];
    }
}
