import React, { useState, useEffect, useMemo } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import {IconButton, ListItem, Paper} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AreaProtocolliReatiChart from "../../components/charts/AreeProtocolliReatiChart";
import Card from "@material-ui/core/Card";
import {Link as RouteLink, withRouter} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import {HomePageApi} from "../../services/api.service";
import RischioQuotePerArea from "../../components/charts/RischioQuotePerArea";
import RischioSanzioniPerArea from "../../components/charts/RischioSanzioniPerArea";
import {handleModal} from "../../redux/actions";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import {useTheme} from "@material-ui/styles";
import envConfig from "../../config.json";

const HomeApi = new HomePageApi();

const boxPaperHeight = 150;
const bigBoxPaperRelativeHeight = 59;
const titleBoxPaperRelativeHeight = 10;
const smallBoxPaperRelativeHeight = 18;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        minHeight: boxPaperHeight,
        cursor: 'pointer',
        display: 'table-cell'
    },
    paperCustom: {
        paddingTop: '2rem',
        paddingBottom: '2rem',
        marginTop: "2rem",
        marginRight: "2rem",
        marginBottom: "2rem",
        marginLeft: "2rem",
        minHeight: window.innerHeight,
        borderRadius: 0,
        backgroundColor: '#f3f3f3'
    },
    typographyCustom: {
        marginBottom: "1rem"
    },
    dividerCustom: {
        marginTop: "2rem"
    },
    /* Custom */
    iconButtonMenu: {
        // backgroundColor: theme.palette.grey[300]
    },
    cardTopHome: {
        maxHeight: '500px'
    },
    cardTitle: {
        textAlign: 'left',
        marginTop: '2rem',
        marginBottom: '2rem',
        fontWeight: 'bold'
    },
    bottomPaper: {
        minHeight: 25,
        backgroundColor: "#006699"
    },
    gridTitleBox: {
        padding: '1rem',
        fontWeight: "bolder",
        fontSize: 12,
        color: "#000000",
        marginBottom: 10
    },
    gridBoxBig: {
        height: 60,
        paddingLeft: '1rem',
        paddingRight: '1rem'
    },
    gridBoxSmall: {
        height: (boxPaperHeight * smallBoxPaperRelativeHeight / 100),
        backgroundColor: theme.palette.primary[theme.palette.mode]+"70",
        paddingRight: '1rem',
        paddingLeft: 4,
        paddingTop: 4,
        paddingBottom: 4,
        textAlign: 'right',
        fontSize: 14,
        color: "#FFFFFF",
        borderRadius: '0 0 4px 4px'
    },
    bigNumber: {
        fontSize: 35,
        fontWeight: "bolder",
        width: "65%",
        display: "inline-block",
        paddingTop: "5%"
    },
    percent: {
        marginLeft: 2,
        fontSize: 14
    },
    boxNumbers: {
        color: "#adadad",
        width: "35%",
        textAlign: "right",
        display: "inline-block",
        fontSize: 13
    },
    rightGrid: {
    /*    paddingLeft: '2rem',*/
    },
    subtitleHeader: {
        color: theme.palette.primary[theme.palette.mode],
        marginBottom: 20,
        fontWeight: 'bold'
    },
    gridWithMargin: {
        marginTop: '2rem'
    },
    percentGreen: {
        color: "#98d49d",
        marginLeft: 2,
        fontSize: 14
    },
    gridBoxBigGreen: {
        height: (boxPaperHeight * bigBoxPaperRelativeHeight / 100),
        paddingLeft: '1rem',
        paddingRight: '1px'
    },
    gridBoxSmallGreen: {
        height: (boxPaperHeight * smallBoxPaperRelativeHeight / 100),
        backgroundColor: "#98d49d",
        paddingRight: '1rem',
        paddingLeft: 4,
        paddingTop: 4,
        paddingBottom: 4,
        textAlign: 'right',
        fontSize: 14,
        color: "#FFFFFF",
        borderRadius: '0 0 4px 4px'
    },
    percentYellow: {
        color: "#e8b72e",
        marginLeft: 2,
        fontSize: 14
    },
    gridBoxBigYellow: {
        height: (boxPaperHeight * bigBoxPaperRelativeHeight / 100),
        paddingLeft: '1rem',
        paddingRight: '1px'
    },
    gridBoxSmallYellow: {
        height: (boxPaperHeight * smallBoxPaperRelativeHeight / 100),
        backgroundColor: "#e8b72e",
        paddingRight: '1rem',
        paddingLeft: 4,
        paddingTop: 4,
        paddingBottom: 4,
        textAlign: 'right',
        fontSize: 14,
        color: "#FFFFFF",
        borderRadius: '0 0 4px 4px'
    },
    percentRed: {
        color: "#d13232",
        marginLeft: 2,
        fontSize: 14
    },
    gridBoxBigRed: {
        height: (boxPaperHeight * bigBoxPaperRelativeHeight / 100),
        paddingLeft: '1rem',
        paddingRight: '1px'
    },
    gridBoxSmallRed: {
        height: (boxPaperHeight * smallBoxPaperRelativeHeight / 100),
        backgroundColor: "#d13232",
        paddingRight: '1rem',
        paddingLeft: 4,
        paddingTop: 4,
        paddingBottom: 4,
        textAlign: 'right',
        fontSize: 14,
        color: "#FFFFFF",
        borderRadius: '0 0 4px 4px'
    },
    bigNumberGreen: {
        fontSize: 40,
        fontWeight: "bolder",
        color: "#98d49d",
        width: "50%",
        display: "inline-block",
        paddingTop: "5%"
    },
    bigNumberYellow: {
        fontSize: 40,
        fontWeight: "bolder",
        color: "#e8b72e",
        width: "50%",
        display: "inline-block",
        paddingTop: "5%"
    },
    bigNumberRed: {
        fontSize: 40,
        fontWeight: "bolder",
        color: "#d13232",
        width: "50%",
        display: "inline-block",
        paddingTop: "5%"
    },
    modal: {
        position:'absolute !important',
        overflow:'scroll',
        maxHeight:'80%',
        maxWidth: '80%',
        display:'block',
        inset: '10% 0 0 10% !important'
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    boxino: {

    },
    gridPaperContainer: {
        marginBottom: 12
    },
    boxForGraphs: {
        backgroundColor: "#fff",
        height: 365,
        paddingBottom: '36px !important'
    }
}));

function NewHomePage({ history }) {
    const classes = useStyles();
    const theme = useTheme();

    const [reati, setReati] = useState(0);
    const [reatiWithAree, setReatiWithAree] = useState(0);
    const [reatiWithAreePerc, setReatiWithAreePerc] = useState(0);

    const [aree, setAree] = useState(0);

    const [protocolli, setProtocolli] = useState(0);
    const [protocolliWithAree, setProtocolliWithAree] = useState(0);
    const [protocolliWithAreePerc, setProtocolliWithAreePerc] = useState(0);

    const [indicatori, setIndicatori] = useState(0);
    const [indicatoriWithAree, setIndicatoriWithAree] = useState(0);
    const [indicatoriWithAreePerc, setIndicatoriWithAreePerc] = useState(0);

    const [funzioni, setFunzioni] = useState(0);
    const [funzioniWithAree, setFunzioniWithAree] = useState(0);
    const [funzioniWithAreePerc, setFunzioniWithAreePerc] = useState(0);

    const [flussi, setFlussi] = useState(0);
    const [flussiCompilati, setFlussiCompilati] = useState(0);
    const [flussiCompilatiPerc, setFlussiCompilatiPerc] = useState(0);

    const [valutazioni, setValutazioni] = useState(0);
    const [valutazioniPossibili, setValutazioniPossibili] = useState(0);
    const [valutazioniPerc, setValutazioniPerc] = useState(0);
    const [valutazioniAlte, setValutazioniAlte] = useState(0);
    const [valutazioniAltePerc, setValutazioniAltePerc] = useState(0);
    const [valutazioniMedie, setValutazioniMedie] = useState(0);
    const [valutazioniMediePerc, setValutazioniMediePerc] = useState(0);
    const [valutazioniBasse, setValutazioniBasse] = useState(0);
    const [valutazioniBassePerc, setValutazioniBassePerc] = useState(0);

    const [open, setOpen] = React.useState(false);
    const [modalList, setModalList] = useState([]);
    const [modalTitle, setModalTitle] = useState('-');

    // Base Call
    React.useEffect(() => {
        const fetchData = async () => {
            const boxData = await HomeApi.pullBoxData();
            setAree(boxData.areeCount);

            setReati(boxData.reatiCount);
            setReatiWithAree(boxData.reatiWithAree);
            setReatiWithAreePerc(calcolaPercentuale(boxData.reatiCount, boxData.reatiWithAree));

            setProtocolli(boxData.protocolliCount);
            setProtocolliWithAree(boxData.protocolliWithAree);
            setProtocolliWithAreePerc(calcolaPercentuale(boxData.protocolliCount, boxData.protocolliWithAree));

            setIndicatori(boxData.indicatoriCount);
            setIndicatoriWithAree(boxData.indicatoriWithAree);
            setIndicatoriWithAreePerc(calcolaPercentuale(boxData.indicatoriCount, boxData.indicatoriWithAree));

            setFunzioni(boxData.funzioniCount);
            setFunzioniWithAree(boxData.funzioniWithAree);
            setFunzioniWithAreePerc(calcolaPercentuale(boxData.funzioniCount, boxData.funzioniWithAree));

            setFlussi(boxData.flussiCount);
            setFlussiCompilati(boxData.flussiCompilati);
            setFlussiCompilatiPerc(calcolaPercentuale(boxData.flussiCount, boxData.flussiCompilati));

            setValutazioni(boxData.valutazioniCount);
            setValutazioniPossibili(boxData.valutazioniPossibili);
            setValutazioniPerc(calcolaPercentuale(boxData.valutazioniPossibili, boxData.valutazioniCount));
            setValutazioniAlte(boxData.valutazioniAlte);
            setValutazioniAltePerc(calcolaPercentuale(boxData.valutazioniCount, boxData.valutazioniAlte));
            setValutazioniMedie(boxData.valutazioniMedie);
            setValutazioniMediePerc(calcolaPercentuale(boxData.valutazioniCount, boxData.valutazioniMedie));
            setValutazioniBasse(boxData.valutazioniBasse);
            setValutazioniBassePerc(calcolaPercentuale(boxData.valutazioniCount, boxData.valutazioniBasse));
        };
        fetchData();
    }, []);

    const handleOpen = async (what) => {
        const modalData = await HomeApi.pullModalData(what);
        setModalTitle(modalData.title);
        setModalList(modalData.list);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    /**
     * @param {integer} tot
     * @param {integer} value
     * @returns {number}
     */
    function calcolaPercentuale(tot, value) {
        if ( Number.isInteger(tot) && Number.isInteger(value) && tot > 0 && value >= 0 ) {
            return Math.round((value * 100 / tot));
        } else {
            return 0;
        }
    }

    const accountName = () => {
        try {
            if ( !localStorage.getItem('GRC_USER') ) {
                return 'unidentified';
            }

            let json = JSON.parse(localStorage.getItem('GRC_USER')).utente.accounts.acc_name

            if ( !json ) {
                return 'unidentified';
            }

            if ( json.utente && json.utente.accounts && json.utente.accounts.acc_name ) {
                return json.utente.accounts.acc_name;
            }

            return 'unidentified';
        } catch (e) {
            console.log(e);
            return 'unidentified'
        }
    };

    const hasSole = ((envConfig.CUSTOMER ?? "default") == "Sole24") ? true : false;

    return (
        <Paper className={classes.paperCustom} elevation={0}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.modalPaper}>
                        <h2 id="transition-modal-title">{modalTitle}</h2>
                        <p id="transition-modal-description">
                                {modalList && modalList.map(function(item, i) {
                                    return <ListItem key={i}>{item}</ListItem>;
                                })}
                        </p>
                    </div>
                </Fade>
            </Modal>
            <Grid container direction={"row"} spacing={2}>
                { hasSole && 
                    <div style={{marginTop: '20px', padding: '0',position: 'absolute', right: 0}}>
                        <a href="https://modulo24.ilsole24ore.com/compliance-landing/" target="_blank">
                            <img src="/assets/images/banner.gif" alt="Banner Modulo 24 Compliance" style={{cursor: 'pointer', width: '100%', borderRadius: '4px 0px 0px 4px',boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'}} />
                        </a>
                    </div>
                }
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h4" className={classes.cardTitle}>Benvenuto {JSON.parse(localStorage.getItem('GRC_USER')).utente.accounts.acc_name}</Typography>
                    <Typography variant="h5" className={classes.subtitleHeader}>Schemi di Controllo</Typography>
                </Grid>  
                <Grid item xs={12} md={5} lg={5}>
                    {/* Here should all the components come */}
                    <Grid container spacing={2}>
                        <Grid xs={6} md={4} key={'aree_di_rischo'} item className={classes.gridPaperContainer}>
                            <Paper elevation={2} className={classes.paper} onClick={() => history.push('/schemi-di-controllo/aree-di-rischio')}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.gridTitleBox}>
                                        Aree di Rischio
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxBig}>
                                        <span className={classes.bigNumber}>{aree}</span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxSmall}>
                                        <u>Tutte le aree</u>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid xs={6} md={4} key={'reati'} item className={classes.gridPaperContainer}>
                            <Paper elevation={2} className={classes.paper} onClick={() => handleOpen('reati')}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.gridTitleBox}>
                                        Reati non presenti in aree di rischio
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxBig}>
                                        <span className={classes.bigNumber}>{reatiWithAreePerc}<span className={classes.percent}>{'%'}</span></span>
                                        <span className={classes.boxNumbers}>{reatiWithAree}/{reati}</span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxSmall}>
                                        <u>Guarda la lista</u>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid xs={6} md={4} key={'protocolli'} item className={classes.gridPaperContainer}>
                            <Paper elevation={2} className={classes.paper} onClick={() => handleOpen('protocolli')}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.gridTitleBox}>
                                        Protocolli non presenti in aree di rischio
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxBig}>
                                        <span className={classes.bigNumber}>{protocolliWithAreePerc}<span className={classes.percent}>{'%'}</span></span>
                                        <span className={classes.boxNumbers}>{protocolliWithAree}/{protocolli}</span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxSmall}>
                                        <u>Guarda la lista</u>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid xs={6} md={4} key={'indicatori_comportamentali'} item className={classes.gridPaperContainer}>
                            <Paper elevation={2} className={classes.paper} onClick={() => handleOpen('indComportamentali')}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.gridTitleBox}>
                                        Indicatori comportamentali non presenti in aree di rischio
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxBig}>
                                        <span className={classes.bigNumber}>{indicatoriWithAreePerc}<span className={classes.percent}>{'%'}</span></span>
                                        <span className={classes.boxNumbers}>{indicatoriWithAree}/{indicatori}</span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxSmall}>
                                        <u>Guarda la lista</u>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid xs={6} md={4} key={'funzioni'} item className={classes.gridPaperContainer}>
                            <Paper elevation={2} className={classes.paper} onClick={() => handleOpen('funzioniAmministrative')}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.gridTitleBox}>
                                        Funzioni aziendali non presenti in aree di rischio
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxBig}>
                                        <span className={classes.bigNumber}>{funzioniWithAreePerc}<span className={classes.percent}>{'%'}</span></span>
                                        <span className={classes.boxNumbers}>{funzioniWithAree}/{funzioni}</span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxSmall}>
                                        <u>Guarda la lista</u>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid xs={6} md={4} key={'flussi_odv'} item className={classes.gridPaperContainer}>
                            <Paper elevation={2} className={classes.paper} onClick={() => handleOpen('flussiOdv')}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.gridTitleBox}>
                                        Flussi OdV non presenti in aree di rischio
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxBig}>
                                        <span className={classes.bigNumber}>{flussiCompilatiPerc}<span className={classes.percent}>{'%'}</span></span>
                                        <span className={classes.boxNumbers}>{flussiCompilati}/{flussi}</span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxSmall}>
                                        <u>Guarda la lista</u>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Grid container spacing={2} className={classes.rightGrid}>
                        <Grid item xs={12} md={12}>
                            <Card elevation={2} className={classes.cardTopHome}>
                                <CardContent className={classes.boxForGraphs}>
                                    <AreaProtocolliReatiChart />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={12} className={classes.gridWithMargin}>
                    <Typography variant="h5" className={classes.subtitleHeader}>Risk Assessment</Typography>
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                    {/* Here should all the components come */}
                    <Grid container spacing={2}>
                        <Grid xs={4} md={4} key={'valutazioni'} item className={classes.gridPaperContainer}>
                            <Paper elevation={2} className={classes.paper} onClick={() => history.push('/risk-assessment/elenco-valutazioni')}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.gridTitleBox}>
                                        Valutazioni
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxBig}>
                                        <span className={classes.bigNumber}>{valutazioniPerc}<span className={classes.percent}>{'%'}</span></span>
                                        <span className={classes.boxNumbers}>{valutazioni}/{valutazioniPossibili}</span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxSmall}>
                                        <u>Guarda la lista</u>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid xs={4} md={4} key={'empty_1'} item className={classes.gridPaperContainer}>

                        </Grid>
                        <Grid xs={4} md={4} key={'empty_2'} item className={classes.gridPaperContainer}>

                        </Grid>
                        <Grid xs={4} md={4} key={'rischo_residuo_basso'} item className={classes.gridPaperContainer}>
                            <Paper elevation={2} className={classes.paper} onClick={() => handleOpen('valutazioniRischioBasso')}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.gridTitleBox}>
                                        Rischio Residuo Basso
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxBigGreen}>
                                        <span className={classes.bigNumberGreen}>{valutazioniBassePerc}<span className={classes.percentGreen}>{'%'}</span></span>
                                        <span className={classes.boxNumbers}>{valutazioniBasse}/{valutazioni}</span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxSmallGreen}>
                                        <u>Guarda la lista</u>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid xs={4} md={4} key={'rischio_residuo_medio'} item className={classes.gridPaperContainer}>
                            <Paper elevation={2} className={classes.paper} onClick={() => handleOpen('valutazioniRischioMedio')}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.gridTitleBox}>
                                        Rischio Residuo Medio
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxBigYellow}>
                                        <span className={classes.bigNumberYellow}>{valutazioniMediePerc}<span className={classes.percentYellow}>{'%'}</span></span>
                                        <span className={classes.boxNumbers}>{valutazioniMedie}/{valutazioni}</span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxSmallYellow}>
                                        <u>Guarda la lista</u>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid xs={4} md={4} key={'rischio_residuo_alto'} item className={classes.gridPaperContainer}>
                            <Paper elevation={2} className={classes.paper} onClick={() => handleOpen('valutazioniRischioAlto')}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.gridTitleBox}>
                                        Rischio Residuo Alto
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxBigRed}>
                                        <span className={classes.bigNumberRed}>{valutazioniAltePerc}<span className={classes.percentRed}>{'%'}</span></span>
                                        <span className={classes.boxNumbers}>{valutazioniAlte}/{valutazioni}</span>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridBoxSmallRed}>
                                        <u>Guarda la lista</u>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Grid container spacing={2} className={classes.rightGrid}>
                        <Grid item xs={6} md={6}>
                            <Card elevation={2} className={classes.cardTopHome}>

                                <CardContent className={classes.boxForGraphs} style={{display: 'grid'}}>
                                    <RischioQuotePerArea />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Card elevation={2} className={classes.cardTopHome}>

                                <CardContent className={classes.boxForGraphs} style={{display: 'grid'}}>
                                    <RischioSanzioniPerArea />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

NewHomePage.propTypes = {};

export default withRouter(NewHomePage);
