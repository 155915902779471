import {SliderClass} from "./components/form-components/slider";
import {RadioGroupClass} from "./components/form-components/radio-group";
import {SwitchGroupClass} from "./components/form-components/switch-group";
import {DatePickerClass} from "./components/form-components/date-picker";
import {ChecksGroupClass} from "./components/form-components/checks-group";
import {TextFieldClass} from "./components/form-components/text-field";
import {FileInputClass} from "./components/form-components/file-input";
import {MODE_VIEW_COMPILE} from "./index.constants";
import FormItem from "./components/form-components/form-item.class";

export function generateID(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function initComponentClass(name, viewMode = MODE_VIEW_COMPILE, type, props = {}) {
    switch (type) {
        case 'slider':
            return new SliderClass({viewMode, name, type, ...props});
        case 'radio-group':
            return new RadioGroupClass({viewMode, name, type, ...props});
        case 'switch-group':
            return new SwitchGroupClass({viewMode, name, type, ...props});
        case 'date-picker':
            return new DatePickerClass({viewMode, name, type, ...props});
        case 'checks-group':
            return new ChecksGroupClass({viewMode, name, type, ...props});
        case 'text-field':
            return new TextFieldClass({viewMode, name, type, ...props});
        case 'file-input':
            return new FileInputClass({viewMode, name, type, ...props});
        default:
            console.error(`Invalid ${type} component!`);
            return new FormItem({viewMode, name, type, valid: true, isEmpty: true});
    }

}
