import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { Checkbox, CircularProgress, Fab, FormControlLabel, FormGroup, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

import { BehaviorIndicatorApi, RiskAreaIndicatoriMatrixApi } from '../../../services/api.service'
import RiskAssessmentMatrixBehaviorIndicatorFilters from './risk-assessment-matrix-behavior-indicator-filters'

const RiskAreaIndicatoriMatrix = new RiskAreaIndicatoriMatrixApi()
const BehaviorIndicator = new BehaviorIndicatorApi()
const RiskAssessmentMatrixBehaviorIndicator = ({ dispatch, onSave }) => {
  const { watch, setValue, register } = useFormContext()

  const [loading, setLoading] = useState(false)
  const [riskAreas, setRiskAreas] = useState([])
  const [behaviorIndicators, setBehaviorIndicators] = useState([])

  const selectedArea = useMemo(() => {
    return riskAreas?.find(({ uuid }) => uuid === watch('risk_area'))
  }, [riskAreas, watch('risk_area')])

  const filters = useMemo(() => {
    return watch('indicatorsFilters')
  }, [watch('indicatorsFilters')])

  const filteredIndicators = useMemo(() => {
    return behaviorIndicators
      .filter((item) => !!item.ind_attivo)
      .filter((item) => {
        return (filters.generico ? item.ind_specifico == 0 : true) &&
          (filters.specifico ? item.ind_specifico == 1 : true)
      })
  }, [behaviorIndicators, filters])

  const isIndicatorPreSelected = useCallback((protocol) => {
    const ids = watch('selectedIndicators')
    return !!ids?.includes(protocol.value)
  }, [watch('selectedIndicators')])

  const onCheckIndicator = useCallback((checked, indicator) => {
    const ids = watch('selectedIndicators')

    return checked
      ? setValue('selectedIndicators', [...ids, indicator.value])
      : setValue('selectedIndicators', ids.filter((id) => id !== indicator.value))
  }, [watch('selectedIndicators')])
  
  const fetchData = useCallback(async () => {
    setLoading(true)

    const riskAreas = await RiskAreaIndicatoriMatrix.query({
      matrice: 'indicatori_comportamentali'
    })
      
    let indicators = await BehaviorIndicator.query()
    indicators = indicators.map(({uuid, ind_titolo, ...rest}) => ({
      ...rest,
      label: ind_titolo,
      value: uuid
    }))
      
    setBehaviorIndicators(orderBy(indicators, ['label'], ['asc']))
    setRiskAreas(riskAreas)

    setLoading(false)
  }, [])

  const onClickSave = useCallback(async () => {
    await onSave()
    await fetchData()
  }, [onSave, fetchData])

  useEffect(() => {
    register({ name: 'selectedIndicators' })
  }, [])

  useEffect(() => {
    const protocols_ids = selectedArea?.aree_rischio_indic_comp?.map((indicator) => indicator?.indicatori_comportamentali?.uuid) ?? []
    setValue('selectedIndicators', protocols_ids ?? [])
  }, [selectedArea])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (loading) return (
    <Grid item style={{minWidth: '100%', textAlign: 'center'}}>
      <CircularProgress size={150} />
    </Grid>
  )

  return (
    <Grid container justify={"center"}>
      <Grid item xs={12} md={10} lg={8}>
        <RiskAssessmentMatrixBehaviorIndicatorFilters />
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Ind. Comport.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredIndicators?.map((indicator) => (
                <TableRow key={indicator.value}>
                  <TableCell>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!selectedArea}
                            checked={isIndicatorPreSelected(indicator)}
                            onChange={(e) => onCheckIndicator(e.target.checked, indicator)}
                          />
                        }
                        label={indicator.label}
                      />
                    </FormGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Fab
          onClick={onClickSave}
          style={{
            position: 'fixed',
            right: '70px',
            top: '335px',
            width: '120px',
          }}
          aria-label={'Salva'}
          color={'secondary'}
          variant={"extended"}
          disabled={!selectedArea}
        >
          Salva
        </Fab>
      </Grid>
    </Grid>
  )
}

export default connect()(RiskAssessmentMatrixBehaviorIndicator)