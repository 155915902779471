import React, {useState} from 'react';
import PropTypes from 'prop-types';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import {useFormContext} from "react-hook-form";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: theme.spacing(1, 0),
    },
}));

const RadioGroupCompile = props => {
    const {label, name, radioGroup, initialValue, required, disabled} = props;
    const {register, setValue, errors} = useFormContext();
    const [value, setRadioValue] = useState(initialValue);
    const classes = useStyles();
    const handleChange = (e) => {
        setValue(e.target.name, e.target.value);
        setRadioValue(e.target.value);
    };

    return (
        <FormControl component="fieldset" className={classes.formControl} disabled={disabled}>
            <FormLabel component="legend">{label}</FormLabel>
            {!radioGroup.length && <FormHelperText color={"error"}>Error! Missing radio options!</FormHelperText>}
            <RadioGroup aria-label={label} name={name} className={classes.group}
                        value={value} onChange={handleChange}>
                {radioGroup.map(
                    ({value, label, disabled}, index) =>
                        <FormControlLabel value={value} control={<Radio inputRef={register({required})}/>}
                                          label={label} disabled={disabled} key={`${name}-${index}`}/>
                )}
                {errors[name] &&
                <Typography color={"error"} variant={"body2"}>{errors[name].message}</Typography>}
            </RadioGroup>
        </FormControl>
    );
};

RadioGroupCompile.defaultProps = {
    label: '',
    radioGroup: [],
    initialValue: ''
};

RadioGroupCompile.propTypes = {
    initialValue: PropTypes.string,
    required: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    radioGroup: PropTypes.arrayOf(
        PropTypes.exact({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            disabled: PropTypes.bool
        })
    ).isRequired
};

export default RadioGroupCompile;
