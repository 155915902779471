
import React, { useCallback, useState } from 'react'
import {connect} from "react-redux"
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { Grid, Tab } from '@material-ui/core'
import useForm, {FormContext} from "react-hook-form";

import RiskAreaMatrix from "../matrix-risk-area/risk-area-matrix.page"
import MatrixProtocolsRiskArea from "../matrix-protocols-risk-area/matrix-protocols-risk-area.page"
import MatrixBehaviorIndicatorRiskArea from "../matrix-behavior-indicator-risk-area/matrix-behavior-indicator-risk-area.page"
import AreaFunctionsMatrix from "../matrix-area-functions/area-functions-matrix.page"
import MatrixFlowRiskArea from "../matrix-flow-risk-area/matrix-flow-risk-area.page"
import RiskAssessmentMatrixFilters from '../../components/risk-assessment-matrix/risk-assessment-matrix-filters';
import RiskAssessmentMatrixReati from '../../components/risk-assessment-matrix/risk-assessment-matrix-reati/risk-assessment-matrix-reati';
import BreadcrumbComponent from '../../components/ui/breadcrumb/breadcrumb.component';
import { handleToast } from '../../redux/actions';
import { RiskAreaApi, RiskAreaFunzioniMatrixApi } from '../../services/api.service';
import RiskAssessmentMatrixProtocolli from '../../components/risk-assessment-matrix/risk-assessment-matrix-protocolli/risk-assessment-matrix-protocolli';
import RiskAssessmentMatrixBehaviorIndicator from '../../components/risk-assessment-matrix/risk-assessment-matrix-behavior-indicator/risk-assessment-matrix-behavior-indicator';
import RiskAssessmentMatrixFunctions from '../../components/risk-assessment-matrix/risk-assessment-matrix-functions/risk-assessment-matrix-functions';
import RiskAssessmentMatrixFlow from '../../components/risk-assessment-matrix/risk-assessment-matrix-flow/risk-assessment-matrix-flow';

const RiskArea = new RiskAreaApi()
const RiskAreaFn = new RiskAreaFunzioniMatrixApi()
const RiskAssessmentMatrixPage = ({ dispatch }) => {
	const [value, setValue] = useState('1')
	
  const handleChange = useCallback((event, newValue) => {
    setValue(newValue)
  }, [])

	const typologiesForm = useForm({
		mode: "onChange"
	})

	const { watch } = typologiesForm

	const onSaveReati = useCallback(async () => {
		try {
			await RiskArea.updateReati({
				uuid: watch('risk_area'),
				reati: watch('selectedReati')
			})

			dispatch(handleToast({
				message: 'Matrice salvata'
			}))
		} catch (message) {
			dispatch(handleToast({
				message
			}));
		}
	}, [watch('risk_area'), watch('selectedReati')])

	const onSaveProtocols = useCallback(async () => {
		try {
			await RiskArea.updateProtocols({
				uuid: watch('risk_area'),
				protocolli: watch('selectedProtocols')
			})

			dispatch(handleToast({
				message: 'Protocolli salvati'
			}));
		} catch (message) {
			dispatch(handleToast({
				message
			}))
		}
	}, [watch('risk_area'), watch('selectedProtocols')])

	const onSaveBehavior = useCallback(async () => {
		try {
			await RiskArea.updateBehaviourIndicators({
				uuid: watch('risk_area'),
				indicatori_comportamentali: watch('selectedIndicators')
			})

			dispatch(handleToast({
				message: 'Ind. comportamentali salvati'
			}))
		} catch (message) {
			dispatch(handleToast({
				message
			}))
		}
	}, [watch('risk_area'), watch('selectedIndicators')])

	const onSaveFunctions = useCallback(async () => {
		try {
			await RiskAreaFn.updateFunzioni(watch('risk_area'), {
				funzioni: [watch('selectedMainFunction')],
				funzioni_secondarie: watch('selectedFunctions') ?? []
			})

			dispatch(handleToast({
				message: 'Funzioni salvate'
			}))
		} catch (message) {
			dispatch(handleToast({
				message
			}))
		}
	}, [watch('risk_area')], watch('selectedMainFunction'))

	const onSaveFlows = useCallback(async () => {
		try {
			await RiskArea.updateFlows({
				uuid: watch('risk_area'),
				flussi: watch('selectedFlows') ?? []
			})

			dispatch(handleToast({
				message: 'Flussi salvati'
			}))
		} catch (message) {
			dispatch(handleToast({
				message
			}))
		}
	}, [watch('risk_area'), watch('selectedFlows')])

  return (
		<FormContext {...typologiesForm}>
			<form>
				<Grid container justify={"center"}>
					<Grid item xs={12}>
						<TabContext value={value}>
							<Grid container justify={"center"} >
								<RiskAssessmentMatrixFilters />
								<Grid item sx={{ borderBottom: 1, borderColor: 'divider' }}>
									<TabList onChange={handleChange}>
										<Tab label="Reati" value="1" />
										<Tab label="Protocolli" value="2" />
										<Tab label="Ind. Comport." value="3" />
										<Tab label="Funzioni" value="4" />
										<Tab label="Flussi ODV" value="5" />
									</TabList>
								</Grid>
							</Grid>
							
							<TabPanel value="1">
								<RiskAssessmentMatrixReati onSave={onSaveReati} />
							</TabPanel>
							<TabPanel value="2">
								<RiskAssessmentMatrixProtocolli onSave={onSaveProtocols} />
							</TabPanel>
							<TabPanel value="3">
								<RiskAssessmentMatrixBehaviorIndicator onSave={onSaveBehavior} />
							</TabPanel>
							<TabPanel value="4">
								<RiskAssessmentMatrixFunctions onSave={onSaveFunctions} />
							</TabPanel>
							<TabPanel value="5">
								<RiskAssessmentMatrixFlow onSave={onSaveFlows} />
							</TabPanel>
						</TabContext>
					</Grid>
				</Grid>
			</form>
		</FormContext>
  )
}

export default connect()(RiskAssessmentMatrixPage)
