import {generateID} from "../../index.utils";

export const PLACEHOLDER_CONFIG = (config) => ({
    name: 'placeholder',
    value: config.placeholder || '',
    placeholder: 'Inserisci placeholder',
    required: true,
    isBoolean: false,
    disabled: false,
    textAnchor: true
});

export const LABEL_CONFIG = (config) => ({
    name: 'label',
    value: config.label || '',
    placeholder: 'Inserisci titolo',
    required: true,
    isBoolean: false,
    disabled: false,
    textAnchor: true
});

export const HELPER_TEXT_CONFIG = (config) => ({
    name: 'helperText',
    value: config.helperText || '',
    placeholder: 'Inserisci testo informativo',
    required: false,
    isBoolean: false,
    disabled: false,
    textAnchor: true
});

export const REQUIRED_CONFIG = (config) => ({
    name: 'required',
    value: config.required || '',
    placeholder: 'Testo dell\'errore',
    required: !!config.required,
    boolean: !!config.required,
    isBoolean: true,
    disabled: false,
    textAnchor: true
});

export default class FormItem {
    constructor({name, ...props}) {
        this.id = generateID(9);
        this.name = name || generateID(9);
        this.isEmpty = true;
        for (const k in props) {
            this[k] = props[k];
        }
    }

}
/*
export default class FormComponent extends Component {
    state = {
        id: Math.random(),
        isEmpty: true
    };

    constructor(props) {
        super(props);
        this.props = props || {};
        this.config = this.config.bind(this);
        this.defaultValues = this.defaultValues.bind(this);
        this.saveConfiguration = this.saveConfiguration.bind(this);
    }

    render() {
        return <EmptyItemComponent/>
        //return <Typography color={"error"}>Invalid form component!</Typography>
    }

    saveConfiguration(rows) {
        // console.log('saveConfiguration form', rows);
        let wrapper = {};
        rows.forEach(({name, value}) => {
            if (name === 'required' && value === '') {
                value = false;
            }
            wrapper = {
                ...wrapper,
                [name]: value
            }
        });
        this.state = {
            ...this.state,
            ...wrapper
        };
    };

    onSave() {
        return this.state;
    }

    // noinspection JSMethodCanBeStatic
    config() {
        return [
            LABEL_CONFIG(this.state),
            PLACEHOLDER_CONFIG(this.state),
            HELPER_TEXT_CONFIG(this.state),
            REQUIRED_CONFIG(this.state)
        ]
    }

    defaultValues() {
        if (this.state.initialValue) {
            return {
                [this.state.name]: this.state.initialValue
            };
        }
        return {};
    }

    styleGrid = (index, cols, rows, style = {}) => {
        if (this.state.viewMode === MODE_VIEW_COMPILE) {
            return {
                ...style,
                border: 'none'
            }
        }

        index = index + 1;
        const y = index % cols;
        // const x = index % rows;
        //console.log(index, 'y', y, 'x', x);
        style = {
            borderRight: '1px dashed gray',
            borderBottom: '1px dashed gray',
            ...style
        };
        if (y === 0 && index >= cols) {
            //console.log(`item ${index} is the last of row`);
            style = {
                ...style,
                borderRight: 'none'
            }
        }
        // console.log('index > (rows * cols) - cols', index, (rows * cols), cols)
        if (index > (rows * cols) - cols || rows === 1) {
            //console.log(`item ${index} is the last of col`);
            style = {
                ...style,
                borderBottom: 'none'
            }
        }
        this.style = style;
        return style;
    };
}
*/
