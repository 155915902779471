import React from 'react';
import StepperComponent from "../../components/ui/stepper/stepper.component";
import AccountShoppingSteps from "../../components/steps/account-shopping/account-shopping.steps";
import {handleToast} from "../../redux/actions";
import {AccountShoppingApi} from "../../services/api.service";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const AccountShopping = new AccountShoppingApi();

const AccountShoppingPage = ({dispatch, history}) => {
    const steps = ['Dati account', 'Licenze e servizi', 'Riepilogo ordine'];
    const save = async (data) => {
        try {
            await AccountShopping.update(data);
            dispatch(handleToast({message: 'Account modificato con successo!'}));
            history.push('/gestione-account/conferma-modifica')
        } catch (message) {
            dispatch(handleToast({message}))
        }
    };
    return (
        <StepperComponent steps={steps} onComplete={save} title={'Gestione account - Acquisti'}>
            <AccountShoppingSteps/>
        </StepperComponent>
    );
};

export default connect()(withRouter(AccountShoppingPage));
