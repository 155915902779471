import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from "@material-ui/core/Fab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import AuthService from "../../services/auth.service";

const useStyles = makeStyles(theme => ({
    listItem: {
        borderBottom: '1px solid rgba(0,0,0, .12)',
        padding: theme.spacing(2)
    }
}));

const AccountManagementPage = ({history}) => {
    const classes = useStyles();
    const [selected, setAction] = useState();
    const actions = [
        {
            title: 'Attivazione account',
            route: '/gestione-account/attivazione'
        },
        {
            title: 'Acquisti',
            subtitle: 'Account già esistente',
            route: '/gestione-account/acquisti'
        },
        {
            title: 'Disdetta account',
            route: '/gestione-account/disdetta'
        },
        {
            title: 'Sospensione account',
            route: '/gestione-account/sospensione'
        },
        {
            title: 'Riattivazione account',
            route: '/gestione-account/riattivazione'
        }
    ];
    const handleSelectAction = (index) => {
        setAction(actions[index])
    };
    const isSelected = (index) => {
        return selected && actions[index].title === selected.title;
    };
    const select = () => {
        history.push(selected.route);
    };
    const logout = () => {
        AuthService.logout().then( () => {
            history.push('/login');
            /*setTimeout( function () {
                window.location.reload();
            }, 300 );*/
        });
    };
    return (
        <Container maxWidth={"xs"}>
            <Grid container direction={"column"} spacing={4}>
                {/*<Grid item>
                    <img src={'/assets/images/horizontal-logo-dark.png'} width={'auto'} height={'40px'}
                         style={{margin: '0 auto', display: 'block'}}/>
                </Grid>*/}
                <Grid item>
                    <Typography variant={"h5"} align={"center"}>
                        Gestione account
                    </Typography>
                    <Typography variant={"subtitle1"} align={"center"}>
                        Selezione l'azione da svolgere
                    </Typography>
                </Grid>
                <Grid item>
                    <List>
                        {actions.map(({title, subtitle}, index) => (
                            <ListItem key={index} button selected={isSelected(index)}
                                      onClick={() => handleSelectAction(index)}
                                      style={{borderTop: index === 0 ? '1px solid rgba(0,0,0, .12)' : 'none'}}
                                      className={classes.listItem}>
                                <ListItemText primary={title} secondary={subtitle}/>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item>
                    <Grid container justify={"center"} spacing={4}>
                        <Grid item xs={8}>
                            <Fab variant={"extended"} size={"small"} disabled={!selected}
                                 color={"primary"}
                                 onClick={select} style={{width: '100%'}}>avanti</Fab>
                        </Grid>
                        <Grid item xs={8}>
                            <Fab variant={"extended"} size={"small"} color={"secondary"}
                                 onClick={logout} style={{width: '100%'}}>esci</Fab>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

AccountManagementPage.propTypes = {};

export default AccountManagementPage;
