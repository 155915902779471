import React from 'react';
import Grid from "@material-ui/core/Grid";
import ToolbarComponent from "../components/ui/toolbar/toolbar.component";
// import DrawerComponent from "../components/ui/drawer/drawer.component";
import FooterComponent from "../components/ui/footer/footer.component";
import PropTypes from "prop-types";
// import AuthService from "../services/auth.service";
import {withRouter} from "react-router-dom";
// import _ from 'lodash';

const ProfileLayout = (props) => {
    const {children, toolbarPosition, showBackButton} = props;
    // const [open, toggleDrawer] = useState(false);
    // const account = AuthService.user.utente.accounts;
    // const isAdmin = AuthService.isAdmin();
    return (
        <Grid container style={{position: 'relative', minHeight: '100vh'}}>
            <Grid item xs={12}>
                <ToolbarComponent position={toolbarPosition} showAccountMenu hideMenu showBackButton={showBackButton}/>
            </Grid>
            <Grid item xs={12} style={{marginTop: toolbarPosition === 'fixed' ? '64px' : 'inherit'}}>
                {children}
            </Grid>
            <Grid item xs={12}>
                <FooterComponent/>
            </Grid>
        </Grid>
    );
};

ProfileLayout.defaultProps = {
    toolbarPosition: 'absolute'
};

ProfileLayout.propTypes = {
    toolbarPosition: PropTypes.string
};

export default withRouter(ProfileLayout);
