import {generateID} from "../../../index.utils";
import {MODE_VIEW_TEMPLATE} from "../../../index.constants";
import FormItem from "../form-item.class";

export default class ChecksGroupClass  extends FormItem {
    constructor({viewMode, displayName, values, checksGroup, ...props}) {
        super(props);
        this.type = 'checks-group';
        this.isEmpty = false;
        this.viewMode = viewMode || MODE_VIEW_TEMPLATE;
        this.displayName = displayName || 'Checks Group';
        this.values = values || {};
        this.checksGroup = checksGroup || [
            {
                label: '',
                name: `check-${generateID(9)}`
            },
            {
                label: '',
                name: `check-${generateID(9)}`
            }
        ]
    }
}
