import React, {useEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {handleToast} from "../../../redux/actions";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {TableBody, Typography} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from "@material-ui/core/TextField";
import {ServiceApi} from "../../../services/api.service";
import PropTypes from "prop-types";

const Service = new ServiceApi();
const CheckServicesForm = ({dispatch, defaultServices}) => {    
    const {register, errors, watch, setValue, triggerValidation} = useFormContext();
    const [services, setServices] = useState([]);
    useEffect(() => {
        const fetchApi = async () => {
            try {
                const {servizi} = await Service.query();
                setServices(servizi);
                if (defaultServices) {
                    Object.keys(defaultServices).forEach(
                        (key) => {
                            const index = servizi.indexOf(servizi.find(({uuid}) => uuid === key));
                            setValue(`services[${index}].uuid`, key);
                            setValue(`services[${index}].quantity`, defaultServices[key].quantity);
                            setValue(`services[${index}].ser_name`, defaultServices[key].ser_name);
                            setValue(`services[${index}].ser_description`, defaultServices[key].ser_description);
                        }
                    )
                }
            } catch (message) {
                dispatch(handleToast({
                    message
                }));
            }
        };
        fetchApi();
    }, [defaultServices,dispatch,setValue]);
    const handleQuantityChange = (index) => (e) => {
        setValue(`services[${index}].quantity`, e.target.value);
        triggerValidation();
    };
    const toggleCheck = (index, uuid) => (e) => {
        const isChecked = watch(`services[${index}].uuid`) === uuid;
        setValue(`services[${index}].uuid`, isChecked ? false : uuid);
        //setValue(`services[${index}].quantity`, isChecked ? undefined : 1);
        triggerValidation();
        //console.log(getValues(), Object.values(errors));
    };
    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid item style={{width: '100%'}}>
                <Table>
                    <TableBody>
                        {(!!services.length && services.map(({uuid, ser_name, ser_sap_product_id, ser_description}, index) => (
                            <TableRow
                                hover onClick={toggleCheck(index, uuid)}
                                role="checkbox" aria-checked={watch(uuid)}
                                tabIndex={-1} key={uuid}
                                selected={watch(uuid)}>
                                <TableCell padding="checkbox">
                                    <Checkbox name={`services[${index}].uuid`} value={uuid}
                                              onClick={toggleCheck(index, uuid)}
                                              inputRef={register} checked={!!watch(`services[${index}].uuid`)}
                                              onChange={(e) => setValue(`services[${index}].uuid`, e.target.checked)}/>
                                    <input type={'hidden'} name={`services[${index}].ser_name`} value={ser_name}
                                           ref={register}/>
                                    <input type={'hidden'} name={`services[${index}].ser_description`}
                                           value={ser_description} ref={register}/>
                                </TableCell>
                                <TableCell component="th" id={uuid} scope="row" padding="none">
                                    {ser_name}                                    
                                    {ser_sap_product_id && (
                                        <Typography  variant="body2">Cod. SAP #: {ser_sap_product_id}</Typography>
                                    )}
                                </TableCell>
                                <TableCell align="left">{ser_description}</TableCell>
                                <TableCell align="right">
                                    <TextField type={'number'} name={`services[${index}].quantity`}
                                               onClick={(e) => {
                                                   if (watch(`services[${index}].uuid`)) e.stopPropagation()
                                               }}
                                               label={'Quantità'} placeholder={'0'}
                                               value={watch(`services[${index}].quantity`) || 1}
                                               disabled={!watch(`services[${index}].uuid`)}
                                               error={!!errors[`services[${index}].quantity`]}
                                               onChange={handleQuantityChange(index)}
                                               inputRef={register({
                                                   validate: (value) => watch(`services[${index}].uuid`) && !value ? 'Quantità' : true
                                               })}/>
                                </TableCell>
                            </TableRow>
                        ))) || (
                            <TableRow>
                                <TableCell>
                                    <Skeleton variant="rect" height={'300px'}/>
                                </TableCell>
                            </TableRow>
                        )}                        
                    </TableBody>
                </Table>                
            </Grid>
            <Grid item style={{textAlign: 'right'}}>
                <Typography variant={"caption"}>(*) Ai nuovi account è inizialmente reso disponibile uno spazio sul server fino a 1 Gb.</Typography>
            </Grid>
        </Grid>
    );
};

CheckServicesForm.propTypes = {
    defaultServices: PropTypes.object
};

export default connect()(CheckServicesForm);
