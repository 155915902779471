import React, { Fragment, useState } from 'react';
import Typography from "@material-ui/core/Typography";
import {emphasize} from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import Chip from "@material-ui/core/Chip";
import useTheme from "@material-ui/core/styles/useTheme";
import NoSsr from "@material-ui/core/NoSsr";
import Select from 'react-select';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'auto',
        maxHeight: 250
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipLabel: {
        '& .MuiChip-label': {
            textOverflow: 'ellipsis',
            maxWidth: '50vw',
            whiteSpace: 'nowrap',
            display: 'block',
            overflow: 'hidden',
        }
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
};

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: {classes, TextFieldProps},
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

Control.propTypes = {
    /**
     * Children to render.
     */
    children: PropTypes.node,
    /**
     * The mouse down event and the innerRef to pass down to the controller element.
     */
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
};

function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}>
            {props.children}
        </MenuItem>
    );
}

Option.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        onMouseMove: PropTypes.func.isRequired,
        onMouseOver: PropTypes.func.isRequired,
        tabIndex: PropTypes.number.isRequired,
    }).isRequired,
    /**
     * Inner ref to DOM Node
     */
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
    /**
     * Whether the option is focused.
     */
    isFocused: PropTypes.bool.isRequired,
    /**
     * Whether the option is selected.
     */
    isSelected: PropTypes.bool.isRequired,
};

function Placeholder(props) {
    const {selectProps, innerProps = {}, children} = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

Placeholder.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

SingleValue.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * Props passed to the wrapping element for the group.
     */
    //innerProps: PropTypes.any.isRequired,
    selectProps: PropTypes.object.isRequired,
};

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}>
            Nessuna opzione disponibile
        </Typography>
    );
}

NoOptionsMessage.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * Props to be passed on to the wrapper.
     */
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            title={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipLabel]: true,
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<Icon {...props.removeProps}>cancel</Icon>}
        />
    );
};

MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool.isRequired,
    removeProps: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        onMouseDown: PropTypes.func.isRequired,
        onTouchEnd: PropTypes.func.isRequired,
    }).isRequired,
    selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

Menu.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.element.isRequired,
    /**
     * Props to be passed to the menu wrapper.
     */
    innerProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
};

function ClearIndicator(props) {
    const [openClearModal, setOpenClearModal] = useState(false)
    const confirmClearInput = () => setOpenClearModal(true)
    const handleClose = () => setOpenClearModal(false)
    const confirmClear = () => props.clearValue()
    return (
        <Fragment>
            <Dialog
                open={openClearModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confermare Azione"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Eliminare tutte le associazioni?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Annulla</Button>
                    <Button onClick={confirmClear} color="primary" autoFocus>Conferma</Button>
                </DialogActions>
            </Dialog>
            <Icon onClick={confirmClearInput} style={{ marginRight: 10, cursor: 'pointer' }}>clear</Icon>
        </Fragment>
    )
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    ClearIndicator,
};

const AutoCompleteComponent = ({suggestions, placeholder, defaultValues, label, multiple, onChange}) => {
    const classes = useStyles();
    const theme = useTheme();

    const [multi, setMulti] = React.useState(defaultValues);

    let myDefaltSingle = null;
    let myDefaltSingleValue = '';

    if ( !multiple && defaultValues && defaultValues.length > 0 ) {
        myDefaltSingle = defaultValues[0].label;
        myDefaltSingleValue = defaultValues[0].value ? defaultValues[0].value : '';
    }

    const [single, setSingle] = React.useState(myDefaltSingle);
    const [singleValue, setSingleValue] = React.useState(myDefaltSingleValue);
    function handleChangeSingle(value) {
        if ( typeof value === 'object' && value !== null && value.value ) {
            setSingle(value.label);
            setSingleValue(value.value);
            if ( onChange ) {
                onChange(value);
            }
        } else {
            setSingle(value);
            setSingleValue(value);
            if ( onChange ) {
                onChange(value);
            }
        }
    }

    function handleChangeMulti(value) {
        setMulti(value);
        onChange(value);
    }

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    return (
        <div className={classes.root}>
            <NoSsr>
                <Typography variant={"subtitle2"} gutterBottom>{label}</Typography>
                {!multiple &&
                <Select autoComplete={false} autoSelect={false}
                    classes={classes} styles={selectStyles} inputId="react-select-single"
                    TextFieldProps={{
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },
                    }}
                    fullWidth placeholder={placeholder} options={suggestions} getOptionLabel={(option) => option.label}
                    components={components} value={singleValue} inputValue={single} onChange={handleChangeSingle}/>}
                {multiple &&
                <Select
                    classes={classes} styles={selectStyles} inputId="react-select-multiple"
                    TextFieldProps={{
                        InputLabelProps: {
                            htmlFor: 'react-select-multiple',
                            shrink: true,
                        },
                    }}
                    fullWidth placeholder={placeholder} options={suggestions} getOptionLabel={(option) => option.label}
                    components={components} value={multi} onChange={handleChangeMulti} isMulti />}
            </NoSsr>
        </div>
    );
};

AutoCompleteComponent.defaultProps = {
    suggestions: [],
    defaultValues: [],
    placeholder: '',
    onChange: (e) => console.log('onChange', e),
    label: '',
    multiple: false
};

AutoCompleteComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired,
    defaultValues: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    multiple: PropTypes.bool
};

export default AutoCompleteComponent;
