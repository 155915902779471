import React, {useState} from "react";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import CheckLicensesForm from "../../hooks-forms/check-licenses-form/check-licenses.form";
import CheckServicesForm from "../../hooks-forms/check-services-form/check-services.form";
import PageSubtitleComponent from "../../ui/page-subtitle/page-subtitle.component";
import {Typography} from "@material-ui/core";
import UnknownStepComponent from "../../ui/unknown-step/unknown-step.component";
import ServicesSummaryComponent from "../../ui/services-summary/services-summary.component";
import LicensesSummaryComponent from "../../ui/licenses-summary/licenses-summary.component";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const AccountActivationSteps = ({onComplete, activeStep, handleBack, handleNext}) => {
    const [formValues, setFormValues] = useState({});
    const activationForm = useForm({
        mode: 'onSubmit',
        defaultValues: formValues
    });
    const onSubmit = async (data, e) => {
        //console.log('onSubmit', data);
        setFormValues({
            ...formValues,
            ...data
        });
        handleNext();
    };
    const back = () => {
        handleBack();
    };
    const inputs = [
        {
            name: 'name',
            required: 'Campo obbligatorio',
            label: 'Ragione sociale',
            placeholder: 'Nome azienda...',
            width: 12,
            margin: 'normal'
        },
        {
            name: 'address',
            required: 'Campo obbligatorio',
            label: 'Indirizzo',
            placeholder: 'Via Anselmo, 13...',
            width: 8,
            margin: 'normal'
        },
        {
            name: 'zip',
            required: 'Campo obbligatorio',
            label: 'Cap',
            placeholder: 'codice postale...',
            width: 4,
            margin: 'normal'
        },
        {
            name: 'city',
            required: 'Campo obbligatorio',
            label: 'Città',
            placeholder: 'Torino...',
            width: 8,
            margin: 'normal'
        },
        {
            name: 'province',
            required: 'Campo obbligatorio',
            label: 'Provincia',
            placeholder: 'Torino...',
            width: 4,
            margin: 'normal'
        },
        {
            name: 'email',
            required: 'Campo obbligatorio',
            label: 'Email',
            placeholder: 'email...',
            width: 4,
            margin: 'normal'
        },
        {
            name: 'phone',
            required: false,
            label: 'Telefono',
            placeholder: 'Numero fisso...',
            width: 4,
            margin: 'normal'
        },
        {
            name: 'mobile',
            required: 'Campo obbligatorio',
            label: 'Cellulare',
            placeholder: 'Cellulare...',
            width: 4,
            margin: 'normal'
        },
        {
            name: 'admin_email',
            required: 'Campo obbligatorio',
            label: 'Email amministrativa',
            placeholder: 'email...',
            width: 4,
            margin: 'normal'
        },
        {
            name: 'p_iva',
            validate: (value) => activationForm.watch('p_iva') ? (value && value.length === 11 ? true : 'P.Iva non valida') : !activationForm.watch('fiscal_code') ? 'P.Iva o C.F. richiesti' : true,
            label: 'P. Iva',
            placeholder: 'p. iva...',
            width: 4,
            margin: 'normal'
        },
        {
            name: 'fiscal_code',
            validate: (value) => activationForm.watch('fiscal_code') ? (value && (value.length === 11 || value.length === 16) ? true : 'C. Fiscale non valido') : !activationForm.watch('p_iva') ? 'P.Iva o C.F. richiesti' : true,
            label: 'Codice fiscale',
            placeholder: 'codice fiscale...',
            width: 4,
            margin: 'normal'
        }
    ];
    const agentCode = [
        {
            name: 'agent_code',
            required: false,
            label: 'Inserisci il tuo codice agente',
            placeholder: 'Codice agente...',
            width: 12
        }
    ];

    const save = () => {
        const {licenses, services, ...account} = formValues;
        let licenze = licenses || [];
        licenze = licenze.filter(({uuid}) => uuid).map(
            ({quantity, uuid}) => {
                return {
                    quantity,
                    uuid
                }
            }
        );
        let servizi = services || [];
        servizi = servizi.filter(({uuid}) => uuid).map(
            ({quantity, uuid}) => {
                return {
                    quantity,
                    uuid
                }
            }
        );
        onComplete({
            ...account,
            licenze,
            servizi
        })
    };
    switch (activeStep) {
        case 0:
            return (
                <FormContext {...activationForm}>
                    <form onSubmit={activationForm.handleSubmit(onSubmit)}>
                        <Grid container justify={"space-between"} spacing={4}>
                            <Grid item xs={12}>
                                <GenericTextFieldsForm inputs={agentCode} title={'CODICE AGENTE'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <GenericTextFieldsForm inputs={inputs} title={'DATI ACCOUNT'}/>
                            </Grid>
                            <Grid item>
                                <Button onClick={back}>
                                    Indietro
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" type="submit" color={"primary"}>
                                    Avanti
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormContext>
            );
        case 1:
            return (
                <FormContext {...activationForm}>
                    <form onSubmit={activationForm.handleSubmit(onSubmit)}>
                        <Grid container justify={"space-between"} spacing={4}>
                            <Grid item xs={12}>
                                <PageSubtitleComponent subtitle={'RINNOVO'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox inputRef={activationForm.register}
                                                       checked={!!activationForm.watch('ute_renewal')}
                                                       onChange={(e)=> activationForm.setValue('ute_renewal', e.target.checked)}
                                                       name={'ute_renewal'} />}
                                    label="Rinnovo automatico"/>
                            </Grid>
                            <Grid item xs={12}>
                                <PageSubtitleComponent subtitle={'SELEZIONA TIPO DI LICENZA'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckLicensesForm/>
                            </Grid>
                            <Grid item xs={12}>
                                <PageSubtitleComponent subtitle={'SELEZIONA TIPO DI SERVIZIO'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckServicesForm/>
                            </Grid>
                            <Grid item>
                                <Button onClick={back}>
                                    Indietro
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" type="submit">
                                    Avanti
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormContext>
            );
        case 2:
            let licenses = formValues['licenses'] || [];
            licenses = licenses.filter(({uuid}) => uuid);
            let services = formValues['services'] || [];
            services = services.filter(({uuid}) => uuid);
            return (
                <Grid container direction={"column"} spacing={4}>
                    <Grid item>
                        <PageSubtitleComponent subtitle={'DATI ANAGRAFICI'}/>
                    </Grid>
                    <Grid item container direction={"column"} spacing={1} alignItems={"center"}
                          style={{marginLeft: '2rem'}}>
                        {inputs.map(({label, name}, index) => {
                            return (
                                <Grid item container spacing={1} key={`summary-${index}`}>
                                    <Grid item>
                                        <Typography variant={"subtitle2"}>{label}:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body1"}>{formValues[name]}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Grid item container spacing={1} key={`summary-renewal`}>
                            <Grid item>
                                <Typography variant={"subtitle2"}>Rinnovo automatico:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"}>{formValues['ute_renewal'] ? 'Sì' : 'No'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={'LICENZE SELEZIONATE'}/>
                    </Grid>
                    <Grid item>
                        <LicensesSummaryComponent licenses={licenses}/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={'SERVIZI SELEZIONATI'}/>
                    </Grid>
                    <Grid item>
                        <ServicesSummaryComponent services={services}/>
                    </Grid>
                    <Grid item container justify={"space-between"}>
                        <Grid item>
                            <Button variant="contained" color="default" onClick={back}>
                                Indietro
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={save}>
                                Attiva utenza
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            );
        default:
            return <UnknownStepComponent handleBack={handleBack}/>;
    }
};

export default AccountActivationSteps;
