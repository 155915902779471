import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AuthService from "../../../services/auth.service";
import {withRouter} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import {ENV} from "../../../constant";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {CircularProgress, ListItemText} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import {ConfigApi} from "../../../services/api.service";

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    whiteText: {
        color: 'white'
    },
    solidAppBar: {
        zIndex: `${theme.zIndex.drawer + 2}!important`,
        backgroundColor: theme.palette.primary[theme.palette.mode]
    },
    appBar: {
        zIndex: `${theme.zIndex.drawer + 2}!important`,
        background: theme.palette.primary[theme.palette.mode],
        boxShadow: 'none'
    },
    menu: {
        '& .MuiMenu-paper': {
            width: '350px',
            borderRadius: 0,
            padding: '1rem',
            '& .MuiListItem-button': {
                borderBottom: '1px solid rgba(0,0,0,.12)',
                padding: '1rem'
            }
        }
    },
    logo: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)'
    }
}));

const ToolbarComponent = ({toggleDrawer, position, history, showDrawer, hideMenu,showAccountMenu, showBackButton, inHome, pageWidth}) => {
    const configApi = new ConfigApi();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [logo, setLogo] = React.useState(false);

    useEffect(() => {
        const loadSetup = async () => {
            const setupData = await configApi.setup();
            if ( setupData && setupData.logo && setupData.logo !== '' ) {
                setLogo(setupData.logo);
            }
        };
        loadSetup();
    }, []);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (e) => {
        setAnchorEl(null);
    };

    // const selectCompany = (company) => () => {
    //     AuthService.company = company;
    //     setAnchorEl(null);
    //     window.location.reload();
    // };
    const goBack = () => {
        history.goBack();
    };
    const logout = () => {
        AuthService.logout().then( () => {
            history.push('/login');
            /*setTimeout( function () {
                window.location.reload();
            }, 300 );*/
        });
    };
    const {com_name} = AuthService.company;
    // const companies = AuthService.getCompanies();

    const renderSpecificToolbarComponent = () => {
        if (localStorage.getItem('permessi_utente').includes('risk.referente') || localStorage.getItem('permessi_utente').includes('odv.referente')) {
            // Risk or OdV User
            return (
                <Menu
                    id="toolbar-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    keepMounted variant={"menu"}
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    transformOrigin={{vertical: "top", horizontal: "right"}}
                    open={Boolean(anchorEl)} className={classes.menu} onClose={handleClose}>
                    <MenuItem onClick={() => history.push(`/home/`)}>
                        <Icon style={{marginRight: '1rem'}} color={"disabled"}>view_list</Icon>
                        <Typography>Visualizza lista società</Typography>
                    </MenuItem>
                    <MenuItem onClick={logout} style={{borderBottom: "none"}}>
                        <Icon style={{marginRight: '1rem'}} color={"disabled"}>exit_to_app</Icon>
                        <Typography>Logout</Typography>
                    </MenuItem>
                </Menu>
            )
        }
        // Default User
        return (
            <Menu
                id="toolbar-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted variant={"menu"}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={Boolean(anchorEl)} className={classes.menu} onClose={handleClose}>
                <MenuItem onClick={() => history.push(`/profilo-utenza/`)}>
                    <Icon style={{marginRight: '1rem'}} color={"disabled"}>account_balance</Icon>
                    <Typography>Profilo società</Typography>
                </MenuItem>
                <MenuItem onClick={() => history.push(`/home/`)}>
                    <Icon style={{marginRight: '1rem'}} color={"disabled"}>view_list</Icon>
                    <Typography>Visualizza lista società</Typography>
                </MenuItem>
                <MenuItem onClick={logout} style={{borderBottom: "none"}}>
                    <Icon style={{marginRight: '1rem'}} color={"disabled"}>exit_to_app</Icon>
                    <Typography>Logout</Typography>
                </MenuItem>
            </Menu>
        )
    }

    return (
        <div className={classes.grow}>
            <AppBar position={position} className={position === 'absolute' ? classes.appBar : classes.solidAppBar}>
                <Toolbar>
                    <Button onClick={() => history.push('/home')} color={"inherit"} className={classes.logo}>
                        {logo && <img src={logo} alt={'valore24 logo'} height={'40px'} width={'auto'}/>}
                        {!logo && <CircularProgress />}
                    </Button>
                    <Grid container justify={"space-between"} alignItems={"center"}>
                        <Grid item>
                            {showBackButton && (
                                <Button aria-controls="toolbar-menu" aria-haspopup="true" onClick={goBack}>
                                    <Icon className={classes.whiteText} style={{marginRight: '.5rem'}}>chevron_left</Icon>
                                    <Typography className={classes.whiteText}>Torna Indietro</Typography>
                                </Button>
                            )}
                            {showDrawer && (pageWidth < 1000 || !inHome) && (
                                <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
                                    <Icon fontSize={"large"}>menu</Icon>
                                </IconButton>
                            )}
                        </Grid>
                        {!hideMenu &&
                        <Grid item>
                            <Button aria-controls="toolbar-menu" aria-haspopup="true" onClick={handleClick}>
                                <Typography className={classes.whiteText}>
                                    {com_name}
                                </Typography>
                                <Icon className={classes.whiteText} style={{marginLeft: '.5rem'}}>keyboard_arrow_down</Icon>
                            </Button>
                        </Grid>}
                        {showAccountMenu &&
                        <Grid item>
                            <Button aria-controls="profile-menu" aria-haspopup="true" onClick={handleClick}>
                                <Typography className={classes.whiteText}>PROFILO</Typography>
                                <Icon className={classes.whiteText} style={{marginLeft: '.5rem'}}>keyboard_arrow_down</Icon>
                            </Button>
                        </Grid>
                        }
                    </Grid>
                </Toolbar>
            </AppBar>
            {showAccountMenu && (
                <Menu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    keepMounted variant={"menu"}
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    transformOrigin={{vertical: "top", horizontal: "right"}}
                    open={Boolean(anchorEl)} className={classes.menu} onClose={handleClose}>
                    <Typography align={"center"} variant={"subtitle2"} style={{width: '100%'}} gutterBottom>
                        IL MIO PROFILO
                    </Typography>
                    <MenuItem onClick={()=> history.push(`/profilo/`)}>
                        <Icon style={{marginRight: '1rem'}} color={"disabled"}>settings</Icon>
                        <Typography>Impostazioni account</Typography>
                    </MenuItem>
                    <MenuItem onClick={logout} style={{borderBottom: "none"}}>
                        <Icon style={{marginRight: '1rem'}} color={"disabled"}>exit_to_app</Icon>
                        <Typography>Logout</Typography>
                    </MenuItem>
                </Menu>
            )}
            {!hideMenu &&
            (renderSpecificToolbarComponent())
            }
        </div>
    );
};

ToolbarComponent.defaultProps = {
    toggleDrawer: () => console.error('Missing toggleDrawer function'),
    position: 'absolute',
    hideMenu: false
};

ToolbarComponent.propTypes = {
    toggleDrawer: PropTypes.func.isRequired,
    position: PropTypes.string,
    hideMenu: PropTypes.bool
};

export default withRouter(ToolbarComponent);
