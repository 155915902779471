import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

export const EMPTY_ITEM = {
    type: 'empty-item',
    isEmpty: true
};
const EmptyItemComponent = props => {
    const {title, text} = props;
    return (
        <Grid container justify={"center"}>
            <Grid item>
                <Typography align={"center"} variant={"subtitle2"}>{title}</Typography>
                <Typography variant={"body2"}>{text}</Typography>
            </Grid>
        </Grid>
    );
};

EmptyItemComponent.defaultProps = {
    title: 'Slot vuoto',
    text: 'Trascina un template'
};

EmptyItemComponent.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};

export default EmptyItemComponent;
