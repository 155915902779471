import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import DiscreteSlider from "../ui/discrete-slider/discrete-slider.component";
import { Radar } from "react-chartjs-2";
import { getAverage } from "../../functions/insert-risk-assessment.helper";
import "../../constants/styles";
import { options } from "../../constants/graph";
const labels = {
  INCENTIVI: "Incentivi al management",
  RAPPORTI: "Rapporti con PA o terzi",
  FREQUENZA: "Frequenza",
  RILEVANZA: "Rilevanza",
  OUTSOURCING: "Outsourcing",
  FORECASTING: "Imprevedibilità",
};

const Step1 = ({
  disabled = false,
  handleSaveStep,
  handleBack,
  probabilita,
  setProbabilita,
}) => {
  const {
    incentivi_management,
    rapporti_pa_terzi,
    frequenza,
    rilevanza,
    outsourcing,
    prevedibilita,
  } = probabilita;
  const valueList = [
    incentivi_management,
    rapporti_pa_terzi,
    frequenza,
    rilevanza,
    outsourcing,
    prevedibilita,
  ];
  const data = {
    labels: Object.values(labels),
    datasets: [
      {
        label: "PROBABILITÀ",
        data: valueList,
        backgroundColor: ["rgba(116,186,248,0.5)"],
        borderColor: ["rgba(116,186,248,1)"],
      },
    ],
  };
  return (
    <Grid container className={"stepContainer"} xs={12}>
      <Grid container xs={12} className={"riskValutazioneContent"}>
        <Grid xs={12} item>
          <Typography variant={"subtitle1"}>
            Seleziona, oppure inserisci i valori da 1 (min) a 5 (max)
          </Typography>
        </Grid>
        <Grid xs={12} md={6} style={{ marginTop: "2em" }} item>
          <DiscreteSlider
            disabled={disabled}
            value={incentivi_management}
            setValue={(incentivi_management) =>
              setProbabilita({
                ...probabilita,
                incentivi_management,
              })
            }
            label={labels.INCENTIVI}
          />
          <DiscreteSlider
            disabled={disabled}
            value={rapporti_pa_terzi}
            setValue={(rapporti_pa_terzi) =>
              setProbabilita({
                ...probabilita,
                rapporti_pa_terzi,
              })
            }
            label={labels.RAPPORTI}
          />
          <DiscreteSlider
            disabled={disabled}
            value={frequenza}
            setValue={(frequenza) =>
              setProbabilita({
                ...probabilita,
                frequenza,
              })
            }
            label={labels.FREQUENZA}
          />
          <DiscreteSlider
            disabled={disabled}
            value={rilevanza}
            setValue={(rilevanza) => {
              setProbabilita({
                ...probabilita,
                rilevanza,
              });
            }}
            label={labels.RILEVANZA}
          />
          <DiscreteSlider
            disabled={disabled}
            value={outsourcing}
            setValue={(outsourcing) =>
              setProbabilita({
                ...probabilita,
                outsourcing,
              })
            }
            label={labels.OUTSOURCING}
          />
          <DiscreteSlider
            disabled={disabled}
            value={prevedibilita}
            setValue={(prevedibilita) =>
              setProbabilita({
                ...probabilita,
                prevedibilita,
              })
            }
            label={labels.FORECASTING}
          />
        </Grid>
        <Grid xs={12} md={6} className={"graphContainer"} item>
          <Radar options={options} data={data} />
        </Grid>
      </Grid>
      <Grid
        container
        className={"riskValutazioneFooter"}
        style={{
          borderTop: "1px solid #AAAAAA",
          paddingTop: "2rem",
          marginTop: 20,
        }}
        xs={12}
      >
        <Grid container xs={6}>
          <Grid style={{ marginRight: 20 }} item>
            <Typography style={{ fontWeight: "bold" }} variant={"h6"}>
              VALORE MEDIO:
            </Typography>
          </Grid>
          <Grid item>
            {" "}
            <Typography
              style={{ fontWeight: "bold"}}
              variant={"h5"}
              color={"primary"}
            >
              {getAverage(valueList)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={6} style={{ justifyContent: "flex-end" }}>
          <Grid style={{ marginRight: 20 }} item>
            <Button
              style={{ fontWeight: "bold" }}
              onClick={handleBack}
              color={"primary"}
              variant={"outlined"}
            >
              Indietro
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{ fontWeight: "bold" }}
              onClick={handleSaveStep}
              color={"primary"}
              variant={"contained"}
            >
              {disabled ? "prosegui" : "Salva e prosegui"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Step1;
