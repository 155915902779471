import { SAVE_USER, SAVE_COMPANY } from "../types";
import AuthService from "../../services/auth.service";

const authReducer = (state = {}, { type, user, companyId, companyName }) => {
  switch (type) {
    case SAVE_USER:
      return {
        ...state,
        ...user,
      };
    case SAVE_COMPANY:
      return {
        ...state,
        companyId,
        companyName,
      };
    default:
      return {
        ...state,
        ...(state.user || AuthService.user),
      };
  }
};

export default authReducer;
