import React, { useState, useEffect, useMemo } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {IconButton, ListItem, Paper} from "@material-ui/core";
import {Link as RouteLink, withRouter} from "react-router-dom";
import {ChangelogApi, HomePageApi} from "../../services/api.service";
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from "@material-ui/core/List";
import Container from "@material-ui/core/Container";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const CLApi = new ChangelogApi();

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        color: 'rgb(0 33 157 / 54%)',
        fontSize: '1.15rem',
        boxSizing: 'border-box',
        listStyle: 'none',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 500,
        lineHeight: 24
    },
    listSection: {
        backgroundColor: 'inherit',
        marginTop: 10,
        borderBottom: '1px solid black',
        paddingTop: 10,
        "&:nth-child(1)": {
            borderBottom: 'none'
        }
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    topHeader: {
        paddingTop: 100,
        paddingBottom: 100
    },
    subHeader: {
        fontSize: 18,
        fontWeight: 'bolder'
    }
}));

function Changelog({ history }) {
    const classes = useStyles();
    const [logs, setLogs] = useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            const changelog = await CLApi.getList();
            setLogs(changelog);
        };
        fetchData();
    }, []);

    return (
        <Container maxWidth={'md'} className={classes.topHeader}>
            <h2>Registro Aggiornamenti</h2>
            <List className={classes.root} subheader={<li />}>
                {logs.map((log) => (
                    <li key={`section-${log.id}`} className={classes.listSection}>
                        <ul className={classes.ul}>
                            <ListSubheader className={classes.subHeader}>{log.deploy_date} - {log.title}</ListSubheader>
                            <ListItem key={`item-${log.id}}`}>
                                <ListItemText primary={ReactHtmlParser(log.contenuto)} />
                            </ListItem>
                        </ul>
                    </li>
                ))}
            </List>
        </Container>
    );
}

Changelog.propTypes = {};

export default withRouter(Changelog);
