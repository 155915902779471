import { TOGGLE_VIEW_MODE, EDIT_MODE_ON, EDIT_MODE_OFF } from "../types";

const violationReducer = (
  state = { editMode: false, viewMode: false, area: null, reato: null },
  { type, payload }
) => {
  switch (type) {
    case TOGGLE_VIEW_MODE:
      return {
        ...state,
        viewMode: !state.viewMode,
      };
    case EDIT_MODE_ON:
      return {
        ...state,
        editMode: true,
        area: payload.area,
        reato: payload.reato,
      };
    case EDIT_MODE_OFF:
      return {
        ...state,
        editMode: false,
        area: null,
        reato: null,
      };
    default:
      return state;
  }
};

export default violationReducer;
