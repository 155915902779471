import {MODE_VIEW_TEMPLATE} from "../../../index.constants";
import {generateID} from "../../../index.utils";
import FormItem from "../form-item.class";

export default class DatePickerClass  extends FormItem {
    constructor({viewMode, displayName, name, ...props}) {
        super(props);
        this.type = 'date-picker';
        this.isEmpty = false;
        this.name = name || generateID(9);
        this.viewMode = viewMode || MODE_VIEW_TEMPLATE;
        // initialValue: moment('11/02/1993').toISOString(),
        this.displayName = displayName || 'Date/Time Picker';
    }

}
