import React, {useEffect, useState} from 'react';
import _ from 'lodash'
import MenuItem from "@material-ui/core/MenuItem";
import {TextField} from "@material-ui/core";
import {connect} from "react-redux";
import {useFormContext} from "react-hook-form";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import {Violation231CategoryApi} from "../../../services/api.service";
import Typography from "@material-ui/core/Typography";

const Violation231Category = new Violation231CategoryApi();
const Violation231CategoriesSelectComponent = ({api, name, disabled, required, onChange}) => {
    const {watch, errors, setValue, register} = useFormContext();
    const [violation231Categories, setCategories] = useState([]);
    const getHelperText = () => {
        if (!errors[name]) {
            return '';
        }
        return errors[name].message;
    };
    useEffect(
        () => {
            const fetchCategories = async () => {
                const reati231_cat = await Violation231Category.query();
                setCategories(_.orderBy(reati231_cat,"cat_descrizione","asc"));
            };
            fetchCategories();
        }, [api.fetch]
    );
    const handleChange = (e) => {
        setValue(name, e.target.value);
        onChange(name, e.target.value)
    };
    return (
        <Grid container direction={"column"}>
            <Grid item>
                <TextField select fullWidth label={'Categoria reato'}
                           placeholder={'Seleziona categoria reato'} disabled={disabled}
                           inputRef={register({name})} name={name}
                           value={watch(name) || ''} error={!!errors[name]}
                           helperText={getHelperText()}
                           onChange={handleChange}>
                    {!!violation231Categories.length && <MenuItem>-- tutti --</MenuItem>}
                    {violation231Categories.map(
                        ({uuid, cat_descrizione}, index) =>
                            <MenuItem key={uuid} value={uuid} style={{
                                whiteSpace: "pre-wrap",
                                paddingRight: '2.5rem',
                                backgroundColor: index % 2 !== 0 ? 'inherit' : '#f5f5f5'
                            }}>
                                <Typography variant={"body2"} style={{whiteSpace: 'pre-wrap'}}>{cat_descrizione}</Typography>
                            </MenuItem>
                    )}
                    {!violation231Categories.length &&
                    <MenuItem>Nessuna categoria ancora presente</MenuItem>}
                </TextField>
            </Grid>
        </Grid>
    );
};

Violation231CategoriesSelectComponent.defaultProps = {
    name: 'cat_uuid',
    onChange: () => console.log('trigger on change typology select'),
    disabled: false
};

Violation231CategoriesSelectComponent.propTypes = {
    name: PropTypes.string,
    required: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};
export default connect(mapStateToProps)(Violation231CategoriesSelectComponent);
