import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";

const style = makeStyles(theme => ({
    templateWrapper: {
        cursor: 'grab',
        width: '200px'
    },
    template: {
        padding: theme.spacing(1)
    }
}));

const TextFieldTemplate = props => {
    const classes = style();
    const handleDragStart = (e) => {
        e.dataTransfer.setData('new-template', 'text-field');
    };
    return (
        <div className={classes.templateWrapper} draggable onDragStart={handleDragStart}
             onDragOver={(e) => e.preventDefault()}>
            <Grid container direction={"column"} className={classes.template}>
                <Grid item>
                    <Typography variant={"subtitle2"} gutterBottom>Text field/Select</Typography>
                </Grid>
                <Grid item>
                    <TextField variant={"outlined"} fullWidth={true} disabled={true}
                               placeholder={'Text Input'}/>
                </Grid>
            </Grid>
        </div>
    );
};

TextFieldTemplate.propTypes = {};

export default TextFieldTemplate;
