import React from 'react';
import PropTypes from 'prop-types';
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import {useFormContext} from "react-hook-form";
import FormHelperText from "@material-ui/core/FormHelperText";

const SliderCompile = (props) => {
    const {label, name, marks, min, max, helperText, initialValue, step, orientation, validate, disabled, range} = props;
    const {register, setValue, errors} = useFormContext();
    const handleChange = (e, value) => {
        setValue(name, value);
    };

    function valuetext(value) {
        return `${value}`;
    }
    return (
        <div>
            <Typography id="discrete-slider" style={{marginBottom: '3rem'}}>{label}</Typography>
            <Slider
                defaultValue={range ? [initialValue[0], initialValue[1]] : initialValue} orientation={orientation} disabled={disabled}
                getAriaValueText={valuetext} aria-labelledby={`${label}-slider`} onChange={handleChange}
                ref={register({name: name}, {validate})}
                valueLabelDisplay="on" step={step} marks={marks} min={min} max={max}/>
            <FormHelperText style={{color: errors[name] ? 'red' : 'inherit'}}>
                {errors[name] ? errors[name].message : helperText}
            </FormHelperText>
        </div>
    );
};

SliderCompile.defaultProps = {
    min: 0,
    max: 0,
    initialValue: 0,
    step: 1,
    marks: true,
    disabled: false,
    label: 'Inserisci titolo',
    orientation: 'horizontal',
    validate: () => true
};

SliderCompile.propTypes = {
    validate: PropTypes.func.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    initialValue: PropTypes.number,
    helperText: PropTypes.string,
    step: PropTypes.number,
    marks: PropTypes.bool,
    disabled: PropTypes.bool,
    orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

export default SliderCompile;
