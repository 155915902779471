import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ToolbarComponent from "../components/ui/toolbar/toolbar.component";
import DrawerComponent from "../components/ui/drawer/drawer.component";

const useStyles = makeStyles(theme => ({
    absoluteBar: {
        background: 'transparent',
        boxShadow: 'none'
    },
    stickyBar: {
        backgroundColor: '#474B57',
    },
    logo: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)'
    },
    body: {
        marginTop: 'auto',
        paddingTop: '64px',
        marginBottom: 'auto'
    }
}));

const FormBuilderLayout = ({children, toolbarPosition}) => {
    const classes = useStyles();
    const [open, toggleDrawer] = useState(false);
    const toggle = () => {
        toggleDrawer(!open);
    };
    return (
        <Grid container direction={"column"}>
            <Grid item xs={12}>
                <ToolbarComponent position={toolbarPosition} toggleDrawer={toggle}/>
                <DrawerComponent open={open} toggleDrawer={toggle}/>
            </Grid>
            <Grid item className={classes.body}>
                {children}
            </Grid>
        </Grid>
    );
};

FormBuilderLayout.defaultProps = {
    toolbarPosition: 'sticky'
};

FormBuilderLayout.propTypes = {
    toolbarPosition: PropTypes.string
};


export default FormBuilderLayout;
