import PropTypes from 'prop-types';
import AuthService from "../../../services/auth.service";

const CrudCtaComponent = ({com_id, children}) => {
    const isAdmin = AuthService.isAdmin();
    if (isAdmin || com_id) {
        return children
    } else {
        return null;
    }
};

CrudCtaComponent.propTypes = {
    com_id: PropTypes.number,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func
};

export default CrudCtaComponent;