import React from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import {Card} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import {connect} from "react-redux";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {handleModal} from "../../redux/actions";
import TutorialVideoComponent from "../../components/ui/tutorial-video/tutorial-video.component";
import PageSubtitleComponent from '../../components/ui/page-subtitle/page-subtitle.component';

const style = makeStyles(theme => ({
    tutorialTitle:{
        minHeight: 50
    }
}))

const TutorialPage = ({dispatch}) => {
    const classes = style();
    const tutorials = [
        {
            title: '01 - Introduzione e gestione dell’account e delle società',
            cover: 'v24screen-01.png',
            video: '01 - Introduzione e gestione dell account e delle societa.mp4'
        },
        {
            title: '02 - Le categorie e i reati 231',
            cover: 'v24screen-02.png',
            video: '02 - Le categorie e i reati 231.mp4'
        },
        {
            title: '03 - Schemi di controllo - Introduzione',
            cover: 'v24screen-03.png',
            video: '03 - Schemi di controllo - Introduzione.mp4'
        },
        {
            title: '04 - Schemi di Controllo - Tabelle',
            cover: 'v24screen-04.png',
            video: '04 - Schemi di Controllo - Tabelle.mp4'
        },
        {
            title: '05 - Schemi di Controllo - Matrici',
            cover: 'v24screen-05.png',
            video: '05 - Schemi di Controllo - Matrici.mp4'
        },
        {
            title: '06 - Modello Organizzativo - Parte Generale',
            cover: 'v24screen-06.png',
            video: '06 - Modello Organizzativo - Parte Generale.mp4'
        },
        {
            title: '07 - Il modello Organizzativo - Parte Speciale',
            cover: 'v24screen-07.png',
            video: '07 - Il modello Organizzativo - Parte Speciale.mp4'
        },
        {
            title: '08 - Flussi OdV - Introduzione',
            cover: 'v24screen-08.png',
            video: '08 - Flussi OdV - Introduzione.mp4'
        },
        {
            title: '09 - Flussi OdV - Struttura',
            cover: 'v24screen-09.png',
            video: '09 - Flussi OdV - Struttura.mp4'
        },
        {
            title: '10 - Organizzazione e utenze',
            cover: 'v24screen-10.png',
            video: '10 - Struttura Organizzativa.mp4'
        },
        {
            title: '11 - Flussi per L_OdV - Raccolta Flussi',
            cover: 'v24screen-11.png',
            video: '11 - Flussi per L_OdV - Raccolta Flussi.mp4'
        },
    ];
    const openTutorial = (tutorial) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            content: <TutorialVideoComponent {...tutorial} />
            ,
        }));
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent hideHelp/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Tutorial'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="stretch">
                            {tutorials.map(
                                (tutorial) =>
                                    <Grid item xs={12} md={6} lg={4} xl={4} key={tutorial.cover}>
                                        <Card>
                                            <CardMedia
                                                style={{
                                                    height: 175,
                                                    backgroundSize: 'contain'
                                                }}
                                                image={require(`../../tutorials/screen/${tutorial.cover}`)}
                                                title={tutorial.title}/>
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item>
                                                        <Typography variant={"body1"} className={classes.tutorialTitle}>{tutorial.title}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <CardActions>
                                                <Button color={"secondary"} fullWidth onClick={openTutorial(tutorial)}>
                                                    apri tutorial
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default connect()(TutorialPage);
