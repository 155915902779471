import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {CircularProgress, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import Button from "@material-ui/core/Button";
import {handleModal, handleToast} from "../../redux/actions";
import SafeguardingPolicyTermsSectionsTable from "../../components/atomic-tables/safeguarding-policy-terms-sections/safeguarding-policy-terms-sections.table";
import SafeguardingPolicySectionsComponent from "../../components/CRUD/safeguarding-policy-sections/safeguarding-policy-sections.component";
import SafeguardingPolicyTable from "../../components/atomic-tables/safeguarding-policy-models/safeguarding-policy-models.table";
import SafeguardingPolicyTree from '../../components/tree/safeguarding-policy.tree'
import SafeguardingPolicyModelsComponents from "../../components/CRUD/safeguarding-policy-models/safeguarding-policy-models.component";
import { SafeguardingPolicyModelsApi } from "../../services/api.service";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

const SafeguardingPolicyModels = new SafeguardingPolicyModelsApi();
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const SafeguardingPolicyPage = ({dispatch, location: {search}, history}) => {
    const [parentTitle, setParentTitle] = useState();
    const [previewLoading, setPreviewLoading] = useState();
    const [previewWordLoading, setPreviewWordLoading] = useState();
    const [cloning, setCloning] = useState(false);
    const createSection = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Aggiungi sezione',
            content: <SafeguardingPolicySectionsComponent mode={'create'}/>,
        }));
    };
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = async () => {
        setCloning(true);
        let confirmed = await SafeguardingPolicyModels.copyDefault();
        console.log(confirmed);
        setOpen(false);
        setCloning(false);
        window.location.reload();
    };
    const confirmReimportSafeguardingPolicy = () => {
        setOpen(true);
    };
    const preview = async (type) => {
        try {
            const file = await SafeguardingPolicyModels.downloadPreview(type);
        } catch (e) {
            console.error(e);
            dispatch(handleToast({
                message: 'Impossibile scaricare l\'anteprima'
            }));
        }
        setPreviewLoading(false);
    };

    const createGeneralModel = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Crea versione',
            content: <SafeguardingPolicyModelsComponents mode={'create'}/>,
        }));
    };
    useEffect(
        () => {
            const params = search.replace('?', '').split('&').map((param) => {
                const keyValue = param.split('=');
                return {
                    [keyValue[0]]: keyValue[1]
                }
            });
            const parent = params.find(({parent_title}) => parent_title);
            if (parent) {
                setParentTitle(decodeURI(parent.parent_title));
            } else {
                setParentTitle();
            }
        },
        [search]
    );
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Safeguarding Policy - Elenco Sezioni`} back/>
                    </Grid>
                    <Grid item container alignItems={"center"} spacing={2}>
                        <Grid item xs={12}>
                            <Grid item container alignItems={"flex-start"} spacing={2}>
                                <Grid item xs={3}>
                                    <Grid item container style={{marginBottom: 15 }}>
                                        <Grid item>
                                            <Typography variant={"h6"}>Naviga sezioni</Typography>
                                            <Typography variant={"subtitle1"}>Doppio click per aprire sezione</Typography>
                                        </Grid>
                                    </Grid>
                                    <SafeguardingPolicyTree />
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid item container alignItems={"flex-start"} style={{marginBottom: 15 }}>
                                        {/*parentTitle && (
                                            <Grid item>
                                                <IconButton onClick={() => history.goBack()}>
                                                    <Icon>arrow_back</Icon>
                                                </IconButton>
                                            </Grid>
                                        )*/}
                                        <Grid item xs>
                                            <Typography variant={"h6"}>{parentTitle ? parentTitle : 'Elenco Sezioni'}</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Button variant={"outlined"} onClick={confirmReimportSafeguardingPolicy} color={"secondary"}><ReportProblemOutlinedIcon /> Importa modello</Button>
                                            <Dialog
                                                open={open}
                                                TransitionComponent={Transition}
                                                keepMounted
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-slide-title"
                                                aria-describedby="alert-dialog-slide-description"
                                            >
                                                <DialogTitle id="alert-dialog-slide-title">{"Importa modello base"}</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-slide-description">
                                                        Attenzione, questa azione sovrascriverà ogni modifica effettuata alla corrente Safeguarding Policy e non sarà possibile tornare indietro, confermi?
                                                    </DialogContentText>
                                                </DialogContent>
                                                {!cloning && <DialogActions>
                                                    <Button onClick={handleClose} color="primary">
                                                        Annulla
                                                    </Button>
                                                    <Button onClick={handleConfirm}  style={{backgroundColor: '#ff6052'}}>
                                                        Confermo
                                                    </Button>
                                                </DialogActions>}
                                                {cloning && <DialogActions>
                                                    <Button disabled={true} color="primary">
                                                        Clonazione in corso, attendere ...
                                                    </Button>
                                                </DialogActions>}
                                            </Dialog>
                                        </Grid>
                                        <Grid item>
                                            <Button variant={"outlined"} onClick={createSection}>Nuova sezione</Button>
                                        </Grid>
                                    </Grid>
                                    <SafeguardingPolicyTermsSectionsTable />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Safeguarding Policy - Elenco Versioni`}/>
                    </Grid>
                    <Grid item container alignItems={"center"} spacing={2}>
                        <Grid item container alignItems={"center"} spacing={2}>
                            <Grid item xs>
                                <Typography variant={"h6"}>Elenco versioni</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"} onClick={() => preview('docx')} disabled={previewWordLoading}>
                                    <Grid container alignItems={"center"} spacing={1}>
                                        <Grid item>
                                            {!!previewWordLoading ? 'Download' : 'Anteprima Word'}
                                        </Grid>
                                        {!!previewWordLoading &&
                                        <Grid item style={{display: 'block'}}>
                                            <CircularProgress size={15} color={"inherit"} style={{display: 'block', margin: 'auto'}}/>
                                        </Grid>}
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} onClick={createGeneralModel}>Nuova versione</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <SafeguardingPolicyTable/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default connect()(withRouter(SafeguardingPolicyPage));
