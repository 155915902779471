import React, {useEffect, useState} from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import {TextField} from "@material-ui/core";
import {connect} from "react-redux";
import {useFormContext} from "react-hook-form";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import {RiskAreaTypologyApi} from "../../../services/api.service";

const RiskAreaTypology = new RiskAreaTypologyApi();
const RiskAreaTypologiesSelectComponent = ({api, name, disabled, required, onChange}) => {
    const {watch, errors, setValue, register} = useFormContext();
    const [riskAreaTypologies, setTypologies] = useState([]);
    const getHelperText = () => {
        if (!errors[name]) {
            return '';
        }
        return errors[name].message;
    };
    useEffect(
        () => {
            const fetchTypologies = async () => {
                const aree_rischio_tipologie = await RiskAreaTypology.query();
                setTypologies(aree_rischio_tipologie);
            };
            fetchTypologies();
        }, [api.fetch]
    );
    const handleChange = (e) => {
        setValue(name, e.target.value);
        onChange(name, e.target.value)
    };
    return (
        <Grid container direction={"column"}>
            <Grid item>
                <TextField select fullWidth label={'Tipologia area di rischio'}
                           placeholder={'Seleziona tipologia'} disabled={disabled}
                           inputRef={register({name})} name={name}
                           value={watch(name) || ''} error={!!errors[name]}
                           helperText={getHelperText()}
                           onChange={handleChange}>
                    {!!riskAreaTypologies.length && <MenuItem value="">-- tutti --</MenuItem>}
                    {riskAreaTypologies.map(
                        ({uuid, art_name}) =>
                            <MenuItem key={uuid} value={uuid}>
                                {art_name}
                            </MenuItem>
                    )}
                    {!riskAreaTypologies.length &&
                    <MenuItem>Nessuna tipologia ancora presente</MenuItem>}
                </TextField>
            </Grid>
        </Grid>
    );
};

RiskAreaTypologiesSelectComponent.defaultProps = {
    name: 'art_uuid',
    onChange: ()=> console.log('trigger on change typology select'),
    disabled: false
};

RiskAreaTypologiesSelectComponent.propTypes = {
    name: PropTypes.string,
    required: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};
export default connect(mapStateToProps)(RiskAreaTypologiesSelectComponent);
