import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import AutoCompleteComponent from "../../components/ui/auto-complete/auto-complete.component";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import {ProtocolApi, RiskAreaApi, RiskAreaMatrixApi} from "../../services/api.service";
import Fab from "@material-ui/core/Fab";
import {handleToast} from "../../redux/actions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import orderBy from 'lodash/orderBy';

const RiskArea = new RiskAreaApi();
const RiskAreaMatrix = new RiskAreaMatrixApi();
const Protocols = new ProtocolApi();
const MatrixProtocolsRiskAreaPage = ({dispatch}) => {
    const [protocols, setProtocols] = useState([]);
    const [riskAreas, setRiskAreas] = useState([]);
    const [filter, setFilter] = React.useState();
    useEffect(
        () => {
            const fetchData = async () => {
                let aree_rischio = await RiskAreaMatrix.query({
                    matrice: 'protocolli'
                });
                aree_rischio = aree_rischio.map(area => {
                    area.aree_rischio_protocolli = area.aree_rischio_protocolli.filter(area_protocollo => area_protocollo.protocolli)
                    return area
                })
                let protocolli = await Protocols.query();
                protocolli = protocolli.map(
                    ({uuid, pro_titolo, pro_attivo}) => {
                        return {
                            label: pro_titolo,
                            value: uuid,
                            incluso: pro_attivo
                        }
                    });
                setProtocols(orderBy(protocolli, ['label'], ['asc']));
                setRiskAreas(aree_rischio.filter(area => filter ? area.are_titolo.toLowerCase().search(filter.toLowerCase()) !== -1 : true));

            };
            fetchData();
        }, [filter]
    );
    const updateRiskAreas = (riskAreaId) => (protocols) => {
        setRiskAreas([
            ...riskAreas.map((area) => {
                if (area.uuid === riskAreaId) {
                    area.aree_rischio_protocolli = protocols || [];
                }
                return area;
            })
        ]);
    };
    const save = async () => {
        try {
            await Promise.all(await riskAreas.map(
                async ({aree_rischio_protocolli, uuid}) => {
                    await RiskArea.updateProtocols({
                        uuid,
                        protocolli: aree_rischio_protocolli.map(({value, protocolli}) => value || (protocolli ? protocolli.uuid : undefined))
                    })
                })
            );
            dispatch(handleToast({
                message: 'Protocolli salvati'
            }));
        } catch (message) {
            dispatch(handleToast({
                message
            }))
        }
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0', position: 'relative'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Matrice Aree di rischio per protocolli'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Toolbar>
                            <TextField label={'Cerca'} fullWidth={true}
                                       onChange={(e) => setFilter(e.target.value)}
                                       InputProps={{
                                           endAdornment:
                                               <InputAdornment position="end">
                                                   <Icon>search</Icon>
                                               </InputAdornment>
                                       }}/>
                        </Toolbar>
                    </Grid>
                    {riskAreas.map((area) => {
                         const {uuid, are_titolo, aree_rischio_protocolli} = area;

                         // Lista di UUID dei protocolli già selezionati con incluso = true
                         const selectedProtocolUuids = aree_rischio_protocolli
                             .filter(item => item.protocolli && item.protocolli.incluso)
                             .map(item => item.protocolli.uuid);
                     
                         // Suggerimenti: protocolli con incluso = true che non sono già selezionati
                         const filteredProtocols = protocols
                             .filter(protocol => protocol.incluso && !selectedProtocolUuids.includes(protocol.uuid));
                     
                         const includedDefaultValues = aree_rischio_protocolli
                             .filter(({protocolli}) => protocolli && protocolli.pro_attivo)
                             .map(({protocolli}) => {
                                 return {
                                     label: protocolli.pro_titolo,
                                     value: protocolli.uuid
                                 };
                             });
                

                         return (
                             <Grid item key={uuid} style={{minWidth: '100%'}}>
                                 <Card style={{overflow: 'visible'}}>
                                     <CardContent>
                                         <AutoCompleteComponent 
                                             suggestions={filteredProtocols}
                                             defaultValues={includedDefaultValues}
                                             onChange={updateRiskAreas(area.uuid)} multiple
                                             label={are_titolo} placeholder={'Inserisci protocolli'}
                                         />
                                     </CardContent>
                                 </Card>
                             </Grid>
                         );
                    })}
                    <Grid item xs={12}>
                        <Fab
                            onClick={save}
                            style={{
                                position: 'fixed',
                                right: '70px',
                                top: '335px',
                                width: '120px',
                            }} aria-label={'Salva'} color={'secondary'} variant={"extended"}>Salva</Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

MatrixProtocolsRiskAreaPage.propTypes = {};

export default connect()(MatrixProtocolsRiskAreaPage);
