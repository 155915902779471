import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { GeneralTreeApi } from "../../services/api.service";

const GeneralTree = new GeneralTreeApi();

const useStyles = makeStyles({
    treeContainer: {
        flexGrow: 1,
        width: '100%',
        height: 'auto',

    },
    treeItemWithChildren: {
        marginBottom: 10
    },
    treeItem:{
        marginBottom: 5
    }
});

const GeneralSectionsTree = ({history, location: {search}}) => {
    const classes = useStyles();
    const [tree, setTree] = useState(null);

    const clickedNode = useCallback((e,node) => {
        if(node){
            history.push(`/modello-organizzativo/parte-generale?parent_uuid=${node.uuid}&parent_title=${node.titolo}`)
            window.scrollTo(0,0)
        }
        e.stopPropagation()
        e.preventDefault()
    },[history])

    const fetchData = async () => {
        const tree = await GeneralTree.query();
        setTree(tree);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const renderNode = useCallback((node,parent = null) => {
        if(node.children && node.children.length>0){
            return (
                <TreeItem key={node.uuid} nodeId={node.uuid} label={node.titolo} onDoubleClick={(e) => clickedNode(e,node)} className={classes.treeItemWithChildren}>
                    {node.children.map(child => renderNode(child,node))}
                </TreeItem>
            )
        }

        return <TreeItem key={node.uuid} nodeId={node.uuid} label={`- ${node.titolo}`} className={classes.treeItem} onDoubleClick={(e) => clickedNode(e,parent)} />
    },[classes, clickedNode])

    const renderTree = useMemo(() => {
        if(!tree) return <CircularProgress />
        if(tree && tree.length === 0) return <Typography variant="body1">Non sono presenti sezioni al momento</Typography>
        return (
            <TreeView
                className={classes.treeContainer}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
            >
            {tree.map(node => renderNode(node))}
        </TreeView>
        )
    },[tree, classes, renderNode])

    return renderTree
}

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};
export default connect(mapStateToProps)(withRouter(GeneralSectionsTree))
