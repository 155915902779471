import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import {Violation231CategoryApi} from "../../../services/api.service";
import PageSubtitleComponent from "../../ui/page-subtitle/page-subtitle.component";
import TextField from "@material-ui/core/TextField";
import envConfig from "../../../config.json";

const Violation231Category = new Violation231CategoryApi();
const hasNormattiva = ((envConfig.CUSTOMER ?? "default") == "Sole24") ? false : true;
const ViolationCategoryComponent = ({dispatch, mode, category}) => {
    const [loading, setLoading] = useState(false);
    const addCategoryInputs = [
        {
            name: 'cat_descrizione',
            required: 'campo obbligatorio',
            label: 'Nome categoria',
            placeholder: 'Inserisci nome',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete',
            multiline: true
        },
        {
            name: 'cat_articolo',
            required: false,
            label: 'ART D.LGS 231',
            placeholder: 'Inserisci art',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete'
        },
        {
            name: 'cat_normativa',
            required: false,
            label: 'Collegamento al portale Normattiva',
            placeholder: 'https://...',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete'
        },
        {
            name: 'cat_commento',
            required: false,
            label: 'Commento legale',
            placeholder: 'Inserisci commento',
            width: 12,
            margin: 'normal',
            disabled: mode === 'read' || mode === 'delete',
            multiline: true
        }
    ];
    const categoryForm = useForm({
        mode: 'onSubmit',
        defaultValues: mode !== 'create' ? category : {}
    });
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onCategorySave = async (data) => {
        try {
            setLoading(true);
            switch (mode) {
                case 'create':
                    await Violation231Category.create(data);
                    dispatch(handleToast({
                        message: 'Categoria creata'
                    }));
                    break;
                case 'update':
                    await Violation231Category.update({
                        uuid: category.uuid,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Categoria modificata'
                    }));
                    break;
                case 'delete':
                    await Violation231Category.delete(category.uuid);
                    dispatch(handleToast({
                        message: 'Categoria eliminata'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <FormContext {...categoryForm}>
            <form onSubmit={categoryForm.handleSubmit(onCategorySave)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        { mode !== 'read' && <GenericTextFieldsForm inputs={addCategoryInputs.filter(item => (hasNormattiva || item.name!='cat_normativa'))}/>}
                        { mode === 'read' &&
                        <Grid container direction={"column"} spacing={2}>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} key={'cat_descrizione'}>
                                        <TextField label={'Nome Categoria *'} fullWidth
                                                   defaultValue={category.cat_descrizione} name={'cat_descrizione'}
                                                   type={'text'} disabled={true}
                                                   multiline={false}/>
                                    </Grid>
                                    <Grid item xs={12} key={'cat_articolo'}>
                                        <TextField label={'ART D.LGS 231'} fullWidth
                                                   defaultValue={category.cat_articolo} name={'cat_articolo'}
                                                   type={'text'} disabled={true}
                                                   multiline={false}/>
                                    </Grid>
                                    { hasNormattiva &&
                                    <Grid item xs={12} key={'cat_normativa'}>
                                        {category.cat_normativa && <a href={category.cat_normativa} target='_blank'>{category.cat_normativa}</a>}
                                        {!category.cat_normativa && <i>Nessun collegamento alla normativa disponibile</i>}
                                    </Grid>
                                    }
                                    <Grid item xs={12} key={'cat_commento'}>
                                        <TextField label={'Commento Legale'} fullWidth
                                                   defaultValue={category.cat_commento} name={'cat_commento'}
                                                   type={'text'} disabled={true}
                                                   multiline={true}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && mode!=='read' && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

ViolationCategoryComponent.defaultProps = {
    category: {}
};
ViolationCategoryComponent.propTypes = {
    category: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(ViolationCategoryComponent);
