import React, { useEffect, useState } from "react";
import { Violation231Api, CompanyApiNew } from "../../../services/api.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import AuthService from "../../../services/auth.service";

const Violation231 = new Violation231Api();
const RiskAreaSelect = ({ label, setReato, reato, api }) => {
  const [fetchedItems, setFetchedItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await Violation231.query();
      setFetchedItems(data);
    };
    fetchData();
  }, [api.fetch]);

  return (
    <Grid container style={{ justifyContent: "flex-end" }}>
      <Grid item xs={10}>
        <Autocomplete
          id="combo-box-demo"
          value={reato}
          options={[
            ...fetchedItems.map((item, index) => ({
              title: item.rea_titolo + " - " + item.rea_articolo,
              id: item.id,
              compiled: item.compiled,
            })),
          ]}
          onChange={(event, newValue) => {
            setReato(newValue);
          }}
          getOptionLabel={(option) => option.title}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} label={label} variant="outlined" />
          )}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ fetchApiReducer }) => {
  return {
    api: fetchApiReducer,
  };
};
export default connect(mapStateToProps)(RiskAreaSelect);
