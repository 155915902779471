import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import {ReferentApi} from "../../../services/api.service";

const Referent = new ReferentApi();
const ReferentComponent = ({dispatch, mode, referent}) => {
    const [loading, setLoading] = useState(false);
    const disabled = mode === 'read' || mode === 'delete';
    const addRiskReferentInputs = [
        {
            name: 'ref_referente',
            required: 'campo obbligatorio',
            label: 'Nome',
            placeholder: 'Inserisci nome',
            width: 12,
            margin: 'normal',
            autoComplete: "off",
            disabled,
            validate: (value) => ((!value && (referentForm.watch('ref_email') || referentForm.watch('ref_telefono'))) || (!value && !referentForm.watch('ref_email') && !referentForm.watch('ref_telefono'))) ? 'Nome richiesto' : true
        },
        {
            name: 'ref_email',
            required: 'campo obbligatorio',
            label: 'Email',
            placeholder: 'Inserisci email',
            width: 12,
            margin: 'normal',
            autoComplete: "email-new",
            disabled,
            validate: (value) => ((!value && (referentForm.watch('ref_telefono') || referentForm.watch('ref_referente')))  || (!value && !referentForm.watch('ref_email') && !referentForm.watch('ref_telefono'))) ? 'Email richiesta' : true
        },
        {
            name: 'ref_telefono',
            label: 'Telefono',
            required: 'campo obbligatorio',
            placeholder: 'Inserisci telefono',
            width: 12,
            margin: 'normal',     
            autoComplete: "phone-new",      
            disabled,
            validate: (value) => (mode!=='delete' &&  ((!value && (referentForm.watch('ref_email') || referentForm.watch('ref_referente')))  || (!value && !referentForm.watch('ref_email') && !referentForm.watch('ref_telefono')))) ? 'Telefono richiesto' : true
        }
    ];
    const referentForm = useForm({
        mode: 'onSubmit',
        defaultValues: mode !== 'create' ? {
            ...referent,
            fun_uuid: referent.funzioni ? referent.funzioni.uuid : null
        } : {}
    });
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onReferentSave = async (data) => {
        try {
            setLoading(true);
            switch (mode) {
                case 'create':
                    await Referent.create({
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Referente creato'
                    }));
                    break;
                case 'update':
                    await Referent.update({
                        uuid: referent.uuid,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Referente modificato'
                    }));
                    break;
                case 'delete':
                    await Referent.delete(referent.uuid);
                    dispatch(handleToast({
                        message: 'Referente eliminato'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <FormContext {...referentForm}>
            <form onSubmit={referentForm.handleSubmit(onReferentSave)} autoComplete="new-password">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm inputs={addRiskReferentInputs}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

ReferentComponent.defaultProps = {
    referent: {}
};
ReferentComponent.propTypes = {
    referent: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(ReferentComponent);
