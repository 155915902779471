import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import {useFormContext} from "react-hook-form";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: theme.spacing(1, 0),
    },
}));

const ChecksGroupCompile = props => {
    const {label, name, checksGroup, disabled, ...otherProps} = props;
    const {register, setValue, watch, errors} = useFormContext();
    const classes = useStyles();
    const handleChange = (e) => {
        setValue(e.target.name, e.target.checked);
    };
    return (
        <FormControl component="fieldset" className={classes.formControl} disabled={disabled}>
            <FormLabel component="legend">{label}</FormLabel>
            {!checksGroup.length && <FormHelperText color={"error"}>Error! Missing checks options!</FormHelperText>}
            <FormGroup aria-label={label} name={name} className={classes.group}>
                {checksGroup.map(
                    ({label, initialValue, disabled, name, required}) => (
                        <Grid container key={name}>
                            <Grid item>
                                <FormControlLabel control={
                                    <Checkbox onChange={handleChange} disabled={disabled}
                                              name={name} checked={watch(name) || initialValue} inputRef={register({required})}/>
                                } label={label} {...otherProps}/>
                                {errors[name] &&
                                <Typography color={"error"} variant={"body2"}>{errors[name].message}</Typography>}
                            </Grid>
                        </Grid>
                    ))}
            </FormGroup>
        </FormControl>
    );
};

ChecksGroupCompile.defaultProps = {
    label: 'Titolo',
    checksGroup: []
};

ChecksGroupCompile.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checksGroup: PropTypes.arrayOf(
        PropTypes.exact({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            helperText: PropTypes.string,
            initialValue: PropTypes.bool,
            required: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool
            ]),
            disabled: PropTypes.bool
        })
    ).isRequired
};

export default ChecksGroupCompile;
