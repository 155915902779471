import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import useForm, {FormContext} from "react-hook-form";
import {withRouter} from "react-router-dom";

const SelectUserForm = ({onSubmit, history}) => {
    const accountForm = useForm({
        mode: 'onSubmit'
    });
    const agentCode = [
        {
            name: 'agent_code',
            required: false,
            label: 'Inserisci il tuo codice agente',
            placeholder: 'Codice agente...',
            width: 12
        }
    ];
    const accountInputs = [
        {
            name: 'p_iva',
            validate: (value) => accountForm.watch('fiscal_code') ? true : (value && (value.length === 11 || value.length === 16) ? true : 'P. Iva non valida'),
            label: 'P. iva',
            placeholder: 'Inserisci p. iva...',
            width: 4
        },
        {
            name: 'fiscal_code',
            validate: (value) => accountForm.watch('p_iva') ? true : (value && value.length === 11 ? true : 'C.F. non valido'),
            label: 'Codice Fiscale',
            placeholder: 'Inserisci codice fiscale...',
            width: 8
        }
    ];
    return (
        <FormContext {...accountForm}>
            <form onSubmit={accountForm.handleSubmit(onSubmit)}>
                <Grid container justify={"space-between"} spacing={4}>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm title={'CODICE AGENTE'} inputs={agentCode}/>
                    </Grid>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm title={'SELEZIONA ACCOUNT'} inputs={accountInputs}/>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => history.goBack()}>
                            Indietro
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" type="submit" color={"primary"}>
                            Avanti
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    );
};

SelectUserForm.defaultProps = {
    account: {}
};

SelectUserForm.propTypes = {
    account: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default withRouter(SelectUserForm);
