import React, { useEffect, useState } from "react";
import {
  Violation231Api,
  ViolationFromAreaApi,
} from "../../../services/api.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, IconButton, Icon, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { handleModal } from "../../../redux/actions";
import ViolationInfoModal from "../../violation-info-modal/ViolationInfoModal";
import { isNotCompiled } from "../../../constants/graph";

const Violation231 = new Violation231Api();
const ViolationFromArea = new ViolationFromAreaApi();
const RiskAreaSelect = ({
  refetch,
  label,
  area,
  setReato,
  reato,
  api,
  dispatch,
  editMode,
  companyId,
  disabled,
  edit,
}) => {
  const [fetchedItems, setFetchedItems] = useState([]);

  const [reatoValue, setReatoValue] = useState(reato);
  const getOptionsArray = () => {
    if (area?.id == 0) {
      return [
        ...fetchedItems.map((item, index) => ({
          title: item.rea_titolo + " - " + item.rea_articolo,
          id: item.id,
          compiled: item.compiled,
          ...item,
        })),
      ];
    } else {
      if (edit) {
        return [
          { title: "Tutti i reati", id: 0 },
          ...fetchedItems.map((item, index) => ({
            title: item.rea_titolo + " - " + item.rea_articolo,
            id: item.id,
            compiled: item.compiled,
            ...item,
          })),
        ];
      } else {
        if ( fetchedItems.reati ) {
          return [
            { title: "Tutti i reati", id: 0 },
            ...fetchedItems.reati
                .filter((item) => isNotCompiled(item))
                .map((item, index) => ({
                  title: item.rea_titolo + " - " + item.rea_articolo,
                  id: item.id,
                  compiled: item.compiled,
                  ...item,
                })),
          ];
        }
        return [
          { title: "Tutti i reati", id: 0 },
          ...fetchedItems
            .filter((item) => isNotCompiled(item))
            .map((item, index) => ({
              title: item.rea_titolo + " - " + item.rea_articolo,
              id: item.id,
              compiled: item.compiled,
              ...item,
            })),
        ];
      }
    }
  };

  const showInfo = (violation) => () => {
    dispatch(
      handleModal({
        open: true,
        maxWidth: "lg",
        title: violation.rea_titolo,
        content: <ViolationInfoModal id={violation.uuid} />,
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (area && area.id !== 0) {
          const reatiCall = await ViolationFromArea.query(area.uuid);
        const reati = reatiCall.reati;
        if (reato && !disabled && !editMode) {
            setReato(
                reati.find((r) => r.id === reato.id) || {
                    title: "Tutti i reati",
                    id: 0,
                }
            );
        }
        setFetchedItems(reati);
      } else {
        const data = await Violation231.query();
        setFetchedItems(data);
      }
    };

    fetchData();
  }, [refetch, area, api.fetch]);

  return (
    <Grid container style={{ justifyContent: "center" }}>
      <Grid item xs={10}>
        <Autocomplete
          disabled={disabled}
          id="combo-box-demo"
          value={reatoValue}
          options={getOptionsArray()}
          onChange={(event, newValue) => {
            setReato(newValue);
            setReatoValue(newValue);
          }}
          getOptionLabel={(option) => option.title}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} label={label} variant="outlined" />
          )}
        />
      </Grid>

      <Grid item xs={2} style={{ alignContent: "center" }}>
        {reato && reato.id != 0 && (
          <IconButton style={{ height: "100%" }} onClick={showInfo(reato)}>
            <Icon>info</Icon>
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ fetchApiReducer }) => {
  return {
    api: fetchApiReducer,
  };
};
export default connect(mapStateToProps)(RiskAreaSelect);
