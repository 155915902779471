import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Checkbox, CircularProgress, Fab, FormControlLabel, FormGroup, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { FunzioniMatriceApi, RiskAreaApi } from '../../../services/api.service'
import { orderBy } from 'lodash'
import { useFormContext } from 'react-hook-form'

const FunzioniAmministrative = new FunzioniMatriceApi()
const RiskAreaV2 = new RiskAreaApi()
const RiskAssessmentMatrixFunctions = ({ dispatch, onSave }) => {
  const { watch, register, setValue } = useFormContext()

  const [loading, setLoading] = useState(false)
  const [riskAreas, setRiskAreas] = useState([])
  const [functions, setFunctions] = useState([])

  const selectedArea = useMemo(() => {
    return riskAreas?.find(({ uuid }) => uuid === watch('risk_area'))
  }, [riskAreas, watch('risk_area')])

  const isSecondaryFunctionPreSelected = useCallback((fn) => {
    const ids = watch('selectedFunctions')
    return !!ids?.includes(fn.value)
  }, [watch('selectedFunctions')])

  const onCheckSecondaryFunction = useCallback((checked, fn) => {
    const ids = watch('selectedFunctions')

    return checked
      ? setValue('selectedFunctions', [...ids, fn.value])
      : setValue('selectedFunctions', ids.filter((id) => id !== fn.value))
  }, [watch('selectedFunctions')])

  const fetchData = useCallback(async () => {
    setLoading(true)

    const response = await FunzioniAmministrative.query()
    const riskAreas = await RiskAreaV2.queryV2()

    const data = response.map(fn => ({
      ...fn,
      label: fn.fun_funzione,
      value: fn.uuid
    }))

    setFunctions(orderBy(data, ['label'], ['asc']))
    setRiskAreas(riskAreas)

    setLoading(false)
  }, [])

  const onClickSave = useCallback(async () => {
    await onSave()
    await fetchData()
  }, [onSave, fetchData])

  useEffect(() => {
    register({ name: 'selectedMainFunction' })
    register({ name: 'selectedFunctions' })
  }, [])

  useEffect(() => {
    const mainFunctionID = selectedArea?.funzioni?.map((fn) => fn.uuid) ?? []
    const functionsIDs = selectedArea?.funzioni_secondarie?.map((fn) => fn.uuid) ?? []
    setValue('selectedMainFunction', mainFunctionID[0] ?? [])
    setValue('selectedFunctions', functionsIDs ?? [])
  }, [selectedArea])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (loading) return (
    <Grid item style={{minWidth: '100%', textAlign: 'center'}}>
      <CircularProgress size={150} />
    </Grid>
  )

  return (
    <Grid container justify={"center"}>
      <Grid item xs={12} md={10} lg={8}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Funzione</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {functions.map((fn) => (
                <TableRow key={fn.value}>
                  <TableCell>
                    <RadioGroup row>
                      <Radio
                        disabled={!selectedArea}
                        checked={watch('selectedMainFunction') === fn.uuid}
                        onChange={(e) => setValue('selectedMainFunction', e.target.value)}
                        value={fn.value}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!selectedArea}
                            checked={isSecondaryFunctionPreSelected(fn)}
                            onChange={(e) => onCheckSecondaryFunction(e.target.checked, fn)}
                          />
                        }
                        label={fn.label}
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Fab
          onClick={onClickSave}
          style={{
            position: 'fixed',
            right: '70px',
            top: '335px',
            width: '120px',
          }}
          aria-label={'Salva'}
          color={'secondary'}
          variant={"extended"}
          disabled={!selectedArea}
        >
          Salva
        </Fab>
      </Grid>
    </Grid>
  )
}

export default connect()(RiskAssessmentMatrixFunctions)