import React from 'react';
import PropTypes from 'prop-types';
import {useFormContext} from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import {FormHelperText} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormGroup from "@material-ui/core/FormGroup";

const makeClasses = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: theme.spacing(1, 0),
    }
}));

const SwitchGroupCompile = props => {
    const {label, switchGroup, disabled, readOnly} = props;
    const classes = makeClasses();
    const {register, setValue, watch, errors} = useFormContext();
    const handleChange = (e) => {
        setValue(e.target.name, e.target.checked);
    };
    return (
        <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{label}</FormLabel>
            {!switchGroup.length && <FormHelperText color={"error"}>Error! Missing switch options!</FormHelperText>}
            <FormGroup className={classes.group}>
                {switchGroup.map(({name, label, labelPlacement, helperText, initialValue, required}) => (
                    <Grid container key={name} className={classes.group}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        readOnly={readOnly} disabled={disabled}
                                        onChange={handleChange} inputRef={register({required})}
                                        checked={watch(name) || initialValue} name={name}
                                        inputProps={{'aria-label': label}}/>
                                }
                                label={label} labelPlacement={labelPlacement}
                            />
                            <FormHelperText style={{color: errors[name] ? 'red' : ''}}>
                                {errors[name] ? errors[name].message : helperText}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                ))}
            </FormGroup>
        </FormControl>
    );
};

SwitchGroupCompile.defaultProps = {
    initialValue: false,
    label: '',
    helperText: '',
    switchGroup: [],
    labelPlacement: 'top'
};

SwitchGroupCompile.propTypes = {
    label: PropTypes.string,
    switchGroup: PropTypes.arrayOf(
        PropTypes.exact({
            name: PropTypes.string.isRequired,
            labelPlacement: PropTypes.string,
            helperText: PropTypes.string,
            label: PropTypes.string.isRequired,
            initialValue: PropTypes.bool,
            required: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool
            ]),
            disabled: PropTypes.bool
        })).isRequired
};

export default SwitchGroupCompile;
