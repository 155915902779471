import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import { Checkbox, CircularProgress, Fab, FormControlLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

import { FlowApi, RiskAreaFlussiApi } from '../../../services/api.service'

const RiskAreaFlussiCall = new RiskAreaFlussiApi()
const Flow = new FlowApi()
const RiskAssessmentMatrixFlow = ({ dispatch, onSave }) => {
  const { register, setValue, watch } = useFormContext()

  const [flows, setFlows] = useState([])
  const [riskAreas, setRiskAreas] = useState([])
  const [loading, setLoading] = useState(false)

  const selectedArea = useMemo(() => {
    return riskAreas?.find(({ uuid }) => uuid === watch('risk_area'))
  }, [riskAreas, watch('risk_area')])

  const isFlowPreSelected = useCallback((flow) => {
    const ids = watch('selectedFlows')
    return !!ids?.includes(flow.value)
  }, [watch('selectedFlows')])

  const onCheckFlow = useCallback((checked, flow) => {
    const ids = watch('selectedFlows')

    return checked
      ? setValue('selectedFlows', [...ids, flow.value])
      : setValue('selectedFlows', ids.filter((id) => id !== flow.value))
  }, [watch('selectedFlows')])

  const fetchData = useCallback(async () => {
    setLoading(true)

    const aree_rischio = await RiskAreaFlussiCall.query()
    let { flussi } = await Flow.query()

    flussi = flussi.map(flow => ({ ...flow, label: flow.flu_titolo, value: flow.uuid }))

    setFlows(orderBy(flussi, ['label'], ['asc']));
    setRiskAreas(aree_rischio)

    setLoading(false)
  }, [])

  const onClickSave = useCallback(async () => {
    await onSave()
    await fetchData()
  }, [onSave, fetchData])

  useEffect(() => {
    register({ name: 'selectedFlows' })
  }, [])

  useEffect(() => {
    const flowsIDs = selectedArea?.aree_rischio_flussi?.map(fn => fn.flussi?.uuid)
    setValue('selectedFlows', flowsIDs ?? [])
  }, [selectedArea])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (loading) return (
    <Grid item style={{minWidth: '100%', textAlign: 'center'}}>
      <CircularProgress size={150} />
    </Grid>
  )

  return (
    <Grid container justify={"center"}>
      <Grid item xs={12} md={10} lg={8}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Flusso</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {flows.map((flow) => (
                <TableRow key={flow.value}>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!selectedArea}
                          checked={isFlowPreSelected(flow)}
                          onChange={(e) => onCheckFlow(e.target.checked, flow)}
                        />
                      }
                      label={flow.label}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Fab
          onClick={onClickSave}
          style={{
            position: 'fixed',
            right: '70px',
            top: '335px',
            width: '120px',
          }}
          aria-label={'Salva'}
          color={'secondary'}
          variant={"extended"}
          disabled={!selectedArea}
        >
          Salva
        </Fab>
      </Grid>
    </Grid>
  )
}

export default connect()(RiskAssessmentMatrixFlow)