import React, {useEffect, useState, useMemo} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import AutoCompleteComponent from "../../components/ui/auto-complete/auto-complete.component";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import {RiskAreaApi, Violation231Api} from "../../services/api.service";
import Fab from "@material-ui/core/Fab";
import {handleToast} from "../../redux/actions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import orderBy from 'lodash/orderBy';

const RiskArea = new RiskAreaApi();
const Violations = new Violation231Api();
const RiskAreaMatrixPage = ({dispatch}) => {
    const [violations, setViolations] = useState([]);
    const [loading, setLoading] = useState(true)
    const [riskAreas, setRiskAreas] = useState([]);
    const [filter, setFilter] = React.useState();
    useEffect(() => {
        const fetchData = async () => {
            const aree_rischio = await RiskArea.queryV2();
            let reati231 = await Violations.query();
            reati231 = reati231.map(
                ({uuid, rea_titolo, rea_articolo}) => {
                    return {
                        label: `${rea_titolo} - ${rea_articolo}`,
                        value: uuid
                    };
                });
            setViolations(orderBy(reati231, ['label'], ['asc']));
            setRiskAreas(aree_rischio.filter(area => {
                if (filter) {
                    return area.are_titolo.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                }
                return true;
            }));
            setLoading(false)

        };
        fetchData();
    }, [filter]);
    const updateRiskAreas = (riskAreaId) => (violations) => {
        setRiskAreas([
            ...riskAreas.map((area) => {
                if (area.uuid === riskAreaId) {
                    area.aree_rischio_reati231 = violations || [];
                }
                return area;
            })
        ]);
    };
    const save = async () => {
        try {
            await Promise.all(await riskAreas.map(
                async ({aree_rischio_reati231, uuid}) => {
                    await RiskArea.updateReati({
                        uuid,
                        reati: aree_rischio_reati231.map(({value, reati231}) => value || (reati231 ? reati231.uuid : undefined))
                    });
                })
            );
            dispatch(handleToast({
                message: 'Matrice salvata'
            }));
        } catch (message) {
            dispatch(handleToast({
                message
            }));
        }
    };
    const renderMatrix = useMemo(() => {
        if(loading) return (
            <Grid item style={{minWidth: '100%', textAlign: 'center'}}>
                <CircularProgress size={150} />
            </Grid>
        )

        if(!loading && riskAreas){
            return riskAreas.map(area => {
                const {uuid, are_titolo, aree_rischio_reati231} = area;
                return (
                    <Grid item key={uuid} style={{minWidth: '100%'}}>
                        <Card style={{overflow: 'visible'}}>
                            <CardContent>
                                <AutoCompleteComponent
                                    suggestions={violations}
                                    defaultValues={aree_rischio_reati231.map(({reati231, label, value}) => {
                                        return {
                                            label: reati231 ? `${reati231.rea_titolo} - ${reati231.rea_articolo}` : label,
                                            value: reati231 ? reati231.uuid : value
                                        };
                                    })}
                                    onChange={updateRiskAreas(area.uuid)}
                                    multiple
                                    label={are_titolo}
                                    placeholder={'Inserisci Reato'}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                );
            })
        }
    },[riskAreas,loading])
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0', position: 'relative'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Matrice Aree di rischio per Reati 231'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Toolbar>
                            <TextField label={'Cerca'} fullWidth={true}
                                       onChange={(e) => setFilter(e.target.value)}
                                       InputProps={{
                                           endAdornment:
                                               <InputAdornment position="end">
                                                   <Icon>search</Icon>
                                               </InputAdornment>
                                       }}/>
                        </Toolbar>
                    </Grid>
                    {renderMatrix}
                    <Grid item xs={12}>
                        <Fab
                            onClick={save}
                            style={{
                                position: 'fixed',
                                right: '70px',
                                top: '335px',
                                width: '120px',
                            }} aria-label={'Salva'} color={'secondary'} variant={"extended"}>Salva</Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

RiskAreaMatrixPage.propTypes = {};

export default connect()(RiskAreaMatrixPage);
