import React from 'react';
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {DatePicker} from "@material-ui/pickers";

const DatePickerCompile = (props) => {
    const {
        label, name, helperText, disabled, placeholder, required, maxDate, minDate, initialValue
    } = props;
    const {register, watch, setValue, errors} = useFormContext();
    const handleChange = (value) => {
        setValue(name, value.toDate());
    };
    const getHelperText = () => {
        if (!errors[name]) {
            return helperText;
        }
        return errors[name].message;
    };
    const forceRequired = (value) => {
        //console.log(required, value)
        return (required && (value === undefined || value === '')) ? `${required}` : true;
    };
    return (
        <FormControl component="fieldset" fullWidth={true}>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <DatePicker label={placeholder} disabled={disabled} name={name} onChange={handleChange}
                        value={watch(name) || initialValue} margin={'normal'}
                        helperText={getHelperText()} maxDate={maxDate} format="DD/MM/YYYY"
                        minDate={minDate} emptyLabel={' -- seleziona data --'} error={!!errors[name]}
                        inputRef={register({name}, {validate: forceRequired})}/>
        </FormControl>
    );
};

DatePickerCompile.defaultProps = {
    disabled: false,
    label: 'Titolo',
    placeholder: 'Seleziona Data',
    required: false,
    maxDate: new Date('2100-01-01'),
    minDate: new Date('1900-01-01'),
    initialValue: null
};

DatePickerCompile.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    initialValue: PropTypes.string,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ])
};

export default DatePickerCompile;
