import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import AutoCompleteComponent from "../../components/ui/auto-complete/auto-complete.component";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import {FunctionApi, ReferentApi} from "../../services/api.service";
import Fab from "@material-ui/core/Fab";
import {handleToast} from "../../redux/actions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import orderBy from 'lodash/orderBy';

const Function = new FunctionApi();
const Referent = new ReferentApi();
const MatrixFunctionsReferentsPage = ({dispatch}) => {
    const [categories, setCategories] = useState([]);
    const [functions, setFunctions] = useState([]);
    const [filter, setFilter] = React.useState();
    useEffect(
        () => {
            const fetchData = async () => {
                const funzioni = await Function.query();
                let referenti = await Referent.query();
                referenti = referenti.referenti.map(
                    ({uuid, ref_referente}) => {
                        return {
                            label: ref_referente,
                            value: uuid
                        }
                    });
                setCategories(orderBy(referenti, ['label'], ['asc']));
                setFunctions(funzioni.filter(fun => filter ? fun.fun_funzione.toLowerCase().search(filter.toLowerCase()) !== -1 : true));

            };
            fetchData();
        }, [filter]
    );
    const updateFunctions = (riskAreaId) => (categories) => {
        setFunctions([
            ...functions.map((fun) => {
                if (fun.uuid === riskAreaId) {
                    fun.funzioni_referenti = categories || [];
                }
                return fun;
            })
        ]);
    };
    const save = async () => {
        try {
            await Promise.all(await functions.map(
                async ({funzioni_referenti, uuid}) => {
                    await Function.updateReferents({
                        uuid,
                        referenti: funzioni_referenti.map(({value, referenti}) => value || (referenti ? referenti.uuid : undefined))
                    })
                })
            );
            dispatch(handleToast({
                message: 'Referenti salvati'
            }));
        } catch (message) {
            dispatch(handleToast({
                message
            }))
        }
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0', position: 'relative'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Matrici - Funzioni per Referenti'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Toolbar>
                            <TextField label={'Cerca'} fullWidth={true}
                                    onChange={(e) => setFilter(e.target.value)}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                    }}/>
                        </Toolbar>
                    </Grid>
                    {functions.map((fun) => {
                        const {uuid, fun_funzione, funzioni_referenti} = fun;
                        return (
                            <Grid item key={uuid} style={{minWidth: '100%'}}>
                                <Card style={{overflow: 'visible'}}>
                                    <CardContent>
                                        <AutoCompleteComponent suggestions={categories}
                                                               defaultValues={funzioni_referenti.map(({referenti, label, value}) => {
                                                                   return {
                                                                       label: referenti ? referenti.ref_referente : label,
                                                                       value: referenti ? referenti.uuid : value
                                                                   }
                                                               })}
                                                               onChange={updateFunctions(fun.uuid)} multiple
                                                               label={fun_funzione} placeholder={'Inserisci referenti'}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                    <Grid item xs={12}>
                        <Fab
                            onClick={save}
                            style={{
                                position: 'fixed',
                                right: '70px',
                                top: '335px',
                                width: '120px',
                            }} aria-label={'Salva'} color={'secondary'} variant={"extended"}>Salva</Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

MatrixFunctionsReferentsPage.propTypes = {};

export default connect()(MatrixFunctionsReferentsPage);
