import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import {handleModal} from "../../../redux/actions/modal.action";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {BehaviorIndicatorApi} from "../../../services/api.service";
import BehaviorIndicatorComponent from "../../CRUD/behavior-indicator/behavior-indicator.component";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import {searchFilter} from "../../../constant";

const IndicatorApi = new BehaviorIndicatorApi();
const columns = [
    {id: 'pro_priorita', label: 'Ordinamento', colSpan: 1, sortable: true},
    {id: 'ind_titolo', label: 'Nome', colSpan: 1, sortable: true},
    {id: 'active', label: 'Attivo', colSpan: 2}
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {

        overflow: 'auto',
    },
});

const BehaviorIndicatorTable = ({dispatch, api}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [indicators, setIndicators] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('pro_titolo');
    const [filter, setFilter] = React.useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const indicatori_comportamentali = await IndicatorApi.query();
            setIndicators(indicatori_comportamentali);
            setTableRows(indicatori_comportamentali);
        };
        fetchData();
    }, [api.fetch]);
    useEffect(() => {
        setPage(0);
        setTableRows(indicators.filter(searchFilter(filter, columns)))
    }, [filter,indicators]);
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    const updateIndicator = (indicator) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Modifica indicatore',
            content: <BehaviorIndicatorComponent mode={'update'} indicator={indicator}/>,
        }));
    };

    const deleteIndicator = (indicator) => () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Elimina indicatore',
            content: <BehaviorIndicatorComponent mode={'delete'} indicator={indicator}/>,
        }));
    };

    return (
        <Paper className={classes.root}>
            <Toolbar>
                <TextField label={'Cerca'} fullWidth={true} style={{margin: '1rem 1rem 2rem'}}
                           onChange={(e) => setFilter(e.target.value)}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <Icon>search</Icon>
                                   </InputAdornment>
                           }}/>
            </Toolbar>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(
                                ({id, align, sortable, colSpan, label}) => (
                                    <TableCell key={id} align={align} colSpan={colSpan} style={{zIndex: 3}}>
                                        {sortable ?
                                            <TableSortLabel
                                                active={orderBy === id}
                                                direction={order}
                                                onClick={createSortHandler(id)}>
                                                {label}
                                            </TableSortLabel>
                                            : label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.orderBy(tableRows, [orderBy], [order])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(
                                (indicator, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={index}>
                                            <TableCell align={"left"}>
                                                {indicator.ind_priorita}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {indicator.ind_titolo}
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {indicator.ind_attivo ?
                                                    <Icon style={{color: '#27ae60'}}>done</Icon> :
                                                    <Icon color={"error"}>block</Icon>}
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                <IconButton onClick={updateIndicator(indicator)}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={deleteIndicator(indicator)}>
                                                    <Icon color={"error"}>delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        {!indicators.length &&
                        <TableRow hover tabIndex={-1}>
                            <TableCell align={"center"} colSpan={columns.length}>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    nessun dato
                                </Typography>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={(e, newPage) => setPage(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>);
};

BehaviorIndicatorTable.propTypes = {
    filterBy: PropTypes.object
};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};
export default connect(mapStateToProps)(BehaviorIndicatorTable);
