const containerBaseStyle = {
  height: 50,
  textAlign: "center",
  alignContent: "center",
};

const styles = {
  containerBaseStyle: {
    ...containerBaseStyle,
  },
  containerMiddleStyle: {
    ...containerBaseStyle,
    backgroundColor: "orange",
    borderRight: "2px solid white",
    borderLeft: "2px solid white",
  },
  containerFirstStyle: {
    ...containerBaseStyle,
    backgroundColor: "green",
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
    borderRight: "2px solid white",
  },
  containerLastStyle: {
    ...containerBaseStyle,
    backgroundColor: "red",
    borderTopRightRadius: 25,
    borderLeft: "2px solid white",
    borderBottomRightRadius: 25,
  },
  textStyle: {
    color: "white",
    alignSelf: "center",
  },
  textGreyStyle: {
    color: "grey",
    alignSelf: "center",
  },
  movingStyle: {
    marginLeft: "-50px",
    marginRight: "50px",
  },
  indicatorDiv: {
    width: "100px",
    textAlign: "center",
  },
  indicatorText: {
    width: "100px",
    textAlign: "center",
    color: "orange",
  },
  wrapperStyle: {
    width: "33%",
    display: "flex",
    justifyContent: "flex-end",
  },
};

export default styles;
