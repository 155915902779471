import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {FormBuilderApi} from "../../services/api.service";
import {handleToast} from "../../redux/actions";
import Grid from "@material-ui/core/Grid";
import {CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import {MODE_VIEW_COMPILE, MODE_VIEW_READONLY} from "../../form-builder/index.constants";
import {Section} from "../../form-builder/components/section/section.class";
import FormBuilderComponent from "../../form-builder/components/form-builder/form-builder.component";
import {Alert} from "@material-ui/lab";

const FormBuilder = new FormBuilderApi();
const CompileFormPage = ({dispatch, match: {params: {ref_uuid, form_uuid}}, history}) => {
    const [form, setForm] = useState();
    const [sections, setSections] = useState([]);
    const [flow, setFlow] = useState();
    const [compiled, setCompiled] = useState();
    const [loading, setLoading] = useState();
    useEffect(
        () => {
            const fetchForm = async () => {
                try {
                    setLoading(true);
                    const {form, anno, close, periodo, compilato, tracciato, files} = await FormBuilder.collectFlow(ref_uuid, form_uuid);
                    if (form && form.for_sections) {
                        setFlow({
                            compiledBy: compilato,
                            closed: close ? true : false,
                            year: anno,
                            period: periodo,
                            flu_titolo: form.for_titolo_flusso,
                            flu_descrizione: form.for_descrizione_flusso,
                            track_uuid: tracciato
                        });
                        if(tracciato) {
                            history.push({
                                search: `?track_uuid=${tracciato}`
                            })
                        }
                        if(compilato) {
                            dispatch(handleToast({
                                message: `Form già compilato da ${compilato}`,
                                autoHideDuration: null
                            }));
                        }
                        setSections(
                            form.for_sections.map((section) => new Section(section, {viewMode: MODE_VIEW_READONLY}))
                        );
                        setForm(form);
                    }
                } catch (message) {
                    dispatch(handleToast({
                        message
                    }));
                }
                setLoading(false);
            };
            fetchForm();
        },
        [ref_uuid, form_uuid]
    );
    const getDocs = (formData) => {
        const docs = [];
        sections.forEach(
            ({inputList}) => {
                if (inputList) {
                    inputList.forEach(
                        (component) => {
                            if (component.type === 'file-input') {
                                component.initialValue = formData[component.name];
                                if (component.file) {
                                    docs.push(component.file);
                                } else {
                                    console.error('Missing file!')
                                }
                            }
                        });
                }
            });
        return docs;
    };

    const mapSections = (formData) => {
        // console.log('formData', formData);
        return sections.map(
            (section) => {
                if (section.inputList) {
                    section.inputList = section.inputList.map(
                        (component) => {
                            if (component.type === 'switch-group') {
                                component.switchGroup = component.switchGroup.map(
                                    (_switch) => {
                                        if (formData[_switch.name]) {
                                            _switch.initialValue = formData[_switch.name];
                                        }
                                        return _switch;
                                    }
                                )
                            } else if (component.type === 'checks-group') {
                                component.checksGroup = component.checksGroup.map(
                                    (checks) => {
                                        if (formData[checks.name]) {
                                            checks.initialValue = formData[checks.name];
                                        }
                                        return checks;
                                    }
                                )
                            } else if (formData[component.name]) {
                                component.initialValue = formData[component.name];
                            } else {
                                console.error(`${component.name} not found in formData`, component)
                            }
                            return component;
                        }
                    );
                }
                return section;
            }
        )
    };
    const onSubmit = async (data) => {
        //console.log('data', data);
        try {
            await FormBuilder.compile({
                uuid: form_uuid,
                referente_uuid: ref_uuid,
                docs: getDocs(data),
                form: {
                    ...form,
                    for_sections: mapSections(data),
                },
            });
            dispatch(handleToast({
                message: 'Risposte salvate con successo'
            }));
            setCompiled(true);
        } catch (message) {
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <div>
            {!loading && sections.length === 0 && <Alert severity="error">Attenzione i form vecchi non sono ancora stati importati e potrebbero essere solo presenti il titolo e la descrizione</Alert>}
            {!loading && flow && !compiled &&
            <Grid container justify={"center"}>
                <Grid item xs={10}>
                    <FormBuilderComponent setSections={setSections} setFlow={setFlow} sections={sections}
                                          saveForm={onSubmit} flow={flow} defaultViewMode={(flow.compiledBy || flow.closed) ? MODE_VIEW_READONLY : MODE_VIEW_COMPILE}/>
                </Grid>
            </Grid>}
            {compiled &&
            <Grid container justify={"center"}>
                <Grid item>
                    <Icon color={"primary"} style={{fontSize: 120}}>check</Icon>
                </Grid>
                <Grid item xs={12}>
                    <Typography align={"center"} variant={"h4"} color={"primary"}>Questionario completato</Typography>
                </Grid>
            </Grid>}
            {loading &&
            <Grid container justify={"center"}>
                <Grid item>
                    <CircularProgress size={40}/>
                </Grid>
            </Grid>}
        </div>
    );
};

export default connect()(withRouter(CompileFormPage));
