import React, {useState} from "react";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CheckLicensesForm from "../../hooks-forms/check-licenses-form/check-licenses.form";
import CheckServicesForm from "../../hooks-forms/check-services-form/check-services.form";
import PageSubtitleComponent from "../../ui/page-subtitle/page-subtitle.component";
import {Typography} from "@material-ui/core";
import UnknownStepComponent from "../../ui/unknown-step/unknown-step.component";
import {connect} from "react-redux";
import {handleToast} from "../../../redux/actions";
import {AccountsApi} from "../../../services/api.service";
import SelectUserForm from "../../hooks-forms/select-user-form/select-user.form";
import ServicesSummaryComponent from "../../ui/services-summary/services-summary.component";
import LicensesSummaryComponent from "../../ui/licenses-summary/licenses-summary.component";
import {getRegistryLabel} from "../../../constant";

const Accounts = new AccountsApi();
const AccountShoppingSteps = ({dispatch, onComplete, activeStep, handleBack, handleNext}) => {
    const [account, setAccountData] = useState({});
    const [orders, setOrders] = useState({});
    const [licenses, setLicenses] = useState({});
    const [services, setServices] = useState({});
    const orderForm = useForm({
        mode: 'onSubmit',
        defaultValues: orders
    });

    const onSubmit = async data => {
        if (activeStep === 0) {
            try {
                const {
                    licenze, servizi, account: {
                        acc_fiscal_code,
                        acc_p_iva,
                        acc_address,
                        acc_admin_email,
                        acc_city,
                        acc_email,
                        acc_mobile,
                        acc_name,
                        acc_phone,
                        acc_province,
                        acc_zip
                    }
                } = await Accounts.query(data);
                setAccountData({
                    acc_fiscal_code,
                    acc_p_iva,
                    acc_address,
                    acc_admin_email,
                    acc_city,
                    acc_email,
                    acc_mobile,
                    acc_name,
                    acc_phone,
                    acc_province,
                    acc_zip,
                });
                setLicenses(Object.keys(licenze).reduce((arr, key) => {
                    return [
                        ...arr,
                        {
                            lic_name: licenze[key].name,
                            lic_description: licenze[key].description,
                            quantity: licenze[key].quantity
                        }
                    ]
                }, []));
                setServices(Object.keys(servizi).reduce((arr, key) => {
                    return [
                        ...arr,
                        {
                            ser_name: servizi[key].name,
                            ser_description: servizi[key].description,
                            quantity: servizi[key].quantity
                        }
                    ]
                }, []));
                handleNext();
            } catch (message) {
                dispatch(handleToast({message}));
            }
        } else if (activeStep === 1) {
            setOrders(data);
            handleNext();
        }
    };
    const back = () => {
        if (activeStep === 1) {
            setOrders(orderForm.getValues())
        }
        handleBack();
    };


    const save = () => {
        let licenze = orders.licenses || [];
        licenze = licenze.filter(({uuid}) => uuid).map(
            ({quantity, uuid}) => {
                return {
                    quantity,
                    uuid
                }
            }
        );
        let servizi = orders.services || [];
        servizi = servizi.filter(({uuid}) => uuid).map(
            ({quantity, uuid}) => {
                return {
                    quantity,
                    uuid
                }
            }
        );
        onComplete({
            ...account,
            licenze,
            servizi
        })
    };
    switch (activeStep) {
        case 0:
            return <SelectUserForm onSubmit={onSubmit} account={account}/>;
        case 1:
            return (
                <FormContext {...orderForm}>
                    <form onSubmit={orderForm.handleSubmit(onSubmit)}>
                        <Grid container justify={"space-between"} spacing={4}>
                            <Grid item xs={12}>
                                <PageSubtitleComponent subtitle={'LICENZE ATTIVE'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <LicensesSummaryComponent licenses={licenses}/>
                            </Grid>
                            <Grid item xs={12}>
                                <PageSubtitleComponent subtitle={'SERVIZI ATTIVI'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <ServicesSummaryComponent services={services}/>
                            </Grid>
                            <Grid item xs={12}>
                                <PageSubtitleComponent subtitle={'SELEZIONA NUOVE LICENZE'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckLicensesForm/>
                            </Grid>
                            <Grid item xs={12}>
                                <PageSubtitleComponent subtitle={'SELEZIONA NUOVI SERVIZI'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckServicesForm/>
                            </Grid>
                            <Grid item>
                                <Button onClick={back}>
                                    Indietro
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" type="submit">
                                    Avanti
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormContext>
            );
        case 2:
            let _licenses = orderForm.watch('licenses') || [];
            _licenses = _licenses.filter(({uuid}) => uuid);
            let _services = orderForm.watch('services') || [];
            _services = _services.filter(({uuid}) => uuid);
            return (
                <Grid container direction={"column"} spacing={4}>
                    <Grid item>
                        <PageSubtitleComponent subtitle={'DATI ANAGRAFICI'}/>
                    </Grid>
                    <Grid item container direction={"column"} spacing={1} alignItems={"center"}
                          style={{marginLeft: '2rem'}}>
                        {Object.keys(account).map((key, index) => {
                            return account[key] ? (
                                <Grid item container spacing={2} key={`summary-${index}`}>
                                    <Grid item>
                                        <Typography variant={"subtitle2"}>{getRegistryLabel(key)}:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body1"}>{account[key]}</Typography>
                                    </Grid>
                                </Grid>
                            ) : ''
                        })}
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={'LICENZE SELEZIONATE'}/>
                    </Grid>
                    <Grid item>
                        <LicensesSummaryComponent licenses={_licenses}/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={'SERVIZI SELEZIONATI'}/>
                    </Grid>
                    <Grid item>
                        <ServicesSummaryComponent services={_services}/>
                    </Grid>
                    <Grid item container justify={"space-between"} spacing={4}>
                        <Grid item>
                            <Button variant="contained" color="default" onClick={back}>
                                Indietro
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={save}>
                                Completa acquisto
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            );
        default:
            return <UnknownStepComponent handleBack={handleBack}/>;
    }
};

export default connect()(AccountShoppingSteps);
