import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const style = makeStyles(theme => ({
    templateWrapper: {
        cursor: 'grab',
        width: '200px'
    },
    template: {
        padding: theme.spacing(1)
    }
}));

const SwitchGroupTemplate = props => {
    const classes = style();
    const handleDragStart = (e) => {
        e.dataTransfer.setData('new-template', 'switch-group');
    };
    return (
        <div className={classes.templateWrapper} draggable onDragStart={handleDragStart}
             onDragOver={(e) => e.preventDefault()}>
            <Grid container direction={"column"} className={classes.template}>
                <Grid item>
                    <Typography variant={"subtitle2"} gutterBottom>Switch Group</Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel label={'on/off'} control={<Switch disabled={true}/>}/>
                </Grid>
            </Grid>
        </div>
    );
};

SwitchGroupTemplate.propTypes = {};

export default SwitchGroupTemplate;
