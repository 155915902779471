export const IMPATTO = 0;
export const PROBABILITA = 1;
export const PRESIDI = 2;
export const VALUTAZIONE = 3;

export const isImpatto = (val) => val === IMPATTO;
export const isProbabilita = (val) => val === PROBABILITA;
export const isPresidi = (val) => val === PRESIDI;
export const isValutazione = (val) => val === VALUTAZIONE;

export const isCompiledImpatto = (reato) => reato?.compiled?.impatti;
export const isCompiledPresidi = (reato) => reato?.compiled?.presidio;
export const isCompiledProbabilita = (reato) => reato?.compiled?.probabilita;
export const isNotCompiled = (reato) =>
  !(
    reato?.compiled?.impatti &&
    reato?.compiled?.presidio &&
    reato?.compiled?.probabilita
  );
export const graphWidth = 400;
export const graphHeight = 400;
export const options = {
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: true,
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  tooltips: {
    enabled: false,
  },
  scaleLabel: {
    scale: {
      fontSize: 22,
    },
  },
  minor: {
    fontSize: 22,
  },
  scale: {
    ticks: {
      beginAtZero: true,
      fontSize: 14,
      max: 5,
      min: 0,
      stepSize: 1,
    },
  },
};
