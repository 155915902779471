import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CtaBoxComponent from "../../components/ui/cta-box/cta-box.component";
import Violation231ListTable from "../../components/atomic-tables/violation-231-list/violation-231-list.table";
import {handleModal} from "../../redux/actions";
import CategoryComponent from "../../components/CRUD/violation-category/violation-category.component";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import useForm, {FormContext} from "react-hook-form";
import Violation231CategoriesSelectComponent
    from "../../components/ui/violation-231-categories-select/violation-231-categories-select.component";

const Violation231ListPage = ({dispatch, history}) => {
    const [filterBy, setFilter] = useState(null);
    const categoryForm = useForm({
        mode: "onChange"
    });
    const createViolation = () => history.push('/reati-231/elenco-reati-231/crea-reato');
    const createViolationCategory = () => {
            dispatch(handleModal({
                open: true,
                maxWidth: 'sm',
                title: 'Aggiungi categoria',
                content: <CategoryComponent mode={'create'}/>
            }));
        }
    ;
    const actions = [
        {
            label: 'Aggiungi reato',
            handler: createViolation
        },
        {
            label: 'Aggiungi categoria',
            handler: createViolationCategory
        }
    ];
    const filterTable = (key, value) => {
        setFilter(value ? {key, value} : null)
    };
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container justify={"center"} spacing={4} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <BreadcrumbComponent/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSubtitleComponent subtitle={'Categorie reato 231 e reati associati'}/>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={8} md>
                            <Typography variant={"h6"}>
                                Filtra per categoria di Reato 231
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Button variant={"outlined"} color={"primary"} fullWidth onClick={createViolationCategory}>
                                Aggiungi categoria
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormContext {...categoryForm}>
                                <form>
                                    <Violation231CategoriesSelectComponent onChange={filterTable}/>
                                </form>
                            </FormContext>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"space-between"} alignItems={"center"} spacing={2} style={{margin: 0}}>
                            <Grid item xs>
                                <Typography variant={"subtitle2"}>Elenco Reati 231</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} onClick={createViolation}>Aggiungi reato</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Violation231ListTable filterBy={filterBy}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>
                        <CtaBoxComponent title={'Aggiungi reato o categoria di reato'} actions={actions}/>
                    </Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    );
};

Violation231ListPage.propTypes = {};

export default connect()(withRouter(Violation231ListPage));
