import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CircularProgress} from "@material-ui/core";
import {ConfigApi} from "../../../services/api.service";

const useStyles = makeStyles(theme => ({
    whiteText: {
        color: 'white'
    },
    whiteDivider: {
        backgroundColor: 'rgba(255, 255, 255, 0.12)'
    },
    footer: {
        background: theme.palette.primary[theme.palette.mode]
    }
}));
const FooterComponent = props => {
    const configApi = new ConfigApi();
    const classes = useStyles();
    const [logoFooter, setLogoFooter] = React.useState(false);
    useEffect(() => {
        const loadSetup = async () => {
            const setupData = await configApi.setup();
            if ( setupData && setupData.logo_footer && setupData.logo_footer !== '' ) {
                setLogoFooter(setupData.logo_footer);
            }
        };
        loadSetup();
    }, []);

    return (
        <Grid container justify={'center'} className={classes.footer} spacing={4}
              style={{
                  margin: 0,
                  width: '100%',
              }}>
            {/*<Grid item container justify={"center"}>
                <img src={'/assets/images/horizontal-logo.png'} alt={'valore24 logo'} width={'auto'} height={'40px'}
                     style={{marginTop: '1rem'}}/>
            </Grid>*/}
            {/*<Grid item>
                <Divider className={classes.whiteDivider}/>
            </Grid>
            <Grid item>
                <Divider className={classes.whiteDivider}/>
            </Grid>*/}
            <Grid item xs={12} md={11}>
                <Grid container justify={"space-between"}>
                    <Grid item>
                        {logoFooter && <img src={logoFooter} alt={'valore24 logo'} height={'30px'} width={'auto'}/>}
                        {!logoFooter && <CircularProgress />}
                    </Grid>
                    {/*<Grid item>
                        <Typography variant={"subtitle1"} className={classes.whiteText}>
                            Termini e condizioni | Privacy Policy 2019.
                        </Typography>

                    </Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FooterComponent;
