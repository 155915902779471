import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const style = makeStyles(theme => ({
    templateWrapper: {
        cursor: 'grab',
        width: '200px'
    },
    template: {
        padding: theme.spacing(1)
    }
}));

const ChecksGroupTemplate = props => {
    const classes = style();
    const handleDragStart = (e) => {
        e.dataTransfer.setData('new-template', 'checks-group');
    };
    return (
        <div className={classes.templateWrapper}>
            <Grid container direction={"column"} className={classes.template}
                  draggable onDragStart={handleDragStart} onDragOver={(e) => e.preventDefault()}>
                <Grid item>
                    <Typography variant={"subtitle2"} gutterBottom>Checks Group</Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox disabled/>} label={'check options'}/>
                </Grid>
            </Grid>
        </div>
    );
};

ChecksGroupTemplate.propTypes = {};

export default ChecksGroupTemplate;
