import React from 'react';
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {connect} from "react-redux";
import {handleHelpDrawer} from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        background: 'transparent',
        fontSize: 15,
        color: 'grey'
    },
    helpContainer:{
        textAlign: 'right'
    },
    helpButton: {
        background:'transparent',
        boxShadow: 'none',
        color:'#8bc34a'
    }
}));
const BreadcrumbComponent = ({hideHelp, location: {pathname}, match: {params}, dispatch, customTitle}) => {
    const classes = useStyles();
    let breadcrumb = pathname.split('/');
    for (const key in params) {
        breadcrumb.splice(breadcrumb.indexOf(params[key]));
    }
    breadcrumb = breadcrumb.map((pathName) => pathName.replace(/-/g, ' '));
    return (
        <Grid container spacing={0} alignItems={"center"} style={{margin: 0}}>
            <Grid item xs={hideHelp ? 12 : 11} className={classes.breadcrumb}>
                <Grid container spacing={0}>
                    {!customTitle && breadcrumb.map(
                        (pathName, index) => {
                            const isLast = index === breadcrumb.length - 1;
                            return (
                                <Grid item key={index}>
                                    <Typography variant={"body1"}
                                                style={{
                                                    textTransform: 'capitalize',
                                                    fontWeight: isLast && index !== 0 ? 'bold' : 'inherit'
                                                }}>
                                        {`${pathName} ${!isLast && index !== 0 ? '/' : ''}`}
                                    </Typography>
                                </Grid>
                            )
                        }
                    )}
                    {customTitle && (
                        <Grid item>
                            <Typography variant={"body1"} style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                                {customTitle}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {!hideHelp && <Grid item xs={1} className={classes.helpContainer}>
                <Fab variant={"extended"} className={classes.helpButton} size={"small"}
                     onClick={() => dispatch(handleHelpDrawer({open: true}))}>
                    aiuto
                </Fab>
            </Grid>
            }
        </Grid>
    );
};

BreadcrumbComponent.propTypes = {};

export default connect()(withRouter(BreadcrumbComponent));
