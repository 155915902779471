import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import BreadcrumbComponent from "../../components/ui/breadcrumb/breadcrumb.component";
import PageSubtitleComponent from "../../components/ui/page-subtitle/page-subtitle.component";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {handleModal, handleToast} from "../../redux/actions";
import {CompanyApi} from "../../services/api.service";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReferentsTable from "../../components/atomic-tables/referents/referents.table";
import ReferentComponent from "../../components/CRUD/referent/referent.component";
import AccountComponent from "../../components/CRUD/account/account.component";
import CircularProgress from "@material-ui/core/CircularProgress";
import AuthService from "../../services/auth.service";

const Company = new CompanyApi();
const AccountProfilePage = ({dispatch}) => {
    const [company, setCompany] = useState();
    useEffect(
        () => {
            const fetchUser = async () => {
                try {
                    const companies = await Company.get(AuthService.company.uuid);console.log(companies);
                    setCompany(companies.data);
                } catch (message) {
                    dispatch(handleToast({
                        message,
                        autoHideDuration: null
                    }));
                    //history.push('/home');
                }
            };
            fetchUser();
        }, []
    );
    const createReferent = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Crea referente',
            content: <ReferentComponent mode={'create'}/>,
        }));
    };

    const deleteAccount = () => {
        dispatch(handleModal({
            open: true,
            maxWidth: 'lg',
            title: 'Elimina utenza',
            content: <AccountComponent mode={'delete'} company={company}/>,
        }));
    };
    const renderListReferents = () => {
        return (
            <Grid item container alignItems={"center"} spacing={2}>
                <Grid item xs>
                    <Typography variant={"h6"}>Elenco referenti</Typography>
                </Grid>
                <Grid item>
                    <Button variant={"outlined"} onClick={createReferent}>Nuovo referente</Button>
                </Grid>
                <Grid item xs={12}>
                    <ReferentsTable/>
                </Grid>
            </Grid>
        )
    }
    if (!company) {
        return (
            <Grid container justify={"center"} style={{margin: '3rem 0'}}>
                <Grid item>
                    <CircularProgress/>
                </Grid>
            </Grid>
        );
    }
    return (
        <Grid container justify={"center"} style={{margin: '3rem 0'}}>
            <Grid item xs={12} md={10} lg={8}>
                <Grid container direction={"column"} spacing={4} style={{margin: 0}}>
                    <Grid item>
                        <BreadcrumbComponent customTitle={"Profilo Società"}/>
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Profilo società`}/>
                    </Grid>
                    <Grid item>
                        <AccountComponent company={company} mode={"update"} />
                    </Grid>
                    <Grid item>
                        <PageSubtitleComponent subtitle={`Elimina questa società`}/>
                    </Grid>
                    <Grid item>
                        <Button variant={"outlined"} color={"primary"} onClick={deleteAccount}>Elimina
                            società</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};


export default connect()(withRouter(AccountProfilePage));
