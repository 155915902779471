import React, {useEffect, useState, useCallback} from 'react';
import {connect} from "react-redux";
import {FlowCollectionApi, FlowTrackApi} from "../../../services/api.service";
import {withRouter} from "react-router-dom";
import {handleToast} from "../../../redux/actions";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import makeStyles from "@material-ui/core/styles/makeStyles";

const FlowCollection = new FlowCollectionApi();
const Sollecito = new FlowTrackApi();
const columns = [
    {id: 'funzione', label: 'Funzione', colSpan: 1},
    {id: 'status', label: 'Status', colSpan: 1},
    {id: 'avanzamento', label: 'Avanzamento', colSpan: 2}
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {

        overflow: 'auto',
    },
});

const FlowsCollectionTable = ({history, dispatch, api, rac_uuid}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [flowCollection, setFlowCollection] = useState(null);
    const fetchData = useCallback(async () => {
        setLoading(true);
        const {raccolte} = await FlowCollection.get(rac_uuid);
        setFlowCollection(raccolte);
        setLoading(false);
    },[rac_uuid]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const sendSollectito = async (uuid) => {
        try {
            const message = await Sollecito.send({uuid});
            dispatch(handleToast({
                message
            }));
        } catch (message) {
            dispatch(handleToast({message}));
        }
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(
                                ({id, align, colSpan, label}) => (
                                    <TableCell key={id} align={align} colSpan={colSpan} style={{zIndex: 3}}>
                                        {label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading &&
                        <TableRow hover tabIndex={-1}>
                            <TableCell align={"center"} colSpan={4}>
                                <CircularProgress size={12}/>
                            </TableCell>
                        </TableRow>}
                        {flowCollection && flowCollection.tracciati.map(
                            (row, index) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell align={"left"}>
                                            <Grid container alignContent={"center"} spacing={2}>
                                                <Grid item>
                                                    {flowCollection.rac_close && row.completed === 100 &&
                                                    <Icon color="primary">check_circle_outline</Icon>}
                                                    {flowCollection.rac_close && row.completed !== 100 &&
                                                    <Icon color="error">error_outline</Icon>}
                                                </Grid>
                                                <Grid item>
                                                    {row.funzioni?.fun_funzione} {row.funzioni?.deletedAt && (
                                                        <span style={{ color: "#ff0000", fontSize: '12px', marginLeft: '5px' }}>(Cancellata)</span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align={"left"}>
                                            {row.trc_status}
                                        </TableCell>
                                        <TableCell style={{width: '30%'}}>
                                            <Grid container alignItems={"center"} spacing={2}>
                                                <Grid item xs={10}>
                                                    <LinearProgress variant="determinate" value={row.completed}
                                                                    style={{height: 10}}/>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {row.completed}%
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            {!flowCollection.rac_close && row.completed !== 100 &&
                                            <Button variant={"outlined"} size={"small"} color="primary"
                                                    onClick={() => sendSollectito(row.uuid)}>
                                                Invia Sollecito
                                            </Button>}
                                            {((!flowCollection.rac_close && row.completed === 100) || flowCollection.rac_close) &&
                                            <Button size={"small"} variant={"outlined"}  color={"primary"} onClick={() => history.push(`/flussi-OdV/raccolta-flussi/visualizza-form/${row.uuid}`)}>
                                                Visualizza
                                            </Button>}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </div>
        </Paper>);
};

const mapStateToProps = ({fetchApiReducer}) => {
    return {
        api: fetchApiReducer
    }
};
export default connect(mapStateToProps)(withRouter(FlowsCollectionTable));
