import React from "react";
import {HELPER_TEXT_CONFIG, LABEL_CONFIG, REQUIRED_CONFIG} from "../form-item.class";
import SliderCompile from "./slider.compile";
import SliderTemplate from "./slider.template";
import EmptyItemComponent from "../empty-item/empty-item.component";
import SliderConfigure from "./slider.configure";
import {
    MODE_VIEW_COMPILE,
    MODE_VIEW_CONFIGURATION,
    MODE_VIEW_PREVIEW,
    MODE_VIEW_READONLY,
    MODE_VIEW_TEMPLATE
} from "../../../index.constants";
import PropTypes from "prop-types";

const SliderComponent = (props) => {
    const {viewMode, save} = props;

    const config = () => {
        return [
            LABEL_CONFIG(props),
            {
                name: 'min',
                value: props.min || '',
                placeholder: 'Inserisci minimo',
                required: true,
                isBoolean: false,
                disabled: false,
                type: 'number',
                textAnchor: true
            },
            {
                name: 'max',
                value: props.max || '',
                placeholder: 'Inserisci massimo',
                required: true,
                isBoolean: false,
                disabled: false,
                type: 'number',
                textAnchor: true
            },
            HELPER_TEXT_CONFIG(props),
            REQUIRED_CONFIG(props),
            {
                name: 'range',
                value: props.range || false,
                placeholder: 'Range slider',
                required: false,
                isBoolean: true,
                disabled: false,
                textAnchor: false
            }
        ]
    };
    const saveConfiguration = (rows, valid) => {
        // console.log('saveConfiguration form', rows);
        let wrapper = {};
        rows.forEach(({name, value}) => {
            if (name === 'required' && value === '') {
                value = false;
            }
            wrapper = {
                ...wrapper,
                [name]: value
            }
        });
        save({
            ...wrapper,
            valid
        });
    };

    const render = () => {
        switch (viewMode) {
            case MODE_VIEW_PREVIEW:
            case MODE_VIEW_COMPILE:
                return <SliderCompile {...props}/>;
            case MODE_VIEW_READONLY:
                return <SliderCompile {...props} disabled={true}/>;
            case MODE_VIEW_TEMPLATE:
                return <SliderTemplate/>;
            case MODE_VIEW_CONFIGURATION:
                return <SliderConfigure {...props} defaultConfig={config()} onSave={saveConfiguration}/>;
            default:
                return <EmptyItemComponent/>;
        }
    };
    return render();
};

SliderComponent.propTypes = {
    save: PropTypes.func.isRequired,
    viewMode: PropTypes.number.isRequired
};

export default SliderComponent;
